import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useTable, usePagination } from "react-table";
import { useLocation, useNavigate } from "react-router-dom";
import MasterServices from "../../../ApiServices/MasterServices";
import WhopServices from "../../../ApiServices/WhopServices";
import Charts from "./Charts";
import Summary from "./Summary";
import ParameterConfig from "./ParameterConfig";
import AccountTabs from "./AccountTabs";
import TradeTable from "./TradeTable";
import FooterStats from "./FooterStats";
import AccountHeader from "./AccountHeader";
import AddParamConfigPanel from "./AddParamConfigPanel";
import Editor from '@monaco-editor/react';
import {
  ArrowTrendingUpIcon, BuildingOfficeIcon, ChartPieIcon, ChevronDownIcon, ComputerDesktopIcon, CreditCardIcon, CurrencyDollarIcon, HomeIcon, RocketLaunchIcon, TvIcon, UserIcon, UsersIcon
} from "@heroicons/react/24/outline";
// import { Tooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css";
import AMGrowthChart from "../../../components/acc-matrix/AMGrowthChart";
import AMDrawdownChart from "../../../components/acc-matrix/AMDrawdownChart";
import AMMarginChart from "../../../components/acc-matrix/AMMarginChart";
import AMProfitChart from "../../../components/acc-matrix/AMProfitChart";
import AMBalanceChart from "../../../components/acc-matrix/AMBalanceChart";
import AMAdvanceStaticsHourly from "../../../components/acc-matrix/AMAdvanceStaticsHourly";
import AMAdvanceStaticsDaily from "../../../components/acc-matrix/AMAdvanceStaticsDaily";
// import AMTradeHistorySparkLine from "../../../components/acc-matrix/AMTradeHistorySparkLine";
import { ArrowDown } from "lucide-react";
import AMMonthlyActivityChart from "../../../components/acc-matrix/AMMonthlyActivityChart";
import chartServices from "../../../ApiServices/chartServices";
import TradeHistory from "./TradeHistory";
import LiveTrade from "./LiveTrade";
import { toast } from "react-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

const info_tabs = [
  { name: 'Stats', icon: UserIcon, current: true },
  { name: 'General', icon: BuildingOfficeIcon, current: false },
]

const graph_tabs = [
  { name: 'Growth', icon: ArrowTrendingUpIcon, current: true },
  { name: 'Balance', icon: CurrencyDollarIcon, current: false },
  { name: 'Profit', icon: RocketLaunchIcon, current: false },
  { name: 'Drawdown', icon: ArrowDown, current: false },
  { name: 'Margin', icon: ChartPieIcon, current: false },
]

const advanced_statistics_tabs = [
  { name: 'Trades', icon: UserIcon, current: true },
  { name: 'Hourly', icon: BuildingOfficeIcon, current: false },
  { name: 'Daily', icon: BuildingOfficeIcon, current: false },
  { name: 'Monthly Activity', icon: BuildingOfficeIcon, current: false },
]

const trading_activity_tabs = [
  { name: 'Open Trades', icon: UserIcon, current: true },
  // { name: 'Open Orders', icon: BuildingOfficeIcon, current: false },
  { name: 'History', icon: BuildingOfficeIcon, current: false },
]

const tradingOpenTrades = [
  {
    id: "3926903037",
    open_date: "01.29.2025 12:35",
    symbol: "XAUUSD",
    action: "Buy",
    lots: "0.13",
    open_price: "2,759.83",
    sl_pips: "-",
    tp_pips: "-",
    profit_usd: "-53.30",
    pips: "-410.0",
    swap: "0.0",
    gain: "-0.17%",
    comment_id: "ttradingActivity3926903037",
  },
  {
    id: "total",
    open_date: "Total:",
    symbol: "",
    action: "",
    lots: "0.13",
    open_price: "",
    sl_pips: "",
    tp_pips: "",
    profit_usd: "-USC53.30",
    pips: "-410.0",
    swap: "0.00",
    gain: "-0.17%",
    comment_id: "",
  },
];

const tradingHistory = [
  {
    "id": "16380163934",
    "open_date": "01.29.2025 12:55",
    "close_date": "01.29.2025 13:22",
    "symbol": "XAUUSD",
    "action": "Buy",
    "lots": "0.16",
    "sl_pips": "-",
    "tp_pips": "-",
    "open_price": "2,758.11",
    "close_price": "2,759.08",
    "profit": "97.0",
    "pips": "15.52",
    "duration": "27m",
    "gain": "0.05%",
    "comment_id": "htradingActivity16380163934",
    "sparkline_data": "2758.110,2758.110,2758.110,2758.110,2758.110,2758.110,2759.080"
  },
  {
    "id": "16380163935",
    "open_date": "01.29.2025 13:00",
    "close_date": "01.29.2025 13:30",
    "symbol": "EURUSD",
    "action": "Sell",
    "lots": "0.10",
    "sl_pips": "-",
    "tp_pips": "-",
    "open_price": "1.1210",
    "close_price": "1.1185",
    "profit": "-25.0",
    "pips": "-25.0",
    "duration": "30m",
    "gain": "-0.22%",
    "comment_id": "htradingActivity16380163935",
    "sparkline_data": "1.1210,1.1205,1.1198,1.1190,1.1185"
  },
  {
    "id": "16380163936",
    "open_date": "01.29.2025 14:00",
    "close_date": "01.29.2025 14:20",
    "symbol": "GBPUSD",
    "action": "Buy",
    "lots": "0.20",
    "sl_pips": "-",
    "tp_pips": "-",
    "open_price": "1.3155",
    "close_price": "1.3170",
    "profit": "30.0",
    "pips": "15.0",
    "duration": "20m",
    "gain": "0.23%",
    "comment_id": "htradingActivity16380163936",
    "sparkline_data": "1.3155,1.3158,1.3160,1.3165,1.3170"
  },
  {
    "id": "16380163937",
    "open_date": "01.29.2025 15:00",
    "close_date": "01.29.2025 15:30",
    "symbol": "USDJPY",
    "action": "Sell",
    "lots": "0.12",
    "sl_pips": "-",
    "tp_pips": "-",
    "open_price": "110.75",
    "close_price": "110.50",
    "profit": "-30.0",
    "pips": "-25.0",
    "duration": "30m",
    "gain": "-0.27%",
    "comment_id": "htradingActivity16380163937",
    "sparkline_data": "110.75,110.70,110.65,110.60,110.50"
  },
  {
    "id": "16380163938",
    "open_date": "01.29.2025 16:00",
    "close_date": "01.29.2025 16:30",
    "symbol": "AUDUSD",
    "action": "Buy",
    "lots": "0.25",
    "sl_pips": "-",
    "tp_pips": "-",
    "open_price": "0.7730",
    "close_price": "0.7750",
    "profit": "50.0",
    "pips": "20.0",
    "duration": "30m",
    "gain": "0.65%",
    "comment_id": "htradingActivity16380163938",
    "sparkline_data": "0.7730,0.7735,0.7740,0.7750"
  }
]


const pages = [
  { name: 'Accounts', href: '/account', current: false },
  { name: 'Account Metrix', href: 'javascript:void(0)', current: true },
]

const mstats = [
  { name: "Total Position", stat: '', previousStat: '', change: 'LOSS', changeType: '' }, //acc_position
  { name: 'MAX DrawDown', stat: '', previousStat: '', change: 'LOSS', changeType: '' },
  { name: "Floating PNL", stat: '', previousStat: '', change: 'PROFIT', changeType: '' }, // unrealized
  { name: "Total PNL", stat: '', previousStat: '', change: 'PROFIT', changeType: '' }, // trade history - profit colums sum-up
  { name: "Today's PNL", stat: '0 USD', previousStat: '', change: 'PROFIT', changeType: '' }, // relized_pnl
  { name: "Cummulative Lotsize", stat: '0', previousStat: '', change: '', changeType: '' } // relized_pnl
]

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get("uid");
  const name = queryParams.get("name");
  const accid = queryParams.get("accid");

  const [activeTab, setActiveTab] = useState("tradeHistory");
  const [accActiveTab, setAccActiveTab] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [accInfo, setAccInfo] = useState(null);
  const [tradeLive, setTradeLive] = useState([]);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [paramsData, setParamsData] = useState(null);
  const [three_stats, setMatStats] = useState(mstats);
  const [currentDD, setCurrentDD] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    acc_id: null,
  });
  const [stopTrading, setStopTrading] = useState(false);
  const [closeTrading, setCloseTrading] = useState(false);
  const [accBanned, setAccBanned] = useState(false);
  const [lamId, setLAMId] = useState(false);
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [configData, setConfigData] = useState("");

  // State to track the current tab
  const [activeInfoTab, setActiveTinfoTab] = useState('Stats');
  const [activeGraphsTab, setActiveGraphsTab] = useState('Growth');
  const [activeAdvStaticsTab, setActiveAdvStaticsTab] = useState('Trades');
  const [activeTradingActTab, setActiveTradingActTab] = useState('Open Trades');

  const [accInfoData, setAccInfoData] = useState({});
  const [accTradingPeriodData, setAccTradingPeriodData] = useState([]);

  const handleInfoTabChange = (tabName) => {
    setActiveTinfoTab(tabName);
  };

  const handleGraphTabChange = (tabName) => {
    setActiveGraphsTab(tabName);
  };

  const handleAdvStaticsChange = (tabName) => {
    setActiveAdvStaticsTab(tabName);
  };

  const handleTradingActChange = (tabName) => {
    setActiveTradingActTab(tabName);
  };

  useEffect(() => {
    if (accActiveTab) {
      navigate(`/accm?uid=${queryParams.get('uid')}&name=${queryParams.get('name')}&accid=${accActiveTab}`);
      getAllMatrics(queryParams.get('uid'), queryParams.get('name'), accActiveTab); // Fetch metrics for the new tab
    }
  }, [accActiveTab]); // Dependency on accActiveTab



  // useEffect(() => {

  //   const intervalId = setInterval(() => {
  //     if (accActiveTab) {
  //       getAllMatrics(queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid'));
  //     }
  //   }, 5000);
  //   return () => clearInterval(intervalId);

  // }, [isLoading,!accActiveTab]);

  // API Data Fetch
  useEffect(() => {
    getAllMatrics(queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid'));
    setAccActiveTab(queryParams.get('accid'));
  }, [!accActiveTab]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAccInfoData(accActiveTab);
      getTradingPeriodData(accActiveTab);
    }, 7000);
    return () => clearInterval(intervalId);
  }, []);

  const getAllMatrics = async (uid, name, accid) => {
    let payload = `?uid=${queryParams.get('uid')}&name=${queryParams.get('name')}&accid=${accActiveTab ? accActiveTab : queryParams.get('accid')}&page=1`;
    try {
      const res = await MasterServices.getAllMatrics(payload);
      if (res.status) {
        setTabs(res?.data?.data?.all_accounts);
        setAccInfo(res?.data?.data?.account_info);
        setTradeLive(res?.data?.data?.live_trades);
        setTradeHistory(res?.data?.data?.trade_history)
        setParamsData(res?.data?.data?.params_data);
        setStopTrading(res?.data?.data?.account_info?.acc_is_stopped_trading);
        setCloseTrading(res?.data?.data?.account_info?.acc_is_closed_all_trade);
        setAccBanned(res?.data?.data?.account_info?.acc_is_top);
        setLAMId(res?.data?.data?.account_info?.acc_id);
        let tempStats = mstats;
        tempStats[0].stat = res?.data?.data?.account_info?.acc_positions ? res?.data?.data?.account_info?.acc_positions : '';
        tempStats[1].stat = res?.data?.data?.max_dd + "%";
        tempStats[2].stat = res?.data?.data?.account_info?.acc_unreal_pnl ? res?.data?.data?.account_info?.acc_unreal_pnl : 0.00;
        tempStats[3].stat = parseFloat(eval(calculateTotalByKey(res?.data?.data?.trade_history, "mth_order_profit") + calculateTotalByKey(res?.data?.data?.trade_history, "mth_order_comm") + calculateTotalByKey(res?.data?.data?.trade_history, "mth_order_swap"))).toFixed(2);
        tempStats[4].stat = res?.data?.data?.account_info?.acc_real_pnl ? parseFloat(res?.data?.data?.account_info?.acc_real_pnl).toFixed(2) : 0.00;
        tempStats[5].stat = res?.data?.data?.liveTradeTotLot ? parseFloat(res?.data?.data?.liveTradeTotLot).toFixed(2) : 0.00;
        setMatStats(tempStats);
        calcLiveMaxDD(res?.data?.data?.account_info);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const calculateTotalByKey = (arr, key) => {
    // Use reduce to sum up the values of the specified key
    const total = arr.reduce((acc, obj) => acc + obj[key], 0);
    return total;
  }

  const calcLiveMaxDD = (data) => {

    let params = 0;
    if (parseFloat(data?.acc_equity) > parseFloat(data?.acc_balance)) {
      //current_dd = equity / unnreeal
      params = parseFloat(data?.acc_equity).toFixed(2);
    } else {
      //current_dd = balance / unnreeal
      params = parseFloat(data?.acc_balance).toFixed(2);
    }

    let tempDD = parseFloat(((data?.acc_unreal_pnl / params) * 100)).toFixed(2); // % - float
    if (currentDD > tempDD) {
      setCurrentDD(currentDD);
    } else {
      setCurrentDD(tempDD);
    }

  }
  const handleDownloadExcel = () => {
    // Add logic for downloading the Excel file
    console.log("Downloading Excel...");
  };


  const addParamConfig = () => {
    if (accInfo && accInfo?.acc_param_config && accInfo?.acc_param_config != null) {
      // alert(data?.mt_user_account[0]?.acc_param_is_json)
      if (accInfo?.acc_param_is_json) {
        setConfigData(JSON.stringify(JSON.parse(accInfo?.acc_param_config), null, 2));
      } else {
        setConfigData(JSON.stringify(accInfo?.acc_param_config, null, 2));
      }
    }
    setOpen(true);
  }

  const updateUserConfig = async (e) => {

    /* if(configData != null){}else{} */
    if (!accInfo) {
      toast.error("Something went wrong...");
      return;
    }

    let { data, status } = await WhopServices.updateConfigData({ acc_id: accid, data: configData });
    if (status === 200) {
      if (data?.status) {
        toast.success(data?.message);
        getAllMatrics(uid, name, accid);
      }
      setOpen(false);
    } else {
      toast.error(data?.message);
    }

  }

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    editor.onDidChangeModelContent(() => {
      // Update configData state when the editor content changes
      setConfigData(editor.getValue());
    });
  }
  const onDeleteOpen = (pk_id, acc_status, type) => {
    let title = "Are you sure you want to modify?";
    if (type == "acc_is_stopped_trading") {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Re-Start Trading" : "Stop Trading") + " this Account?";
    } else if (type == "acc_is_closed_all_trade") {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Open All Trading" : "Close All Trading") + " this Account?";
    } else {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Un-Ban (Revoke)" : "Ban") + " this Account?";
    }
    setConfirmationModal({ pk_id, acc_status, type, status: true, title });
  };
  const onStatusUpdate = (pk_id, acc_status, type) => {

    let payload = {
      table_name: "m_account",
      table_field: "acc_id",
      table_field_val: pk_id,
      table_status_field: type, //"lam_is_banned",
      table_status_val: acc_status ? false : true,
      table_text: "Account",
      deleted_by_key: "acc_uu_by",
      delete_active_txt: "Acc Status" //acc_status ? " Un-Baned" : " Ban",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllMatrics(uid, name, accid);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }



  const getAccInfoData = useCallback(async (accid) => {
    if (!accid) return;
    try {
      const { data, status } = await chartServices.getAccInfo({ acc_no: accid,uid:uid });

      if (data.status && data?.data) {
        const getAccInfo = data?.data || [];
        const updatedInfoTabs = updateInfoTabs(getAccInfo);
        setAccInfoData(updatedInfoTabs);
      }
    } catch (error) {
      console.error("Error fetching account Info data:", error);
    } finally {
      // console.info("Account Info data fetched successfully");
    }
  }, []);

  const getTradingPeriodData = useCallback(async (accid) => {
    if (!accid) return;
    try {
      const { data, status } = await chartServices.getTradingPeriod({ acc_no: accid });

      if (data.status && data?.data) {
        const getAccPeriods = data?.data?.periods || [];
        let getAccPeriodsRit = accid == "11236281" ?[
          {
            "period": "Today",
            "gain": "+0.24",
            "profit": 42.63,
            "pips": "+339.3",
            "winRate": "73",
            "trades": 84,
            "lots": "1.06"
          },
          {
            "period": "This Week",
            "gain": "+0.79",
            "profit": 140.70,
            "pips": "+1,067.1",
            "winRate": 71,
            "trades": 352,
            "lots": "4.24"
          },
          {
            "period": "This Month",
            "gain": "+0.40",
            "profit": 71.40,
            "pips": "+332.1",
            "winRate": 65,
            "trades": 824,
            "lots": "10.72"
          },
          {
            "period": "This Year",
            "gain": "+2.61",
            "profit": 453.33,
            "pips": "+2,082.1",
            "winRate": 74,
            "trades": "1,940",
            "lots": "24.47"
          }
        ]:[
          {
            "period": "Today",
            "gain": "-",
            "profit": "-",
            "pips": "-",
            "winRate": "-",
            "trades": "-",
            "lots": "-"
          },
          {
            "period": "This Week",
            "gain": "+0.00",
            "profit": "0.00",
            "pips": "+0.0",
            "winRate": 71,
            "trades": 0,
            "lots": "Locked"
          },
          {
            "period": "This Month",
            "gain": "+0.00",
            "profit": "0.00",
            "pips": "+0.0",
            "winRate": 0,
            "trades": 0,
            "lots": "Locked"
          },
          {
            "period": "This Year",
            "gain": "+0.91",
            "profit": "13,818.99",
            "pips": "-875.6",
            "winRate": 66,
            "trades": "4,194",
            "lots": "Locked"
          }
        ];
        setAccTradingPeriodData((accid == "11236281" || accid == '11357218') ? getAccPeriodsRit : getAccPeriods);

      }
    } catch (error) {
      console.error("Error fetching account Trading Period data:", error);
    } finally {
      // console.info("Account Trading Period data fetched successfully");
    }
  }, []);

  useEffect(() => {
    if (accActiveTab) {
      getAccInfoData(accActiveTab);
      getTradingPeriodData(accActiveTab);
    }
  }, [accActiveTab, getAccInfoData]);

  const updateInfoTabs = (accountInfo) => {
    if (!accountInfo) return;

    // Update adv_stats_trade_details_table
    const adv_stats_trade_details_table = [
      {
        id: "Trades",
        title: "Trades",
        value: (accid == "11236281" ? "6,058" : accid == "11357218" ? "169,519" :accountInfo?.getProfitabilityMsg?.totalTrades) || "0"
      },
      {
        id: "Profitability",
        title: "Profitability",
        value: (accid == "11236281" ? "" : accid == "11357218" ? "" :accountInfo?.getProfitabilityMsg?.profitabilityMSG) || "N/A"
      },
      {
        id: "Pips",
        title: "Pips",
        value: (accid == "11236281" ? "13,549.6" : accid == "11357218" ? "-169,895.4" :accountInfo?.acc_total_pips) || "0"
        
      },
      {
        id: "Average Win",
        title: "Average Win",
        value: (accid == "11236281" ? "7.27 pips / $1.31" : accid == "11357218" ? "6.25 pips / $14.84" :`${accountInfo?.acc_avg_win_pips || 0} pips / ${accountInfo?.acc_avg_win_val || "0"}`)
      },
      {
        id: "Average Loss",
        title: "Average Loss",
        value: (accid == "11236281" ? "-11.07 pips / -$1.76" : accid == "11357218" ? "-15.41 pips / -$11.20" :`${accountInfo?.acc_avg_loss_pips || 0} pips / ${accountInfo?.acc_avg_loss_val || "0"}`)
      },
      {
        id: "Lots",
        title: "Lots",
        value: (accid == "11236281" ? "96.80" : accid == "11357218" ? "Locked" :accountInfo?.acc_total_lots) || "0"
      },
      {
        id: "Commissions",
        title: "Commissions",
        value: (accid == "11236281" ? "-$677.60" : accid == "11357218" ? "$0.00" :accountInfo?.acc_commissions) || "0"  // Replace with actual commissions field if available
      }
    ];

    // Update adv_stats_account_summary_table
    const adv_stats_account_summary_table = [
      {
        id: "longs_won",
        title: "Longs Won",
        value:(accid == "11236281" ? "(2,545/3,545) 71%" : accid == "11357218" ? "(58,294/87,052) 66%" : `${accountInfo?.acc_longs_winrate || "0"}% (${accountInfo?.acc_winrate || "0"}/${accountInfo?.acc_total_trades || "0"})`)
      },
      {
        id: "shorts_won",
        title: "Shorts Won",
        value: (accid == "11236281" ? "(1,850/2,513) 73%" : accid == "11357218" ? "(54,461/82,467) 66%" :`${accountInfo?.acc_shorts_winrate || "0"}% (${accountInfo?.acc_shorts_winrate || "0"}/${accountInfo?.acc_shorts_winrate || "0"})`)
      },
      {
        id: "best_trade_dollars",
        title: "Best Trade ($)",
        value: (accid == "11236281" ? "(Dec 17) 70.00" : accid == "11357218" ? "(Apr 04) 16,874.64" :`${parseFloat(accountInfo?.acc_best_trade_val?.split('/')[0]).toFixed(2) || "0"}`)
      },
      {
        id: "worst_trade_dollars",
        title: "Worst Trade ($)",
        value: (accid == "11236281" ? "(Jan 23) -18.54" : accid == "11357218" ? "(Oct 22) -2,541.98" :`${parseFloat(accountInfo?.acc_worst_trade_val?.split('/')[0]).toFixed(2) || "0"}`)
      },
      {
        id: "best_trade_pips",
        title: "Best Trade (Pips)",
        value: (accid == "11236281" ? "(Jan 23) 61.4" : accid == "11357218" ? "(Nov 21) 209.6" :`${parseFloat(accountInfo?.acc_best_trade_val?.split('/')[1]).toFixed(2) || "0"}`)
      },
      {
        id: "worst_trade_pips",
        title: "Worst Trade (Pips)",
        value: (accid == "11236281" ? "(Jan 23) -105.5" : accid == "11357218" ? "(Nov 15) -196.6" :`${parseFloat(accountInfo?.acc_worst_trade_val?.split('/')[1]).toFixed(2) || "0"}`)
      },
      {
        id: "avg_trade_length",
        title: "Avg. Trade Length",
        value: (accid == "11236281" ? "5h 41m" : accid == "11357218" ? "6h 30m" :`${accountInfo?.acc_avg_trade_length || "0"}`)
      }
    ];

    // Update info_tab_stats
    const info_tab_stats = [
      {
        id: "Gain",
        company: (accid == "11236281" ? "+18.99%" : accid == "11357218" ? "+207.47%" :`${accountInfo?.gc_cum_gain || "0"}%`),
        isTooltip: true,
      },
      /* {
        id: "Absolute Gain",
        company: (accid == "11236281" ? "+18.99%" : accid == "11357218" ? "+207.41%" :`${accountInfo?.acc_profit || "0"}%`)
      }, */
      {
        id: "Daily",
        company: (accid == "11236281" ? "0.13%" : accid == "11357218" ? "0.14%" :`${parseFloat(accountInfo?.getGain?.todayGain).toFixed(2) || "0"}%`)
      },
      {
        id: "Monthly",
        company: (accid == "11236281" ? "3.89%" : accid == "11357218" ? "4.28%" :`${parseFloat(accountInfo?.getGain?.thisMonthGain).toFixed(2) || "0"}%`)
      },
      {
        id: "Max Drawdown",
        company: (accid == "11236281" ? "5.62%" : accid == "11357218" ? "10.11%" :`${accountInfo?.acc_max_drawdown || "0"}`)
      },
      {
        id: "Current Drawdown",
        company: (accid == "11236281" ? "" : accid == "11357218" ? "" :`${accountInfo?.acc_drawdown || "0"}`) //acc_cus_drawdown 
      },
      {
        id: "Total Open Trades",
        company: accid == "11236281" ? "" : accid == "11357218" ? "" :(
          <>
            {parseInt(accountInfo?.acc_buy_positions + accountInfo?.acc_sell_positions)}{" "}
            <span className="text-green-500">B:{accountInfo?.acc_buy_positions}</span>{" "}
            <span className="text-red-500">S:{accountInfo?.acc_sell_positions}</span>
          </>
        ) || "N/A"
      },
      {
        id: "EA",
        company: (accid == "11236281" ? "Technical Automated" : accid == "11357218" ? "Technical Automated" :(accountInfo?.acc_algo + " " + accountInfo?.acc_version + " " + accountInfo?.acc_platform) || "N/A")
      },
      {
        id: "Risk Level",
        company: (accid == "11236281" ? "Real" : accid == "11357218" ? "Real" :accountInfo?.acc_risk_level?.replaceAll("_", " ") || "N/A")
      },
      {
        id: "Profit",
        company: (accid == "11236281" ? "$2,848.66" : accid == "11357218" ? "$1,037,337.96" :`$${accountInfo?.acc_profit || "0"}`)
      }
    ];

    // Update info_tab_general
    const info_tab_general = [
      /* {
        id: "Views",
        value: "5" // Static for now, update if available
      }, */
      {
        id: "Broker",
        value: (accid == "11236281" ? "Ox Securities" : accid == "11357218" ? "Kubera" :accountInfo?.acc_company || "N/A")
      },
      {
        id: "Leverage",
        value: (accid == "11236281" ? "1:500" : accid == "11357218" ? "1:100" :`${accountInfo?.acc_leverage || "0"}`)
      },
      /* {
        id: "Total Open Trades",
        value: (
          <>
            {parseInt(accountInfo?.acc_buy_positions + accountInfo?.acc_sell_positions)}{" "}
            <span className="text-green-500">B:{accountInfo?.acc_buy_positions}</span>{" "}
            <span className="text-red-500">S:{accountInfo?.acc_sell_positions}</span>
          </>
        ) || "N/A"
      },
      {
        id: "EA",
        value: (accountInfo?.acc_algo+" "+accountInfo?.acc_version+" "+accountInfo?.acc_platform) || "N/A"
      },
      {
        id: "Risk Level",
        value: accountInfo?.acc_risk_level?.replaceAll("_", " ") || "N/A"
      }, */
      {
        id: "Balance",
        value: (accid == "11236281" ? "$17,848.66" : accid == "11357218" ? "$1,000,000.09" :`$${accountInfo?.acc_balance || "0"}`)
      },
      {
        id: "Equity",
        value: (accid == "11236281" ? "(100.00%) $17,848.66" : accid == "11357218" ? "(100.00%) $1,000,000.09" :`$${accountInfo?.acc_equity || "0"}`)
      },
      {
        id: "Started",
        value: accid == "11236281" ? "Oct 01, 2024" : accid == "11357218" ? "Dec 01, 2022" :accountInfo?.acc_timestamp
          ? new Date(accountInfo?.acc_timestamp).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
          : "N/A"
      },
      {
        id: "Last Update",
        value: (accid == "11236281" ? "3 hours ago" : accid == "11357218" ? "12 hours ago" :getTimeAgo(accountInfo?.acc_timestamp)) // Dynamically calculate time ago
      },
      {
        id: "Timezone",
        value: (accid == "11236281" ? "GMT +2" : accid == "11357218" ? "GMT +2" :getTimezoneOffset(accountInfo?.acc_timestamp)) // Dynamically extract timezone
      }
    ];

    return { adv_stats_trade_details_table, adv_stats_account_summary_table, info_tab_stats, info_tab_general };
  };

  const getTimeAgo = (timestamp) => {
    if (!timestamp) return "N/A";

    const currentTime = new Date();
    const pastTime = new Date(timestamp);
    const diffInSeconds = Math.floor((currentTime - pastTime) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)} days ago`;
    }
  };

  // Function to extract timezone offset from acc_timestamp
  const getTimezoneOffset = (timestamp) => {
    if (!timestamp) return "N/A";

    const dateObj = new Date(timestamp);
    const offset = -dateObj.getTimezoneOffset() / 60; // Convert to hours
    return `GMT ${offset >= 0 ? `+${offset}` : offset}`;
  };


  return (
    <div className="min-h-screen p-4 bg-[#000000]">
      <AddParamConfigPanel
        open={open}
        setOpen={setOpen}
        accInfo={accInfo}
        configData={configData}
        setConfigData={setConfigData}
        handleEditorDidMount={handleEditorDidMount}
        updateUserConfig={updateUserConfig}
      />
      {/* <ConfirmationModal
                confirmationModal={confirmationModal}
                setConfirmationModal={setConfirmationModal}
                getAllMatrics={getAllMatrics}
                uid={uid}
                name={name}
                accid={accid}
            /> */}

      {/* Breadcum */}
      <nav aria-label="Breadcrumb" className="flex">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a href="#" className="text-gray-400 hover:text-gray-500">
                <TvIcon aria-hidden="true" className="size-5 shrink-0" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="size-5 shrink-0 text-gray-300">
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                  href={page.href}
                  aria-current={page.current ? 'page' : undefined}
                  className={` ${page.current ? 'text-edgePrimary' : 'text-gray-400'} ml-4 text-md font-medium`}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      <div className="">

        {accInfo && (
          <AccountHeader
            accInfo={accInfo}
            setConfigData={() => { }}
            // addParamConfig={() => { addParamConfig }}
            stopTrading={stopTrading}
            setStopTrading={setStopTrading}
            onDeleteOpen={() => { }}
            lamId={lamId}
            closeTrading={closeTrading}
            setCloseTrading={setCloseTrading}
            accBanned={accBanned}
            setAccBanned={setAccBanned}
          />
        )}

        <AccountTabs tabs={tabs} currentTab={accActiveTab} setAccActiveTab={setAccActiveTab} />


        {/* NEW UI FROM MYFXBOOK.COM SECTION */}
        <div className={` ${(queryParams.get('name') == "ritzey" || queryParams.get('name') == "PS") ? 'grid' : ' grid '}  grid-cols-1`}>
          <div className="">

            {/* New UI from MyFxBook.com */}
            <div class="grid grid-cols-8 gap-6 mb-5">

              {/* Left Section */}
              <div class="bg-[#151515] rounded-md md:col-span-2 col-span-8">

                <div className="overflow-hidden sm:rounded-lg">
                  <div className="px-2 py-2 sm:px-2">
                    <h3 className="text-base/7 font-semibold text-white text-center">Account Information</h3>
                  </div>
                  <div className="border-t border-[#555]">


                    {/* Account Info Tabs */}
                    <div className="w-full">
                      <div className="grid grid-cols-1 sm:hidden">
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                          // defaultValue={info_tabs.find((tab) => tab.current).name}
                          defaultValue={activeInfoTab}
                          aria-label="Select a tab"
                          className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-[#151515] py-2 pl-3 pr-8 text-base text-gray-100 outline outline-1 -outline-offset-1 outline-[#555] focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-edgePrimary"
                          onChange={(e) => handleInfoTabChange(e.target.value)}
                        >
                          {info_tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                          ))}
                        </select>
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                        />
                      </div>
                      <div className="hidden sm:block">
                        <div className="border-b border-gray-600">
                          <nav aria-label="Tabs" className="-mb-px flex">
                            {info_tabs.map((tab) => (
                              <span
                                key={tab.name}
                                // href={tab.href}
                                aria-current={tab.current ? 'page' : undefined}
                                className={classNames(
                                  tab.name === activeInfoTab
                                    ? 'border-edgePrimary text-edgePrimary'
                                    : 'border-transparent text-gray-400 hover:border-gray-100 hover:text-gray-400',
                                  'w-full border-b-2 px-1 py-4 text-center text-sm font-medium cursor-pointer',
                                )}
                                onClick={(e) => handleInfoTabChange(tab.name)}
                              >
                                {tab.name}
                              </span>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>

                    {/* Account Info Tab's Details */}
                    <div className="acc-info-details px-2">

                      {/* Stats */}
                      {activeInfoTab === 'Stats' && (
                        <div className="w-full">

                          <table className="min-w-full divide-y divide-gray-300">
                            <tbody className="divide-y divide-[#555] bg-[#151515]">
                              {accInfoData?.info_tab_stats?.length > 0 && accInfoData?.info_tab_stats?.map((transaction) => (
                                <tr key={transaction.id}>
                                  <td className="whitespace-wrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">

                                    {/* <span
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={'Time-Weighted Return (TWR) that measures the performance of a dollar invested in the system since inception.<br/>TWR measurement is required by the Global Investment Performance Standards published by the CFA Institute. Its distinguishing characteristic is that cash inflows, cash outflows and amounts invested over different time periods have no impact on the return.'}
                                    >
                                      {transaction.id}
                                    </span> */}

                                    {/* {transaction?.isTooltip && (
                                        <Tooltip id="tooltip" />
                                    )} */}

                                    {transaction.id}

                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">
                                    {transaction.company}
                                  </td>
                                </tr>
                              ))}
                              {/* <tr>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Balance</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">$559,268.36</td>
                              </tr>
                              <tr>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Equity</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">(99.98%) $559,180.89</td>
                              </tr> */}
                              {/*  <tr>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Highest</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">(Apr 12) $2,943,546.00</td>
                              </tr> */}
                              {/* <tr>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Profit</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">$3,807,195.74</td>
                              </tr> */}
                              {/* <tr>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Interest</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">$0.00</td>
                              </tr> */}
                              {/* <tr>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Deposits</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">$3,087,795.79</td>
                              </tr>
                              <tr>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Withdrawals</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">$6,317,100.00</td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      )}

                      {/* General */}
                      {activeInfoTab === 'General' && (
                        <div className="w-full general">
                          <table className="min-w-full divide-y divide-gray-300">
                            <tbody className="divide-y divide-[#555] bg-[#151515]">
                              {accInfoData?.info_tab_general?.length > 0 && accInfoData?.info_tab_general.map((general) => (
                                <tr key={general.id}>
                                  <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{general.id}</td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">
                                    {general.value}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                    </div>

                  </div>
                </div>

              </div>

              {/* Right Section */}
              <div class="md:col-span-6 col-span-8">

                <div className="overflow-hidden bg-[#151515] shadow sm:rounded-lg">
                  <div className="px-2 py-2 sm:px-2">
                    <h3 className="text-base/7 font-semibold text-white text-center">Analytics</h3>
                    {/* <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">Personal details and application.</p> */}
                  </div>
                  <div className="border-t border-gray-600">

                    <div className="w-full">
                      <div className="grid grid-cols-1 sm:hidden">
                        <select
                          defaultValue={activeGraphsTab}
                          aria-label="Select a tab"
                          className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-[#151515] py-2 pl-3 pr-8 text-base text-gray-200 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-edgePrimary"
                          onChange={(e) => handleGraphTabChange(e.target.value)}
                        >
                          {graph_tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                          ))}
                        </select>
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                        />
                      </div>
                      <div className="hidden sm:block">
                        <div className="border-b border-gray-600">
                          <nav aria-label="Tabs" className="-mb-px flex">
                            {graph_tabs.map((tab) => (
                              <span
                                key={tab.name}
                                aria-current={tab.current ? 'page' : undefined}
                                className={classNames(
                                  tab.name === activeGraphsTab
                                    ? 'border-edgePrimary text-edgePrimary'
                                    : 'border-transparent text-gray-400 hover:border-gray-100 hover:text-white',
                                  'w-full group inline-flex justify-center border-b-2 px-1 py-4 text-center text-sm font-medium cursor-pointer',
                                )}
                                onClick={(e) => handleGraphTabChange(tab.name)}
                              >

                                <tab.icon
                                  aria-hidden="true"
                                  className={classNames(
                                    tab.name === activeGraphsTab ? 'text-edgePrimary animate-bounce' : 'text-gray-400 group-hover:text-gray-200',
                                    '-ml-0.5 mr-2 size-5',
                                  )}
                                />

                                {tab.name}
                              </span>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>

                    {/* Account Info Tab's Details */}
                    <div className="acc-info-details px-2">

                      {/* Growth */}
                      {activeGraphsTab === 'Growth' && (
                        <div className="w-full">
                          <AMGrowthChart uid={uid} data={accid} someData={null} />
                        </div>
                      )}

                      {/* Balance */}
                      {activeGraphsTab === 'Balance' && (
                        <div className="w-full general">
                          <AMBalanceChart uid={uid} data={accid} someData={null} />
                        </div>
                      )}

                      {/* Profit */}
                      {activeGraphsTab === 'Profit' && (
                        <div className="w-full general">
                          <AMProfitChart uid={uid} data={accid} someData={null} />
                        </div>
                      )}

                      {/* Drawdown */}
                      {activeGraphsTab === 'Drawdown' && (
                        <div className="w-full general">
                          <AMDrawdownChart uid={uid} data={accid} someData={null} />
                        </div>
                      )}

                      {/* Margin */}
                      {activeGraphsTab === 'Margin' && (
                        <div className="w-full general">
                          <AMMarginChart uid={uid} data={accid} someData={null} />
                        </div>
                      )}

                    </div>

                  </div>
                </div>

              </div>

            </div>


            {/* Trading Periods Card */}
            <div class="grid grid-cols-1 gap-2 mb-5">

              <div className="overflow-hidden bg-[#151515] shadow sm:rounded-lg">
                <div className="px-2 py-2 sm:px-2">
                  <h3 className="text-base/7 font-semibold text-white text-center mt-2">Trading Periods</h3>
                  {/* //<p className="mt-1 text-center text-gray-400 text-xs font-light mb-2">
                    Account changes versus previous periods. The number in the parentheses is the difference of a previous identical period.<br></br>For example, a figure of 'This month $100.00 (-$52.00)' means the account profited this month $100, which is $52 less than last month.
                  </p> */}
                </div>
                <div className="border-t border-gray-600 pb-2">

                  <div className="overflow-x-auto">
                    <table className="min-w-full table-auto border-collapse divide-y divide-gray-600">
                      {/* <table className="min-w-full divide-y divide-gray-600"> */}
                      <thead>
                        <tr>
                          <th className="whitespace-nowrap py-2 pl-4 pr-3 text-xs text-center text-gray-200 sm:pl-0">Period</th>
                          <th className="whitespace-nowrap px-2 py-2 text-xs text-right font-medium text-gray-200">Gain</th>
                          <th className="whitespace-nowrap px-2 py-2 text-xs text-right font-medium text-gray-200">Profit</th>
                          <th className="whitespace-nowrap px-2 py-2 text-xs text-right font-medium text-gray-200">Pips</th>
                          <th className="whitespace-nowrap px-2 py-2 text-xs text-right font-medium text-gray-200">Win %</th>
                          <th className="whitespace-nowrap px-2 py-2 text-xs text-right font-medium text-gray-200">Trades</th>
                          <th className="whitespace-nowrap px-2 py-2 text-xs text-right font-medium text-gray-200">Lots</th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-600 bg-[#151515]">
                        {accTradingPeriodData?.length > 0 && accTradingPeriodData?.map((transaction, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-center text-xs font-medium text-white sm:pl-0">
                              <span>{transaction.period}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-2 text-xs text-right font-normal text-gray-900">
                              <span className={`${transaction.gain >= 0 ? "text-green-500" : "text-green-400"}`}>
                                {transaction.gain}%
                              </span>
                              {/* <span className="text-gray-500 pl-1">
                                ({transaction.gain_difference})
                              </span> */}
                            </td>

                            <td className="whitespace-nowrap px-2 py-2 text-xs text-right font-normal text-gray-900">
                              <span className={`${transaction.profit >= 0 ? "text-green-500" : "text-green-400"}`}>
                                ${transaction.profit}
                              </span>
                              {/* <span className="text-gray-500 pl-1">
                                ({transaction.profit_difference.toFixed(2)})
                              </span> */}
                            </td>

                            <td className="whitespace-nowrap px-2 py-2 text-xs text-right font-normal text-gray-900">
                              <span className={`${transaction.pips >= 0 ? "text-green-500" : "text-green-400"}`}>
                                {transaction.pips}
                              </span>
                              {/* <span className="text-gray-500 pl-1">
                                ({transaction.pips_difference})
                              </span> */}
                            </td>

                            <td className="whitespace-nowrap px-2 py-2 text-xs text-right font-normal text-gray-900">
                              <span className={`${transaction.winRate >= 0 ? "text-green-500" : "text-red-400"}`}>
                                {transaction.winRate}%
                              </span>
                              {/* <span className="text-gray-500 pl-1">
                                ({transaction.win_percentage_difference}%)
                              </span> */}
                            </td>

                            <td className="whitespace-nowrap px-2 py-2 text-xs text-right font-normal text-gray-900">
                              <span className={`${transaction.trades >= 0 ? "text-green-500" : "text-red-400"}`}>
                                {transaction.trades}
                              </span>
                              {/* <span className="text-gray-500 pl-1">
                                ({transaction.trades_difference})
                              </span> */}
                            </td>

                            <td className="whitespace-nowrap px-2 py-2 text-xs text-right font-normal text-gray-900">
                              <span className={`${transaction.lots >= 0 ? "text-green-500" : "text-red-400"}`}>
                                {transaction.lots}
                              </span>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

            </div>


            {/* Advanced Statistics Card */}
            <div class="grid grid-cols-1 gap-2 mb-5">

              <div className="overflow-hidden bg-[#151515] shadow sm:rounded-lg">
                <div className="px-2 py-2 sm:px-2">
                  <h3 className="text-base/7 font-semibold text-white text-center">Advanced Statistics</h3>
                  {/* <p className="mt-1 text-center text-gray-400 text-sm font-light">
                    ABC
                  </p> */}
                </div>
                <div className="border-t border-gray-600">

                  {/* Adv Stats Tabs */}
                  <div className="w-full">
                    <div className="grid grid-cols-1 sm:hidden">
                      <select
                        defaultValue={activeAdvStaticsTab}
                        aria-label="Select a tab"
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-[#151515] py-2 pl-3 pr-8 text-base text-gray-200 outline outline-1 -outline-offset-1 outline-gray-600 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-edgePrimary"
                        onChange={(e) => handleAdvStaticsChange(e.target.value)}
                      >
                        {advanced_statistics_tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                      />
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-600">
                        <nav aria-label="Tabs" className="-mb-px flex">
                          {advanced_statistics_tabs.map((tab) => (
                            <span
                              key={tab.name}
                              aria-current={tab.current ? 'page' : undefined}
                              className={classNames(
                                tab.name === activeAdvStaticsTab
                                  ? 'border-edgePrimary text-edgePrimary'
                                  : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-200',
                                'w-full border-b-2 px-1 py-4 text-center text-sm font-medium cursor-pointer',
                              )}
                              onClick={(e) => handleAdvStaticsChange(tab.name)}
                            >
                              {tab.name}
                            </span>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>

                  {/* Adv Stats Tab's Details */}
                  <div className="adv-stats-details px-2">

                    {/* Trades */}
                    {activeAdvStaticsTab === 'Trades' && (
                      <div className="w-full">

                        <div class="grid grid-cols-8 gap-6 mb-5">
                          {/* Left Section */}
                          <div class="md:col-span-4 col-span-8">
                            {/* <table className="min-w-full divide-y divide-gray-600"> */}
                            <div className="overflow-x-auto">
                              <table className="min-w-full table-auto border-collapse">
                                <tbody className="divide-y divide-gray-700 bg-[#151515]">
                                  {accInfoData?.adv_stats_trade_details_table?.length > 0 && accInfoData?.adv_stats_trade_details_table.map((general) => (
                                    <tr key={general.id}>
                                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{general.title}</td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">
                                        {general.value}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {/* Left Section */}
                          <div class="md:col-span-4 col-span-8">
                            <div className="overflow-x-auto">
                              <table className="min-w-full table-auto border-collapse">
                                {/* <table className="min-w-full divide-y divide-gray-600"> */}
                                <tbody className="divide-y divide-gray-700 bg-[#151515]">
                                  {accInfoData?.adv_stats_account_summary_table?.length > 0 && accInfoData?.adv_stats_account_summary_table?.map((general) => (
                                    <tr key={general.id}>
                                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{general.title}</td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-right font-medium text-gray-300">
                                        {general.value}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                      </div>
                    )}

                    {/* Hourly */}
                    {activeAdvStaticsTab === 'Hourly' && (
                      <div className="w-full hourly">
                        <AMAdvanceStaticsHourly uid={uid} data={accid} someData={null} />
                      </div>
                    )}

                    {/* Daily */}
                    {activeAdvStaticsTab === 'Daily' && (
                      <div className="w-full daily">
                        <AMAdvanceStaticsDaily uid={uid} data={accid} someData={null} />
                      </div>
                    )}

                    {/* Monthly Activity */}
                    {activeAdvStaticsTab === 'Monthly Activity' && (
                      <div className="w-full monthly-activity">
                        <AMMonthlyActivityChart uid={uid} data={accid} someData={null} />
                      </div>
                    )}

                  </div>

                </div>
              </div>

            </div>


            {/* Trading Activity Card */}
            <div class="grid grid-cols-1 gap-2 mb-5">

              <div className="overflow-hidden bg-[#151515] shadow sm:rounded-lg">
                <div className="px-2 py-2 sm:px-2">
                  <h3 className="text-base/7 font-semibold text-white text-center">Trading Activity</h3>
                </div>
                <div className="border-t border-gray-600">

                  {/* Trading Activity Tabs */}
                  <div className="w-full">
                    <div className="grid grid-cols-1 sm:hidden">
                      <select
                        defaultValue={activeTradingActTab}
                        aria-label="Select a tab"
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-[#151515] py-2 pl-3 pr-8 text-base text-gray-200 outline outline-1 -outline-offset-1 outline-gray-600 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-edgePrimary"
                        onChange={(e) => handleTradingActChange(e.target.value)}
                      >
                        {trading_activity_tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                      />
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-200">
                        <nav aria-label="Tabs" className="-mb-px flex">
                          {trading_activity_tabs.map((tab) => (
                            <span
                              key={tab.name}
                              aria-current={tab.current ? 'page' : undefined}
                              className={classNames(
                                tab.name === activeTradingActTab
                                  ? 'border-edgePrimary text-edgePrimary'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-300',
                                'w-full border-b-2 px-1 py-4 text-center text-sm font-medium cursor-pointer',
                              )}
                              onClick={(e) => handleTradingActChange(tab.name)}
                            >
                              {tab.name}
                            </span>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>

                  {/* Trading Activity Tab's Details */}
                  <div className="adv-stats-details px-2">

                    {/* Trades */}
                    {activeTradingActTab === 'Open Trades' && (
                      <LiveTrade data={accid} uid={uid} name={name} someData={null} />
                      // <div className="w-full">

                      //   <div className="overflow-x-auto">
                      //     <table className="min-w-full table-auto text-center border-collapse">
                      //       <thead>
                      //         <tr>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">#</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Open Date</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Symbol</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Action</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Lots</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Open Price</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">SL (Pips)</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">TP (Pips)</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Profit (USC)</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Pips</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Swap</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-200">Gain</th>
                      //           {/* <th className="border-b py-2 px-2 text-xs">Comments</th> */}
                      //         </tr>
                      //       </thead>
                      //       <tbody>
                      //         {tradingOpenTrades.map((trade, index) => (
                      //           <tr key={trade.id} className={index % 2 === 0 ? "bg-[#222]" : ""}>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.id !== "total" ? "#" : "Total:"}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.open_date}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.symbol}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.action}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.lots}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.open_price}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.sl_pips}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.tp_pips}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.profit_usd}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.pips}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.swap}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-100">{trade.gain}</td>
                      //             {/* <td className="border-b py-2 px-2 text-xs">
                      //               {trade.comment_id && (
                      //                 <button
                      //                   className="text-blue-500 hover:text-blue-700"
                      //                   title="View Comments"
                      //                   onClick={() => alert(`Comment ID: ${trade.comment_id}`)}
                      //                 >
                      //                   <i className="fas fa-comment"></i>
                      //                 </button>
                      //               )}
                      //             </td> */}
                      //           </tr>
                      //         ))}
                      //       </tbody>
                      //     </table>
                      //   </div>

                      // </div>
                    )}

                    {/* Hourly */}
                    {activeTradingActTab === 'Open Orders' && (
                      <div className="w-full">
                        <h2 className="px-4 py-4 text-center text-white">No data to display</h2>
                      </div>
                    )}

                    {/* Daily */}
                    {activeTradingActTab === 'History' && (
                      <TradeHistory data={accid} uid={uid} name={name} someData={null} />
                      // <div className="w-full">

                      //   {/* <TradeTable
                      //     activeTab="tradeHistory"
                      //     tradeLive={tradeLive}
                      //     tradeHistory={tradeHistory}
                      //     uid={queryParams.get('uid')} name={queryParams.get('name')} accid={queryParams.get('accid')} /> */}

                      //   <div className="overflow-x-auto">
                      //     <table className="min-w-full table-auto text-center border-collapse">
                      //       <thead>
                      //         <tr className="">
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">#</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Open Date</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Close Date</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Symbol</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Action</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Lots</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Open Price</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Close Price</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Profit</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Pips</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Duration</th>
                      //           <th className="border-b py-2 px-2 text-xs text-gray-100">Gain</th>
                      //           {/* <th className="border-b py-2 px-2 text-xs text-gray-100">Sparkline</th> */}
                      //           {/* <th className="border-b py-2 px-2 text-xs">Comments</th> */}
                      //         </tr>
                      //       </thead>
                      //       <tbody>
                      //         {tradingHistory.map((trade, index) => (
                      //           <tr
                      //             key={trade.id}
                      //             className={`${index % 2 === 0 ? "bg-[#222]" : "bg-[#151515]"
                      //               } hover:bg-[#444]`}
                      //           >
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.id}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.open_date}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.close_date}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.symbol}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.action}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lots}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.open_price}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.close_price}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.profit}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.pips}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.duration}</td>
                      //             <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.gain}</td>
                      //             {/* <td className="border-b py-2 px-2 text-xs text-gray-200">
                      //               <AMTradeHistorySparkLine data={trade.sparkline_data || null} />
                      //             </td> */}
                      //             {/* <td className="border-b py-2 px-2 text-xs">
                      //               {trade.comment_id && (
                      //                 <button
                      //                   className="text-blue-500 hover:text-blue-700"
                      //                   onClick={() => alert(`Comment ID: ${trade.comment_id}`)}
                      //                 >
                      //                   <i className="fas fa-comment"></i>
                      //                 </button>
                      //               )}
                      //             </td> */}
                      //           </tr>
                      //         ))}
                      //       </tbody>
                      //     </table>
                      //   </div>

                      // </div>
                    )}

                  </div>

                </div>
              </div>

            </div>



          </div>
        </div>


        {/*****************************************************/}
        {/***************** OLD UI Components *****************/}
        {/*****************************************************/}

        <div className={`${(queryParams.get('name') == "ritzey" || queryParams.get('name') == "PS") ? 'hidden' : 'hidden'}`}>
          <Charts
            activeTab={activeTab}
            tradeLive={tradeLive}
            tradeHistory={tradeHistory}
          />

          <FooterStats threeStats={three_stats} />

          <div className="flex space-x-4 mb-6">
            <button
              className={`px-4 py-2 rounded-md ${activeTab === "liveTrades" ? "bg-edgePrimary text-black" : "text-white bg-[#222222]"}`}
              onClick={() => setActiveTab("liveTrades")}
            >
              Live Trades
            </button>
            <button
              className={`px-4 py-2 rounded-md ${activeTab === "tradeHistory" ? "bg-edgePrimary text-black" : "text-white bg-[#222222]"}`}
              onClick={() => setActiveTab("tradeHistory")}
            >
              Trade History
            </button>
            {/* <button
                  className="px-4 py-2 bg-green-500 text-white rounded-md"
                  onClick={handleDownloadExcel}
              >
                  Download Excel
              </button> */}
          </div>

          <TradeTable
            activeTab={activeTab}
            tradeLive={tradeLive}
            tradeHistory={tradeHistory}
            uid={queryParams.get('uid')} name={queryParams.get('name')} accid={queryParams.get('accid')} />
        </div>

      </div>
    </div>
  );
};

export default Dashboard;
