import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Editor from "@monaco-editor/react";

const AddParamConfigPanel = ({
    open,
    setOpen,
    accInfo,
    configData,
    setConfigData,
    handleEditorDidMount,
    updateUserConfig,
}) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            updateUserConfig(e);
                                        }}
                                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                    >
                                        <div className="h-0 flex-1 overflow-y-auto">
                                            <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                                        Parameter Module - Details
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-indigo-300">
                                                        Account: {accInfo ? accInfo?.acc_no : "N/A"}
                                                    </p>
                                                    <p className="text-sm text-indigo-300">
                                                        Product:{" "}
                                                        {accInfo && accInfo?.user_plan_details
                                                            ? accInfo?.user_plan_details
                                                                  .map(
                                                                      (plan) =>
                                                                          plan?.product_relation?.prod_title || "N/A"
                                                                  )
                                                                  .join(", ")
                                                            : "N/A"}
                                                    </p>
                                                    <p className="text-sm text-indigo-300">
                                                        Name:{" "}
                                                        {accInfo
                                                            ? accInfo?.m_customer_relation?.cust_username +
                                                              " - " +
                                                              accInfo?.m_customer_relation?.cust_email
                                                            : "N/A"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between">
                                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                    <div className="space-y-6 pb-5 pt-6">
                                                        <div>
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium leading-6 text-gray-900"
                                                            >
                                                                Config Data - (JSON/TEXT)
                                                            </label>
                                                            <div className="mt-2">
                                                                <Editor
                                                                    options={{
                                                                        autoIndent: true,
                                                                        formatOnType: true,
                                                                        formatOnPaste: true,
                                                                        language: "json",
                                                                        autoClosingBrackets: true,
                                                                        inlineSuggest: true,
                                                                        automaticLayout: true,
                                                                    }}
                                                                    height="70vh"
                                                                    defaultLanguage="json"
                                                                    theme="vs-dark"
                                                                    defaultValue={configData}
                                                                    onMount={handleEditorDidMount}
                                                                    className="border bg-slate-400"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                onClick={() => setOpen(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="ml-4 inline-flex justify-center rounded-md bg-[#27ff10] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default AddParamConfigPanel;
