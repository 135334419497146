import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import Breadcrumb from "../../../components/Breadcrumb";
import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import paymentServices from "../../../ApiServices/paymentServices";
import Table from "../../../components/tables/table";
import { payment_columns } from "../../../components/tables/tableheader";
import CommonPagination from "../../../components/CommonPagination";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
} from "../../../helper/commonHelper";
import { useSearch } from "../../../components/tables/SearchContext";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";

const TransactionList = () => {
  let TranscationBASEURLFILE = process.env.REACT_APP_LOCAL_File_URL + "payments/";
  const pages = [{ title: "Payment List", href: "/payments" }];
  const navigate = useNavigate();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [allowedActions, setAllowedActions] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  const { searchQuery, setSearchQuery } = useSearch();
  const [filteredPaymentHistory, setFilteredPaymentHistory] = useState([]);
  useEffect(() => {
    fetchPayments(pageNo);
  }, [pageNo, searchQuery]);


  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredPaymentHistory(paymentHistory);
    } else {
      const filtered = paymentHistory.filter((topic) =>
        topic?.trax_no?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.transaction_id?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.cust_first_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.cust_last_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.cust_email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.trax_status?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      setFilteredPaymentHistory(filtered);
    }
  }, [searchQuery, paymentHistory]);

  const fetchPayments = async (pageIndex) => {
    setLoading(true);
    setError(null);
    try {
      const payload = `?trax_no=&fk_cust_id=0&transaction_id=&payment_card_id=&payment_id=&isSelectedColumn=0&isAll=0&page=${pageIndex + 1
        }&pageSize=10&serach_txt${searchQuery}`;
      const { data } = await paymentServices.getAllTransaction(payload);
      if (data?.status && data?.data) {
        console.log("Payment history:", data.data);
        setPaymentHistory(data.data.paymentList || []);
        setFilteredPaymentHistory(data.data.paymentList || []);
        setPageCount(data.data.pagination?.totalPages || 0);
        setTotalCount(data.data.pagination?.totalPayments || 0);
      }
    } catch (err) {
      console.error("Error fetching payments:", err);
      setError("Failed to load payments. Try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    if (!paymentHistory || paymentHistory.length === 0) {
      toast.error("No payments found to export!");
      return;
    }

    const dataSheet = paymentHistory.map((payment, index) => ({
      SN: index + 1,
      Transaction_No: payment.trax_no,
      Amount: `$${payment.trax_req_amt.toFixed(2)}`,
      Customer_Name: `${payment.cust_first_name} ${payment.cust_last_name}`,
      Email: payment.cust_email,
      Mobile: payment.cust_mobile,
      Transaction_Date: moment(payment.trax_start_date).format(
        "DD-MM-YYYY HH:mm"
      ),
      Status: payment.trax_status || "Pending",
      Approved: payment.trax_approved_txt === "True" ? "Approved" : "Declined",
      Orders: payment.orders || "No Orders",
    }));

    const fileName = `Payment_Report_${moment().format("DD-MM-YYYY")}.xlsx`;
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [
      [
        "SN",
        "Transaction No",
        "Amount",
        "Customer Name",
        "Email",
        "Mobile",
        "Transaction Date",
        "Status",
        "Approved",
        "Orders",
      ],
    ]);
    utils.sheet_add_json(ws, dataSheet, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Payments");
    writeFile(wb, fileName);
  };
  const handleCheckTransaction = (transactionId) => {
    console.log("Checking transaction:", transactionId);
    // Add logic to fetch and display transaction details
  };

  const handleViewTransaction = (trax_no, transaction_id) => {
    console.log("Viewing transaction:", trax_no);
    navigate(`/payments/${trax_no}/${transaction_id}`);
    // Navigate to transaction details page or show a modal
  };
  const downloadInvoice = async (trax_no, transaction_id, file_name) => {

    if (file_name) {
      // window.location.href = `${TranscationBASEURLFILE}${file_name}`;
      window.open(`${TranscationBASEURLFILE}${file_name}`, '_blank');
      return;
    }
    // setLoading(true);
    setError(null);
    try {
      const payload = {
        trax_no: trax_no,
        transactionID: transaction_id
      };
      const { data } = await paymentServices.downloadInvTransaction(payload);
      if (data?.status && data?.data) {
        window.open(`${TranscationBASEURLFILE}${data?.data?.file_name}`, '_blank');
        // window.location.href = `${TranscationBASEURLFILE}${data?.data?.file_name}`;
        fetchPayments(pageNo);
        console.log("Payment Download:", data.data);
      }
    } catch (err) {
      console.error("Error fetching payments:", err);
      // setError("Failed to Download payments. Try again later.");
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="px-4 sm:px-4 lg:px-4">
      <Breadcrumb pages={pages} />
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h1 className="text-xl font-semibold text-white">Payment List ({totalCount})</h1>
        <div className="flex">
          <input
            type="text"
            placeholder="Search Payment..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="inline-flex items-center justify-center rounded-md bbt bg-[#222222] px-4 py-2 text-sm font-medium text-white shadow-sm hffff ml-auto ml-2 mr-2"
          />
          {allowedActions.filter((data) => data.permission_id == 123)?.length >
            0 ? (
            <>
              <button
                onClick={handleExport}
                className="inline-flex items-center justify-center rounded-md bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm ml-auto"
              >
                <ArrowDownCircleIcon
                  className="-ml-1.5 h-5 w-5 mr-1"
                  aria-hidden="true"
                />
                Download Excel
              </button>
            </>
          ) : null}
        </div>
      </div>

      {loading ? (
        <FallingLinesLoader />
        // <p className="text-center text-gray-400">Loading payment history...</p>
      ) : error ? (
        <p className="text-center text-red-400">{error}</p>
      ) : (
        <Table
          columns={payment_columns({
            handleCheckTransaction,
            handleViewTransaction,
            allowedActions,
            downloadInvoice
          })}
          fetchData={fetchPayments}
          pageCount={pageCount}
          data={filteredPaymentHistory}
          isCustomPagination={true}
        />
      )}

      {paymentHistory.length > 0 && (
        <CommonPagination
          pageNo={pageNo}
          pageCount={pageCount}
          gotoPage={setPageNo}
          previousPage={() => setPageNo((prev) => Math.max(prev - 1, 0))}
          nextPage={() =>
            setPageNo((prev) => Math.min(prev + 1, pageCount - 1))
          }
        />
      )}
    </div>
  );
};

export default TransactionList;
