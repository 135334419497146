/* eslint-disable */
import Api from "./Api";
export default {

  //  Chart Section Start
  async getAccInfo(payload) {
    const response = await Api().post(`api/dashboard/metrix/`, payload);
    return response;
  },

  async getAccGrowthChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/acc-growth/`, payload);
    return response;
  },

  async getAccBalanceChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/acc-balance/`, payload);
    return response;
  },
  async getAccProfitChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/acc-profit/`, payload);
    return response;
  },
  async getAccDrawdownChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/acc-drawdown/`, payload);
    return response;
  },
  async getAccMarginChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/acc-margin/`, payload);
    return response;
  },
  async getTradingPeriod(payload) {
    const response = await Api().post(`api/dashboard/metrix/acc-trading-period/`, payload);
    return response;
  },

  async getMonthlyAccBalanceChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/monthly-acc-balance/`, payload);
    return response;
  },

  async getAccEquityChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/acc-equity/`, payload);
    return response;
  },

  async getMonthlyAccGrowthChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/monthly-acc-growth/`, payload);
    return response;
  },

  async getMonthlyYearlyAccGrowthChart(payload) {
    const response = await Api().post(`api/dashboard/metrix/monthly-yearly-acc-growth/`, payload);
    return response;
  },
  async getTradeWinnerLooserStats(payload) {
    const response = await Api().post(`api/dashboard/metrix/winner-looser/`, payload);
    return response;
  },
  async getAccSymbols(payload) {
    const response = await Api().post(`api/dashboard/metrix/symbole-acc-count/`, payload);
    return response;
  },
  //  Chart Section End
}