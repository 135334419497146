import React from "react";

const Pagination = ({ pageNo, pageCount, gotoPage, previousPage, nextPage }) => {
    const renderPageNumbers = () => {
        let pages = [];
        let startPage = Math.max(0, pageNo - 2);
        let endPage = Math.min(startPage + 4, pageCount - 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => gotoPage(i)}
                    className={`px-3 py-1 ${i === pageNo ? "bg-edgePrimary text-black" : "bg-[#222222] text-gray-300"} rounded-md`}
                >
                    {i + 1}
                </button>
            );
        }
        return pages;
    };

    return (
        <div className="flex items-center mt-4">
            <button
                onClick={previousPage}
                disabled={pageNo === 0}
                className="px-3 py-1 bg-[#222222] text-gray-300 rounded-md mr-2"
            >
                {"<"}
            </button>

            <div className="flex flex-wrap gap-1">
                {renderPageNumbers()}
            </div>

            <button
                onClick={nextPage}
                disabled={pageNo === pageCount - 1}
                className="px-3 py-1 bg-[#222222] text-gray-300 rounded-md ml-2"
            >
                {">"}
            </button>

            <span className="text-xs text-edgePrimary ml-2">
                Page <strong>{pageNo + 1}</strong> of {pageCount}
            </span>
        </div>
    );
};

export default Pagination;