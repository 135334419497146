import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import chartServices from '../../ApiServices/chartServices';
import { getGraphGap } from '../../helper/commonHelper';

const AMBalanceChart = ({ uid, data, someData }) => {
    let accNo = data;

    const chartData = {
        chart: {
            zooming: {
                type: 'xy'
            },
            backgroundColor: "#151515",
            style: {
                fontFamily: 'Onest'
            }
        },
        title: {
            text: '',
            align: 'left'
        },
        credits: {
            text: 'EdgeFin: ' +
                '<a href="https://www.EdgeFin.com"' +
                'target="_blank">EF</a>'
        },
        xAxis: [{
            gridLineColor: '#333333',
            categories: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ],
            crosshair: true,
            labels: {
                style: {
                    color: "#fff"
                }
            },
            title: {
                text: 'Months',
                style: {
                    color: "#fff"
                }
            }
        }],
        yAxis: [{ // Primary yAxis
            gridLineColor: '#333333',
            labels: {
                format: '{value}',
                style: {
                    color: "#fff"
                }
            },
            title: {
                text: 'ABC',
                style: {
                    color: "#fff"
                },
                enabled: false
            }
        }, { // Secondary yAxis
            gridLineColor: '#333333',
            title: {
                text: 'XYZ',
                style: {
                    color: "#fff"
                },
                enabled: false
            },
            labels: {
                format: '{value}',
                style: {
                    color: "#fff"
                }
            },
            opposite: true
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            backgroundColor: "#151515" || 'rgba(255,255,255,0.25)',
            itemStyle: {
                color: '#fff'
            }
        },
        series: []

    }

    const [accChartData, setAccChartData] = useState(chartData);

    const getAccBalanceChartData = useCallback(async (accid) => {
        if (!accid) return;
        try {
            const { data, status } = await chartServices.getAccBalanceChart({ acc_no: accid, uid: uid });
            console.log('data?.data Balance=>', data?.data)
            if (data.status && data?.data) {
                if (data?.data?.gainBalance?.length > 0) {
                    const updatedChartData = updateChartDataWithBalance(data?.data?.gainBalance, data?.data?.deposit, data?.data?.withdrawal, data?.data?.equity);
                    console.log('updatedChartData=>', updatedChartData)
                    // const updatedChartData = updateChartDataWithBalance(data?.data?.balance, data?.data?.deposit, data?.data?.withdrawal, data?.data?.equity);
                    setAccChartData(updatedChartData);
                }

            }
        } catch (error) {
            console.error("Error fetching account Balance chart data:", error);
        } finally {
            // console.info("Account Balance chart data fetched successfully");
        }
    }, []);

    useEffect(() => {
        if (data) {
            getAccBalanceChartData(data);
        }
    }, [data, getAccBalanceChartData]);
    const updateChartDataWithBalance = (balanceData, depositData, withdrawalData, equityData) => {
        const balanceValues = [];
        const depositValues = [];
        const withdrawalValues = [];
        const equityValues = [];

        const getTimestamp = (dateStr) => {
            return dateStr ? new Date(dateStr).getTime() : null;
        };

        balanceData?.forEach(item => {
            const timestamp = getTimestamp(item.gc_ord_open_time);
            if (timestamp) balanceValues.push([new Date(item.gc_ord_open_time), item.gc_balance]);
        });

        depositData?.forEach(item => {
            const timestamp = getTimestamp(item.accd_deposit_date_time);
            if (timestamp) depositValues.push([new Date(item.accd_deposit_date_time), item.accd_deposit_amount]);
        });

        withdrawalData?.forEach(item => {
            const timestamp = getTimestamp(item.accw_withdrawal_date_time);
            if (timestamp) withdrawalValues.push([new Date(item.accw_withdrawal_date_time), item.accw_withdrawal_amount]);
        });

        equityData?.forEach(item => {
            const timestamp = getTimestamp(item.accw_withdrawal_date_time);
            if (timestamp) equityValues.push([new Date(item.accw_withdrawal_date_time), item.accec_equity]);
        });

        // Sort all series data by timestamp
        balanceValues.sort((a, b) => a[0] - b[0]);
        depositValues.sort((a, b) => a[0] - b[0]);
        withdrawalValues.sort((a, b) => a[0] - b[0]);
        equityValues.sort((a, b) => a[0] - b[0]);
        console.log([
            { name: 'Balance', data: balanceValues },
            { name: 'Deposit', data: depositValues },
            { name: 'Withdrawal', data: withdrawalValues },
            { name: 'Equity', data: equityValues }
        ]);
        return {
            chart: {
                type: 'spline',
                zoomType: 'x' // Enables zooming along the x-axis
            },
            title: {
                // text: 'Gain Data Over Time'
            },
            xAxis: {
                type: 'datetime', // ✅ Required for date-based x-axis
                dateTimeLabelFormats: {
                    day: '%d %b %Y', // Format as "04 Feb 2025"
                    month: '%b %Y',
                    year: '%Y'
                },
                title: {
                    text: 'Date'
                },
                labels: {
                    style: {
                        color: '#fff' // Optional: Set label color
                    }
                },
                tickInterval: 24 * 3600 * 1000,
            },

            yAxis: {
                title: {
                    text: 'Gain Value'
                },
                // min: Math.min(...formattedData.map(d => d[1])) - 1 // Adjust min for better visualization
            },
            tooltip: {
                shared: true,
                formatter: function () {
                    console.log('Tooltip Data:', new Date(balanceValues[this.x][0]).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true
                    }));
                    let tooltipHtml = `<b>${new Date(balanceValues[this.x][0]).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true
                    })}</b><br/>`;

                    this.points.forEach(point => {
                        tooltipHtml += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y.toFixed(2)}</b><br/>`;
                    });
                    console.log(tooltipHtml);

                    return tooltipHtml;
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        symbol: 'circle',
                        fillColor: '#FFFFFF',
                        enabled: true,
                        radius: 2.5,
                        lineWidth: 1,
                        lineColor: null
                    },
                    fillColor: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: [
                            [0, '#26FF10'],
                            [1, '#26FF1014'],
                        ],
                    },
                }
            },
            // plotOptions: {
            //     series: {
            //         marker: {
            //             symbol: 'circle',
            //             fillColor: '#FFFFFF',
            //             enabled: true,
            //             radius: 2.5,
            //             lineWidth: 1,
            //             lineColor: null
            //         }
            //     }
            // },
            colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
            series: [
                { name: 'Balance', data: balanceValues, color: '#26FF10' },
                { name: 'Deposit', data: depositValues, color: '#26FF10' },
                { name: 'Withdrawal', data: withdrawalValues, color: '#26FF10' },
                { name: 'Equity', data: equityValues, color: '#26FF10' }
            ]
        };

    };
    return (
        <div>
            <HighchartsReact
                options={accChartData}
                highcharts={Highcharts}
            />
        </div>

    );
};

export default AMBalanceChart;
