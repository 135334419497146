import React, { useState, useEffect } from "react";
import { FaRedo, FaPlus, FaFire, FaChartLine, FaBookOpen, FaBars, FaMoneyBill, FaThumbsUp, FaThumbsDown, FaCommentDots, FaSearch, FaEllipsisV, FaClock, FaCaretDown, FaPaperPlane, FaPaperclip } from 'react-icons/fa';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import chatTopicServices from "../../../ApiServices/chatTopicServices";
import messageServices from "../../../ApiServices/messageServices";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ArrowPathIcon, ChatBubbleLeftIcon, CheckCircleIcon, ChevronDownIcon, ChevronRightIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Router, SearchIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import { TrashIcon } from "@heroicons/react/24/outline";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal"; // Adjust the path
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";




const forexQuestions = [
  "What is Forex trading?",
  "How does Forex trading work?",
  "What are currency pairs in Forex?",
  "What is leverage in Forex trading?",
  "What is the best time to trade Forex?",
  "How do I choose a Forex broker?",
  "What is a pip in Forex?",
  "What is a Forex trading strategy?",
  "What is the difference between a market order and a limit order?",
  "How can I manage risk in Forex trading?"
];

const statuses = {
  Complete: 'text-green-500 bg-green-600/50 ring-green-600/20',
  'In progress': 'text-gray-400 bg-blue-600/50 ring-blue-500/10',
  Closed: 'text-red-400 bg-red-600/50 ring-red-600/20',
  Open: 'text-green-400 bg-green-500/50 ring-green-500/20',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ForumHome = () => {

  const navigate = useNavigate();
  const [topicList, setTopicList] = useState([]);
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSort, setSelectedSort] = useState("Recents Topics");
  const [isLoading, setIsLoading] = useState(false);
  const [allowedActions, setAllowedActions] = useState([]);
  // const { values, setFieldValue } = useFormikContext(); 

  const [currentPlaceholderSlide, setCurrentPlaceholderSlide] = useState(0);
  const totalPlaceholderSlides = 10;

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPlaceholderSlide((prev) => (prev + 1) % totalPlaceholderSlides);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const topicSchema = Yup.object().shape({
    sct_title: Yup.string().required("Thread is required").test("min-words", "Thread must have at least 3 words", (value) => {
      if (!value) return false; // Ensure value exists
      return value.trim().split(/\s+/).length >= 3; // Check word count
    }),
  });

  const goToChatWithID = (thread_id) => {
    navigate('/forum/' + thread_id)
  }

  useEffect(() => {
    fetchTopics();
  }, [searchQuery, selectedSort]);


  const fetchTopics = async () => {
    setIsLoading(true);
    const queryParams = {
      search_topic: searchQuery || '',
      sortBy:
        selectedSort === "Recents Topics"
          ? "desc"
          : selectedSort === "Old Topics"
            ? "asc"
            : "bigCount",
    };
    try {
      const res = await chatTopicServices.getAllTopic(queryParams);
      if (res.status) {
        setTopicList(res?.data?.data?.supportTopics || []);
      }
    } catch (error) {
      console.error("Error fetching topics:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTopic = async (values) => {
    try {
      const payload = {
        ...values,
      };
      const res = await chatTopicServices.SaveTopic(payload);
      if (res.status) {
        setIsCreateTopicModalOpen(false);
        fetchTopics();
        // resetForm();
      }
    } catch (error) {
      console.error("Error creating topic:", error);
    }
  };

  const handleThreadAI = async (values, setFieldValue) => {
    console.log(values);

    const res = await chatTopicServices.getAiThread({ prompt: values?.sct_title });
    if (res.status) {
      console.log(res.data.data);
      setFieldValue('sct_title', res.data.data);
    }
  }

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    id: null,
  });

  const handleDeleteClick = (project) => {
    console.log("Deleting project:", project);
    setRemoveConfirmationModal({ id: project?.id, sct_is_deleted: project?.sct_is_deleted, status: true });
  };
  const confirmDeleteTopic = async(id,removeConfirmationModal) => {
    if (id) {
      console.log("Deleting project:", id,removeConfirmationModal);
      
      // Call API to delete the project
      let payload = {
           table_name: "support_chat_topic",
           table_field: "sct_id",
           table_field_val: id,
           table_status_field: 'sct_is_deleted', //"sct_is_deleted",
           table_status_val: !!removeConfirmationModal?.sct_is_deleted ? false : true,
           table_text: "Topic",
           deleted_by_key: "sct_uu_by",
           delete_active_txt: "Topic Status" //acc_status ? " Un-Baned" : " Ban",
         }  
         console.log('payload',payload);
        //  return false;
         MasterServices.removeMaster(payload).then((res) => {
           const { data, status, message } = res.data
           if (status) {
             toast.success(message);
             fetchTopics();
           } else {
             toast.error(message)
           }
         })
    }
    setRemoveConfirmationModal({ id: null, status: false });
  };
  return (
    <div className="p-4 bg-[#000000] min-h-screen">

      {/* page Title */}
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-2xl font-semibold text-white mb-6">Forum</h3>
      </div>

      {/* First Account DD LEFT-RIGHT */}
      <div class="items-center mb-2">

        {/* Left Section */}
        {/* <div class="md:col-span-2 col-span-7">

          <div class="text-left px-6 py-6 bg-[#151515] rounded-md">
        
            <div class="mt-4 flex items-center justify-center gap-x-6">
              <button type="button"
                onClick={() => setIsCreateTopicModalOpen(true)}
                class="inline-flex w-full justify-center gap-x-2 rounded-md bg-edgePrimary px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Create a New Post
                <svg class="-mr-0.5 size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>

        </div> */}

        {/* Right Sectoin */}
        <div class="">

          <div class="">
            <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">

              {/* Recenets Menu Section */}
              <div class="">

                <Menu as="div" className="relative inline-block text-left md:w-50 w-full">
                  <div>
                    <MenuButton className="flex flex-wrap items-center justify-between sm:flex-nowrap w-full gap-x-1.5 rounded-md bg-[#151515] px-3 py-2 text-md text-gray-400">
                      {selectedSort}
                      <ChevronDownIcon aria-hidden="true" className="-mr-1 size-5 text-gray-400" />
                    </MenuButton>
                  </div>

                  <MenuItems
                    transition
                    className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-[#151515] shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="py-1">
                      {["Recents Topics", "Old Topics", "Hot Topics"].map((option) => (
                        <MenuItem key={option}>
                          <a
                            onClick={() => setSelectedSort(option)}
                            href="#"
                            className="block px-4 py-2 text-md text-gray-400 data-[focus]:bg-gray-700 data-[focus]:text-gray-400 data-[focus]:outline-none"
                          >
                            {option}
                          </a>
                        </MenuItem>
                      ))}
                    </div>
                  </MenuItems>
                </Menu>

              </div>

              {/* Search Input Section */}
              <div class=" shrink-0">

                <div>
                  <div className="mt-2 flex">
                    <div className="grid grow grid-cols-1">
                      <input
                        id="query"
                        name="query"
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Topic Search"
                        className="border border-[#151515] col-start-1 row-start-1 block w-full rounded-l-md bg-[#151515] py-1.5 pl-10 pr-3 text-base text-gray-400 outline outline-1 -outline-offset-1 outline-[#151515] placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-[#222] sm:pl-9 sm:text-sm/6"
                      />
                      <SearchIcon
                        aria-hidden="true"
                        className="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400 sm:size-4"
                      />
                    </div>
                    {/* <button
                      type="button"
                      className="flex shrink-0 items-center gap-x-1.5 rounded-r-md bg-[#151515] px-3 py-2 text-sm font-semibold text-gray-400 outline outline-1 -outline-offset-1 outline-[#222] focus:relative focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-[#222]"
                    > */}
                    {/* <SearchIcon aria-hidden="true" className="-ml-0.5 size-4 text-gray-400" /> */}
                    {/* Submit
                    </button> */}


                    {allowedActions.filter((data) => data.permission_id == 113)?.length >
                      0 ? (<button type="button"
                        onClick={() => setIsCreateTopicModalOpen(true)}
                        class="inline-flex justify-center btn-sm rounded-md bg-edgePrimary px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2">
                        Create a New Post
                        <svg class="-mr-0.5 size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                        </svg>
                      </button>) : (<></>)}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div>

            {/* Topic List */}
            {/* divide-y divide-gray-800 */}
            <ul role="list" className="">
              {isLoading
                ?
                Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <li
                      key={index}
                      className="animate-pulse bg-[#151515] w-[80vw] rounded-md border-2 border-[#242323] my-4 px-4 flex items-center justify-between gap-x-6 py-5"
                    >
                      <div className="">
                        <div className="flex gap-x-3 items-center">
                          <div className="h-4 w-48 bg-gray-700 rounded-md"></div> {/* Simulated title */}
                          <div className="h-4 w-20 bg-gray-700 rounded-md"></div> {/* Simulated status */}
                        </div>
                        <div className="mt-1 w-full flex items-center gap-x-2">
                          <div className="h-4 w-24 bg-gray-700 rounded-md"></div> {/* Simulated last seen */}
                          <div className="h-4 w-36 bg-gray-700 rounded-md"></div> {/* Simulated creator */}
                        </div>
                      </div>
                      <div className="flex flex-none items-center gap-x-4">
                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                          <div className="h-4 w-20 bg-gray-700 rounded-md"></div> {/* Simulated role */}
                          <div className="h-4 w-10 bg-gray-700 rounded-md mt-1"></div> {/* Simulated comments */}
                        </div>
                      </div>
                    </li>
                  ))
                : topicList?.length > 0 && topicList?.map((project) => (
                  <li key={project.id} className="hover:bg-[#222] cursor-pointer bg-[#151515] rounded-md border-2 border-[#242323] my-4 px-4 flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0" onClick={() => goToChatWithID(project.id)}>
                      <div className="flex items-start gap-x-3">
                        <p className="text-md font-medium text-gray-100">{project.name}</p>
                        <p
                          className={classNames(
                            statuses[project.status],
                            'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                          )}
                        >
                          {project.status}
                        </p>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-xs/5 text-gray-500">

                        {/* <p className="whitespace-nowrap">
                        Due on <time dateTime={project.dueDateTime}>{project.dueDate}</time>
                      </p> */}

                        {project.lastSeen ? (
                          <p className="whitespace-nowrap text-sm">
                            <time dateTime={project.lastSeenDateTime}>{project.lastSeen}</time>
                          </p>
                        ) : (
                          <div className="mt-1 flex items-center gap-x-1.5">
                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                              <div className="size-1.5 rounded-full bg-emerald-500" />
                            </div>
                            <p className="text-sm text-gray-500">Online</p>
                          </div>
                        )}

                        <svg viewBox="0 0 2 2" className="size-0.5 fill-current">
                          <circle r={1} cx={1} cy={1} />
                        </svg>
                        <p className="truncate text-sm">Created by {project.createdBy}</p>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">

                      <div className="flex shrink-0 items-center gap-x-4">
                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                          <p className="text-sm text-gray-500">{project.role}</p>

                          {/* {project.lastSeen ? (
                          <p className="mt-1 text-xs/5 text-gray-500">
                            Last seen <time dateTime={project.lastSeenDateTime}>{project.lastSeen}</time>
                          </p>
                        ) : (
                          <div className="mt-1 flex items-center gap-x-1.5">
                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                              <div className="size-1.5 rounded-full bg-emerald-500" />
                            </div>
                            <p className="text-xs/5 text-gray-500">Online</p>
                          </div>
                        )} */}

                          <div className="flex w-16 gap-x-2.5">
                            <dt>
                              <span className="sr-only">Total comments</span>
                              {project.commentStatus === 'resolved' ? (
                                <CheckCircleIcon aria-hidden="true" className="size-6 text-gray-400" />
                              ) : (
                                <ChatBubbleLeftIcon aria-hidden="true" className="size-6 text-gray-400" />
                              )}
                            </dt>
                            <dd className="text-sm/6 text-gray-400">{project.totalComments}</dd>
                          </div>

                        </div>
                        {/* Switch case logic for toggling between delete and default */}
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteClick(project);
                          }}
                          className={project.sct_is_deleted ? "text-green-500 hover:text-green-700" : "text-red-500 hover:text-red-700"}
                        // disabled={project.sct_is_deleted}
                        >
                          {project.sct_is_deleted ? (
                            <ArrowPathIcon className="size-6" />
                            // <LockClosedIcon className="size-6" /> // Locked icon when disabled
                          ) : (
                            <TrashIcon className="size-6" /> // Trash icon when active
                          )}
                        </button>

                        {/* <ChevronRightIcon aria-hidden="true" className="size-5 flex-none text-gray-400" /> */}
                      </div>

                    </div>
                  </li>
                ))}
            </ul>

          </div>

        </div>

        {isCreateTopicModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
            <div className="bg-[#151515] border border-gray-50/10 p-5 rounded-lg w-[500px] shadow-xl relative">
              <button
                className="absolute top-2 right-2 text-white text-lg"
                onClick={() => setIsCreateTopicModalOpen(false)}
              >
                &times;
              </button>
              <h4 className="text-2xl font-semibold text-white mb-6 text-center">New Discussion Thread</h4>
              <Formik
                initialValues={{ sct_title: "", sct_description: "" }}
                validationSchema={topicSchema}
                onSubmit={(values, { setFieldValue }) => {
                  handleAddTopic(values);
                  handleThreadAI(values, setFieldValue);
                }}
              >
                {({ values, setValues, setFieldValue, errors, touched }) => (
                  <Form>

                    <div className="mb-6">
                      {/* <label className="text-white block mb-2">Title</label> */}
                      <Field
                        as="textarea"
                        name="sct_title"
                        // placeholder="Write a title here..."
                        value={values.sct_title || ""}
                        placeholder={forexQuestions[currentPlaceholderSlide]}
                        className={`w-full px-3 py-2 rounded-md h-24 resize-none 
                          bg-[#333] text-white border "border-gray-600"}`}
                      />
                      {errors.sct_title && touched.sct_title && (
                        <div className="text-red-500 text-sm">{errors.sct_title}</div>
                      )}
                    </div>
                    <div className="flex justify-end space-x-2">
                      {/* <button
                        type="button"
                        onClick={() => setIsCreateTopicModalOpen(false)}
                        className="bg-gray-200 px-4 py-2 text-xs rounded-lg"
                      >
                        Generate AI
                      </button> */}

                      {!errors.sct_title && (
                        <button
                          onClick={() => {
                            handleThreadAI(values, setFieldValue)
                          }}
                          type="button"
                          class="text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-xs px-5 py-2 text-center">
                          ✧ Rewrite with AI
                        </button>
                      )}

                      <button
                        type="button"
                        onClick={() => setIsCreateTopicModalOpen(false)}
                        className="bg-gray-200 px-4 py-2 text-xs rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-edgePrimary px-4 py-2 text-xs rounded-lg "
                      >
                        Hit to create
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
       
          <DeleteConfirmationModal
            title={
              "Are you sure you want to " +
              (removeConfirmationModal.sct_is_deleted
                ? " Undo"
                : "Delete") +
              " this Topic?"
            }
            confirmationButtonText="Yes"
            open={removeConfirmationModal.status}
            onDelete={() => {
              console.log("confirmationModal = ", removeConfirmationModal);
              confirmDeleteTopic(removeConfirmationModal.id, removeConfirmationModal);
            }}
            setOpen={setRemoveConfirmationModal}
          />
        


      </div>

    </div>
  );
};

export default ForumHome;
