/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SavePlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/plan`, payload);
    return response;
  },

  async getAllPlan(payload) {
    // await AuthToken.getCurrentAuth();
    let isObj= payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj?`api/dashboard/product/plan/all/?${params}`:`api/dashboard/product/plan/all/?search=${payload}`);
    return response;
  },
  async getAllSinglePlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/product/plan/${payload}`);
    return response;
  },
  async setPlanStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/plan/status`, payload);
    return response;
  },
  async deletePlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/plan/remove`, payload);
    return response;
  },

  async changePublicPlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/plan/public`, payload);
    return response;
  },

  async importPlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/plan/import`, payload);
    return response;
  },

  async deleteQuickPlanCode(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/quick-plan/remove`, payload);
    return response;
  },
  //  product Section End
}