/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product`, payload);
    return response;
  },

  async getAllProduct(payload) {
    // await AuthToken.getCurrentAuth();
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/dashboard/product?${params}` : `api/dashboard/product?search=${payload}`);

    // const response = await Api().get(`api/dashboard/product/?search=${payload}`);
    return response;
  },
  async getAllSingleProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/product/${payload}`);
    return response;
  },
  async setProductStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/status`, payload);
    return response;
  },
  async deleteProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/remove`, payload);
    return response;
  },

  async deleteProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product-file/remove`, payload);
    return response;
  },

  async importProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/import`, payload);
    return response;
  },
  //  product Section End



  async getAllProductLicense(payload) {
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/dashboard/product/plan/cust-plan/all?${params}` : `api/dashboard/product/plan/cust-plan/all?search=${payload}`);

    // const response = await Api().get(`api/dashboard/product/plan/cust-plan/all?search=${payload}`);
    return response;
  },

  async getAllRole(payload) {
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `/api/dashboard/masters/role?${params}` : `/api/dashboard/masters/role?search=${payload}`);

    // const response = await Api().get(`api/dashboard/product/plan/cust-plan/all?search=${payload}`);
    return response;
  },

  async getAllUser(payload) {
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `/api/dashboard/user?${params}` : `/api/dashboard/user?search=${payload}`);

    // const response = await Api().get(`api/dashboard/product/plan/cust-plan/all?search=${payload}`);
    return response;
  },

  async getAllUserLicenses(payload) {
    const response = await Api().get(`api/dashboard/product/plan/cust-licenses?${payload}`);
    return response;
  },

  async saveProductLicense(payload) {
    const response = await Api().post(`api/dashboard/product/plan/cust-plan`, payload);
    return response;
  },

  async getAllSingleProductLicense(payload) {
    const response = await Api().get(`api/dashboard/product/plan/cust-plan/single/${payload}`);
    return response;
  },
  async setProductLicenseStatus(payload) {
    const response = await Api().post(`api/dashboard/product/plan/cust-plan/status`, payload);
    return response;
  },
  async deleteProductLicense(payload) {
    const response = await Api().post(`api/dashboard/product/plan/cust-plan/remove`, payload);
    return response;
  },

  async importProductLicense(payload) {
    const response = await Api().post(`api/dashboard/product/plan/cust-plan/import`, payload);
    return response;
  },
  async getProductLicenseInfo(payload) {
    const response = await Api().post(`api/dashboard/product/plan/cust-plan/get-product-info`, payload);
    return response;
  },

}