
import { useEffect, useRef, useState } from 'react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CalendarIcon, PaperClipIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import EmojiPicker from 'emoji-picker-react';
import { FaceSmileIcon } from '@heroicons/react/24/outline';
import SliderTextBadge from '../../../../components/SliderTextBadge';   
import { FaPaperPlane, FaSpinner } from 'react-icons/fa';

export default function MainChat({ isReplying = false, replyingTo = null, setMessage, message = "", handleSendMessage,disableSubmitButton }) {

    const [isEmojiOpen, setIsEmojiOpen] = useState(false)
    const [tempText, setTempText] = useState("");
    const [text, setText] = useState("");
    const [type, setType] = useState("description");
    const [textAreaEvents, setTextAreaEvents] = useState({});

    const onEmojiClick = (data, events) => {
        console.log({ text, data, events });
    }

    const handleCallback = (data) => {
        console.log(data);
        setText(data);
        setMessage(data);
    }


    return (
        <form action="#" className="relative forum">

            <EmojiPicker
                theme='dark'
                emojiStyle='apple'
                open={isEmojiOpen}
                reactionsDefaultOpen={true}
                onEmojiClick={onEmojiClick}
            />

            <div className="rounded-lg bg-[#151515] outline outline-1 -outline-offset-1 outline-gray-800 ">
               
                <textarea
                    id="description"
                    name="description"
                    rows={5}
                    placeholder={isReplying && replyingTo !== null ? "Replying to #" + replyingTo : "What do you want to talk about"}
                    className="bg-[#151515] w-full  text-gray-300 placeholder:text-gray-400"
                    value={message || ''} // Controlled textarea value
                    onChange={(e) => {
                        console.log(e.target.value);
                        if (e.target.value !== '') {
                            // setText(e.target.value);
                            setMessage(e.target.value);
                        } else {
                            // setText("");
                            setMessage("");
                        }
                    }}
                />

                {/* Spacer element to match the height of the toolbar */}
                <div aria-hidden="true">
                    <div className="py-2">
                        <div className="h-11" />
                    </div>
                    <div className="h-px" />
                    {/* <div className="py-2">
                        <div className="py-px">
                        <div className="h-9" />
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="absolute inset-x-px bottom-0">
                {/* Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. */}

                <div className="flex items-center justify-between space-x-3 border-t border-gray-700 px-4 py-0 mt-2 sm:px-5 ">
                    <div className="flex">
                        {/* <button
                            type="button"
                            className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
                        >
                            <PaperClipIcon aria-hidden="true" className="-ml-1 mr-2 size-5 group-hover:text-gray-500" />
                            <span className="text-sm italic text-gray-500 group-hover:text-gray-600">Attach a file</span>
                        </button>
                        <FaceSmileIcon onClick={() => {
                            setIsEmojiOpen(!isEmojiOpen)
                        }} aria-hidden="true" className="-ml-1 mr-2 size-5 text-gray-500" /> */}

                        <SliderTextBadge handleCallback={handleCallback} />

                    </div>
                    <div className="shrink-0">
                        <button
                            onClick={handleSendMessage}
                            type="button"
                            className="inline-flex items-center rounded-md bg-edgePrimary px-3 py-2 ml-2 mt-2 text-sm font-semibold text-black shadow-sm"
                        >
                            {disableSubmitButton ? (
                                <FaSpinner className="animate-spin" />
                            ) : (
                                <>Send <FaPaperPlane className='ml-2' /></>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}
