import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Select from "react-select";
import WhopServices from "../../../../ApiServices/WhopServices";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toast';
import { FaSpinner } from "react-icons/fa";

const AddAccount = () => {

  const { fk_wp_lic_id: fk_wp_lic_id } = useParams();
  const pages = [
    { title: fk_wp_lic_id > 0 ? "Update Account List" : "Add Account List", href: "/add-account" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const navigate = useNavigate();

  const [accRows, setAccounts] = useState([]);
  console.log(accRows);
  const [licenseList, setLicenseList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [licenseID, setLicenseID] = useState({});
  const [selectedUserId, setSelectedUserId] = useState({ value: '', label: '' });
  const [selectedUserName, setSelectedUserName] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState({ value: '', label: '' });
  const [formErrors, setFormErrors] = useState({});
  console.log(formErrors);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);

  useEffect(() => {
    getAllUser(1);
    getAllProduct(1);
    if (fk_wp_lic_id) {
      getSingleAccount(fk_wp_lic_id);
      getSingleLicense(fk_wp_lic_id);
    } else {


      setAccounts([
        {
          id: 1,
          lam_id: 0,
          fk_wp_lic_id: fk_wp_lic_id,
          fk_wp_user_id: "",
          fk_wp_prod_id: "",
          user_name: "",
          prod_name: "",
          lam_account_no: "",
        },
      ]);
    }
  }, [fk_wp_lic_id]);
  const getAllUser = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopUser(payload)
      if (res.status) {
        setUserList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllUser")
      toast.error("Fatal Error")
    }
  }

  const getAllLicense = async (value) => {
    let payload = `1&fk_wp_user_id=${value}&fk_wp_prod_id=${selectedProductId?.value}`;
    try {
      const res = await WhopServices.getAllWhopLicense(payload)
      if (res.status) {
        setLicenseList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllLicense")
      toast.error("Fatal Error")
    }
  }
  const getAllProduct = async (value) => {
    let payload = `1&fk_wp_user_id=${value}`;
    try {
      const res = await WhopServices.getAllWhopProduct(payload)
      if (res.status) {
        setProductList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllLicense")
      toast.error("Fatal Error")
    }
  }

  const addAccount = () => {
    const newAccount = {
      id: accRows.length + 1,
      fk_wp_lic_id: fk_wp_lic_id,
      lam_account_no: "",
      fk_wp_user_id: !selectedUserId?.value ? "" : selectedUserId?.value,
      fk_wp_prod_id: !selectedProductId?.value ? "" : selectedProductId?.value,
      user_name: !selectedUserId?.user_name ? "" : selectedUserId?.user_name,
      prod_name: !selectedProductId?.label ? "" : selectedProductId?.label,
      lam_id: 0,
    };
    setAccounts([...accRows, newAccount]);
  };


  const handleLicChange = (id, selectedOption) => {
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          fk_wp_lic_id: selectedOption.value,
          prod_name: selectedOption.master_whop_product_relation?.prod_name,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("fk_wp_lic_id", selectedOption ? selectedOption.value : null, id);
  };

  const handleUserChange = (id, selectedOption) => {
    getAllLicense(selectedOption.value);
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          fk_wp_user_id: selectedOption.value,
          user_name: selectedOption.user_name,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("fk_wp_user_id", selectedOption ? selectedOption.value : null, id);
  };

  const handleProductChange = (id, selectedOption) => {
    getAllLicense(selectedOption.value);
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          fk_wp_prod_id: selectedOption.value,
          prod_name: selectedOption.prod_name,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("fk_wp_prod_id", selectedOption ? selectedOption.value : null, id);
  };

  const handlelAccountNoChange = (id, lam_account_no) => {
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          lam_account_no: lam_account_no,
        };
      }
      return accRow;
    });

    setAccounts(updatedAccounts);
    validateField("lam_account_no", lam_account_no ? lam_account_no : null, id);
  };

  const removeAccount = (accRowId) => {
    setAccounts((currentAccounts) =>
      currentAccounts.filter(
        (accRow) => accRow.id !== accRowId
      )
    );
  };

  const validateField = (fieldName, value, accRowId = null) => {
    let errors = { ...formErrors };

    switch (fieldName) {

      case "fk_wp_user_id":
        if (value) {
          delete errors[`accRow_${accRowId}_user`];
        } else {
          errors[`accRow_${accRowId}_user`] = "User must be selected.";
        }
        break;

      case "fk_wp_prod_id":
        if (value) {
          delete errors[`accRow_${accRowId}_product`];
        } else {
          errors[`accRow_${accRowId}_user`] = "Product must be selected.";
        }
        break;

      case "fk_wp_lic_id":
        if (value) {
          delete errors[`accRow_${accRowId}_license`];
        } else {
          errors[`accRow_${accRowId}_license`] = "License must be selected.";
        }
        break;


      case "lam_account_no":
        if (value) {
          delete errors[`accRow_${accRowId}_account_no`];
        } else {
          errors[`accRow_${accRowId}_account_no`] = "Account No Field must be Required.";
        }
        break;

    }

    setFormErrors(errors);
  };

  const validateForm = () => {
    let validationErrors = {};

    let i = 0;
    accRows.forEach((accRow) => {
      if (!accRow.fk_wp_user_id) {
        validationErrors[`accRow_${accRow.id}_user`] = `User ${parseInt(i) + 1} must be selected.`;
      }

      if (!accRow.fk_wp_prod_id) {
        validationErrors[`accRow_${accRow.id}_product`] = `User ${parseInt(i) + 1} must be selected.`;
      }
      if (!accRow.fk_wp_lic_id) {
        validationErrors[`accRow_${accRow.id}_license`] = `License ${parseInt(i) + 1} must be selected.`;
      }

      if (!accRow.lam_account_no) {
        validationErrors[`accRow_${accRow.id}_account_no`] = `Account No ${parseInt(i) + 1} Fields must be Required.`;
      }
      i++;
    });

    return validationErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      setIsSubmitAttempted(true);
      console.error("Validation errors:", validationErrors);
      return;
    }

    setFormErrors({});
    setIsSubmitAttempted(false);

    createPayload();
  };

  const createPayload = () => {
    const accRowsPayload = accRows.map((accRow) => ({
      lam_account_no: accRow.lam_account_no,
      fk_wp_lic_id: accRow.fk_wp_lic_id,
      fk_wp_user_id: accRow.fk_wp_user_id,
      fk_wp_prod_id: accRow.fk_wp_prod_id,
      prod_name: accRow.prod_name,
      user_name: accRow.user_name,
    }));

    const payload = {
      lam_id: 0,
      licAccountArr: accRowsPayload,
    };

    setTimeout(() => {
      setDisableSubmitButton(false)
    }, 1000)
    setDisableSubmitButton(true)
    WhopServices.SaveWhopAccount(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        setDisableSubmitButton(false)
        navigate("/account");
      }
      setDisableSubmitButton(false)
    });
  };

  const getSingleAccount = async (fk_wp_lic_id) => {
    setIsLoading(true);
    setAccounts([]);

    await WhopServices.getAllWhopAccount({
      fk_wp_lic_id: fk_wp_lic_id,
      search: 3,
    })
      .then(async (res) => {
        const { data, status } = res.data;
        console.log(data);
        if (status) {
          data.forEach((item) => addAccount(item));
          getAllLicense(data.fk_wp_user_id);
          function addAccount(data) {
            const accRowItem = {
              fk_wp_lic_id: data.fk_wp_lic_id,
              fk_wp_user_id: data.fk_wp_user_id,
              fk_wp_prod_id: data.fk_wp_prod_id,
              prod_name: data?.master_whop_license_relation?.master_whop_product_relation?.prod_name,
              user_name: data?.master_whop_user_relation?.user_name,
              id: data.lam_id,
              lam_account_no: data.lam_account_no,
              lam_id: data.lam_id,
            };
            setAccounts((prevAccounts) => [
              ...prevAccounts,
              accRowItem,
            ]);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSingleLicense = async (fk_wp_lic_id) => {

    await WhopServices.getAllWhopSingleLicense(fk_wp_lic_id)
      .then(async (res) => {
        const { data, status } = res.data;
        console.log("WhopServices=>", data);
        if (status) {
          setSelectedUserName(data?.master_whop_user_relation?.user_name)
          setSelectedUserId(data?.master_whop_user_relation)
          setSelectedProductId(data?.master_whop_user_relation)
          setLicenseID({ label: data?.lic_affiliate_username, value: data?.wp_lic_id })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">{fk_wp_lic_id > 0 ? "Update" : "Add"} Account</h1>

        <div className="mt-4 flex">
          <button
            onClick={addAccount}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-edgePrimary px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
          >
            Add
          </button>
        </div>

        {isLoading ? (
          <FallingLinesLoader />
        ) : (
          <div className={`mt-4 flex flex-col `}>
            <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block  min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden  border border-gray-200 sm:rounded-lg">
                  <table className=" table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr className=" ">
                        <th
                          scope="col"
                          className="   group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          SN
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          User Email
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          User Name
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Product
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          License
                        </th>

                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Account No
                        </th>
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {accRows.map((accRow) => (
                        <tr key={accRow.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {accRow.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {/* isDisabled={accRow?.lam_id > 0 ? true : false} */}
                            <Select
                              isDisabled={fk_wp_lic_id > 0 ? true : false}

                              name="users"
                              value={
                                userList.find(
                                  (options) =>
                                    options.value === accRow.fk_wp_user_id
                                ) || null
                              }
                              onChange={(option) => {
                                handleUserChange(accRow.id, option)
                                getAllLicense(option?.value);
                              }
                              }
                              options={userList}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  background: "#151515",
                                  color: "#FFF",
                                  borderRadius: state.isFocused ? "4px 4px 0 0" : 3,
                                }),
                                menuList: styles => ({
                                  ...styles,
                                  background: '#333333',
                                  color: "#FFFFFF",
                                }),
                                option: (styles, { isFocused, isSelected }) => ({
                                  ...styles,
                                  color: isFocused ? '#151515' : isSelected ? '#111111' : undefined, //"#111111",
                                  background: isFocused ? '#FFF' : isSelected ? '#FFF' : undefined,
                                  zIndex: 1
                                }),
                                menu: base => ({
                                  ...base,
                                  zIndex: 100
                                })
                              }}
                              classNamePrefix="select"
                              className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                            {formErrors[
                              `accRow_${accRow.id}_user`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_user`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

                            {/* isDisabled={accRow?.lam_id > 0 ? true : false} */}
                            <input
                              disabled={fk_wp_lic_id > 0 ? true : false}
                              value={accRow.user_name}
                              type="text"
                              placeholder="User Name"
                              name="user_name"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <Select
                              isDisabled={fk_wp_lic_id > 0 ? true : false}
                              name="Products"
                              value={
                                productList.find(
                                  (options) =>
                                    options.value === accRow.fk_wp_prod_id
                                ) || null
                              }
                              onChange={(option) => {
                                setSelectedProductId(option)
                                handleProductChange(accRow.id, option)
                                getAllLicense(selectedUserId?.value);
                              }
                              }
                              options={productList}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  background: "#151515",
                                  color: "#FFF",
                                  borderRadius: state.isFocused ? "4px 4px 0 0" : 3,
                                }),
                                menuList: styles => ({
                                  ...styles,
                                  background: '#333333',
                                  color: "#FFFFFF",
                                }),
                                option: (styles, { isFocused, isSelected }) => ({
                                  ...styles,
                                  color: isFocused ? '#151515' : isSelected ? '#111111' : undefined, //"#111111",
                                  background: isFocused ? '#FFF' : isSelected ? '#FFF' : undefined,
                                  zIndex: 1
                                }),
                                menu: base => ({
                                  ...base,
                                  zIndex: 100
                                })
                              }}
                              classNamePrefix="select"
                              className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                            {formErrors[
                              `accRow_${accRow.id}_product`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_product`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <Select
                              isDisabled={fk_wp_lic_id > 0 ? true : false}
                              name="licenses"
                              value={
                                licenseID.value ? licenseID : licenseList.find(
                                  (options) =>
                                    options.value === accRow.fk_wp_lic_id
                                ) || null
                              }
                              onChange={(option) => {
                                setLicenseID(option)
                                handleLicChange(accRow.id, option)
                              }
                              }
                              options={licenseList}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  background: "#151515",
                                  color: "#FFF",
                                  borderRadius: state.isFocused ? "4px 4px 0 0" : 3,
                                }),
                                menuList: styles => ({
                                  ...styles,
                                  background: '#333333',
                                  color: "#FFFFFF",
                                }),
                                option: (styles, { isFocused, isSelected }) => ({
                                  ...styles,
                                  color: isFocused ? '#151515' : isSelected ? '#111111' : undefined, //"#111111",
                                  background: isFocused ? '#FFF' : isSelected ? '#FFF' : undefined,
                                  zIndex: 1
                                }),
                                menu: base => ({
                                  ...base,
                                  zIndex: 100
                                })
                              }}
                              classNamePrefix="select"
                              className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                            {formErrors[
                              `accRow_${accRow.id}_license`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_license`
                                    ]
                                  }
                                </span>
                              )}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <input
                              isDisabled={accRow?.lam_id > 0 ? true : false}
                              value={accRow.lam_account_no}
                              onChange={(e) => { handlelAccountNoChange(accRow.id, e.target.value) }}
                              type="text"
                              placeholder="Account No"
                              name="lam_account_no"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                            {formErrors[
                              `accRow_${accRow.id}_account_no`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_account_no`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {accRow.lam_id <= 0 ? (<button
                              onClick={() => removeAccount(accRow.id)}
                              className="text-red-500 hover:text-red-700"
                            >
                              Remove
                            </button>) : (<></>)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-2 flex items-center justify-between gap-x-6">
        <div className="mt-4">
          <button
            disabled={disableSubmitButton}
            onClick={handleSubmit} // Trigger createPayload when this button is clicked
            className="inline-flex items-center justify-center rounded-md bbt bg-edgePrimary px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            {fk_wp_lic_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Submit")}
          </button>
        </div>

      </div>
    </div>
  );
};

export default AddAccount;
