/* eslint-disable */
import Api from "./Api";
export default {

  // Transaction Section Start
  async SaveTransaction(payload) {
    const response = await Api().post(`api/transaction/`, payload);
    return response;
  },
  async downloadInvTransaction(payload) {
    const response = await Api().post(`api/transaction/pdf-download`, payload);
    return response;
  },

  async getAllTransaction(payload) {
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/transaction?${params}` : `api/transaction?search=${payload}`);

    return response;
  },
  async getAllSingleTransaction(payload) {
    const response = await Api().get(`api/transaction/${payload}`);
    return response;
  },
  async setTransactionStatus(payload) {
    const response = await Api().post(`api/transaction/status`, payload);
    return response;
  },
  async deleteTransaction(payload) {
    const response = await Api().post(`api/transaction/remove`, payload);
    return response;
  },

  async getTransactionDetails(payload) {
    const response = await Api().post(`api/transaction/complete`, payload);
    return response;
  },

  async getSingleTransactionDetails(payload) {
    const response = await Api().post(`api/transaction/single`, payload);
    return response;
  },

  async getQuickPaymentList(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/get-quick-payment`, payload);
    return response;
  },

  async getAllStats(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/transaction/get-stats`, payload);
    return response;
  },

  // Transaction Section End
}