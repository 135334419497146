import React, { useCallback, useEffect, useState } from 'react';
import MasterServices from '../../../ApiServices/MasterServices';

const TradeLive = ({ data, uid, name, someData }) => {
    const [tradingLive, setTradingLive] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0); // to store total records for pagination

    // Function to get trading history
    const getAccTradeLiveData = useCallback(async (accid, pageIndex) => {
        if (!accid) return;
        try {
            const payload = `?uid=${uid}&name=${name}&accid=${accid}&page=${pageIndex + 1}`;
            const { data, status } = await MasterServices.getTradeLive(payload);

            if (data.status && data?.data) {
                // console.log("Account Trade Live data:", data?.data);
                setTradingLive(data?.data?.trade_history);
                setTotalRecords(data?.data?.trade_history_pagination?.total_trade_his || 0); // set total records
                setPageCount(data?.data?.trade_history_pagination?.total_pages); // Calculate pages based on records per page
            }
        } catch (error) {
            console.error("Error fetching account Trade Live data:", error);
        } finally {
            // console.info("Account Trade Live data fetched successfully");
        }
    }, [uid, name]);

    // Page navigation functions
    const gotoPage = (pageIndex) => {
        setPageNo(pageIndex);
        getAccTradeLiveData(data, pageIndex);  // Pass correct page number
    };

    const previousPage = () => {
        if (pageNo > 0) {
            setPageNo(pageNo - 1);
            getAccTradeLiveData(data, pageNo - 1);
        }
    };

    const nextPage = () => {
        if (pageNo < pageCount - 1) {
            setPageNo(pageNo + 1);
            getAccTradeLiveData(data, pageNo + 1);
        }
    };

    useEffect(() => {
        if (data) {
            getAccTradeLiveData(data, pageNo); // Fetch data on component mount and whenever pageNo changes
        }
    }, [data, pageNo, getAccTradeLiveData]);

    if (!tradingLive || tradingLive.length === 0) {
        return (
            <div className="w-full">
                <h2 className="text-center text-white py-4">No Trade Live Available</h2>
            </div>
        );
    }

    // Function to render page numbers
    const renderPageNumbers = () => {
        let pages = [];
        let startPage = Math.max(0, pageNo - 2); // To show 5 pages, starting from 2 before the current page
        let endPage = Math.min(startPage + 4, pageCount - 1); // Limit the last page to `pageCount`

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => gotoPage(i)}
                    className={`px-3 py-1 ${i === pageNo ? "bg-edgePrimary text-black" : "bg-[#222222] text-gray-300"} rounded-md`}
                >
                    {i + 1}
                </button>
            );
        }

        return pages;
    };

    const columns = [
        "#", "Open Date", "Symbol", "Action", "Lots", "Open Price",
        "SL (Price)", "TP (Price)", "Profit", "Pips", "Swap"
    ];
    // "Gain"

    return (
        <>
            <div className="w-full">
                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto text-center border-collapse">
                        <thead>
                            <tr>
                                {columns?.map((col) => (
                                    <th key={col} className="border-b py-2 px-2 text-xs text-gray-200">{col}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tradingLive?.map((trade, index) => (
                            
                                <tr key={trade.id} className={`${index % 2 === 0 ? "bg-[#222]" : "bg-[#151515]"} hover:bg-[#444]`}>
                                    {/* {Object.values(trade).map((value, idx) => (
                                        <td key={idx} className="border-b py-2 px-2 text-xs text-gray-100">{value}</td>
                                    ))} */}
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_id}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_timestamp}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_symbol}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_type==0 ? "Buy" : trade.lt_order_type==1?"Sell":""}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_lots}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_price}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_sl}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_tp}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_profit}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_acc_order_pips}</td>
                                    <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_swap}</td>
                                    {/* <td className="border-b py-2 px-2 text-xs text-gray-200">{trade.lt_order_comm}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination controls */}
            <div className="flex items-center mt-4">
                <button
                    onClick={previousPage}
                    disabled={pageNo === 0}
                    className="px-3 py-1 bg-[#222222] text-gray-300 rounded-md mr-2"
                >
                    {"<"}
                </button>

                <div className="flex flex-wrap gap-1">
                    {renderPageNumbers()}
                </div>

                <button
                    onClick={nextPage}
                    disabled={pageNo === pageCount - 1}
                    className="px-3 py-1 bg-[#222222] text-gray-300 rounded-md ml-2"
                >
                    {">"}
                </button>
                <span className="text-xs text-edgePrimary ml-2">
                    Page <strong>{pageNo + 1}</strong> of {pageCount}
                </span>
            </div>
        </>
    );
};

export default TradeLive;
