import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Charts = ({ activeTab, tradeLive, tradeHistory }) => {
    // Determine data and labels based on the active tab
    const labels =
        activeTab === "liveTrades"
            ? tradeLive?.map((trade) => trade?.lt_order_time)
            : tradeHistory?.map((trade) => trade?.mth_order_open_time);
    const data =
        activeTab === "liveTrades"
            ? tradeLive?.map((trade) => trade?.lt_order_profit)
            : tradeHistory?.map((trade) => trade?.mth_order_profit);

    const hasData = data && data.length > 0;

    const chartData = {
        labels: hasData ? labels : [], // Empty if no data
        datasets: hasData
            ? [
                {
                    label: "P&L",
                    data: data,
                    borderColor: "#22FF10", // Green color
                    tension: 0.1,
                    fill: false,
                },
            ]
            : [],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "left",
                align: "center",
                labels: { color: "white" },
                title: { display: true, text: "Trade" },
            },
            title: { display: true, text: "Trade P&L Over Time" },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: "white",
                },
                grid: {
                    color: "#1c1b1b",
                },
            },
        },
    };

    return (
        <div className="mb-8">
            <h3 className="mb-6 text-xl text-gray-300">Trade History</h3>
            {hasData ? (
                <Line data={chartData} options={chartOptions} />
            ) : (
                <div class="relative min-h-60 w-full flex flex-col justify-center items-center my-6 shadow-sm border border-slate-600 rounded-lg p-2 bg-[#222222]">
                    <div class="p-3 text-center">
                        <div class="flex justify-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-slate-400 w-10 h-10">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                            </svg>
                        </div>
                        <div class="flex justify-center mb-2">
                            <h5 class="text-slate-200 text-2xl font-semibold">
                                No chart data availabled.
                            </h5>
                        </div>
                        <p class="block text-slate-200 leading-normal font-light mb-4 max-w-lg">
                            There is no data to display on the chart at the moment. Please ensure data is available or check your settings to load the chart data.
                        </p>
                    </div>
                </div>

            )}
        </div>
    );
};

export default Charts;
