import React, { useEffect, useState, Fragment, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { licenseNew_columns } from "../../../../components/tables/tableheader";
import { licenseSchemaNew } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import {
  ArrowDownCircleIcon,
  ArrowDownIcon,
  ArrowUpCircleIcon,
  ArrowUpIcon,
  PlusCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import WhopServices from "../../../../ApiServices/WhopServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { utils, writeFile, read } from "xlsx";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
} from "../../../../helper/commonHelper";
import Select from "react-select";
import prodServices from "../../../../ApiServices/prodServices";
import CustomerServices from "../../../../ApiServices/CustomerServices";
import AccountServices from "../../../../ApiServices/AccountServices";
import planServices from "../../../../ApiServices/planServices";
import CommonPagination from "../../../../components/CommonPagination";
import { useSortBy, useTable } from "react-table";
import { useSearch } from "../../../../components/tables/SearchContext";

function LicenseList() {
  const pages = [{ title: "License List", href: "/license", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [up_id, setLicenseid] = useState("");
  const [licenseList, setLicenseList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeLicense, setActiveLicense] = useState(null);
  const [expiredLicense, setExpiredLicense] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState({
    value: "",
    label: "",
  });
  const [selectedAccId, setSelectedAccId] = useState({ value: "", label: "" });
  const [selectedPlanId, setSelectedPlanId] = useState({
    value: "",
    label: "",
  });
  const [selectedUserId, setSelectedUserId] = useState({
    value: "",
    label: "",
  });

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    up_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    up_id: null,
  });
  let [accounts, setAccounts] = useState([]);
  let [planList, setPlanList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);
  const { searchQuery, setSearchQuery } = useSearch();
  const [filteredLicenseList, setFilteredLicenseList] = useState([]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredLicenseList(licenseList);
    } else {
      const filtered = licenseList.filter((topic) =>
        topic?.up_license_key?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.m_customer_relation?.cust_username?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.m_customer_relation?.cust_email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.product_relation?.prod_title?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.plan_relation?.plan_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.m_account_relation?.acc_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.m_account_relation?.acc_no_str?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );

      setFilteredLicenseList(filtered);
    }
  }, [searchQuery, licenseList]);


  useEffect(() => {
    document.title = "EdgeFin •  Licenses";

    getAllCustomers(1);
    getAllProducts(1);
    const delayDebounce = setTimeout(() => {
      getAllLicenses(3);
    }, 3000); // 5 seconds delay

    return () => clearTimeout(delayDebounce);
    // getAllLicenses(3);
    // getAllAccounts();
  }, []);
  const getAllAccounts = async (fk_cust_id) => {
    // setIsLoading(true);
    try {
      const res = await AccountServices.getAllAccount({
        search: 1,
        fk_cust_id: fk_cust_id,
      });
      if (res.status) {
        const { accounts, pagination: paginationData } = res.data.data;
        setAccounts(accounts);
        console.log(paginationData)
        return accounts; // Return fetched accounts
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e, "Error fetching accounts");
      toast.error("Failed to fetch accounts.");
      setIsLoading(false);
    }
    return [];
  };
  const getAllPlans = async (fk_prod_id) => {
    // setIsLoading(true);
    try {
      const res = await planServices.getAllPlan({
        search: 1,
        fk_prod_id: fk_prod_id,
        plan_is_whop: 1,
      });
      if (res.status) {
        const { products, pagination: paginationData } = res.data.data;
        console.log("products=>", products);
        setPlanList(products);
        return products;
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e, "Error fetching getAllPlans");
      toast.error("Failed to fetch getAllPlans.");
      setIsLoading(false);
    }
    return [];
  };
  const getAllCustomers = async (payload) => {
    try {
      const res = await CustomerServices.getAllCustomer(payload);
      if (res.status) {
        setUserList(res.data.data?.customers);
      }
    } catch (e) {
      console.log(e, "error in getAllModules");
      toast.error("Fatal Error");
    }
  };
  const getAllProducts = async (payload) => {
    let payloads = {
      search: payload,
      prod_is_whop:1,
    };
    try {
      const res = await prodServices.getAllProduct(payloads);
      if (res.status) {
        setProductList(res.data.data?.products);
      }
    } catch (e) {
      console.log(e, "error in getAllModules");
      toast.error("Fatal Error");
    }
  };

  const getAllLicenses = async (search) => {
    let payload = {
      // serach_txt: searchQuery, 
      search
    };
    setIsLoading(true);
    try {
      const res = await prodServices.getAllProductLicense(payload);
      if (res.status) {
        setLicenseList(res.data.data?.products);
        setFilteredLicenseList(res.data.data?.products);

        const activeLicenses = res.data.data?.products.filter(
          (license) => !license.isExpired
        ).length;
        const expiredLicenses = res.data.data?.products.filter(
          (license) => license.isExpired
        ).length;

        setActiveLicense(activeLicenses);
        setExpiredLicense(expiredLicenses);

        setPageCount(res?.data?.data?.pagination?.totalPages || 0);
        console.log("res?.data?.data?.pagination?.totalPages", res?.data?.data?.pagination);
        setTotalCount(res?.data?.data?.pagination?.totalCount || 0);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules");
      toast.error("Fatal Error");
      setIsLoading(false);
    }
  };

  const onStatusUpdate = (up_id, up_is_active) => {
    let payload = {
      table_name: "user_plan_details",
      table_field: "up_id",
      table_field_val: up_id,
      table_status_field: "up_is_active",
      table_status_val: up_is_active ? false : true,
      table_text: "License",
      deleted_by_key: "up_uu_by",
      delete_active_txt: up_is_active ? " Disabled" : " Enabled",
    };
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        getAllLicenses(3);
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  let initialValues = {
    up_id: "",
    fk_cust_id: "",
    fk_prod_id: "",
    fk_plan_id: "",
    // fk_acc_id: "",
    lic_qty: 1,
    up_start_date: "",
    up_end_date: "",
    up_license_key: "",
  };

  const [formLicense, setFormLicense] = useState(initialValues);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleDrawer = async (type, id, obj) => {
    let fetchedAccounts = [];
    let fetchedPlans = [];
    if (obj.fk_prod_id) {
      console.log(obj.fk_prod_id);
      fetchedPlans = await getAllPlans(obj.fk_prod_id);
      console.log("plans=>", fetchedPlans);
      // setPlanList(plans); // Update state
    }
    if (obj.fk_cust_id) {
      fetchedAccounts = await getAllAccounts(obj.fk_cust_id);
      console.log("accountsData=>", fetchedAccounts);
      // setAccounts(accountsData); // Update state
    }
    // Wait for 5 seconds
    await wait(1000);
    setSelectedProductId("");
    setSelectedUserId("");
    setSelectedPlanId("");
    setSelectedAccId("");

    values.fk_cust_id = "";
    values.fk_prod_id = "";
    if ((type === "edit", id)) {
      setLicenseid(id);
      const initialValues = {
        up_id: obj.up_id,
        fk_cust_id: obj.fk_cust_id,
        fk_prod_id: obj.fk_prod_id,
        fk_plan_id: obj.fk_plan_id,
        // fk_acc_id: obj.fk_acc_id,
        lic_qty: !obj.lic_qty ? 1 : 1,
        up_license_key: obj.up_license_key,

        up_start_date: moment(obj?.up_start_date).format("YYYY-MM-DD"),
        up_end_date: moment(obj?.up_end_date).format("YYYY-MM-DD"),
        up_created_at: moment(obj?.up_created_at).format("YYYY-MM-DD"),
        up_is_active: obj.up_is_active,
      };
      if (Array.isArray(productList) && productList.length > 0) {
        let product = productList.filter(
          (v) => v.value == initialValues.fk_prod_id
        );
        if (product.length > 0) {
          setSelectedProductId({
            value: product[0].value,
            label: product[0].label,
          });
        }
      }
      if (Array.isArray(userList) && userList.length > 0) {
        let User = userList.filter((v) => v.value == initialValues.fk_cust_id);
        if (User.length > 0) {
          setSelectedUserId({ value: User[0].value, label: User[0].label });
        }
      }

      console.log("initialValues.fk_plan_id:", initialValues.fk_plan_id);
      // console.log("initialValues.fk_acc_id:", initialValues.fk_acc_id);
      console.log("planList:", planList);
      console.log("accounts:", accounts);
      console.log("initialValues:", initialValues);
      if (Array.isArray(fetchedPlans) && fetchedPlans.length > 0) {
        const plan = fetchedPlans.filter(
          (v) => v.value === initialValues.fk_plan_id
        );
        if (plan.length > 0) {
          setSelectedPlanId({ value: plan[0].value, label: plan[0].label });
        }
      }

      if (Array.isArray(fetchedAccounts) && fetchedAccounts.length > 0) {
        const accountData = fetchedAccounts.filter(
          (v) => v.value === initialValues.fk_acc_id
        );
        if (accountData.length > 0) {
          setSelectedAccId({
            value: accountData[0].value,
            label: accountData[0].label,
          });
        }
      }
      // if (Array.isArray(planList) && planList.length > 0) {
      //   let plan = planList.filter(v => v.value == initialValues.fk_plan_id)
      //   if (plan.length > 0) {
      //     setSelectedPlanId({ value: plan[0].value, label: plan[0].label })
      //   }
      // }
      /* if (Array.isArray(accounts) && accounts.length > 0) {
        let accountdata = accounts.filter(v => v.value == initialValues.fk_acc_id)
        if (accountdata.length > 0) {
          setSelectedAccId({ value: accountdata[0].value, label: accountdata[0].label })
        }
      } */
      setFormLicense(initialValues);
    } else {
      setLicenseid("");
      setFormLicense(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (up_id, up_is_active) => {
    setConfirmationModal({ up_id, up_is_active, status: true });
  };

  const onDeleteOpenSection = (up_id, lic_is_deleted) => {
    setRemoveConfirmationModal({ up_id, lic_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setErrors } =
    useFormik({
      enableReinitialize: true,
      initialValues: formLicense,
      validationSchema: licenseSchemaNew,
      onSubmit: async (values, action) => {
        let body = {
          up_id: up_id,
          fk_cust_id: values.fk_cust_id,
          fk_prod_id: values.fk_prod_id,
          fk_plan_id: values.fk_plan_id,
          // fk_acc_id: values.fk_acc_id,
          lic_qty: values.lic_qty,
          up_start_date: values.up_start_date,
          up_end_date: values.up_end_date,
          up_license_key: values.up_license_key,
        };

        // if (body.fk_acc_id != undefined || body.fk_acc_id != null || body.fk_acc_id != "") {
        setTimeout(() => {
          setDisableSubmitButton(false);
        }, 1000);
        setDisableSubmitButton(true);
        setLicenseList([]);
        setIsLoading(true);
        let { data, status, message } = await prodServices.saveProductLicense(
          body
        );
        if (status === 200) {
          if (data.status) {
            action.resetForm();
            toast.success(data.message);
            getAllLicenses(3);
            setIsLoading(false);
            setmodalOpenFlage(false);
            setDisableSubmitButton(false);
          } else {
            toast.success(data.message);
            setErrorMessage(data.message);
            setTimeout(() => {
              setErrorMessage("");
            }, 1000);
            setIsLoading(false);
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false);
        }
        /* } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllLicenses(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        } */
      },
    });

  const onDeleteLicense = async (up_id) => {
    let { data, status } = await prodServices.deleteProductLicense({ up_id });
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        getAllLicenses(3);
      }
    } else {
      toast.error(data.message);
    }
    setRemoveConfirmationModal({ up_id: null, status: false });
  };

  const filterUser = (User) => {
    const result = userList.filter(
      (obj) => obj?.cust_email?.toLowerCase() === User?.toLowerCase()
    );
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value;
    } else {
      resp = undefined;
    }
    return resp;
  };

  const filterProduct = (product) => {
    const result = productList.filter(
      (obj) => obj?.prod_title?.toLowerCase() === product?.toLowerCase()
    );
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value;
    } else {
      resp = undefined;
    }
    return resp;
  };

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("License handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.AccountNo != undefined || obj.AccountNo != undefined) {
                const newObj = {
                  ProductName:
                    obj.ProductName !== undefined
                      ? obj.ProductName
                        ? obj.ProductName.trim()
                        : obj.ProductName.trim()
                      : "",
                  PlanName:
                    obj.PlanName !== undefined
                      ? obj.PlanName
                        ? obj.PlanName.toString().trim()
                        : obj.PlanName.toString().trim()
                      : "",
                  CustomerName:
                    obj.CustomerName !== undefined
                      ? obj.CustomerName
                        ? obj.CustomerName.trim()
                        : obj.CustomerName.trim()
                      : "",
                  AccountNo:
                    obj.AccountNo !== undefined
                      ? obj.AccountNo
                        ? obj.AccountNo.trim()
                        : obj.AccountNo.trim()
                      : "",
                  StartDate:
                    obj.StartDate !== undefined
                      ? obj.StartDate
                        ? obj.StartDate.trim()
                        : obj.StartDate.trim()
                      : "",
                  EndDate:
                    obj.EndDate !== undefined
                      ? obj.EndDate
                        ? obj.EndDate.trim()
                        : obj.EndDate.trim()
                      : "",
                  // fk_cust_id: filterUser(obj.CustomerName),
                  // fk_prod_id: filterProduct(obj.ProductName),
                };

                newData.push(newObj);
              }
            });

            console.log("newArray: ", newData);
            const filteredArray = newData.filter(
              (obj) => obj.fk_cust_id !== undefined
            );
            console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              importLicense(newData);
            }
          } else {
            toast.success("Excel is empty");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const importLicense = (data) => {
    const payload = { excel_data: data };
    MasterServices.userExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("License Imported Successfully");
          getAllLicenses(3);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg);
      });
  };

  const handleExport = (excel_type) => {
    if (!licenseList || licenseList.length === 0) {
      toast.error("License list is empty!");
      return;
    }
    if (licenseList?.length > 0) {
      let newSheetdata = [];
      licenseList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          up_id: obj?.up_id || "-",
          fk_cust_id: obj?.fk_cust_id || "-",
          fk_prod_id: obj?.fk_prod_id || "-",
          fk_plan_id: obj?.fk_plan_id || "-",
          acc_id: obj?.m_account_relation?.acc_id || "-",
          up_license_key: capitalizeFirstLetter(obj?.up_license_key) || "-",
          cust_username:
            capitalizeFirstLetter(obj?.m_customer_relation?.cust_username) ||
            "-",
          cust_email: obj?.m_customer_relation?.cust_email || "-",
          prod_title:
            capitalizeFirstLetter(obj?.product_relation?.prod_title) || "-",
          plan_name:
            capitalizeFirstLetter(obj?.plan_relation?.plan_name) || "-",
          acc_name:
            capitalizeFirstLetter(obj?.m_account_relation?.acc_name) || "-",
          acc_no_str:
            capitalizeFirstLetter(obj?.m_account_relation?.acc_no_str) || "-",
          up_start_date: moment(obj?.up_start_date).format("DD-MM-YYYY") || "-",
          up_end_date: moment(obj?.up_end_date).format("DD-MM-YYYY") || "-",
          up_created_at: moment(obj?.up_created_at).format("DD-MM-YYYY") || "-",
          Status: obj?.up_is_active == true ? "Active" : "In-Active" || "-",
        };
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName =
        "License_Excel_Report_" +
        moment(currentDate).format("DD-MM-YYYY") +
        "." +
        excel_type;
      const headings = [
        [
          "SN",
          "License ID",
          "Customer ID",
          "Product ID",
          "Plan ID",
          "Account ID",
          "License Key",
          "Customer Name",
          "Customer Email",
          "Product Name",
          "Plan Name",
          "Account Name",
          "Account No",
          "Start Date",
          "End Date",
          "Created Date",
          "Status",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  };

  const handleExpiryDate = (data) => {
    console.log("data", data);
    let payload = {};
    payload.up_id = data.up_id;
    payload.up_end_date = data.up_end_date;
    MasterServices.updateExpiryDate(payload)
      .then((res) => {
        const { data, status, message } = res.data;
        if (status) {
          toast.success(message);
          getAllLicenses(3);
        } else {
          toast.error(message);
        }
      }
      )
  }

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.up_is_active ? "Disable " : "Enable") +
          " this License?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.up_id,
            confirmationModal.up_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.lic_is_deleted ? " Undo" : "Delete") +
          " this License?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteLicense(removeConfirmationModal.up_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <div className="leftTitle">
          <h1 className="text-xl font-semibold text-white">License List ({totalCount})</h1>
          <span className="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-green-500 ring-1 ring-inset ring-green-400/20 mr-3">
            Active License {activeLicense}
          </span>
          <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20">
            Expired License {expiredLicense}
          </span>
        </div>
        <div className="flex">
          <input
            type="text"
            placeholder="Search Plan..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="inline-flex items-center justify-center rounded-md bbt bg-[#222222] px-4 py-2 text-sm font-medium text-white shadow-sm hffff ml-auto ml-2 mr-2"
          />
          {allowedActions.filter((data) => data.permission_id == 53)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-auto"
            >
              <PlusCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Add License
            </Link>
          ) : null}
          {allowedActions.filter((data) => data.permission_id == 57)?.length >
            0 && licenseList?.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => handleExport("xlsx")}
              type="button"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
            >
              <ArrowDownCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Download Excel
            </Link>
          ) : null}
          {allowedActions.filter((data) => data.permission_id == 58)?.length >
            0 ? (
            <label
              htmlFor="file-upload"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-3 py-1 text-xs font-medium text-black shadow-sm hffff  ml-2 cursor-pointer transition duration-300 ease-in-out"
            >
              <ArrowUpCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Upload License
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleImport}
              />
            </label>
          ) : null}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={licenseNew_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
            handleExpiryDate
          })}
          data={filteredLicenseList}
          is_toggle={false}
          pageCount={pageCount}
          customPageNo={pageNo}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-[#151515] shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-[#222222]  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-gray-100">
                                {up_id ? "Update" : "Add"} License
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-edgePrimaryHover hover:text-gray-200"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              {/* Select User */}
                              {userList && userList.length > 0 ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lic_qty"
                                      className="block text-sm font-medium text-gray-200 mb-1"
                                    >
                                      User (Customer)
                                      <span className="text-red-400">*</span>
                                    </label>
                                  </div>
                                  <Select
                                    name="user_name"
                                    id="wp_user_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span className="text-gray-400">
                                        Select User
                                        {/* <span className="text-red-400">*</span> */}
                                      </span>
                                    }
                                    value={selectedUserId}
                                    onChange={(e) => {
                                      console.log(e);
                                      values.fk_cust_id = e.value;
                                      setSelectedUserId(e);
                                      getAllAccounts(e.value);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      userList
                                        ? userList.map((v) => ({
                                          value: v.cust_id,
                                          label: v.cust_email,
                                        }))
                                        : []
                                    }
                                    styles={{
                                      control: (base, state) => ({
                                        ...base,
                                        background: "#151515",
                                        color: "#FFF",
                                        borderRadius: state.isFocused
                                          ? "4px 4px 0 0"
                                          : 3,
                                      }),
                                      menuList: (styles) => ({
                                        ...styles,
                                        background: "#333333",
                                        color: "#FFFFFF",
                                      }),
                                      option: (
                                        styles,
                                        { isFocused, isSelected }
                                      ) => ({
                                        ...styles,
                                        color: isFocused
                                          ? "#151515"
                                          : isSelected
                                            ? "#111111"
                                            : undefined, //"#111111",
                                        background: isFocused
                                          ? "#FFF"
                                          : isSelected
                                            ? "#FFF"
                                            : undefined,
                                        zIndex: 1,
                                      }),
                                      menu: (base) => ({
                                        ...base,
                                        zIndex: 100,
                                      }),
                                    }}
                                    classNamePrefix="select"
                                    className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />

                                  {errors.fk_cust_id && touched.fk_cust_id ? (
                                    <div className="text-sm text-red-400">
                                      {errors.fk_cust_id.replace(
                                        "fk_cust_id",
                                        "User"
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              {/* Select Product */}
                              {productList && productList.length > 0 ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lic_qty"
                                      className="block text-sm font-medium text-gray-200 mb-1"
                                    >
                                      Product
                                      <span className="text-red-400">*</span>
                                    </label>
                                  </div>
                                  <Select
                                    name="prod_name"
                                    id="fk_prod_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span className="text-gray-400">
                                        Select Product
                                        {/* <span className="text-red-400">*</span> */}
                                      </span>
                                    }
                                    value={selectedProductId}
                                    onChange={(e) => {
                                      console.log(e);
                                      values.fk_prod_id = e.value;
                                      setSelectedProductId(e);
                                      getAllPlans(e.value);
                                      values.fk_plan_id = "";
                                    }}
                                    components={animatedComponents}
                                    options={
                                      productList
                                        ? productList.map((v) => ({
                                          value: v.prod_id,
                                          label: v.prod_title,
                                        }))
                                        : []
                                    }
                                    styles={{
                                      control: (base, state) => ({
                                        ...base,
                                        background: "#151515",
                                        color: "#FFF",
                                        borderRadius: state.isFocused
                                          ? "4px 4px 0 0"
                                          : 3,
                                      }),
                                      menuList: (styles) => ({
                                        ...styles,
                                        background: "#333333",
                                        color: "#FFFFFF",
                                      }),
                                      option: (
                                        styles,
                                        { isFocused, isSelected }
                                      ) => ({
                                        ...styles,
                                        color: isFocused
                                          ? "#151515"
                                          : isSelected
                                            ? "#111111"
                                            : undefined, //"#111111",
                                        background: isFocused
                                          ? "#FFF"
                                          : isSelected
                                            ? "#FFF"
                                            : undefined,
                                        zIndex: 1,
                                      }),
                                      menu: (base) => ({
                                        ...base,
                                        zIndex: 100,
                                      }),
                                    }}
                                    classNamePrefix="select"
                                    className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />

                                  {errors.fk_prod_id && touched.fk_prod_id ? (
                                    <div className="text-sm text-red-400">
                                      {errors.fk_prod_id.replace(
                                        "fk_prod_id",
                                        "Product Name"
                                      )}
                                    </div>
                                  ) : null}
                                  {planList && planList.length === 0 && selectedProductId ? (
                                    <div className="text-sm text-red-400">
                                      No Plan is available for this product
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              {planList && planList.length > 0 ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lic_qty"
                                      className="block text-sm font-medium text-gray-200 mb-1"
                                    >
                                      Plan(s) of above Product
                                      <span className="text-red-400">*</span>
                                    </label>
                                  </div>
                                  <Select
                                    name="plan_name"
                                    id="plan_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span className="text-gray-400">
                                        Select Plan
                                        {/* <span className="text-red-400">*</span> */}
                                      </span>
                                    }
                                    value={selectedPlanId}
                                    onChange={(e) => {
                                      console.log("selectedPlanId : " + e);
                                      values.fk_plan_id = e.value;
                                      setSelectedPlanId(e);



                                    }}
                                    components={animatedComponents}
                                    options={
                                      planList
                                        ? planList.map((v) => ({
                                          value: v.plan_id,
                                          label: v.plan_name,
                                        }))
                                        : []
                                    }
                                    styles={{
                                      control: (base, state) => ({
                                        ...base,
                                        background: "#151515",
                                        color: "#FFF",
                                        borderRadius: state.isFocused
                                          ? "4px 4px 0 0"
                                          : 3,
                                      }),
                                      menuList: (styles) => ({
                                        ...styles,
                                        background: "#333333",
                                        color: "#FFFFFF",
                                      }),
                                      option: (
                                        styles,
                                        { isFocused, isSelected }
                                      ) => ({
                                        ...styles,
                                        color: isFocused
                                          ? "#151515"
                                          : isSelected
                                            ? "#111111"
                                            : undefined, //"#111111",
                                        background: isFocused
                                          ? "#FFF"
                                          : isSelected
                                            ? "#FFF"
                                            : undefined,
                                        zIndex: 1,
                                      }),
                                      menu: (base) => ({
                                        ...base,
                                        zIndex: 100,
                                      }),
                                    }}
                                    classNamePrefix="select"
                                    className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />

                                  {errors.fk_plan_id && touched.fk_plan_id ? (
                                    <div className="text-sm text-red-400">
                                      {errors.fk_plan_id.replace(
                                        "fk_plan_id",
                                        "plan Name"
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              {/* {accounts && accounts.length > 0 && selectedUserId?.value ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="fk_acc_id"
                                    id="fk_acc_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span className="text-gray-400">
                                        Select Account
                                        <span className="text-red-400">*</span>
                                      </span>
                                    }
                                    value={selectedAccId}
                                    onChange={(e) => {
                                      console.log(e);
                                      values.fk_acc_id = e.value;
                                      setSelectedAccId(e);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      accounts
                                        ? accounts.map((v) => ({
                                          value: v.acc_id,
                                          label: v.acc_no,
                                        }))
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                    styles={{
                                      control: (base) => ({
                                        ...base,
                                        background: '#222222',
                                        borderColor: '#222222',
                                        color: '#222222'
                                      }),
                                      menu: (base) => ({
                                        ...base,
                                        background: '#1f2937'
                                      }),
                                      option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isFocused ? '#374151' : '#1f2937',
                                        color: '#e5e7eb'
                                      }),
                                      singleValue: (base) => ({
                                        ...base,
                                        color: '#e5e7eb'
                                      })
                                    }}
                                  />

                                  {errors.fk_acc_id && touched.fk_acc_id ? (
                                    <div className="text-sm text-red-400">
                                      {errors.fk_acc_id.replace("fk_acc_id", "Account No")}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null} */}

                              {/* Date inputs */}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lic_qty"
                                    className="block text-sm font-medium text-gray-200 mb-1"
                                  >
                                    Lincese Quantity
                                    <span className="text-red-400">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lic_qty}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="QTY"
                                    min={1}
                                    name="lic_qty"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.lic_qty && touched.lic_qty ? (
                                    <p className="text-red-400 text-sm">
                                      {errors.lic_qty}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {/* Date */}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="up_start_date"
                                    className="block text-sm font-medium text-gray-200 mb-1"
                                  >
                                    Renewal Period Start
                                    <span className="text-red-400">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.up_start_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min={moment().add(1, 'days').format('YYYY-MM-DD')}
                                    type="date"
                                    placeholder="Renewal Period Start"
                                    style={{
                                      WebkitAppearance: 'none',
                                    }}
                                    name="up_start_date"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.up_start_date ? (
                                    <p className="text-red-400 text-sm">
                                      {errors.up_start_date.replace(
                                        "up_start_date",
                                        "Renewal Period Start"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="up_end_date"
                                    className="block text-sm font-medium text-gray-200 mb-1"
                                  >
                                    Renewal Period End
                                    <span className="text-red-400">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.up_end_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min={moment().add(1, 'days').format('YYYY-MM-DD')}
                                    type="date"
                                    placeholder="Renewal Period End"
                                    name="up_end_date"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.up_end_date ? (
                                    <p className="text-red-400 text-sm">
                                      {errors.up_end_date.replace(
                                        "up_end_date",
                                        "Renewal Period End"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-400 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-[#222222]">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md py-2 px-4 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 focus:ring-offset-gray-900 ${disableSubmitButton
                              ? "bg-gray-600 hover:bg-gray-600 cursor-not-allowed"
                              : "bg-[#27ff10] hover:from-[#c9eb05] hover:to-[#23eb0e]"
                              }`}
                          >
                            {up_id ? (
                              disableSubmitButton ? (
                                <FaSpinner className="animate-spin text-edgePrimary" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner className="animate-spin text-edgePrimary" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default LicenseList;
