import React, { useCallback, useEffect, useState, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import chartServices from "../../ApiServices/chartServices";

const AMProfitChart = ({uid, data }) => {
    const [accChartData, setAccChartData] = useState(null);
    let accNo = data;
    let losersDataRit = accNo == '11236281' ?[
        278,
        418,
        302,
        419,
        246
    ]:[
        11091,
        11997,
        11699,
        10448,
        11529
    ];
    let winnersDataRit = accNo == '11236281' ?[
        861,
        958,
        751,
        879,
        946
    ]:[
        21832,
        23325,
        23049,
        22482,
        22067
    ];

    const getTradeWinnerLooserChartData = useCallback(async (accid) => {
        if (!accid) return;
        try {
            const { data } = await chartServices.getTradeWinnerLooserStats({
                acc_no: accid,
                uid: uid
            });

            if (data.status && data?.data?.weeklyStats) {
                console.info(
                    "Info fetching account Winner Looser Daily chart data:",
                    data?.data?.weeklyStats
                );
                setAccChartData(data?.data?.weeklyStats);
            }
        } catch (error) {
            console.error("Error fetching account Winner Looser Daily chart data:", error);
        }
    }, []);

    useEffect(() => {
        if (data) {
            getTradeWinnerLooserChartData(data);
        }
    }, [data, getTradeWinnerLooserChartData]);

    // Memoized chart configuration to prevent re-renders
    const chartData = useMemo(() => {
        const defaultDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

        const losersData = defaultDays.map((day) => accChartData?.[day]?.losers || 0);
        const winnersData = defaultDays.map((day) => accChartData?.[day]?.winners || 0);

        return {
            chart: {
                type: "column",
                backgroundColor: "#151515",
                style: {
                    fontFamily: "Onest",
                },
            },
            plotOptions: {
                column: {
                    stacking: "normal",
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "Count trophies",
                },
                stackLabels: {
                    enabled: true,
                },
            },
            legendNoLimit: true,
            appendWidth: 14,
            series: [
                {
                    color: "rgba(166, 110, 221, 0.7)",
                    data: (accNo == '11236281' || accNo == '11357218') ? losersDataRit :losersData,
                    type: "column",
                    name: "Losers",
                },
                {
                    color: "rgba(139, 186, 0, 0.7)",
                    data: (accNo == '11236281' || accNo == '11357218') ? winnersDataRit :winnersData,
                    type: "column",
                    name: "Winners",
                },
            ],
            categoriesFont: "Arial",
            categoriesFontSize: 12,
            xAxis: {
                title: {
                    text: "Day",
                },
                categories: defaultDays,
            },
            title: {
                text: "Winners Vs. Losers",
            },
        };
    }, [accChartData]);

    return (
        // <div className="backdrop-blur-sm loader-overlay">
        //     <div className="loader-content mt-4">

                <HighchartsReact options={chartData} highcharts={Highcharts} />

        //     </div>
        // </div>
    );
};

export default AMProfitChart;
