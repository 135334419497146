import React, { useCallback, useEffect, useState, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import chartServices from "../../ApiServices/chartServices";

const AMProfitChart = ({uid, data }) => {
    const [accChartData, setAccChartData] = useState(null);
    let accNo = data;
    let losersDataRit = accNo == '11236281' ?[
        45,
        88,
        103,
        95,
        151,
        123,
        78,
        118,
        191,
        76,
        43,
        54,
        29,
        43,
        54,
        52,
        84,
        30,
        20,
        34,
        74,
        50,
        28
    ]:[
        499,
        1438,
        1670,
        1820,
        1807,
        1487,
        1412,
        1379,
        1782,
        3091,
        3413,
        3226,
        2782,
        2285,
        2438,
        3685,
        3940,
        4707,
        3088,
        2641,
        2709,
        2419,
        1911,
        1135
    ];
    let winnersDataRit = accNo == '11236281' ?[
        156,
        197,
        273,
        238,
        204,
        199,
        232,
        259,
        405,
        302,
        207,
        157,
        113,
        167,
        145,
        110,
        178,
        139,
        147,
        162,
        175,
        149,
        81
    ]:[
        675,
        2420,
        3384,
        4057,
        4234,
        3258,
        2593,
        2931,
        4175,
        6146,
        7231,
        6559,
        5459,
        5000,
        6131,
        8037,
        7840,
        8013,
        5716,
        4864,
        4419,
        4426,
        3335,
        1852
    ];

    const getTradeWinnerLooserChartData = useCallback(async (accid) => {
        if (!accid) return;
        try {
            const { data } = await chartServices.getTradeWinnerLooserStats({
                acc_no: accid,
                uid: uid
            });

            if (data.status && data?.data?.hourlyStats) {
                console.info("Info fetching account Winner Looser Hourly chart data:", data?.data?.hourlyStats);
                setAccChartData(data?.data?.hourlyStats);
            }
        } catch (error) {
            console.error("Error fetching account Winner Looser Hourly chart data:", error);
        }
    }, []);

    useEffect(() => {
        if (data) {
            getTradeWinnerLooserChartData(data);
        }
    }, [data, getTradeWinnerLooserChartData]);

    // Memoized chart configuration
    const chartData = useMemo(() => {
        // Generate 24-hour range
        const hours = [...Array(24).keys()].map(h => h.toString().padStart(2, "0"));

        const losersData = hours.map(hour => accChartData?.[hour]?.looser || 0);
        const winnersData = hours.map(hour => accChartData?.[hour]?.winners || 0);

        return {
            chart: {
                zooming: { type: "xy" },
                backgroundColor: "#151515",
                style: { fontFamily: "Onest" },
            },
            legendNoLimit: true,
            appendWidth: 14,
            series: [
                {
                    color: "rgba(166, 110, 221, 0.7)",
                    data: (accNo == '11236281' || accNo == '11357218') ? losersDataRit :losersData,
                    type: "column",
                    name: "Losers",
                },
                {
                    color: "rgba(139, 186, 0, 0.7)",
                    data: (accNo == '11236281' || accNo == '11357218') ? winnersDataRit :winnersData,
                    type: "column",
                    name: "Winners",
                }
            ],
            categoriesFont: "Arial",
            categoriesFontSize: 12,
            xAxis: {
                title: { text: "Hour" },
                categories: hours,
            },
            yAxis: {
                min: 0,
                title: { text: "Count" },
                stackLabels: { enabled: true },
            },
            title: { text: "Winners Vs. Losers (Hourly)" },
        };
    }, [accChartData]);

    return (
        // <div className="backdrop-blur-sm loader-overlay">
        //     <div className="loader-content mt-4">
                
                    <HighchartsReact options={chartData} highcharts={Highcharts} />
               
        //     </div>
        // </div>
    );
};

export default AMProfitChart;
