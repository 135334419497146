import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Select from "react-select";
import CopyAccountServices from "../../../../ApiServices/CopyAccountServices";
import GroupServices from "../../../../ApiServices/GroupServices";
import GroupAccServices from "../../../../ApiServices/GroupAccServices";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toast';
import { FaSpinner } from "react-icons/fa";

const riskTypeList = [{
  label: "Select Risk Type",
  value: ""
},{
  label: "Risk Multiplier By Balance",
  value: "RISK_MULTIPLIER_BY_BALANCE"
}, {
  label: "Risk Multiplier By Equity",
  value: "RISK_MULTIPLIER_By_EQUITY"
}, {
  label: "Lot Multiplier",
  value: "LOT_MULTIPLIER"
}, {
  label: "Fixed Lot",
  value: "FIXED_LOT"
}, {
  label: "None",
  value: "NONE"
}];
const AddGroupAccount = () => {

  const { fk_grp_id: fk_grp_id } = useParams();
  const pages = [
    { title: fk_grp_id > 0 ? "Update Group Account List" : "Add Group Account List", href: "/add-account" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const navigate = useNavigate();

  const [accRows, setAccounts] = useState([]);
  console.log(accRows);
  const [accountList, setAccountList] = useState([]);
  const [oldAccountList, setOldAccountList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupID, setGroupID] = useState({});
  const [selectedUserId, setSelectedUserId] = useState({ value: '', label: '' });
  const [selectedUserName, setSelectedUserName] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState({ value: '', label: '' });
  const [formErrors, setFormErrors] = useState({});
  console.log(formErrors);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);

  useEffect(() => {
    getAllTradeAcc(1);
    getAllOldTradeAcc(1);
    getAllGroups(1);
    if (fk_grp_id) {
      getSingleGroup(fk_grp_id);
    } else {
      setAccounts([
        {
          id: 1,
          gam_id: 0,
          fk_grp_id: fk_grp_id,
          fk_mua_id: "",
          gam_risk_type: "",
          gam_multiplier: "",
          gam_is_smo: 0,
        },
      ]);
    }
  }, [fk_grp_id]);

  const getAllGroups = async (payload) => {
    setIsLoading(true);
    try {
      const res = await GroupServices.getAllGroup(payload)
      if (res.status) {
        setGroupList(res.data.data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }
  const getAllTradeAcc = async (payload) => {
    setIsLoading(true);
    try {
      const res = await CopyAccountServices.getAllCopyTrade(payload)
      if (res.status) {
        setAccountList(res.data.data);

      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }
  const getAllOldTradeAcc = async (payload) => {
    setIsLoading(true);
    try {
      const res = await CopyAccountServices.getAllOldCopyTrade(payload)
      if (res.status) {
        setOldAccountList(res.data.data);

      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const addAccount = () => {
    const newAccount = {
      id: accRows.length + 1,
      fk_grp_id: parseInt(fk_grp_id),
      fk_mua_id: !selectedUserId?.value ? "" : selectedUserId?.value,
      gam_risk_type: "",
      gam_multiplier: "",
      gam_is_smo: 0,
      gam_id: 0,
    };
    setAccounts([...accRows, newAccount]);
  };


  const handleGroupChange = (id, selectedOption) => {
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          fk_grp_id: selectedOption.value,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("fk_grp_id", selectedOption ? selectedOption.value : null, id);
  };

  const handleAccountChange = (id, selectedOption) => {

    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          fk_mua_id: selectedOption.value,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("fk_mua_id", selectedOption ? selectedOption.value : null, id);
  };

  const handleRiskTypeChange = (id, gam_risk_type) => {
    console.log("gam_risk_type=>",gam_risk_type)
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          gam_risk_type: gam_risk_type,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("gam_risk_type", gam_risk_type ? gam_risk_type : 0, id);
  };

  const handleMultiplierChange = (id, gam_multiplier) => {
    console.log("gam_multiplier=>",gam_multiplier)
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          gam_multiplier: gam_multiplier,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("gam_multiplier", gam_multiplier ? gam_multiplier : 0, id);
  };


  const handleAccStateChange = (id, gam_is_smo) => {
    const updatedAccounts = accRows.map((accRow) => {
      if (accRow.id === id) {
        return {
          ...accRow,
          gam_is_smo: gam_is_smo,
        };
      }
      return accRow;
    });
    setAccounts(updatedAccounts);
    validateField("gam_is_smo", gam_is_smo ? gam_is_smo : 0, id);
  };


  const removeAccount = (accRowId) => {
    setAccounts((currentAccounts) =>
      currentAccounts.filter(
        (accRow) => accRow.id !== accRowId
      )
    );
  };

  const validateField = (fieldName, value, accRowId = null) => {
    let errors = { ...formErrors };

    switch (fieldName) {

      case "fk_mua_id":
        if (value) {
          delete errors[`accRow_${accRowId}_acc`];
        } else {
          errors[`accRow_${accRowId}_acc`] = "Account No must be selected.";
        }
        break;

      case "fk_grp_id":
        if (value) {
          delete errors[`accRow_${accRowId}_group`];
        } else {
          errors[`accRow_${accRowId}_group`] = "Group must be selected.";
        }
        break;

      // case "gam_risk_type":
      //   if (value) {
      //     delete errors[`accRow_${accRowId}_risk_type`];
      //   } else {
      //     errors[`accRow_${accRowId}_risk_type`] = "Risk Type must be selected.";
      //   }
      //   break;

      // case "gam_multiplier":
      //   if (value) {
      //     delete errors[`accRow_${accRowId}_multiplier`];
      //   } else {
      //     errors[`accRow_${accRowId}_multiplier`] = "Please Enter Multiplier.";
      //   }
      //   break;


    }

    setFormErrors(errors);
  };

  const validateForm = () => {
    let validationErrors = {};

    let i = 0;
    accRows.forEach((accRow) => {
      if (!accRow.fk_mua_id) {
        validationErrors[`accRow_${accRow.id}_acc`] = `Account ${parseInt(i) + 1} must be selected.`;
      }

      if (!accRow.fk_grp_id) {
        validationErrors[`accRow_${accRow.id}_group`] = `Group ${parseInt(i) + 1} must be selected.`;
      }

      // if (!accRow.gam_risk_type) {
      //   validationErrors[`accRow_${accRow.id}_risk_type`] = `Risk Type ${parseInt(i) + 1} must be Enetr.`;
      // }
      // if (!accRow.gam_multiplier) {
      //   validationErrors[`accRow_${accRow.id}_multiplier`] = `Multiplier ${parseInt(i) + 1} must be Enetr.`;
      // }

      i++;
    });

    return validationErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      setIsSubmitAttempted(true);
      console.error("Validation errors:", validationErrors);
      return;
    }

    setFormErrors({});
    setIsSubmitAttempted(false);

    createPayload();
  };

  const createPayload = () => {
    const accRowsPayload = accRows.map((accRow) => ({
      gam_id: accRow.gam_id,
      fk_grp_id: accRow.fk_grp_id,
      fk_mua_id: accRow.fk_mua_id,
      gam_risk_type: accRow.gam_risk_type,
      gam_multiplier: accRow.gam_multiplier,
      gam_is_smo: accRow.gam_is_smo,
    }));

    const payload = {
      gam_id: 0,
      gam_data: accRowsPayload,
    };

    setTimeout(() => {
      setDisableSubmitButton(false)
    }, 1000)
    setDisableSubmitButton(true)
    GroupAccServices.saveMultiGroupAcc(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        setDisableSubmitButton(false)
        navigate("/group-acc");
      }
      setDisableSubmitButton(false)
    });
  };

  const getSingleGroupAcc = async (fk_grp_id) => {
    setIsLoading(true);
    setAccounts([]);

    await GroupAccServices.getAllWhopAccount({
      fk_grp_id: fk_grp_id,
      search: 3,
    })
      .then(async (res) => {
        const { data, status } = res.data;
        console.log(data);
        if (status) {
          data.forEach((item) => addAccount(item));
          function addAccount(data) {
            const accRowItem = {
              fk_grp_id: data.fk_grp_id,
              fk_mua_id: data.fk_mua_id,
              gam_risk_type: data.gam_risk_type,
              gam_multiplier: data.gam_multiplier,
              gam_is_smo: data?.gam_is_smo,
              id: data.gam_id,
              gam_id: data.gam_id,
            };
            setAccounts((prevAccounts) => [
              ...prevAccounts,
              accRowItem,
            ]);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSingleGroup = async (fk_grp_id) => {

    await GroupServices.getAllSingleGroup(fk_grp_id)
      .then(async (res) => {
        const { data, status } = res.data;
        console.log("GroupServices=>", data);
        if (status) {
          if (data?.m_group_acc_map?.length > 0) {
            let accRowData = data?.m_group_acc_map;
            let accRowDataArr = [];
            accRowData.map((item, index) => {

              accRowDataArr.push({ ...item, id: parseInt(index) + 1 });
            })
            setAccounts(accRowDataArr);
          }
          setGroupID({ label: data?.grp_name, value: data?.grp_id })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">{fk_grp_id > 0 ? "Update" : "Add"} GroupAccount</h1>

        <div className="mt-4 flex">
          <button
            onClick={addAccount}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-edgePrimary px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
          >
            Add
          </button>
        </div>

        {isLoading ? (
          <FallingLinesLoader />
        ) : (
          <div className={`mt-4 flex flex-col `}>
            <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block  min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden  border border-gray-200 sm:rounded-lg">
                  <table className=" table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr className=" ">
                        <th
                          scope="col"
                          className="   group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          SN
                        </th>

                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Group
                        </th>

                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Account No
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Risk Type
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Multiplier
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {accRows.map((accRow) => (
                        <tr key={accRow.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {accRow.id}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <Select
                              isDisabled={fk_grp_id > 0 ? true : false}
                              name="gtoup"
                              value={
                                groupID.value ? groupID : groupList.find(
                                  (options) =>
                                    options.value === accRow.fk_grp_id
                                ) || null
                              }
                              onChange={(option) => {
                                setGroupID(option)
                                handleGroupChange(accRow.id, option)
                              }
                              }
                              options={groupList}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  background: "#151515",
                                  color: "#FFF",
                                  borderRadius: state.isFocused ? "4px 4px 0 0" : 3,
                                }),
                                menuList: styles => ({
                                  ...styles,
                                  background: '#333333',
                                  color: "#FFFFFF",
                                }),
                                option: (styles, { isFocused, isSelected }) => ({
                                  ...styles,
                                  color: isFocused ? '#151515' : isSelected ? '#111111' : undefined, //"#111111",
                                  background: isFocused ? '#FFF' : isSelected ? '#FFF' : undefined,
                                  zIndex: 1
                                }),
                                menu: base => ({
                                  ...base,
                                  zIndex: 100
                                })
                              }}
                              classNamePrefix="select"
                              className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                            {formErrors[
                              `accRow_${accRow.id}_group`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_group`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <Select

                              name="Account"
                              value={
                                oldAccountList.find(
                                  (options) =>
                                    options.value === accRow.fk_mua_id
                                ) || null
                              }
                              onChange={(option) => {
                                handleAccountChange(accRow.id, option)
                              }
                              }
                              options={accountList}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  background: "#151515",
                                  color: "#FFF",
                                  borderRadius: state.isFocused ? "4px 4px 0 0" : 3,
                                }),
                                menuList: styles => ({
                                  ...styles,
                                  background: '#333333',
                                  color: "#FFFFFF",
                                }),
                                option: (styles, { isFocused, isSelected }) => ({
                                  ...styles,
                                  color: isFocused ? '#151515' : isSelected ? '#111111' : undefined, //"#111111",
                                  background: isFocused ? '#FFF' : isSelected ? '#FFF' : undefined,
                                  zIndex: 1
                                }),
                                menu: base => ({
                                  ...base,
                                  zIndex: 100
                                })
                              }}
                              classNamePrefix="select"
                              className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                            {formErrors[
                              `accRow_${accRow.id}_acc`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_acc`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {/* {JSON.stringify(accRow)} */}
                            <select
                              value={accRow.gam_risk_type}
                              onChange={(e) => { handleRiskTypeChange(accRow.id, e.target.value) }}
                              name={`accRow_${accRow.id}_risk_type`}
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            >
                              {riskTypeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {formErrors[
                              `accRow_${accRow.id}_risk_type`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_risk_type`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {/* {accRow.gam_multiplier} */}
                            <input
                              value={accRow.gam_multiplier}
                              onChange={(e) => { handleMultiplierChange(accRow.id, e.target.value) }}
                              type="number"
                              placeholder="Enter Group Multiplier"
                              name={`accRow_${accRow.id}_multiplier`}
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                            />
                            {formErrors[
                              `accRow_${accRow.id}_multiplier`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_multiplier`
                                    ]
                                  }
                                </span>
                              )}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span className="isolate inline-flex rounded-md shadow-sm">
                              <button
                                type="button"
                                className={` ${accRow.gam_is_smo == 2 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 focus:z-10"`}
                                onClick={() => handleAccStateChange(fk_grp_id, accRow.gam_is_smo, accRow)}
                              >
                                Slave
                              </button>
                              <button
                                type="button"
                                className={` ${accRow.gam_is_smo == 1 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"`}
                                onClick={() => handleAccStateChange(fk_grp_id, accRow.gam_is_smo, accRow)}
                              >
                                Master
                              </button>
                              <button
                                type="button"
                                className={` ${accRow.gam_is_smo == 0 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"`}
                                onClick={() => handleAccStateChange(fk_grp_id, accRow.gam_is_smo, accRow)}
                              >
                                Off
                              </button>


                            </span>
                            {formErrors[
                              `accRow_${accRow.id}_accState`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `accRow_${accRow.id}_accState`
                                    ]
                                  }
                                </span>
                              )}
                          </td>


                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {accRow.gam_id <= 0 ? (<button
                              onClick={() => removeAccount(accRow.id)}
                              className="text-red-500 hover:text-red-700"
                            >
                              Remove
                            </button>) : (<></>)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-2 flex items-center justify-between gap-x-6">
        <div className="mt-4">
          <button
            disabled={disableSubmitButton}
            onClick={handleSubmit} // Trigger createPayload when this button is clicked
            className="inline-flex items-center justify-center rounded-md bbt bg-edgePrimary px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            {fk_grp_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Submit")}
          </button>
        </div>

      </div>
    </div >
  );
};

export default AddGroupAccount;
