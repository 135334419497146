import React from "react";
import { Switch } from "@headlessui/react";

const AccountHeader = ({
    accInfo,
    setConfigData,
    // addParamConfig,
    stopTrading,
    setStopTrading,
    onDeleteOpen,
    lamId,
    closeTrading,
    setCloseTrading,
    accBanned,
    setAccBanned,
}) => {
    const userStatusToggle = (...classes) => classes.filter(Boolean).join(" ");

    return (
        <div className="flex justify-between items-center">
            <div className="py-6">
                <h1 className="text-xl font-bold mb-4 text-white">Account Matrix</h1>
                <p className="mt-1 max-w-2xl text-md leading-6 text-gray-300">
                    Detailed Account MetriX of <b>{accInfo?.acc_name}</b>.
                </p>
            </div>
            {/* <div className="px-8 py-6 sm:px-6">
                <div className="flex justify-between items-center">
                    
                </div>
            </div> */}
        </div>
    );
};

export default AccountHeader;
