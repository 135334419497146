import React, { useCallback, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import MasterServices from '../../../ApiServices/MasterServices';
import paymentServices from '../../../ApiServices/paymentServices';
import Highcharts, { chart, format } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

// Register the necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const StatsChart = () => {

  const [stateData, setStateData] = React.useState([]);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async() => {
    try {
      const { data } = await paymentServices.getAllStats({});

      if (data.status && data?.data) {
        console.info("Info fStatsChart:", data?.data);
        const filteredData = data?.data?.filter(item => item?.title !== "Declined Payment");
        setStateData(filteredData);
      }
    } catch (error) {
      console.error("Error fetching account Winner Looser Hourly chart data:", error);
    }
  };

  const renderChart = (chartData, index) => {
    const labels = ['Active', 'Inactive', 'Deleted', 'Total'];

    // Extract the data for each key: active, inactive, deleted, total
    const activeData = chartData.data.find(item => item.key === 'active').count;
    const inactiveData = chartData.data.find(item => item.key === 'inactive').count;
    const deletedData = chartData.data.find(item => item.key === 'deleted').count;
    const totalData = chartData.data.find(item => item.key === 'total').count;

    if(index === 0) {
      // Chart.js data
      const chartDataConfig = {
        labels: [],
        datasets: [
          {
            data: [activeData, inactiveData, deletedData, totalData],
            backgroundColor: [
              '#A7D129', // Active
              '#1E5128', // Inactive
              '#4E9F3D', // Deleted
              '#044A42',  // Total
            ],
            borderColor: [
              '#A7D129', // Active
              '#1E5128', // Inactive
              '#4E9F3D', // Deleted
              '#044A42',  // Total
            ],
            borderWidth: 1,
          },
        ],
      };

      // Chart.js options
      const options = {
        responsive: true,
        cutout: '60%', // Donut chart effect
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                console.log(tooltipItem, tooltipItem?.dataIndex);
                return `${labels[tooltipItem?.dataIndex]}: ${tooltipItem.raw}`; // Display the label name
              },
            },
          },
          legend: {
            position: 'top',
          },
        },
      };

      return <Doughnut data={chartDataConfig} options={options} width={100} height={100} />;
    }else if(index === 2) {

      // Initial chart options
          const initialChartOptions = {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#151515",
                        style: {
                            fontFamily: 'Onest'
                        }
                    },
                    credits: {
                        text: 'EdgeFin: ' +
                            '<a href="https://www.EdgeFin.com"' +
                            'target="_blank">EF</a>'
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> ' +
                            '{point.name}</b><br/>' +
                            'Count: <b>{point.y}</b><br/>'
                    },
                    series: [{
                        minPointSize: 10,
                        innerSize: '20%',
                        zMin: 0,
                        name: 'Symbols',
                        borderRadius: 5,
                        data: [activeData, inactiveData, deletedData, totalData],
                        colors: [
                            "#22FF10",
                            "#2BFF1A",
                            "#3CFF24",
                            "#4DFF2E",
                            "#5EFF38",
                            "#6FFF42",
                            "#80FF4C",
                            "#91FF56",
                            "#A2FF60",
                            "#B3FF6A",
                            "#C4FF74",
                            "#D5FF7E",
                            "#E6FF88",
                            "#F7FF92",
                            "#08FF9C",
                            "#19FFA6",
                            "#2AFFB0",
                            "#3BFFBA",
                            "#4CFFC4",
                            "#5DFFCE"
                        ]
                    }]
                };


      return <HighchartsReact
          options={initialChartOptions}
          highcharts={Highcharts}
      />
    }

  };

  return (
    <div className='p-4 '>
      {/* <h2 className="text-white">Stats Donut Charts</h2> */}
      <div className="chart-container justify-between flex flex-wrap gap-4">
        {stateData.map((chartData, index) => (
          <div key={chartData.title} className="chart-item w-full md:w-1/4 lg:w-1/4">
            <h3 className="text-center">
              <span className="inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 font-medium text-md text-green-400 ring-1 ring-inset ring-green-500/20">
                {chartData.title}
              </span>
            </h3>
            {renderChart(chartData, index)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsChart;
