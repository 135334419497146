import { Outlet } from "react-router-dom";

const PaymentsLayout = () => {
  return (
    <>
    {/* // <div> */}
        {/* <h1>Payments</h1> */}
        <Outlet /> {/* This is required to render child routes */}
    {/* // </div> */}
    </>
  );
};

export default PaymentsLayout;
