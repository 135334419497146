import React, { useCallback, useEffect, useState } from 'react';
import Highcharts, { chart } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import chartServices from '../../ApiServices/chartServices';

const AMMonthlyActivityChart = ({uid, data, someData }) => {
    let accNo = data;
    let categoriesRit = accNo == '11236281' ? [
        "Jan 2025",
        "Feb 2025"
    ] : [
        "Jan 2025"
    ];
    let categories = [
        "Oct 2024",
        "Nov 2024",
        "Dec 2024"
    ];
    let seriesDataRit = accNo == '11236281' ? [
        [
            2.20
        ],
        [
            0.40
        ]
    ] : [
        [
            0.91
        ]
    ];


    const chartData1 = {
        chart: {
            backgroundColor: "#151515",
            type: 'column',
            style: {
                fontFamily: 'Onest'
            }
        },
        title: {
            text: '',
            align: 'left'
        },
        credits: {
            text: 'EdgeFin: ' +
                '<a style="color: #FFFFFF !importatnt;" href="https://www.EdgeFin.com"' +
                'target="_blank">EF</a>',
        },
        xAxis: [{
            type: 'category',
            labels: {
                style: {
                    color: "#fff"
                }
            },
            title: {
                style: {
                    color: "#fff"
                }
            },
            /* crosshair: {
                enabled: true,
                events: {
                    click: function() {
                        alert('category-click on ' + this.chart.columnIndex);
                        console.log('category-click on ', chart.columnIndex)
                    }
                }
            }   */
        }],
        yAxis: [{
            gridLineColor: '#333333',
            labels: {
                style: {
                    color: "#fff"
                }
            },
            title: {
                enabled: false,
                /* style: {
                    color: "#fff"
                } */
            },

        }],
        plotOptions: {
            series: {
                events: {
                    click(e) {
                        alert('category-click on ' + e.point.name);
                        console.log('series click on ', e)
                    }
                }
            }
        },
        legend: {
            enabled: false
        },
        series: [
            {
                name: 'Month',
                colorByPoint: true,
                data: [
                    {
                        "name": "January",
                        "y": 8.5,
                        "drilldown": null
                    },
                    {
                        "name": "February",
                        "y": 7.3,
                        "drilldown": null
                    },
                    {
                        "name": "March",
                        "y": 9.1,
                        "drilldown": null
                    },
                    {
                        "name": "April",
                        "y": 6.7,
                        "drilldown": null
                    },
                    {
                        "name": "May",
                        "y": 8.0,
                        "drilldown": null
                    },
                    {
                        "name": "June",
                        "y": 10.2,
                        "drilldown": null
                    },
                    {
                        "name": "July",
                        "y": 11.4,
                        "drilldown": null
                    },
                    {
                        "name": "August",
                        "y": 7.8,
                        "drilldown": null
                    },
                    {
                        "name": "September",
                        "y": 6.1,
                        "drilldown": null
                    },
                    {
                        "name": "October",
                        "y": 9.5,
                        "drilldown": null
                    },
                    {
                        "name": "November",
                        "y": 8.3,
                        "drilldown": null
                    },
                    {
                        "name": "December",
                        "y": 0,
                        "drilldown": null
                    }
                ]

            }
        ]

    }

    const chartDataDefault = {
        chart: {
            backgroundColor: "#151515",
            type: 'column'
        },
        title: {
            text: '',
            align: 'left'
        },
    }

    const updateChartDataWithBalance = (balanceData) => {
        const seriesData = balanceData.map(item => ({
            name: item.monthName,
            y: parseFloat(item.gain) // Convert balance to number
        }));
        const categories = balanceData.map(item => item.monthName);
        return {
            chart: {
                backgroundColor: "#151515",
                type: 'column'
            },
            title: {
                text: '',
                align: 'left'
            },
            credits: {
                text: 'EdgeFin: <a style="color: #FFFFFF !important;" href="https://www.EdgeFin.com" target="_blank">EF</a>',
            },
            xAxis: [{
                categories: (accNo == '11236281' || accNo == '11357218') ? categoriesRit : categories,
                type: 'category',
                labels: { style: { color: "#fff" } },
                title: { style: { color: "#fff" } }
            }],
            yAxis: [{
                gridLineColor: '#333333',
                labels: {
                    style: { color: "#fff" }, formatter: function () {
                        return this.value + "%";
                    }
                },
                title: { enabled: false }
            }],
            plotOptions: {
                series: {
                    events: {
                        click(e) {
                            alert('Category clicked on: ' + e.point.name);
                            console.log('Series clicked on', e);
                        }
                    }
                }
            },
            legend: { enabled: false },
            series: [{
                name: 'Month',
                colorByPoint: true,
                data: (accNo == '11236281' || accNo == '11357218') ? seriesDataRit : seriesData,
                tooltip: {
                    valueSuffix: '%',
                    valuePrefix: ''
                }
            }]
        };
    };
    const [accChartData, setAccChartData] = useState(chartDataDefault);

    const getAccBalanceChartData = useCallback(async (accid) => {
        if (!accid) return;
        try {
            const { data, status } = await chartServices.getMonthlyAccGrowthChart({ acc_no: accid,uid: uid });

            if (data.status && data?.data) {
                // console.error("Error fetching account MonthlyBalance chart data:", data?.data);
                setAccChartData(updateChartDataWithBalance(data?.data?.monthlyData));

            }
        } catch (error) {
            console.error("Error fetching account Balance chart data:", error);
        } finally {
            // console.info("Account Balance chart data fetched successfully");
        }
    }, []);

    useEffect(() => {
        if (data) {
            getAccBalanceChartData(data);
        }
    }, [data, getAccBalanceChartData]);

    return (
        <>
        {/* <div className="backdrop-blur-sm loader-overlay">
            <div className="loader-content mt-4"> */}
                <HighchartsReact
                    options={accChartData}
                    highcharts={Highcharts}
                />
            {/* </div>
        </div> */}
        </>
    );
};

export default AMMonthlyActivityChart;
