import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import ProtectedRoute from "./protectedRoute";



import Login from "../container/pages/login/Login";
import CustomerRegister from "../container/pages/login/CustomerRegister";
import CustomerLogin from "../container/pages/login/CustomerLogin";
import UsersList from "../container/pages/users/Users";
import RoleList from "../container/pages/role/RoleList";
import CountryList from "../container/pages/country/CountryList";

import Profile from "../container/pages/profile/Profile";
import GenderList from "../container/pages/gender/Gender";
import StateList from "../container/pages/state/State";
import CityList from "../container/pages/city/CityNew";
import EditPermissions from "../container/pages/role/EditPermissions";
import WhopUser from "../container/pages/whop/users/Users";
import Customers from "../container/pages/customers/index";
import WhopProduct from "../container/pages/whop/product/Product";
import Product from "../container/pages/whop/product/index";

// import WhopLicense from "../container/pages/whop/license/License";
import WhopLicense from "../container/pages/whop/license/index";
import WhopAddLicense from "../container/pages/whop/license/addLicense";
// import WhopAccount from "../container/pages/whop/account/Account";
import WhopAccount from "../container/pages/whop/account/index";
import WhopAddAccount from "../container/pages/whop/account/AddAccountNew";
import PlanList from "../container/pages/whop/plan/PlanList";
import MasterPlanList from "../container/pages/whop/plan/index";

import CopyTrade from "../container/pages/whop/copyTrade/CopyTrade";
import GroupList from "../container/pages/whop/group/GroupList";
import GroupAccList from "../container/pages/whop/group/GroupAccList";
import AddGroupAccount from "../container/pages/whop/group/AddGroupAccount";
import ParamConfigList from "../container/pages/whop/param_config/ParamConfigList";
import PCGroupAccList from "../container/pages/whop/param_config/PCGroupAccListNew";

import ChatTopic from "../container/pages/forum/index";
import Chat from "../container/pages/forum/chat";
import Google2FA from "../container/pages/google/2FA";
import ServerDetails from "../container/pages/google/serverDetails";
import Course from "../container/pages/course/index";
import CourseChapter from "../container/pages/course/chapter";

import Error from "../container/error/Error";
import Home from "../container/pages/home/Home";
import AccMetrix from "../container/pages/account_metrix/AccountMatrix";
import AILog from "../container/pages/ai_log/AILog";

import ForumHome from "../container/pages/forum/forum-home";
import ForumChat from "../container/pages/forum/forum-chat";

import TransactionList from "../container/pages/transaction/index";
import PaymentList from "../container/pages/transaction/PaymentList";
import PaymentsLayout from "../container/pages/transaction/PaymentsLayout";
import PaymentDetails from "../container/pages/transaction/PaymentDetails";
import QuickPaymentList from "../container/pages/quickPayment/QuickPaymentList";
import WhopCheckoutLink from "../container/pages/whop/plan/checkout";
import DipgateCustomerList from "../container/pages/dipgateCustomer/dipgateCustomerList";



// const WhopLicense = lazy(() => import("../container/pages/whop/license/index"));
// const WhopAddLicense = lazy(() => import("../container/pages/whop/license/addLicense"));
// const WhopAccount = lazy(() => import("../container/pages/whop/account/index"));
// const WhopAddAccount = lazy(() => import("../container/pages/whop/account/AddAccountNew"));
// const PlanList = lazy(() => import("../container/pages/whop/plan/PlanList"));
// const MasterPlanList = lazy(() => import("../container/pages/whop/plan/index"));

// const Profile = lazy(() => import("../container/pages/profile/Profile"));
// const GenderList = lazy(() => import("../container/pages/gender/Gender"));
// const StateList = lazy(() => import("../container/pages/state/State"));
// const CityList = lazy(() => import("../container/pages/city/CityNew"));
// const EditPermissions = lazy(() => import("../container/pages/role/EditPermissions"));
// const WhopUser = lazy(() => import("../container/pages/whop/users/Users"));
// const Customers = lazy(() => import("../container/pages/customers/index"));
// const WhopProduct = lazy(() => import("../container/pages/whop/product/Product"));
// const Product = lazy(() => import("../container/pages/whop/product/index"));

// const Login = lazy(() => import("../container/pages/login/Login"));
// const CustomerRegister = lazy(() => import("../container/pages/login/CustomerRegister"));
// const CustomerLogin = lazy(() => import("../container/pages/login/CustomerLogin"));
// const UsersList = lazy(() => import("../container/pages/users/Users"));
// const RoleList = lazy(() => import("../container/pages/role/RoleList"));
// const CountryList = lazy(() => import("../container/pages/country/CountryList"));

// const CopyTrade = lazy(() => import("../container/pages/whop/copyTrade/CopyTrade"));
// const GroupList = lazy(() => import("../container/pages/whop/group/GroupList"));
// const GroupAccList = lazy(() => import("../container/pages/whop/group/GroupAccList"));
// const AddGroupAccount = lazy(() => import("../container/pages/whop/group/AddGroupAccount"));
// const ParamConfigList = lazy(() => import("../container/pages/whop/param_config/ParamConfigList"));
// const PCGroupAccList = lazy(() => import("../container/pages/whop/param_config/PCGroupAccListNew"));

// const ChatTopic = lazy(() => import("../container/pages/forum/index"));
// const Chat = lazy(() => import("../container/pages/forum/chat"));
// const Google2FA = lazy(() => import("../container/pages/google/2FA"));
// const ServerDetails = lazy(() => import("../container/pages/google/serverDetails"));
// const Course = lazy(() => import("../container/pages/course/index"));
// const CourseChapter = lazy(() => import("../container/pages/course/chapter"));

// const Error = lazy(() => import("../container/error/Error"));
// const Home = lazy(() => import("../container/pages/home/Home"));
// const AccMetrix = lazy(() => import("../container/pages/account_metrix/AccMetrix"));
// const AILog = lazy(() => import("../container/pages/ai_log/AILog"));
// const ForumHome = lazy(() => import("../container/pages/forum/forum-home"));

// const ForumChat = lazy(() => import("../container/pages/forum/forum-chat"));
// const TransactionList = lazy(() => import("../container/pages/transaction/index"));
// const PaymentList = lazy(() => import("../container/pages/transaction/PaymentList"));
// const PaymentsLayout = lazy(() => import("../container/pages/transaction/PaymentsLayout"));
// const PaymentDetails = lazy(() => import("../container/pages/transaction/PaymentDetails"));
// const QuickPaymentList = lazy(() => import("../container/pages/quickPayment/QuickPaymentList"));

const RoutesConfig = () => {
  return (
    <Router>
       <Suspense fallback={<h2>Loading Page...</h2>}></Suspense>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<Login />} />
        <Route path="/cust-signUp" element={<CustomerRegister />} />
        <Route path="/cust-login" element={<CustomerLogin />} />
        <Route path="*" element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/google" element={<Google2FA />} />
          <Route path="/server-info" element={<ServerDetails />} />
          <Route path="/topic" element={<ChatTopic />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/">
            <Route index element={<Chat />} />{" "}
            <Route path=":sct_id" element={<Chat />} />{" "}
          </Route>{" "}

          <Route path="/forum">
            <Route index element={<ForumHome />} />
            <Route path=":thread_id" element={<ForumChat />} />
          </Route>
          <Route path="/course" element={<Course />} />
          <Route path="/chapter" element={<CourseChapter />} />
          <Route path="/chapter/">
            <Route index element={<CourseChapter />} />{" "}
            <Route path=":course_id" element={<CourseChapter />} />{" "}
          </Route>{" "}
          <Route path="/user" element={<UsersList />} />

          <Route path="/plan" element={<MasterPlanList />} />
          <Route path="/plan/">
            <Route index element={<MasterPlanList />} />{" "}
            <Route path=":prod_id" element={<MasterPlanList />} />{" "}
          </Route>{" "}
          <Route path="/checkout/">
            <Route index element={<WhopCheckoutLink />} />{" "}
            <Route path=":whop_prod_id" element={<WhopCheckoutLink />} />{" "}
          </Route>{" "}
          <Route path="/whop-plan" element={<PlanList />} />
          <Route path="/customer" element={<Customers />} />
          <Route path="/dipgate-customer" element={<DipgateCustomerList />} />
          <Route path="/whop-product" element={<WhopProduct />} />

          <Route path="/license" element={<WhopLicense />} />
          <Route path="/license">
            <Route index element={<WhopLicense />} />
            <Route path=":wp_lic_id" element={<WhopAddLicense />} />
          </Route>
          <Route path="/account" element={<WhopAccount />} />
          <Route path="/account">
            <Route index element={<WhopAccount />} />
            <Route path=":fk_wp_lic_id" element={<WhopAddAccount />} />
          </Route>
          <Route path="/copy-trade" element={<CopyTrade />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-list" element={<UsersList />} />

          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
            <Route index element={<RoleList />} />
            <Route path=":role_id" element={<EditPermissions />} />
          </Route>
          <Route path="/gender" element={<GenderList />} />
          <Route path="/country" element={<CountryList />} />
          <Route path="/state" element={<StateList />} />
          <Route path="/city" element={<CityList />} />
          <Route path="/accm" element={<AccMetrix />} />
          {/* <Route path="/group" element={<GroupList />} /> */}
          <Route path="/group-acc" element={<GroupAccList />} />
          <Route path="/group">
            <Route index element={<GroupAccList />} />
            <Route path=":fk_grp_id" element={<AddGroupAccount />} />
          </Route>
          {/* <Route path="/subscription/">
            <Route index element={<Subscription />} />
            <Route path=":customer_id" element={<Subscription />} />
          </Route> */}
          <Route path="/ai-log" element={<AILog />} />
          {/* <Route path="/param-config" element={<ParamConfigList />} /> */}
          <Route path="/param-config" element={<PCGroupAccList />} />
          <Route path="/payments" element={<PaymentsLayout />}>
            <Route index element={<TransactionList />} />
            <Route path=":trax_no/:transaction_id" element={<PaymentDetails />} />
          </Route>
          <Route path="/quick-payment" element={<QuickPaymentList />} />
        </Route>


      </Routes>
    </Router>
  );

};

export default RoutesConfig;