import React, { useEffect, useState, Fragment, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { roleSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { role_columns } from "../../../components/tables/tableheader";
import {
  deleteRole,
  getAllRoles,
  addRole,
  updateRole,
} from "../../../redux/role/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Cog6ToothIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
} from "../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import { useSearch } from "../../../components/tables/SearchContext";
import prodServices from "../../../ApiServices/prodServices";

function RoleList() {
  const pages = [{ title: "Role List", href: "/role", module_id: 3 }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [role_id, setRoleid] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    role_id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { loading, list: roleList } = useSelector((state) => state.role);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  const { searchQuery, setSearchQuery } = useSearch();

  const [filteredRoleList, setFilteredRoleList] = useState([]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredRoleList(roleList);
    } else {
      const filtered = roleList.filter((topic) =>
        topic?.role_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );

      setFilteredRoleList(filtered);
    }
  }, [searchQuery, roleList]);


  console.log("roleList", roleList);
  useEffect(() => {
    getAllRoleList(1);
  }, [])
  useEffect(() => {
    document.title = "EdgeFin  • Roles";
    // dispatch(getAllRoles());
    const delayDebounce = setTimeout(() => {
      getAllRoleList(1);
    }, 3000); // 5 seconds delay

    return () => clearTimeout(delayDebounce);
  }, [searchQuery]);

  const getAllRoleList = async (search) => {
    let payload = {
      serach_txt: searchQuery,
      search
    };
    setIsLoading(true);
    try {
      const res = await prodServices.getAllRole(payload);
      if (res.status) {
        console.log("res.data.data=> products", res);
        setRoleList(res.data.data?.getRoleList);
        setFilteredRoleList(res.data.data?.getRoleList);
        setPageCount(res?.data?.data?.pagination?.totalPages || 0);
        console.log("res?.data?.data?.pagination?.totalPages", res?.data?.data?.pagination);
        setTotalCount(res?.data?.data?.pagination?.totalCount || 0);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules");
      toast.error("Fatal Error");
      setIsLoading(false);
    }
  };

  const initialValues = {
    role_id: "",
    role_name: "",
    role_is_active: "",
  };

  const [formRole, setFormRole] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setRoleid(id);
      const initialValues = {
        role_id: obj.role_id,
        role_name: obj.role_name,
        role_is_active: obj.role_is_active,
      };
      setFormRole(initialValues);
    } else {
      setRoleid("");
      setFormRole(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (role_id) => {
    setConfirmationModal({ role_id, status: true });
  };

  const onDeleteRole = (role_id) => {
    dispatch(deleteRole(role_id));
    dispatch(getAllRoles());
    setConfirmationModal({ role_id: null, status: false });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formRole,
      validationSchema: roleSchema,
      onSubmit: (values, action) => {
        console.log("Submit Clicked", values);
        let body = {
          role_id: role_id,
          role_is_active: true,
          role_name: values.role_name,
        };
        if (role_id === undefined || role_id === null || role_id === "") {
          setTimeout(() => {
            setDisableSubmitButton(false);
          }, 1000);
          setDisableSubmitButton(true);
          dispatch(addRole(body));
        } else {
          setTimeout(() => {
            setDisableSubmitButton(false);
          }, 1000);
          setDisableSubmitButton(true);
          dispatch(updateRole(role_id, body));
        }
        action.resetForm();
        dispatch(getAllRoles());
        if (modalOpenFlage === true) {
          dispatch(getAllRoles());
          setmodalOpenFlage(false);
          setDisableSubmitButton(false);
        }
        navigate("/role");
      },
    });
  return (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={"Are you sure you want to delete this role?"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteRole(confirmationModal.role_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h1 className="text-xl font-semibold text-white">Role List ({totalCount})</h1>
        <div className="flex">
          <input
            type="text"
            placeholder="Search Rolet..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="inline-flex items-center justify-center rounded-md bbt bg-[#222222] px-4 py-2 text-sm font-medium text-white shadow-sm hffff ml-auto ml-2 mr-2"
          />
          {allowedActions.filter((data) => data.permission_id == 10)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-auto"
            >
              Add Role
            </Link>
          ) : null}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={role_columns({ onDeleteOpen, handleDrawer, allowedActions })}
          data={filteredRoleList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          console.log(e);
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-[#151515] shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-[#222222]  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {role_id ? "Update" : "Add"} Role
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-[#27ff10] text-black hover:text-black"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-700 p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="role_name"
                                    className="block text-sm font-medium text-gray-300 mt-2 mb-1"
                                  >
                                    Enter Role Name
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    disabled={
                                      role_id == 1 &&
                                      values.role_name == "Super Admin"
                                    }
                                    value={values.role_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Role Name"
                                    name="role_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.role_name && touched.role_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.role_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md bbt bg-[#27ff10] py-2 px-4 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            {role_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default RoleList;
