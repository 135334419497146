import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import paymentServices from "../../../ApiServices/paymentServices";
import Breadcrumb from "../../../components/Breadcrumb";
import CommonPagination from "../../../components/CommonPagination";
import {
  FaSearch,
  FaEye,
  FaFileInvoice,
  FaCheck,
  FaTimes,
  FaClipboard,
  FaShareAlt,
} from "react-icons/fa";
import { toast } from "react-toast";
import PublicConfirmationModal from "../../../components/PublicConfirmationModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import planServices from "../../../ApiServices/planServices";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
} from "../../../helper/commonHelper";
import { ArrowDownCircleIcon } from "lucide-react";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import { useSearch } from "../../../components/tables/SearchContext";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";

const QuickPaymentList = () => {
  const navigate = useNavigate();
  const [getQPList, setQuickPaymentList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { searchQuery, setSearchQuery } = useSearch();
  const [filteredQuickPaymentList, setFilteredQuickPaymentList] = useState([]);
  const [publicConfirmationModal, setPublicConfirmationModal] = useState({
    plan_id: null,
    qp_is_public: false,
    status: false,
  });
  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    plan_id: null,
  });
  const [allowedActions, setAllowedActions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);
  const handleCopy = (text, loginUserRefURL) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert("Copied to clipboard!");
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const handleShare = (text, loginUserRefURL) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Payment Details",
          text: `Check this payment: ${text}`,
          url: text,
        })
        .catch((err) => console.error("Share failed:", err));
    } else {
      toast.error("Web Share API is not supported in this browser.");
      // alert("Web Share API is not supported in this browser.");
    }
  };

  const handleCopyBak = (text, loginUserRefURL) => {
    navigator.clipboard
      .writeText(loginUserRefURL)
      .then(() => {
        // alert("Copied to clipboard!");
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const handleShareBak = (text, loginUserRefURL) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Payment Details",
          text: `Check this payment: ${text}`,
          url: loginUserRefURL,
        })
        .catch((err) => console.error("Share failed:", err));
    } else {
      toast.error("Web Share API is not supported in this browser.");
      // alert("Web Share API is not supported in this browser.");
    }
  };

  useEffect(() => {
    fetchPayments(pageNo);
  }, [pageNo, searchQuery]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredQuickPaymentList(getQPList);
    } else {
      const filtered = getQPList.filter((topic) =>
        topic?.productName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.plan_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.plan_period?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.whop_plan_id?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );

      setFilteredQuickPaymentList(filtered);
    }
  }, [searchQuery, getQPList]);


  const fetchPayments = async (pageIndex) => {
    setLoading(true);
    setError(null);
    try {
      const payload = {
        page: pageIndex + 1,
        pageSize: 25,
        search_txt: "",
        sortByKey: "plan_id",
        sortBy: "asc",
        isAll: 0,
        search_txt: searchQuery,
        plan_is_whop: 1,
      };
      const { data } = await paymentServices.getQuickPaymentList(payload);
      if (data?.status && data?.data) {
        setQuickPaymentList(data.data.getQPList || []);
        setFilteredQuickPaymentList(data.data.getQPList || []);
        setPageCount(data.data.pagination?.totalPages || 0);
        setTotalCount(data.data.pagination?.totalCount || 0);
      }
    } catch (err) {
      console.error("Error fetching payments:", err);
      setError("Failed to load payments. Try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePlan = async (plan_id, qp_is_public) => {
    setRemoveConfirmationModal({ plan_id, qp_is_public, status: true });
  };
  const confirmDeletePlan = async (plan_id, qp_is_public) => {
    setRemoveConfirmationModal({ plan_id, qp_is_public, status: true });
    let { data, status } = await planServices.deleteQuickPlanCode({
      plan_id,
      qp_is_public,
    });
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        fetchPayments(pageNo);
      }
    } else {
      toast.error(data.message);
    }
    setRemoveConfirmationModal({ plan_id: null, status: false });
  };

  const toggleStatus = (plan_id, currentStatus) => {
    setPublicConfirmationModal({
      plan_id: plan_id,
      qp_is_public: currentStatus,
      status: true,
    });
  };
  const onPublicPlan = async (plan_id, qp_is_public) => {
    let { data, status } = await planServices.changePublicPlan({
      plan_id,
      qp_is_public: !qp_is_public,
    });

    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        setQuickPaymentList((prevList) =>
          prevList.map((payment) =>
            payment.id === plan_id
              ? { ...payment, qp_is_public: !qp_is_public }
              : payment
          )
        );
        fetchPayments(pageNo);
      }
    } else {
      toast.error(data.message);
    }

    setPublicConfirmationModal({
      plan_id: null,
      qp_is_public: false,
      status: false,
    });
  };

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "index", disableSortBy: true },
      { Header: "Product", accessor: "productName" },
      //   { Header: "Product Type", accessor: "productType" },
      { Header: "OnBoarding Charge", accessor: "productOnboardingCharge" },
      { Header: "Plan", accessor: "plan_name" },
      { Header: "Expiry", accessor: "plan_expiry" },
      { Header: "Plan Price", accessor: "plan_subs_price" },
      { Header: "Period", accessor: "whop_plan_type" },
      // { Header: "Period", accessor: "plan_period" },
      { Header: "Code", accessor: "whop_plan_id" },
      //   { Header: "Code", accessor: "quick_payment_code" },
      { Header: "Delete", accessor: "qp_is_public" },
      { Header: "Public/Private", accessor: "status" },
      { Header: "Created AT", accessor: "plan_created_at" },
      { Header: "Action", accessor: "actions" },
    ],
    []
  );

  const handleExport = () => {
    if (!getQPList || getQPList.length === 0) {
      toast.error("No payments found to export!");
      return;
    }

    const dataSheet = getQPList.map((payment, index) => ({
      index: index + 1,
      productName: payment.productName,
      productType: payment.productType,
      productOnboardingCharge: `$${payment.productOnboardingCharge?.toFixed(
        2
      )}`,
      plan_name: `${payment.plan_name}`,
      plan_expiry: payment.plan_expiry,
      plan_subs_price: `$${payment.plan_subs_price?.toFixed(2)}`,
      plan_period: payment.plan_period,
      //   quick_payment_code: payment.quick_payment_code,
      whop_plan_id: payment.whop_plan_id,
      Delete: payment.qp_is_public ? "Public" : "Private",
      plan_created_at: payment.plan_created_at,
    }));

    const fileName = `Quick_Payment_Report_${moment().format(
      "DD-MM-YYYY"
    )}.xlsx`;
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [
      [
        "SN",
        "Product",
        "Product Type",
        "OnBoarding Charge",
        "Plan",
        "Expiry",
        "Plan Price",
        "Period",
        "Code",
        "Delete",
        "Public/Private",
        "Created AT",
      ],
    ]);
    utils.sheet_add_json(ws, dataSheet, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Payments");
    writeFile(wb, fileName);
  };

  const data = useMemo(
    () =>
      filteredQuickPaymentList.map((payment, index) => ({
        index: index + 1,
        productName: payment.productName,
        // productType: payment.productType,
        productOnboardingCharge: `$${payment.productOnboardingCharge
          ? payment.productOnboardingCharge?.toFixed(2)
          : 0
          }`,
        plan_name: `${payment.plan_name}`,
        plan_expiry: payment.plan_expiry,
        plan_subs_price: `$${payment.plan_subs_price ? payment.plan_subs_price?.toFixed(2) : 0
          }`,
        whop_plan_type: payment.whop_plan_type,
        // plan_period: payment.plan_period,
        whop_plan_id: payment.whop_plan_id,
        // quick_payment_code: payment.quick_payment_code,
        qp_is_public: (
          <>
            {allowedActions.filter((data) => data.permission_id == 125)
              ?.length > 0 ? (
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={payment.qp_is_public}
                  onChange={() =>
                    handleDeletePlan(payment.plan_id, payment.qp_is_public)
                  }
                />
                <div className="w-10 h-5 bg-gray-300 rounded-full peer-checked:bg-green-500 relative transition">
                  <div className="absolute w-5 h-5 bg-white rounded-full shadow-md left-1 top-1/2 -translate-y-1/2 transition peer-checked:translate-x-5"></div>
                </div>
              </label>
            ) : (
              ""
            )}
          </>
        ),
        status: (
          <>
            {allowedActions.filter((data) => data.permission_id == 120)
              ?.length > 0 ? (
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={payment.qp_is_public}
                  onChange={() =>
                    toggleStatus(payment.plan_id, payment.qp_is_public)
                  }
                />
                <div className="w-10 h-5 bg-gray-300 rounded-full peer-checked:bg-green-500 relative transition">
                  <div className="absolute w-5 h-5 bg-white rounded-full shadow-md left-1 top-1/2 -translate-y-1/2 transition peer-checked:translate-x-5"></div>
                </div>
              </label>
            ) : (
              ""
            )}
          </>
        ),
        plan_created_at: payment.plan_created_at,
        actions: (
          <div className="flex gap-2">
            {allowedActions.filter((data) => data.permission_id == 121)
              ?.length > 0 && payment.whop_plan_direct_link ? (
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white text-xs py-1 px-2 rounded flex items-center gap-1"
                onClick={() =>
                  handleCopy(
                    payment.whop_plan_direct_link,
                    payment.loginUserRefURL
                  )
                }
              >
                <FaClipboard /> Copy
              </button>
            ) : (
              ""
            )}
            {allowedActions.filter((data) => data.permission_id == 122)
              ?.length > 0 && payment.whop_plan_direct_link ? (
              <button
                className="bg-edgePrimary hover:bg-edgePrimary text-black text-xs py-1 px-2 rounded flex items-center gap-1"
                onClick={() =>
                  handleShare(
                    payment.whop_plan_direct_link,
                    // `Transaction ID: ${payment.whop_plan_direct_link }`,
                    payment.loginUserRefURL
                  )
                }
              >
                <FaShareAlt /> Share
              </button>
            ) : (
              ""
            )}
          </div>
          //   <div className="flex gap-2">
          //     {allowedActions.filter((data) => data.permission_id == 121)
          //       ?.length > 0 && payment.quick_payment_code ? (
          //       <button
          //         className="bg-gray-600 hover:bg-gray-700 text-white text-xs py-1 px-2 rounded flex items-center gap-1"
          //         onClick={() =>
          //           handleCopy(
          //             payment.quick_payment_code,
          //             payment.loginUserRefURL
          //           )
          //         }
          //       >
          //         <FaClipboard /> Copy
          //       </button>
          //     ) : (
          //       ""
          //     )}
          //     {allowedActions.filter((data) => data.permission_id == 122)
          //       ?.length > 0 && payment.quick_payment_code ? (
          //       <button
          //         className="bg-edgePrimary hover:bg-edgePrimary text-black text-xs py-1 px-2 rounded flex items-center gap-1"
          //         onClick={() =>
          //           handleShare(
          //             `Transaction ID: ${payment.quick_payment_code}`,
          //             payment.loginUserRefURL
          //           )
          //         }
          //       >
          //         <FaShareAlt /> Share
          //       </button>
          //     ) : (
          //       ""
          //     )}
          //   </div>
        ),
      })),
    [getQPList, navigate]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy, usePagination);

  return (
    <div className="min-h-screen p-6 bg-[#000000] text-white">
      <Breadcrumb
        pages={[{ title: "Quick Payment List", href: "/quick-payment" }]}
      />
      <PublicConfirmationModal
        title={
          "Are you sure you want to " +
          (publicConfirmationModal.qp_is_public ? "Private" : "Public") +
          " this Plan?"
        }
        confirmationButtonText="Yes"
        open={publicConfirmationModal.status}
        onDelete={() =>
          onPublicPlan(
            publicConfirmationModal.plan_id,
            publicConfirmationModal.qp_is_public
          )
        }
        setOpen={setPublicConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.qp_is_public ? " Delete" : " Undo") +
          " this Quick Payment Link?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          confirmDeletePlan(
            removeConfirmationModal.plan_id,
            removeConfirmationModal.qp_is_public
          );
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <div>
          <h4 className="text-xl font-semibold">Quick Payment List ({totalCount})</h4>
          <h5 className="text-sm text-gray-300 mt-2">
            Browse all Quick Payments and their current statuses
          </h5>
        </div>
        <div className="mt-4 flex items-center gap-2">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search Quick Payment..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-md bg-[#222222] px-4 py-2 text-sm font-medium text-white shadow-sm border border-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
          />

          {/* Condition to Show Button */}
          {allowedActions.some((data) => data.permission_id === 124) &&
            getQPList.length > 0 ? (
            <button
              onClick={handleExport}
              className="rounded-md bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm flex items-center gap-2 border border-transparent hover:bg-[#1fd50d]"
            >
              <ArrowDownCircleIcon className="h-5 w-5" aria-hidden="true" />
              Download Excel
            </button>
          ) : null}
        </div>

      </div>
      {loading ? (
          <FallingLinesLoader />
        // <p className="text-center text-gray-400">
        //   Loading Payment Payment List...
        // </p>
      ) : error ? (
        <p className="text-center text-red-400">{error}</p>
      ) : (
        <div className="border border-gray-50/10 bg-[#151515] rounded-md overflow-hidden mt-4">
          <div className="w-full overflow-x-auto">
            <table
              {...getTableProps()}
              className="min-w-full table-auto text-center border-collapse"
            >
              <thead className="bg-[#222]">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="border-b py-2 px-2 text-xs text-gray-200 cursor-pointer"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="hover:bg-[#444]">
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            className="border-b py-2 px-2 text-xs text-gray-200"
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="border-b py-2 px-2 text-xs text-gray-200 text-center"
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Pagination */}
      {getQPList.length > 0 && (
        <CommonPagination
          pageNo={pageNo}
          pageCount={pageCount}
          gotoPage={setPageNo}
          previousPage={() => setPageNo((prev) => Math.max(prev - 1, 0))}
          nextPage={() =>
            setPageNo((prev) => Math.min(prev + 1, pageCount - 1))
          }
        />
      )}
    </div>
  );
};

export default QuickPaymentList;
