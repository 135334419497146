/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";

export default {

  async getAllCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/customer?search=${payload}`);
    return response;
  },

  async getDipgateCustomerList(payload) {
    // await AuthToken.getCurrentAuth();
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().post(isObj ? `api/dashboard/customer/get-dipgate-customer?${params}` : `api/dashboard/customer/get-dipgate-customer?search=${payload}`);

    // const response = Api().get(`api/dashboard/customer/get-dipgate-customer?search=${payload}`);
    return response;
  },
  async syncDipgateAllCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/sync`);
    return response;
  },

  async saveCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer`, payload);
    return response;
  },

  async getAllSingleCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/customer/${payload}`);
    return response;
  },
  async setCustomerStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/status`, payload);
    return response;
  },
  async deleteCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/remove`, payload);
    return response;
  },

  async importCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/import`, payload);
    return response;
  },
  async getCustomerInfo(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/get-customer-info`, payload);
    return response;
  },
  async resetPassword(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/reset-password`, payload);
    return response;
  },

  ////
};
