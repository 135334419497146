import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Pagination from "../CommonPagination";
import { useEffect, useMemo, useState } from "react";
import { SortIcon, SortUpIcon, SortDownIcon } from "../../assets/icons/sorting";
// import {
//   ArrowLongLeftIcon,
//   ArrowLongRightIcon,
// } from "@heroicons/react/20/solid";
// import { FaAngleDown, FaAngleUp, FaJedi } from 'react-icons/fa';

// Define a default UI for filtering

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  // console.log(preGlobalFilteredRows, "onFilterChange >>>>>")
  const [value, setValue] = useState(globalFilter);
  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  // console.log(value, 'value')
  return (
    <label className="flex gap-x-2 items-baseline">
      {/* <span className="text-gray-400">Search: </span> */}
      <input
        type="text"
        className="rounded-md bg-[#222222] border-gray-600 text-gray-200 placeholder-gray-500 shadow-sm focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onFilterChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

function Table({
  columns,
  data,
  is_toggle,
  table_id,
  printRef,
  zoom,
  isCustomPagination,
  fetchData,
  pageCount: controlledPageCount,
  customPageNo,
  setCustomPageNo,
}) {
  const [pageSize, setPageSize] = useState(25);
  const tableOptions = {
    columns,
    data,
    manualPagination: isCustomPagination,
    initialState: { pageSize },
  };

  if (isCustomPagination) {
    tableOptions.pageCount = controlledPageCount;
    tableOptions.initialState.pageIndex = customPageNo;
  }

  const {
    state: { pageIndex },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize: setTablePageSize,
  } = useTable(
    tableOptions,
    // {
    //   columns,
    //   data,
    //   is_toggle,
    //   manualPagination: isCustomPagination,
    //   // pageCount: isCustomPagination ? controlledPageCount : undefined,
    //   initialState: isCustomPagination?{ pageIndex: 1, pageSize: 10 }:'', // Initial state example
    //   customPageNo,
    //   setCustomPageNo,
    //   // initialState: { pageIndex: 1 },
    // },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  useEffect(() => {
    setTablePageSize(pageSize);
  }, [pageSize, setTablePageSize]);

  const handlePageChange = (newPage) => {
    if (isCustomPagination) {
      setCustomPageNo(newPage);
      gotoPage(newPage);
    } else {
      gotoPage(newPage);
    }
  };

  return (
    <>
      <div className="border border-gray-50/10 bg-[#151515] rounded-md overflow-hidden mt-4">
        {/* SEARCH UI */}
        {/* <div className="sm:flex sm:gap-x-3 px-4 py-4 mt-5 justify-between">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}

      </div> */}
        {/* TABLE UI */}
        <div
          id={table_id}
          ref={printRef}
          className={`flex flex-col ${zoom || ""}`}
        >
          <div className="overflow-x-auto w-full">
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border-t border-b border-gray-600">
                {/* <div className="flex justify-end p-2">
                  <label className="text-gray-200">
                    Show:
                    <select
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                      className="ml-2 p-1 rounded-md bg-[#222222] text-gray-200"
                    >
                      {[10, 25, 50, 100].map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </label>
                </div> */}
                <table
                  {...getTableProps()}
                  className="min-w-full table-auto text-center border-collapse"
                >
                  <thead className="bg-[#222]">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                           <th
                           scope="col"
                           className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                           {...column.getHeaderProps(
                             column.getSortByToggleProps()
                           )}
                           {...column.getHeaderProps({
                             style: { width: column.width },
                           })}
                         >
                           <div className="flex items-center justify-between">
                             {column.render("Header")}
                             {/* Add a sort direction indicator */}
                             <span>
                               {column.isSorted ? (
                                 column.isSortedDesc ? (
                                   <SortDownIcon className="w-4 h-4 text-gray-400" />
                                 ) : (
                                   <SortUpIcon className="w-4 h-4 text-gray-400" />
                                 )
                               ) : (
                                 <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                               )}
                             </span>
                           </div>
                         </th>
                          // <th
                          //   {...column.getHeaderProps(
                          //     column.getSortByToggleProps()
                          //   )}
                          //   className="border-b py-2 px-2 text-xs text-gray-200 cursor-pointer"
                          // >
                          //   {column.render("Header")}
                          //   <span>
                          //     {column.isSorted ? (
                          //       column.isSortedDesc ? (
                          //         <SortDownIcon className="w-4 h-4 text-gray-400" />
                          //       ) : (
                          //         <SortUpIcon className="w-4 h-4 text-gray-400" />
                          //       )
                          //     ) : (
                          //       <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                          //     )}
                          //   </span>
                          // </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      console.log(row);
                      return (
                        <tr {...row.getRowProps()} className={` hover:bg-[#444]`}>
                          {row.cells.map((cell) => (
                           
                            <td
                              {...cell.getCellProps()}
                              className="border-b py-1 px-2 text-xs text-gray-200"
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.length > 0 && !isCustomPagination && (
        <Pagination
          pageNo={pageIndex}
          pageCount={
            isCustomPagination
              ? controlledPageCount
              : Math.ceil(data.length / pageSize)
          }
          gotoPage={handlePageChange}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
        />
      )}
    </>
  );
}

export default Table;
