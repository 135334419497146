import React, { useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom'
import Highcharts, { color } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import chartServices from "../../ApiServices/chartServices";
import { use } from 'react';
import { getGraphGap } from '../../helper/commonHelper';

const AMGrowthChart = ({uid, data, someData }) => {

    let accNo = data;

    let chartData = {
        chart: {
            zooming: {
                type: 'xy'
            },
            backgroundColor: "#151515",
            style: {
                fontFamily: 'Onest'
            }
        },
        plotOptions: {
            areaspline: {
                color: '#26FF10',
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, '#26FF10'],
                        [1, '#26FF1014'],
                    ],
                },
                threshold: null,
                marker: {
                    lineWidth: 1,
                    lineColor: null,
                    fillColor: 'white',
                },
            },
        },
        title: {
            text: '',
            align: 'left'
        },
        credits: {
            text: 'EdgeFin: ' +
                '<a href="https://www.EdgeFin.com"' +
                'target="_blank">EF</a>'
        },
        xAxis: [{
            gridLineColor: '#333333',
            categories: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ],
            crosshair: true,
            labels: {
                style: {
                    color: "#fff"
                }
            },
            title: {
                text: 'Months',
                style: {
                    color: "#fff"
                }
            }
        }],
        yAxis: [{ // Primary yAxis
            gridLineColor: '#333333',
            labels: {
                format: '{value}',
                style: {
                    color: "#fff"
                }
            },
            title: {
                text: 'ABC',
                style: {
                    color: "#fff"
                },
                enabled: false
            }
        }, { // Secondary yAxis
            gridLineColor: '#333333',
            title: {
                text: 'XYZ',
                style: {
                    color: "#fff"
                },
                enabled: false
            },
            labels: {
                format: '{value}',
                style: {
                    color: "#fff"
                }
            },
            opposite: true
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            backgroundColor: "#151515" || 'rgba(255,255,255,0.25)',
            itemStyle: {
                color: '#fff'
            }
        },
        series: []

    }

    const [accChartData, setAccChartData] = useState(chartData);

    const getAccGrowthChartData = useCallback(async (accid) => {
        if (!accid) return;

        try {
            const { data } = await chartServices.getAccGrowthChart({ acc_no: accid,uid: uid });

            if (data.status && data?.data) {
                const growth = data?.data?.gainRes || [];

                const formattedData = growth.map(item => ([
                    new Date(item.gc_ord_open_time),
                    parseFloat(item.gc_cum_gain),     // ✅ Y-axis: Numeric value
                    // item.gc_ord_open_time        // ✅ Store extra data for tooltip
                ]));

                // Define chart configuration
                const updatedChartData = {
                    chart: {
                        type: 'spline',
                        zoomType: 'x' // Enables zooming along the x-axis
                    },
                    title: {
                        // text: 'Gain Data Over Time'
                    },
                    xAxis: {
                        type: 'datetime', // ✅ Required for date-based x-axis
                        dateTimeLabelFormats: {
                            day: '%d %b %Y', // Format as "04 Feb 2025"
                            month: '%b %Y',
                            year: '%Y'
                        },
                        title: {
                            text: 'Date'
                        },
                        labels: {
                            style: {
                                color: '#fff' // Optional: Set label color
                            }
                        },
                        tickInterval: 24 * 3600 * 1000,
                    },

                    yAxis: {
                        title: {
                            text: 'Gain Value'
                        },
                        // min: Math.min(...formattedData.map(d => d[1])) - 1 // Adjust min for better visualization
                    },
                    tooltip: {
                        shared: false,
                        formatter: function () {
                            const formattedUTCDate = Highcharts.dateFormat('%d %b %Y', this.x);
                            console.log('Tooltip Data:', formattedData[this.x][0]);

                            // Find corresponding data point from formattedData
                            const dataPoint = formattedData.find(d => d[0].getTime());

                            if (dataPoint) {
                                const date = dataPoint[0]; // The Date object
                                const formattedDate = Highcharts.dateFormat('%d %b %Y', date.getTime());

                                return `<b>Date:</b> ${new Date(formattedData[this.x][0]).toLocaleString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true
                                })}<br>
                                        <b>Growth:</b> ${this.y.toFixed(2)}%`;
                            }
                            return `<b>Growth:</b> ${this.y.toFixed(2)}%<br>
                            <b>Original Date:</b> ${this.point.options[2]}`;
                        }
                    },
                    plotOptions: {
                        series: {
                            marker: {
                                symbol: 'circle',
                                fillColor: '#FFFFFF',
                                enabled: true,
                                radius: 2.5,
                                lineWidth: 1,
                                lineColor: null
                            },
                            fillColor: {
                                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                stops: [
                                    [0, '#26FF10'],
                                    [1, '#26FF1014'],
                                ],
                            },
                        }
                    },
                    
                    // plotOptions: {
                    //     series: {
                    //         marker: {
                    //             symbol: 'circle',
                    //             fillColor: '#FFFFFF',
                    //             enabled: true,
                    //             radius: 2.5,
                    //             lineWidth: 1,
                    //             lineColor: null
                    //         }
                    //     }
                    // },
                    colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
                    series: [
                       
                        {
                            name: 'Gain Data',
                            data: formattedData,
                            color: '#26FF10'
                        }
                    ]
                };
                console.log('formattedData', [
                    {
                        name: 'Gain Data',
                        data: formattedData
                    }
                ]);
                setAccChartData(updatedChartData);
            }
        } catch (error) {
            console.error("Error fetching chart data:", error);
        }
    }, []);


    useEffect(() => {
        if (data) {
            getAccGrowthChartData(data);
        }
    }, [data, getAccGrowthChartData]);


    return (
        <>
        {/* <div className="backdrop-blur-sm loader-overlay">
            <div className="loader-content mt-4"> */}
                <HighchartsReact
                    options={accChartData}
                    highcharts={Highcharts}
                // options={options}
                />
            {/* </div>
        </div> */}
        </>
    );
};

export default AMGrowthChart;
