const AccountTabs = ({ tabs, currentTab, setAccActiveTab }) => (
    <div className="mb-6 border-b border-gray-800">
        {tabs.length > 0 ? (
            <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                    <button
                        key={tab.name}
                        onClick={() => setAccActiveTab(tab.name)}
                        className={`whitespace-nowrap border-b-2 px-1 pb-4 text-lg font-medium ${
                            tab.name === currentTab ? "border-[#27ff10] text-[#27ff10]" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        }`}
                    >
                        {tab.name}
                    </button>
                ))}
            </nav>
        ) : (
            <p className="text-white">No accounts available</p>
        )}
    </div>
);

export default AccountTabs;
