import momentTz from 'moment-timezone';
import { read, utils, writeFile } from 'xlsx';
import { timeZone } from './constant';
import moment from 'moment';
export const exportToExcel = (data, headings, fileName, sheetName) => {
  // Create a new workbook
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet([]);
  utils.sheet_add_aoa(worksheet, headings);
  utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
  utils.book_append_sheet(workbook, worksheet, 'Report');
  writeFile(workbook, fileName);
};

export const calculateAge = (dateOfBirth) => {
  // console.log("dateOfBirth=>", dateOfBirth)
  const dob = new Date(dateOfBirth);
  const now = new Date();

  const diff = now.getTime() - dob.getTime();
  // console.log("diff=>", diff)
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  return age;
}

// Function to remove HTML tags using regex
export const removeTagsFromString = (htmlString) => {
  const regex = /(<([^>]+)>)/ig;
  return htmlString.replace(regex, '');
};

export const convertToAnotherTimezone = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // console.log(momentTz(date).format(fmt), "India")
  var zone = timeZone;
  var m = momentTz.tz(date, zone).format(fmt);
  // console.log(m, zone)
  return m
}


export const convertUTStoIST = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // console.log(momentTz(date).format(fmt), "India")
  const parsedDate = moment(date);
  // console.log(parsedDate)
  var local = date;
  if (parsedDate.isValid()) {
    const utcMoment = momentTz.utc(parsedDate);
    // Convert UTC to IST (Indian Standard Time)
    local = utcMoment.add(5, 'hours').add(30, 'minutes');
  }
  return local
}

// Helper function to get allowed actions based on pathname
export const getAllowedActions = (allModules, pathname) => {
  let permissionArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        permissionArr.push(module);
      }
    })
  }
  return permissionArr;
};

export const checkPermissionsAndRedirect = (pathname, allModules, navigate) => {

  let moduleArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        moduleArr.push(module.sys_module_relation.module_key);
      }
    })
  }
  // If moduleArr is empty, redirect to '/'
  if (moduleArr.length == 0) {
    navigate('/');
    // window.location.redirect="/";
    return;
  }
};

export const capitalizeFirstLetter = (string) => {

  return string?.charAt(0)?.toUpperCase() + string?.slice(1);

}

export const getGraphGap = (item) => {
  let gap = 2;
  if (item?.length > 5 && item?.length < 100) {
    gap = 5;
  } else if (item?.length > 100 && item?.length < 200) {
    gap = 30;
  } else if (item?.length > 200 && item?.length < 300) {
    gap = 10;
  } else if (item?.length > 300 && item?.length < 500) {
    gap = 15;
  } else if (item?.length > 500 && item?.length < 1000) {
    gap = 20;
  } else if (item?.length > 1000 && item?.length < 1500) {
    gap = 25;
  } else if (item?.length > 1500 && item?.length < 2000) {
    gap = 30;
  } else if (item?.length > 2000 && item?.length < 2500) {
    gap = 35;
  } else if (item?.length > 2500 && item?.length < 3000) {
    gap = 40;
  } else if (item?.length > 3000 && item?.length < 3500) {
    gap = 45;
  }else  if (item?.length > 3500 && item?.length < 4000) {
    gap = 50;
  }else if (item?.length > 4000 && item?.length < 4500) {
    gap = 55;
  }else if (item?.length > 4500 && item?.length < 5000) {
    gap = 60;
  }else if (item?.length > 5000 && item?.length < 5500) {
    gap = 65;
  }else if (item?.length > 5500 && item?.length < 6000) {
    gap = 70;
  }else if (item?.length > 6000 && item?.length < 6500) {
    gap = 75;
  }else if (item?.length > 6500 && item?.length < 7000) {
    gap = 80;
  }else if (item?.length > 7000 && item?.length < 7500) {
    gap = 85;
  }else if (item?.length > 7500 && item?.length < 8000) {
    gap = 90;
  }else if (item?.length > 8000 && item?.length < 8500) {
    gap = 95;
  }else if (item?.length > 8500 && item?.length < 9000) {
    gap = 100;  
  }else if (item?.length > 9000 && item?.length < 9500) {
    gap = 105;  
  }else if (item?.length > 9500 && item?.length < 10000) {
    gap = 110;  
  }else if (item?.length > 10000 && item?.length < 10500) {
    gap = 115;  
  }else if (item?.length > 10500 && item?.length < 11000) {
    gap = 120;  
  }else if (item?.length > 11000 && item?.length < 11500) {
    gap = 125;  
  }else if (item?.length > 11500 && item?.length < 12000) {
    gap = 130;  
  }else if (item?.length > 12000 && item?.length < 12500) {
    gap = 135;  
  }else if (item?.length > 12500 && item?.length < 13000) {
    gap = 140;  
  }else if (item?.length > 13000 && item?.length < 13500) {
    gap = 145;  
  }else if (item?.length > 13500 && item?.length < 14000) {
    gap = 150;  
  }else if (item?.length > 14000 && item?.length < 14500) {
    gap = 155;  
  }else if (item?.length > 14500 && item?.length < 15000) {
    gap = 160;  
  }else if (item?.length > 15000 && item?.length < 15500) {
    gap = 165;  
  }else if (item?.length > 15500 && item?.length < 16000) {
    gap = 170;  
  }else if (item?.length > 16000 && item?.length < 16500) {
    gap = 175;  
  }else if (item?.length > 16500 && item?.length < 17000) {
    gap = 180;  
  }else if (item?.length > 17000 && item?.length < 17500) {
    gap = 185;  
  }else if (item?.length > 17500 && item?.length < 18000) {
    gap = 190;  
  }else if (item?.length > 18000 && item?.length < 18500) {
    gap = 195;  
  }else if (item?.length > 18500 && item?.length < 19000) {
    gap = 200;  
  }else if (item?.length > 19000 && item?.length < 19500) {
    gap = 205;  
  }else if (item?.length > 19500 && item?.length < 20000) {
    gap = 210;  
  }else if (item?.length > 20000 && item?.length < 20500) {
    gap = 215;  
  }else if (item?.length > 20500 && item?.length < 21000) {
    gap = 220;  
  }else if (item?.length > 21000 && item?.length < 21500) {
    gap = 225;  
  }else if (item?.length > 21500 && item?.length < 22000) {
    gap = 230;  
  }else if (item?.length > 22000 && item?.length < 22500) {
    gap = 235;  
  }else if (item?.length > 22500 && item?.length < 23000) {
    gap = 240;  
  }else if (item?.length > 23000 && item?.length < 23500) {
    gap = 245;  
  }else if (item?.length > 23500 && item?.length < 24000) {
    gap = 250;  
  }else if (item?.length > 24000 && item?.length < 24500) {
    gap = 255;  
  }else if (item?.length > 24500 && item?.length < 25000) {
    gap = 260;  
  }else if (item?.length > 25000 && item?.length < 25500) {
    gap = 265;  
  }else if (item?.length > 25500 && item?.length < 26000) {
    gap = 270;  
  }else if (item?.length > 26000 && item?.length < 26500) {
    gap = 275;  
  }else if (item?.length > 26500 && item?.length < 27000) {
    gap = 280;  
  }else if (item?.length > 27000 && item?.length < 27500) {
    gap = 285;  
  }else if (item?.length > 27500 && item?.length < 28000) {
    gap = 290;  
  }else if (item?.length > 28000 && item?.length < 28500) {
    gap = 295;  
  }else if (item?.length > 28500 && item?.length < 29000) {
    gap = 300;  
  }else if (item?.length > 29000 && item?.length < 29500) {  
    gap = 305;  
  }else if (item?.length > 29500 && item?.length < 30000) {
    gap = 310;  
  }else if (item?.length > 30000 && item?.length < 30500) {
    gap = 315;  
  }else if (item?.length > 30500 && item?.length < 31000) {
    gap = 320;  
  }else if (item?.length > 31000 && item?.length < 31500) {
    gap = 325;  
  }else if (item?.length > 31500 && item?.length < 32000) {
    gap = 330;  
  }else if (item?.length > 32000 && item?.length < 32500) {
    gap = 335;  
  }else if (item?.length > 32500 && item?.length < 33000) {
    gap = 340;  
  }else if (item?.length > 33000 && item?.length < 33500) {
    gap = 345;  
  }else if (item?.length > 33500 && item?.length < 34000) {
    gap = 350;  
  }else if (item?.length > 34000 && item?.length < 34500) {
    gap = 355;  
  }else if (item?.length > 34500 && item?.length < 35000) {
    gap = 360;  
  }else if (item?.length > 35000 && item?.length < 35500) {
    gap = 365;  
  }else if (item?.length > 35500 && item?.length < 36000) {
    gap = 370;  
  }else if (item?.length > 36000 && item?.length < 36500) {
    gap = 375;  
  }else if (item?.length > 36500 && item?.length < 37000) {
    gap = 380;  
  }else if (item?.length > 37000 && item?.length < 37500) {
    gap = 385;  
  }else if (item?.length > 37500 && item?.length < 38000) {
    gap = 390;  
  }else if (item?.length > 38000 && item?.length < 38500) {
    gap = 395;  
  }else if (item?.length > 38500 && item?.length < 39000) {
    gap = 400;  
  }else if (item?.length > 39000 && item?.length < 39500) {
    gap = 405;  
  }else if (item?.length > 39500 && item?.length < 40000) {
    gap = 410;  
  }else if (item?.length > 40000 && item?.length < 40500) {
    gap = 415;  
  }else if (item?.length > 40500 && item?.length < 41000) {
    gap = 420;  
  }else if (item?.length > 41000 && item?.length < 41500) {
    gap = 425;  
  }else if (item?.length > 41500 && item?.length < 42000) {
    gap = 430;  
  }else if (item?.length > 42000 && item?.length < 42500) {
    gap = 435;  
  }else if (item?.length > 42500 && item?.length < 43000) {
    gap = 440;  
  }else if (item?.length > 43000 && item?.length < 43500) {
    gap = 445;  
  }else if (item?.length > 43500 && item?.length < 44000) {
    gap = 450;  
  }else if (item?.length > 44000 && item?.length < 44500) {
    gap = 455;  
  }else if (item?.length > 44500 && item?.length < 45000) {
    gap = 460;  
  }else if (item?.length > 45000 && item?.length < 45500) {
    gap = 465;  
  }else if (item?.length > 45500 && item?.length < 46000) {
    gap = 470;  
  }else if (item?.length > 46000 && item?.length < 46500) {
    gap = 475;  
  }else if (item?.length > 46500 && item?.length < 47000) {
    gap = 480;  
  }else if (item?.length > 47000 && item?.length < 47500) {
    gap = 485;  
  }else if (item?.length > 47500 && item?.length < 48000) {
    gap = 490;  
  }else if (item?.length > 48000 && item?.length < 48500) {
    gap = 495;  
  }else if (item?.length > 48500 && item?.length < 49000) {  
    gap = 500;  
  }else if (item?.length > 49000 && item?.length < 49500) {
    gap = 505;  
  }else if (item?.length > 49500 && item?.length < 50000) {
    gap = 510;  
  }else if (item?.length > 50000 && item?.length < 50500) {
    gap = 515;  
  }else if (item?.length > 50500 && item?.length < 51000) {
    gap = 520;
  }

  return gap;
}
export const getGraphGapNew = (item) => {
  if (!item || !Array.isArray(item)) return 2; 

  const length = item.length;

  if (length <= 5) return 2;
  if (length < 100) return 5;

  return Math.min(2 + Math.floor(length / 100) * 5, 520);
};
