import { Provider } from "react-redux";
import RoutesConfig from "./routes/Routes";
import store from "./redux/store";
import "leaflet/dist/leaflet.css";
import { toast, ToastContainer } from "react-toast";
import { SearchProvider } from "./components/tables/SearchContext";
const App = () => {
  return (
    <Provider store={store}>
      <SearchProvider>
        <RoutesConfig />
        <ToastContainer delay={3000} position="top-right" />
      </SearchProvider>
    </Provider>
  );
};

export default App;
