/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";

export default {

  async getAllCopyTrade(payload) {
    // await AuthToken.getCurrentAuth();
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/dashboard/copy-trade?${params}` : `api/dashboard/copy-trade?search=${payload}`);

    // const response = await Api().get(`api/dashboard/copy-trade?search=${payload}`);
    return response;
  },
  async getAllOldCopyTrade(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/copy-trade/old?search=${payload}`);
    return response;
  },

  async saveCopyAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/copy-trade`, payload);
    return response;
  },

};
