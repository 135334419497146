import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import prodServices from "../../../../ApiServices/prodServices";
import WhopCheckoutServices from "../../../../ApiServices/WhopCheckoutServices";
import { toast } from "react-toast";

// Options for Revoke Access
const details = [
    "Never",
    "After seven days",
    "After one month",
    "After three months",
    "After six months",
    "After one year",
    "After other # of days..."
];

// Options for Subscription Period
const subscriptionPeriods = {
    FREE: "7 days Free",
    MONTHLY: "1 month",
    QUARTERLY: "3 months",
    SEMI_ANNUALY: "6 months",
    YEARLY: "1 year",
    CUSTOM: "After other # of days..."
};



// Validation Schema
const validationSchema = Yup.object().shape({
    fk_prod_id: Yup.string().required("Product Name is required"),
    pricingType: Yup.string().required("Pricing Type is required"),

    // Validation for One-Time Pricing
    one_time_price: Yup.number()
        .when("pricingType", {
            is: "one_time",
            then: (schema) => schema
                .required("One-time price is required")
                .positive("Must be a positive number"),
        }),

    // Validation for Recurring Pricing
    subscription_price: Yup.number()
        .when("pricingType", {
            is: "recurring",
            then: (schema) => schema
                .required("Subscription price is required")
                .positive("Must be a positive number"),
        }),

    subscription_period: Yup.string()
        .when("pricingType", {
            is: "recurring",
            then: (schema) => schema.required("Subscription period is required"),
        }),

    initial_fee: Yup.number()
        .when("pricingType", {
            is: "recurring",
            then: (schema) => schema.min(0, "Initial fee cannot be negative"),
        }),

    // Validation for Free and One-Time pricing types
    revoke_access: Yup.string()
        .when("pricingType", {
            is: (type) => type === "one_time" || type === "free",
            then: (schema) => schema.required("Revoke access option is required"),
        }),
});


const WhopCheckoutLink = () => {
    const { whop_prod_id } = useParams(); // Get product ID from URL
    const [prodList, setProductList] = useState([]);
    const [defaultProduct, setDefaultProduct] = useState("");
    const [subscriptionPeriod, setSubscriptionPeriod] = useState("7 days Free");
    const [days, setDays] = useState(null);
    const [customDays, setCustomDays] = useState("");

    useEffect(() => {
        document.title = "EdgeFin • Plans";
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        try {
            const res = await prodServices.getAllProduct({ search: 1, prod_is_whop: 1 });
            if (res.status) {
                const products = res?.data?.data?.products;
                setProductList(products);

                // Check if whop_prod_id exists in products
                const selectedProduct = products.find((p) => p.whop_prod_id === whop_prod_id);
                setDefaultProduct(selectedProduct ? selectedProduct.whop_prod_id : products[0]?.whop_prod_id);
            }
        } catch (e) {
            console.error("Error in getAllProducts:", e);
            toast.error("Failed to fetch products.");
        }
    };

    const getDaysFromSubscription = (subscriptionType) => {
        switch (subscriptionType) {
            case "FREE":
                return 7; // 7 days free
            case "MONTHLY":
                return 30; // 1 month = 30 days
            case "QUARTERLY":
                return 90; // 3 months = 90 days
            case "SEMI_ANNUALY":
                return 182; // 6 months = 182 days
            case "YEARLY":
                return 365; // 1 year = 365 days
            case "CUSTOM":
                return null; // This will need user input for days
            default:
                return 0; // In case of invalid subscription type
        }
    };

    return (
        <div className="p-6 bg-[#151515] text-white rounded-lg shadow-md max-w-lg mx-auto">
            <h2 className="text-lg font-semibold">Pricing</h2>

            <Formik
                enableReinitialize
                initialValues={{
                    fk_prod_id: whop_prod_id, // Set from whop_prod_id or first product
                    pricingType: "recurring",
                    one_time_price: "",
                    subscription_price: "",
                    subscription_period: "1 month",
                    trial_period_days: "",
                    expiration_days: "",
                    initial_fee: "",
                    revoke_access: "Never",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {

                    values.expiration_days = values.pricingType === "one_time" ? 365 : days;
                    values.trial_period_days = customDays;
                    values.subscription_period = subscriptionPeriod;
                    let getResponse = await WhopCheckoutServices.createWhopCheckout(values);
                    console.log("Submitted values:", values, getResponse?.data?.whopPlan);
                    toast.success("Form submitted successfully");
                    if (getResponse?.data?.whopPlan?.length > 0) {
                        window.open(getResponse?.data?.whopPlan[0]?.whop_plan_direct_link, "_blank");
                    }
                }}
            >
                {({ values }) => (
                    <Form>
                        <div className="p-4 sm:p-6">
                            {/* Product Name Dropdown (Styled like an Input) */}
                            <div className="mt-2 mb-2">
                                <label className="block text-sm font-medium text-gray-300 mb-1">
                                    Product Name<span className="text-red-600">*</span>
                                </label>
                                <Field
                                    as="select"
                                    name="fk_prod_id"
                                    disabled
                                    className="block w-full bg-[#222222] text-white max-w-lg rounded-md border border-gray-600 shadow-sm sm:max-w-xs sm:text-sm px-3 py-2 cursor-not-allowed  text-xs"
                                    value={defaultProduct}
                                >
                                    {prodList?.map((s) => (
                                        <option key={s.whop_prod_id} value={s.whop_prod_id}>
                                            {s.prod_title}
                                        </option>
                                    ))}
                                </Field>

                                <ErrorMessage name="fk_prod_id" component="p" className="text-red-600 text-sm" />
                            </div>

                            {/* Pricing Type Selection */}
                            <div className="flex gap-2 mt-4">
                                {["free", "one_time", "recurring"].map((type) => (
                                    <label
                                        key={type}
                                        className={`cursor-pointer p-2 rounded-lg border transition ${values.pricingType === type
                                            ? "border-[#26FF10] bg-[#26FF10] text-black"
                                            : "border-gray-700 hover:bg-gray-700"
                                            }`}
                                    >
                                        <Field type="radio" name="pricingType" value={type} className="hidden" />
                                        {type === "free" ? "Free" : type === "one_time" ? "One-time" : "Recurring"}
                                    </label>
                                ))}
                            </div>

                            {/* Pricing Inputs */}
                            <div className="mt-6 space-y-4">
                                {values.pricingType === "one_time" && (
                                    <div>
                                        <label className="block text-sm text-gray-300">One-time Price ($)</label>
                                        <Field
                                            type="number"
                                            name="one_time_price"
                                            placeholder="Enter price"
                                            className="w-full p-3 border rounded-md bg-[#222222] border-gray-600 text-white text-xs"
                                        />
                                        <ErrorMessage name="one_time_price" component="p" className="text-red-600 text-sm" />
                                    </div>
                                )}

                                {values.pricingType === "recurring" && (
                                    <>
                                        <div>
                                            <label className="block text-sm text-gray-300">Subscription Price ($)</label>
                                            <Field
                                                type="number"
                                                name="subscription_price"
                                                placeholder="Enter subscription price"
                                                className="w-full p-3 border rounded-md bg-[#222222] border-gray-600 text-white text-xs"
                                            />
                                            <ErrorMessage name="subscription_price" component="p" className="text-red-600 text-sm" />
                                        </div>

                                        <div>
                                            <label className="block text-sm text-gray-300">Subscription Period</label>
                                            <Field
                                                as="select"
                                                name="subscription_period"
                                                className="w-full p-3 border rounded-md bg-[#222222] border-gray-600 text-white text-xs"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setSubscriptionPeriod(selectedValue); // Update the local subscription period state
                                                    const calculatedDays = getDaysFromSubscription(selectedValue);
                                                    setDays(calculatedDays);
                                                }}
                                                value={subscriptionPeriod}
                                            >
                                                {Object.entries(subscriptionPeriods).map(([key, value]) => (
                                                    <option key={key} value={key}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="subscription_period" component="p" className="text-red-600 text-sm" />
                                            {days !== null && (
                                                <div className="mt-4">
                                                    <p className="text-gray-300">Selected period corresponds to: <strong>{days} days</strong></p>
                                                </div>
                                            )}


                                        </div>
                                        {/* Custom Days Input */}
                                        {subscriptionPeriod === "CUSTOM" && (
                                            <div className="mt-4">
                                                <label className="block text-sm text-gray-300">Enter Custom Number of Days for Subscription</label>
                                                <Field
                                                    type="number"
                                                    name="custom_days"
                                                    value={customDays}
                                                    onChange={(e) => setCustomDays(e.target.value)}
                                                    className="w-full p-3 border rounded-md bg-[#222222] border-gray-600 text-white text-xs"
                                                />
                                                <ErrorMessage name="custom_days" component="p" className="text-red-600 text-sm" />
                                            </div>
                                        )}

                                        {/* Initial Fee (Added Back) */}
                                        <div>
                                            <label className="block text-sm text-gray-300">Initial Fee ($)</label>
                                            <Field
                                                type="number"
                                                name="initial_fee"
                                                placeholder="Enter initial fee"
                                                className="w-full p-3 border rounded-md bg-[#222222] border-gray-600 text-white text-xs"
                                            />
                                            <ErrorMessage name="initial_fee" component="p" className="text-red-600 text-sm" />
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="mt-4 bg-[#26FF10] text-black font-semibold py-2 px-4 rounded-md hover:bg-[#22e00e] transition w-full"
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default WhopCheckoutLink;
