import { Link } from "react-router-dom";
import { capitalise } from "../../utilities/utilities";
import { Switch, Menu, Transition } from "@headlessui/react";
import "./style.css"; // import CSS file
import moment from "moment";
import { EyeIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { CurrencyRupeeIcon, PlusIcon } from "@heroicons/react/20/solid";
import {
  ArrowDownCircleIcon,
  Cog6ToothIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
  PlayIcon,
  SpeakerWaveIcon,
  DocumentIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import Select from "react-select";
import React, { Fragment, useEffect, useState } from "react";
import { BsCurrencyRupee } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import TooltipEdge from "../Tooltip";

import {
  FaDownload,
  FaSpinner,
  FaExternalLinkAlt,
  FaEye,
  FaCheckCircle,
  FaTimesCircle,
  FaMoneyBillWave,
  FaUser,
  FaEnvelope,
  FaMobileAlt,
  FaListUl,
  FaClipboard,
  FaShareAlt,
} from "react-icons/fa";

import { calculateAge } from "../../helper/commonHelper";
import {
  BookmarkIcon,
  Monitor,
  MonitorIcon,
  Settings,
  SettingsIcon,
} from "lucide-react";

import { useSearch } from "./SearchContext";
import { toast } from "react-hot-toast";

export function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function magazineStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function magazineIssueStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function productStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}


export const user_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) =>
        capitalise(d.user_first_name) + " " + capitalise(d.user_last_name),
    },
    {
      Header: "Email",
      accessor: "user_email",
    },
    {
      Header: "Mobile",
      accessor: "user_mobile",
    },
    {
      Header: "Role",
      accessor: (d) => capitalise(d.m_role_relation?.role_name),
    },
    /* {
      Header: "Status",
      accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const enabled = cell.row.original.user_is_active;
        const isBtn = cell.row.original.isBtn;

        if (
          allowedActions?.filter((data) => data.permission_id === 20)?.length >
          0 &&
          isBtn
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          return null;
        }
      },
    }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const isBtn = cell.row.original.isBtn;
        const user_is_deleted = cell.row.original.user_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 19
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 21
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     {/* Three-dot button */}
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     {/* Dropdown Menu */}
        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg z-50">

        //         {/* Edit Option */}
        //         {(allowedActions.filter((data) => data.permission_id == 19)?.length > 0 && isBtn) ||
        //           localStorage.getItem("user_id") == cellId ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         ) : null}

        //         {/* Delete Option */}
        //         {allowedActions.filter((data) => data.permission_id == 21)?.length > 0 && isBtn ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpenSection(cellId, user_is_deleted)}
        //                 className={`${active ? "bg-red-100 text-red-600" : "text-red-600"
        //                   } flex items-center w-full px-4 py-2 text-sm`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>
        //         ) : null}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id == 19)
                ?.length > 0 &&
                isBtn) ||
                localStorage.getItem("user_id") == cellId ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 21)?.length >
                0 && isBtn ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, user_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const role_columns = ({
  onDeleteOpen,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className=" text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Role Id",
      accessor: "role_id",
    },
    {
      Header: "Role Name",
      accessor: (d) => capitalise(d.role_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.role_id;
        const isBtn = cell.row.original.isBtn;

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     {/* Three-dot button */}
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     {/* Dropdown Menu */}
        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg z-50">

        //         {/* Edit Option */}
        //         {(allowedActions.filter((data) => data.permission_id == 11)?.length > 0 && isBtn) ||
        //           localStorage.getItem("role_id") == 1 ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         ) : null}

        //         {/* Manage Option */}
        //         {(allowedActions.filter((data) => data.permission_id == 12)?.length > 0 && isBtn) ||
        //           localStorage.getItem("role_id") == 1 ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <Link
        //                 to={`/role/${cellId}`}
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 Manage
        //               </Link>
        //             )}
        //           </Menu.Item>
        //         ) : null}

        //         {/* Delete Option */}
        //         {allowedActions.filter((data) => data.permission_id == 13)?.length > 0 && isBtn ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpen(cellId)}
        //                 className={`${active ? "bg-red-100 text-red-600" : "text-red-600"
        //                   } flex items-center w-full px-4 py-2 text-sm`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>
        //         ) : null}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <div class="inline-flex rounded-md shadow">
              {(allowedActions.filter((data) => data.permission_id == 11)
                ?.length > 0 &&
                isBtn) ||
                localStorage.getItem("role_id") == 1 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  className={`px-2 py-1 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover 
                    ${!(
                      (allowedActions.filter(
                        (data) => data.permission_id == 10
                      )?.length > 0 &&
                        isBtn) ||
                      !localStorage.getItem("role_id") == 1
                    )
                      ? "rounded-r-md"
                      : ""
                    }`}
                >
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
              {allowedActions.filter((data) => data.permission_id == 12)?.length >
                0 && isBtn ? (
                <Link
                  to={`/role/${cellId}`}
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  className={`px-2 py-1 bg-[#26FF10] text-black text-xs border border-t border-b border-edgePrimaryBorder 
                    hover:bg-edgePrimaryHover 
                    ${!(
                      allowedActions.filter(
                        (data) => data.permission_id == 11
                      )?.length > 0 && isBtn
                    )
                      ? "rounded-r-md"
                      : ""
                    }`}
                >
                  Manage
                </Link>
              ) : null}
              {allowedActions.filter((data) => data.permission_id == 13)?.length >
                0 && isBtn ? (
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  class="px-2 py-1 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover"
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

export const country_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Country",
    accessor: (d) => capitalise(d.country_name),
  },
  {
    Header: "Country Code",
    accessor: "country_code",
  },

  {
    Header: "Country ISD Code",
    accessor: "country_isd_code",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.country_id;
      const country_is_edit = cell.row.original.country_is_edit;
      return (
        <>
          {country_is_edit && (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex rounded-md shadow"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>
          )}

          {country_is_edit && (
            <span className="inline-flex rounded-md shadow">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </>
      );
    },
  },
];

export const gender_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Gender English",
    accessor: (d) => capitalise(d.gender_name),
  },
  {
    Header: "Gender Arabic",
    accessor: (d) => capitalise(d.gender_name_ar),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.gender_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex rounded-md shadow"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex rounded-md shadow">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const state_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "State Name English",
    accessor: (d) => capitalise(d.state_name),
  },
  {
    Header: "State Name Arabic",
    accessor: (d) => capitalise(d.state_name_ar),
  },
  {
    Header: "Country Name",
    accessor: (d) => capitalise(d.m_country_relation.country_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.state_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex rounded-md shadow"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex rounded-md shadow">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const city_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "City Name English",
    accessor: (d) => capitalise(d.city_name),
  },
  {
    Header: "City Name Arabic",
    accessor: (d) => capitalise(d.city_name_ar),
  },
  {
    Header: "State En",
    accessor: (d) => capitalise(d.m_state_relation.state_name),
  },
  {
    Header: "State Ar",
    accessor: (d) => capitalise(d.m_state_relation.state_name_ar),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.city_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex rounded-md shadow"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex rounded-md shadow">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const customer_columns = ({
  onCloseTradeOpen,
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  handleBanUserAcc,
  handleForgotPassword
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },

    {
      Header: "Name",
      accessor: (d) => capitalise(d.cust_fname) + " " + capitalise(d.cust_lname),
    },
    {
      Header: "Username",
      accessor: "cust_username",
    },
    {
      Header: "Email",
      // accessor: "cust_email",
      Cell: (cell) => {

        const [isCopied, setIsCopied] = useState(false);

        const handleCopy = (email) => {
          navigator.clipboard.writeText(email)
            .then(() => {
              /* setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 2000); */
              toast.success("Email copied to clipboard");
            })
            .catch((err) => console.error('Failed to copy text: ', err));
        };

        return (
          <div className="flex flex-col">
            <div className="text-sm text-gray-500">
              <TooltipEdge message="Click to copy email">
                <p onClick={() => handleCopy(cell.row.original.cust_email)} className="text-white cursor-pointer">{cell.row.original.cust_email}</p>
              </TooltipEdge>
            </div>
          </div>
        )
      }
    },
    {
      Header: "Mobile",
      accessor: "cust_mobile",
    },
    {
      Header: "License",
      Cell: (cell) => {
        return (
          <p>{cell.row.original.user_plan_details?.length}</p>
        )
      }
    },
    {
      Header: "Single Device Login",
      accessor: (d) =>
        d.cust_auth_token_is_on == true ? "Single Login" : "Multi Login",
      Cell: (cell) => {
        const cellId = cell.row.original.cust_id;
        const enabled = cell.row.original.cust_auth_token_is_on;
        if (
          allowedActions?.filter((data) => data.permission_id === 29)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() =>
                  onCloseTradeOpen(cellId, enabled, "cust_auth_token_is_on")
                }
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: "Close All TRADE",
      accessor: (d) =>
        d.cust_is_closed_all_trade == true ? "Active" : "Inactive",
      Cell: (cell) => {
        const cellId = cell.row.original.cust_id;
        const enabled = cell.row.original.cust_is_closed_all_trade;

        if (
          allowedActions?.filter((data) => data.permission_id === 31)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() =>
                  onCloseTradeOpen(cellId, enabled, "cust_is_closed_all_trade")
                }
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: "Stop All TRADE",
      accessor: (d) => (d.cust_is_stop_all_trade == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.cust_id;
        const enabled = cell.row.original.cust_is_stop_all_trade;

        if (
          allowedActions?.filter((data) => data.permission_id === 30)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() =>
                  onCloseTradeOpen(cellId, enabled, "cust_is_stop_all_trade")
                }
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          return null;
        }
      },
    },
    /* {
        Header: "Pic",
        accessor: "user_profile_pic_url",
  
        Cell: (cell) => {
          const user_profile_pic_url = cell.row.original.user_profile_pic_url;
          const user_name = cell.row.original.user_name;
          return (
            user_profile_pic_url ? (<img src={user_profile_pic_url} width="40" height="40" alt={user_name} />) : (<></>));
        }
      }, */
    {
      Header: "Status",
      accessor: (d) => (d.cust_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.cust_id;
        const enabled = cell.row.original.cust_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 27)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },

    {
      Header: "Created By",
      accessor: (d) => capitalise(d.customer_cu_relation?.user_username),
    },
    {
      Header: "Updated By",
      accessor: (d) => capitalise(d.customer_uu_relation?.user_username),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.cust_id;
        const isBtn = cell.row.original.isBtn;
        const cust_is_deleted = cell.row.original.cust_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 26
        )?.length;
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 28
        )?.length;
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     {/* Three-dot button */}

        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     {/* Dropdown Menu */}
        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg z-50">

        //         {/* Edit Option */}
        //         {(allowedActions.filter((data) => data.permission_id == 26)?.length > 0) ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         ) : null}

        //         {/* Delete Option */}
        //         {(allowedActions.filter((data) => data.permission_id == 28)?.length > 0) ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpenSection(cellId, cust_is_deleted)}
        //                 className={`${active ? "bg-red-100 text-red-600" : "text-red-600"
        //                   } flex items-center w-full px-4 py-2 text-sm`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>
        //         ) : null}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
            <button
                onClick={() => handleForgotPassword(cell.row.original.cust_email)}
                type="button"
                className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder hover:bg-edgePrimaryHover ${actionButtonStatus ? "rounded-l-md" : "rounded-l-md"
                  } focus:z-10`}
              >
                Reset Password
              </button>

              {allowedActions.filter((data) => data.permission_id == 26)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder hover:bg-edgePrimaryHover ${actionButtonStatus ? "rounded-l-md" : "rounded-l-md"
                    } focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 28)?.length >
                0 ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, cust_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder hover:bg-edgePrimaryHover ${actionButtonStatus ? "rounded-r-md" : " rounded-r-md"
                    } focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const customer_columnsBak = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  handleBanUserAcc,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    /* {
        Header: "User ID",
        accessor: "user_id",
      }, */
    {
      Header: "Customer Name",
      accessor: (d) => capitalise(d.user_name),
    },
    {
      Header: "Customer User Name",
      accessor: "user_username",
    },
    {
      Header: "Customer Email",
      accessor: "user_email",
    },
    {
      Header: "Total License",
      accessor: "license",
    },
    {
      Header: "Total Account",
      accessor: "accounts",
    },
    /* {
        Header: "Customer Pic",
        accessor: "user_profile_pic_url",
  
        Cell: (cell) => {
          const user_profile_pic_url = cell.row.original.user_profile_pic_url;
          const user_name = cell.row.original.user_name;
          return (
            user_profile_pic_url ? (<img src={user_profile_pic_url} width="40" height="40" alt={user_name} />) : (<></>));
        }
      }, */
    /* {
        Header: "Status",
        accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
        Cell: (cell) => {
          const cellId = cell.row.original.wp_user_id;
          const enabled = cell.row.original.user_is_active;
  
          if (
            allowedActions?.filter((data) => data.permission_id === 24)?.length >
            0
          ) {
            return (
              <Switch
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled)}
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            );
          } else {
            // Add a default case or return null if nothing should be rendered
            return null;
          }
        },
      }, */
    {
      Header: "BAN (is Banned)",
      accessor: (d) => (d.user_is_banned == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const user_id = cell.row.original.wp_user_id;
        const enabled = cell.row.original.user_is_banned;

        if (
          allowedActions?.filter((data) => data.permission_id === 24)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => handleBanUserAcc(user_id, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    /* {
        Header: "Created By",
        accessor: (d) => capitalise(d.wp_user_cu_relation?.user_first_name),
      },
      {
        Header: "Updated By",
        accessor: (d) => capitalise(d.wp_user_uu_relation?.user_first_name),
      }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.wp_user_id;
        const isBtn = cell.row.original.isBtn;
        const user_is_deleted = cell.row.original.user_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 23
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 25
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 23)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 25)?.length >
                0 && isBtn ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, user_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const whop_product_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    /* {
        Header: "Product ID",
        accessor: "prod_id",
      }, */
    {
      Header: "Product Name",
      accessor: (d) => capitalise(d.prod_name),
    },
    {
      Header: "Product Desc",
      accessor: "prod_description",
    },
    /* {
        Header: "Company Id",
        accessor: "prod_company_id",
      },
      {
        Header: "Page Id",
        accessor: "prod_page_id",
      }, */
    {
      Header: "Visibility",
      accessor: "prod_visibility",
    },

    {
      Header: "Status",
      accessor: (d) => (d.prod_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.wp_prod_id;
        const enabled = cell.row.original.prod_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 30)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    /* {
        Header: "Created By",
        accessor: (d) => capitalise(d.prod_cu_relation?.user_first_name),
      },
      {
        Header: "Updated By",
        accessor: (d) => capitalise(d.prod_uu_relation?.user_first_name),
      }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.wp_prod_id;
        const isBtn = cell.row.original.isBtn;
        const prod_is_deleted = cell.row.original.prod_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 29
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 31
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }
        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 29)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 25)?.length >
                0 && isBtn ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, prod_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const product_columns = ({
  planModalOpen,
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  getProductFile,
  allowedActions,
  setDescModal
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },

    {
      Header: "Product Name",
      accessor: "prod_title",
      Cell: (cell) => {
        const prod_id = cell.row.original.prod_id;
        const prod_title = cell.row.original.prod_title;
        return (
          <div className="flex items-center whitespace-nowrap">
            <span>{prod_title}</span>
          </div>
        );
      },
    },
    {
      Header: "Plan",
      accessor: "product_plan",
      Cell: (cell) => {
        const prod_id = cell.row.original.prod_id;
        const prod_title = cell.row.original.prod_title;
        return (
          <>
            {/* // <div className="flex "> */}
            {/* items-center whitespace-nowrap */}
            {allowedActions?.filter((data) => data.permission_id === 43)?.length >
              0 ? (
              <Link
                to={`/plan/${prod_id}`}
                className="inline-flex px-2 ml-3 text-xs text-edgePrimary leading-5 rounded-full"
              >
                <span>View Plan</span>
                <BookmarkIcon className="w-4 h-4 ml-2" />
              </Link>
            ) : ""}
            {/* // </div> */}
          </>

        );
      },
    },
    {
      Header: "Source",
      accessor: "prod_checkout_link",
      Cell: (cell) => {
        const prod_id = cell.row.original.prod_id;
        const prod_title = cell.row.original.prod_title;
        return (
          <div className="flex items-center whitespace-nowrap">

            {/* <Link
                  to={`/checkout/${cell.row.original.whop_prod_id}`}
                  className="inline-flex px-2 ml-3 text-xs text-edgePrimary leading-5 rounded-full"
                >
                  <span>Create Checkout Link</span>
                  <FaExternalLinkAlt className="w-4 h-4 ml-2" />
                </Link> */}

            {cell.row.original.whop_prod_id ? (
              <div className="flex items-center justify-center w-full" >
                <span className="bg-edgePrimary px-2 py-1 rounded-md text-xs text-black">
                  Whop product
                </span>
              </div>
            ) : <div
              className="flex items-center justify-center w-full"
            >
              <span
                className="bg-gray-800 px-2 py-1 rounded-md text-xs text-gray-100"
              >
                Not a whop product
              </span>
            </div>


            }
          </div>
        );
      },
    },

    // {
    //   Header: "Plan",
    //   accessor: "product_plan",
    //   Cell: (cell) => {
    //     const prod_id = cell.row.original.prod_id;
    //     const prod_title = cell.row.original.prod_title;
    //     return (
    //       <div className="flex items-center whitespace-nowrap">
    //         {allowedActions?.filter((data) => data.permission_id === 43)?.length >
    //           0 ? (
    //           <Link
    //             to={`/plan/${prod_id}`}
    //             className="inline-flex px-2 ml-3 text-xs text-edgePrimary leading-5 rounded-full"
    //           >
    //             <span>View Plan</span>
    //             <BookmarkIcon className="w-4 h-4 ml-2" />
    //           </Link>
    //         ) : ""}

    //       </div>
    //     );
    //   },
    // },
    // {
    //   Header: "Check Out Link",
    //   accessor: "prod_checkout_link",
    //   Cell: (cell) => {
    //     const prod_id = cell.row.original.prod_id;
    //     const prod_title = cell.row.original.prod_title;
    //     return (
    //       <div className="flex items-center whitespace-nowrap">
    //         {cell.row.original.whop_prod_id ? (
    //           <Link
    //             to={`/checkout/${cell.row.original.whop_prod_id}`}
    //             className="inline-flex px-2 ml-3 text-xs text-edgePrimary leading-5 rounded-full"
    //           >
    //             <span>Create Checkout Link</span>
    //             <FaExternalLinkAlt className="w-4 h-4 ml-2" />
    //           </Link>
    //         ) : <div
    //           className="flex items-center justify-center w-full"
    //         >
    //           <span
    //             className="bg-gray-800 px-2 py-1 rounded-md text-xs text-gray-100"
    //           >
    //             Not a whoop product
    //           </span>
    //         </div>


    //         }
    //       </div>
    //     );
    //   },
    // },
    // {
    //   Header: "Product Desc",
    //   accessor: "prod_desc",
    //   Cell: ({ row }) => {
    //     const { prod_id, prod_desc } = row.original;
    //     const [isExpanded, setIsExpanded] = React.useState(false);

    //     // Helper function to add line breaks after every 10 words
    //     const formatTooltip = (text) => {
    //       return text?.split(" ").reduce((acc, word, index) => {
    //         acc += word + " ";
    //         if ((index + 1) % 5 === 0) acc += "<br />";
    //         return acc;
    //       }, "");
    //     };

    //     const formattedDescription = formatTooltip(prod_desc);

    //     // Check if the description needs truncation
    //     const shouldTruncate =
    //       prod_desc?.length > 100 || prod_desc?.split(" ")?.length > 5;
    //     const truncatedDescription = shouldTruncate
    //       ? prod_desc?.split(" ")?.slice(0, 5)?.join(" ") + "..."
    //       : prod_desc;

    //     return (
    //       <>
    //         <span
    //           dangerouslySetInnerHTML={{
    //             __html: isExpanded ? formattedDescription : '',
    //             // __html: isExpanded ? formattedDescription : truncatedDescription,
    //           }}
    //         ></span>
    //         {shouldTruncate && (
    //           <button
    //             onClick={() => setDescModal({
    //               state: true,
    //               desc: prod_desc,
    //               title: row.original.prod_title
    //             })}
    //             style={{
    //               marginLeft: "10px",
    //               cursor: "pointer",
    //               color: "#26FF10",
    //             }}
    //           >
    //             {isExpanded ? "Hide Desc" : "Show Desc"}
    //           </button>
    //         )}
    //         {/* {formattedDescription && (<span
    //           data-tooltip-id="description-tooltip"
    //           data-tooltip-html={formattedDescription} // Use data-tooltip-html for HTML content
    //           style={{
    //             marginLeft: "10px",
    //             cursor: "pointer",
    //             color: "gray",
    //           }}
    //         >
    //           <EyeIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
    //           <Tooltip id="description-tooltip" place="top" />
    //         </span>)} */}
    //       </>
    //     );
    //   },
    // },
    // {
    //   Header: "Onboard Charges",
    //   accessor: "prod_onboarding_charge",
    // },
    // {
    //   Header: "Bonus",
    //   accessor: "prod_bonus",
    // },

    {
      Header: "Status",
      accessor: (d) => (d.prod_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.prod_id;
        const enabled = cell.row.original.prod_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 37)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          return null;
        }
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.prod_id;
        const prod_is_deleted = cell.row.original.prod_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 36
        )?.length;
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 38
        )?.length;
        const getDownloadPermission = allowedActions.filter(
          (data) => data.permission_id == 39
        )?.length;
        const getPlanPermission = allowedActions.filter(
          (data) => data.permission_id == 42
        )?.length;
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     {/* Three-dot button */}
        //     <Menu.Button className="p-1 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     {/* Dropdown Menu (Appears on Top) */}
        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 bottom-full mb-2 w-40 bg-white border rounded-md shadow-lg z-50 origin-bottom-right">

        //         {getPlanPermission > 0 && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => planModalOpen(cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""} flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <PlusIcon className="w-4 h-4 mr-2" />
        //                 Add Plan
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //         {getDownloadPermission > 0 && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => getProductFile(cellId)}
        //                 className={`${active ? "bg-gray-100" : ""} flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 📁 Get Files
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //         {getEditPermission > 0 && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""} flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //         {getDeletePermission > 0 && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpenSection(cellId, prod_is_deleted)}
        //                 className={`${active ? "bg-gray-100" : ""} flex items-center w-full px-4 py-2 text-sm text-red-600`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <div className="flex space-x-2">
            {/* Add Plan Button */}
            {/* {getPlanPermission && (
              <button
                onClick={() => planModalOpen(cellId, cell.row.original)}
                className="px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder rounded-md hover:bg-edgePrimaryHover flex items-center whitespace-nowrap"
              >
                Add Plan
                <PlusIcon className="w-2" />
              </button>
            )} */}

            {/* Get Files Button */}
            {getDownloadPermission && (
              // && cell.row.original?.product_file_version_log?.length > 0
              <button
                onClick={() => getProductFile(cellId)}
                className="px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder rounded-md hover:bg-edgePrimaryHover flex items-center whitespace-nowrap"
              >
                 <span className="sr-only">Get Files</span>
                <ArrowDownCircleIcon className="h-5 w-5" aria-hidden="true" />
                {/* Get Files */}
              </button>
            )}

            {/* Edit Button */}
            {getEditPermission && (
              <button
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder rounded-md hover:bg-edgePrimaryHover flex items-center whitespace-nowrap"
              >
                <span className="sr-only">Edit</span>
                <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}

            {/* Delete Button */}
            {getDeletePermission && (
              <button
                onClick={() => onDeleteOpenSection(cellId, prod_is_deleted)}
                className="px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder rounded-md hover:bg-edgePrimaryHover"
              >
                <span className="sr-only">Delete</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        );
      },
    },
  ];

export const license_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Product Name",
      accessor: (d) => capitalise(d.master_whop_product_relation?.prod_name),
    },
    {
      Header: "User Name",
      accessor: (d) => capitalise(d.master_whop_user_relation?.user_name),
    },
    {
      Header: "License ID",
      accessor: "lic_mem_id",
    },
    /* {
        Header: "Affiliate Name",
        accessor: (d) => capitalise(d.lic_affiliate_username),
      },
      {
        Header: "Checkout ID",
        accessor: "lic_checkout_id",
      },
      {
        Header: "Company Buyer Id",
        accessor: "lic_company_buyer_id",
      }, */
    {
      Header: "License Key",
      accessor: "license_key",
    },
  /* {
      Header: "Page ID",
      accessor: "lic_page_id",
    }, */ {
      Header: "Plan ID",
      accessor: "lic_plan_id",
    },
    {
      Header: "Product ID",
      accessor: "lic_product_id",
    },
    {
      Header: "User ID",
      accessor: "lic_user_id",
    },
    {
      Header: "License Status",
      accessor: "lic_status",
    },
    {
      Header: "Quantity",
      accessor: "lic_quantity",
    },
  /* {
      Header: "Cancel At Period End",
      accessor: "lic_cancel_at_period_end",
    }, {
      Header: "Market Place",
      accessor: "lic_marketplace",
    }, {
      Header: "Valid",
      accessor: "lic_valid",
    }, */ {
      Header: "Renewal Period Start",
      accessor: "lic_renewal_period_start",
    },
    {
      Header: "Renewal Period End",
      accessor: "lic_renewal_period_end",
    },
    {
      Header: "Created AT",
      accessor: "lic_created_at",
    },
    {
      Header: "Expire AT",
      accessor: "lic_expires_at",
    },
    {
      Header: "Status",
      accessor: (d) => (d.lic_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.wp_lic_id;
        const enabled = cell.row.original.lic_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 36)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    /* {
        Header: "Created By",
        accessor: (d) => capitalise(d.lic_cu_relation?.user_first_name),
      },
      {
        Header: "Updated By",
        accessor: (d) => capitalise(d.lic_uu_relation?.user_first_name),
      }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.wp_lic_id;
        const isBtn = cell.row.original.isBtn;
        const lic_is_deleted = cell.row.original.lic_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 35
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 37
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }
        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 35)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              <Link to={`../account/${cellId}`}>
                <button
                  className="px-2 py-2 bg-[#26FF10] text-black text-xs border border-t border-b border-edgePrimaryBorder 
                    hover:bg-edgePrimaryHover"
                  size="default"
                  variant="outlined"
                >
                  Account<span className="sr-only">, </span>
                </button>
              </Link>
              {allowedActions.filter((data) => data.permission_id == 37)?.length >
                0 ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, lic_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const licenseNew_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  handleExpiryDate
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "User Name",
      // accessor: (d) => capitalise(d.m_customer_relation?.cust_username),
      Cell: ({ cell }) => (
        <p className={`text-xs ${cell?.row?.original?.isExpired ? "text-red-600" : ""}`}>{capitalise(cell?.row?.original?.m_customer_relation?.cust_username)}</p>
      ),
    },
    {
      Header: "User Email",
      // accessor: (d) => capitalise(d.m_customer_relation?.cust_email),
      /* Cell: ({ cell }) => (
        <p className={`text-xs ${cell?.row?.original?.isExpired ? "text-red-600" : ""}`}>{capitalise(cell?.row?.original?.m_customer_relation?.cust_email)}</p>
      ), */
      Cell: (cell) => {

        const handleCopy = (email) => {
          navigator.clipboard.writeText(email)
            .then(() => {
              toast.success("Email copied to clipboard");
            })
            .catch((err) => console.error('Failed to copy text: ', err));
        };

        return (
          <div className="flex flex-col">
            <div className="text-sm text-gray-500">
              <TooltipEdge message="Click to copy email">
                <p onClick={() => handleCopy(cell.row.original.m_customer_relation?.cust_email)} className={`text-xs cursor-pointer ${cell?.row?.original?.isExpired ? "text-red-600" : ""}`}>{capitalise(cell?.row?.original?.m_customer_relation?.cust_email)}</p>
              </TooltipEdge>
            </div>
          </div>
        )
      }
    },
    {
      Header: "Product Name",
      accessor: (d) => capitalise(d.product_relation?.prod_title),
    },
    {
      Header: "Plan Name",
      accessor: (d) => capitalise(d.plan_relation?.plan_name),
    },
    {
      Header: "Plan Type",
      // accessor: (d) => capitalise(d.plan_relation?.whop_plan_type),
      Cell: ({ cell }) => (
        <p className={`text-xs ${cell?.row?.original?.isExpired ? "text-red-600" : ""}`}>{capitalise(cell?.row?.original?.plan_relation?.whop_plan_type)}</p>
      ),
    },
    // {
    //   Header: "User Name",
    //   accessor: (d) => capitalise(d.m_customer_relation?.cust_username),
    // },
    // {
    //   Header: "User Email",
    //   accessor: (d) => capitalise(d.m_customer_relation?.cust_email),
    // },
    {
      Header: "Account Name",
      accessor: (d) => capitalise(d.m_account_relation?.acc_name),
    },
    {
      Header: "Account No",
      accessor: (d) => capitalise(d.m_account_relation?.acc_no_str),
    },

    {
      Header: "License Key",
      accessor: "up_license_key",
    },
    {
      Header: "Renewal Period Start",
      accessor: (d) => moment(d.up_start_date).format("DD-MM-YYYY"),
    },
    /* {
      Header: "Renewal Period End",
      accessor: (d) => !!d.up_end_date
        ? moment(d.up_end_date).format("DD-MM-YYYY")
        : moment(d.up_start_date).add(100, 'years').format("DD-MM-YYYY"),
    }, */
    {
      Header: "Renewal Period End",
      accessor: (d) => !!d.up_end_date
        ? moment(d.up_end_date).format("DD-MM-YYYY")
        : moment(d.up_start_date).add(100, 'years').format("DD-MM-YYYY"),
      Cell: (cell) => {

        const [date, setDate] = useState({
          up_id: null,
          up_end_date: null,
        });

        const [error, setError] = useState('');

        const handleClick = (data, end_date) => {
          console.log({ data, end_date });
          setDate({ up_id: data.up_id, up_end_date: end_date || '' });
        };

        const handleSaveUpdate = () => {
          console.log({ date });
          if (!date?.up_end_date || date?.up_end_date.includes('T')) {
            setDate({
              up_id: null,
              up_end_date: null,
            });
          } else {
            console.log(date);
            handleExpiryDate(date);
          }
        };

        return (
          <div className="flex items-center">
            <div
              className={`flex items-center ${date?.up_id === cell.row.original.up_id ? 'hidden' : 'block'}`}
            >
              <span>
                {!!cell.row.original.up_end_date
                  ? moment(cell.row.original.up_end_date).format('DD-MM-YYYY')
                  : moment(cell.row.original.up_start_date).add(100, 'years').format('DD-MM-YYYY')}
              </span>

              <Cog6ToothIcon
                className={`h-5 w-5 ${date?.up_id === cell.row.original.up_id ? 'hidden' : 'block'}`}
                aria-hidden="true"
                onClick={() => handleClick(cell.row.original, cell.row.original.up_end_date)}
              />
            </div>

            <div className="flex flex-col items-center">

              <div className="flex flex-row items-center">
                <input
                  defaultValue={
                    date?.up_end_date ||
                    (cell.row.original.up_end_date
                      ? moment(cell.row.original.up_end_date).format('YYYY-MM-DD')
                      : '')
                  }
                  min={moment().add(1, 'days').format('YYYY-MM-DD')}
                  type="date"
                  className={`bg-gray-700 text-white text-xs ${date?.up_id === cell.row.original.up_id ? 'block' : 'hidden'}`}
                  onChange={(e) => {
                    const selectedDate = moment(e.target.value);
                    const today = moment().startOf('day');

                    if (selectedDate.isAfter(today)) {
                      const newDate = selectedDate.format('DD-MM-YYYY');
                      console.log('Updated Date:', newDate);
                      setDate({ ...date, up_end_date: newDate });
                      setError(''); // Clear any previous error
                    } else {
                      console.error('Please select a future date.');
                      setError('Please select a future date.'); // Set error message
                      e.target.value = ''; // Reset the input value
                    }
                  }}
                />

                <button
                  className={`${date?.up_id === cell.row.original.up_id ? 'block' : 'hidden'}`}
                  onClick={() => handleSaveUpdate()}
                >
                  Save
                </button>
              </div>
              {error && <div className="text-red-500 text-xs mt-1">{error}</div>}

            </div>


          </div>
        );
      }
    },
    {
      Header: "Created AT",
      accessor: (d) => moment(d.up_created_at).format("DD-MM-YYYY"),
    },
    {
      Header: "Status",
      accessor: (d) => (d.up_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.up_id;
        const enabled = cell.row.original.up_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 55)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.up_id;
        const isBtn = cell.row.original.isBtn;
        const up_is_deleted = cell.row.original.up_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 54
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 56
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute bottom-full right-0 mb-2 w-40 bg-white border rounded-md shadow-lg z-50">
        //         {/* Edit Button */}
        //         {allowedActions.filter((data) => data.permission_id == 54)?.length > 0 && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //         {/* Delete Button */}
        //         {allowedActions.filter((data) => data.permission_id == 56)?.length > 0 && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpenSection(cellId, up_is_deleted)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-red-600`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>)}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 54)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {/* <Link
                to={`../account/${cellId}`}
              >
                <button
                  className="px-2 py-2 bg-[#26FF10] text-black text-xs border border-t border-b border-edgePrimaryBorder 
                    hover:bg-edgePrimaryHover"
                  size="default"
                  variant="outlined"
                >
                  Account<span className="sr-only">, </span>
                </button>
              </Link> */}
              {(allowedActions.filter((data) => data.permission_id == 56)?.length > 0 ) ? (
              <button
                onClick={() => onDeleteOpenSection(cellId, up_is_deleted)}
                type="button"
                className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
              >
                <span className="sr-only">Delete</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              ) : null} 
            </span>
          </>
        );
      },
    },
  ];
export const account_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  goToMetrix,
  addParamConfig,
  setConfigData,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Product Name",
      accessor: (d) =>
        capitalise(
          d.master_whop_license_relation?.master_whop_product_relation?.prod_name
        ),
    },
    {
      Header: "Account No",
      accessor: "lam_account_no",
    },
    {
      Header: "User Name",
      accessor: (d) => capitalise(d.master_whop_user_relation?.user_name),
    },
    {
      Header: "User Email",
      accessor: (d) => capitalise(d.master_whop_user_relation?.user_email),
    },
    {
      Header: "MetriX",
      accessor: "metrix",
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_active;
        const acc_id = cell.row.original.lam_account_no;
        const user_id = cell.row.original.fk_wp_user_id;
        const username =
          cell.row.original.master_whop_user_relation.user_username;

        return (
          <button
            type="button"
            class="rounded bg-slate-100 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 transition ease-in-out duration-150 cursor-pointer leading-6"
            onClick={(e) => {
              //navigate("/accm?uid="+cellId+"&name="+cellId+"&accid="+client.mua_acc_login);
              console.log(cell.row);
              goToMetrix(
                "/accm?uid=" + user_id + "&name=" + username + "&accid=" + acc_id
              );
            }}
          >
            Go to MetriX
          </button>
        );
      },
    },
    {
      Header: "Parameter",
      accessor: "config",
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_active;
        const acc_id = cell.row.original.lam_account_no;
        const user_id = cell.row.original.fk_wp_user_id;
        const username =
          cell.row.original.master_whop_user_relation.user_username;

        return (
          <button
            type="button"
            class="rounded bg-slate-100 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 transition ease-in-out duration-150 cursor-pointer leading-6"
            onClick={(e) => {
              setConfigData("");
              addParamConfig(cell.row.original);
            }}
          >
            Add Config
          </button>
        );
      },
    },
    {
      Header: "STOP TRADE",
      accessor: (d) => (d.lam_is_stopped_trading == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_stopped_trading;

        if (
          allowedActions?.filter((data) => data.permission_id === 54)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() =>
                  onDeleteOpen(cellId, enabled, "lam_is_stopped_trading")
                }
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "CLOSE ALL TRADE",
      accessor: (d) =>
        d.lam_is_closed_all_trade == true ? "Active" : "Inactive",
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_closed_all_trade;

        if (
          allowedActions?.filter((data) => data.permission_id === 54)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() =>
                  onDeleteOpen(cellId, enabled, "lam_is_closed_all_trade")
                }
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "BAN (IS BANNED)",
      accessor: (d) => (d.lam_is_banned == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_banned;
        const userBanned =
          cell.row.original.master_whop_user_relation.user_is_banned;

        if (
          allowedActions?.filter((data) => data.permission_id === 54)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                disabled={userBanned}
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled, "lam_is_banned")}
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <span className="text-red-500 text-xs mt-2">
                {userBanned ? "User is Banned" : null}
              </span>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "License Key",
      accessor: (d) => capitalise(d.master_whop_license_relation?.license_key),
    },
    /* {
        Header: "Account ID",
        accessor: "lam_id",
      },{
        Header: "User ID",
        accessor: "fk_wp_user_id",
      },
      {
        Header: "License ID",
        accessor: "fk_wp_lic_id",
      },
      {
        Header: "License Affiliate Username",
        accessor: (d) => capitalise(d.master_whop_license_relation?.lic_affiliate_username),
      },
      {
        Header: "Created By",
        accessor: (d) => capitalise(d.lam_cu_relation?.user_first_name),
      },
      {
        Header: "Updated By",
        accessor: (d) => capitalise(d.lam_uu_relation?.user_first_name),
      }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const isBtn = cell.row.original.isBtn;
        const lam_is_deleted = cell.row.original.lam_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 53
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 55
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 53)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 55)?.length >
                0 && isBtn ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, lam_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const accountNew_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  goToMetrix,
  addParamConfig,
  setConfigData,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Customer Email",
      // accessor: (d) => d.m_customer_relation?.cust_email,
      Cell: (cell) => {

        const handleCopy = (email) => {
          navigator.clipboard.writeText(email)
            .then(() => {
              toast.success("Email copied to clipboard");
            })
            .catch((err) => console.error('Failed to copy text: ', err));
        };

        return (
          <div className="flex flex-col">
            <div className="text-sm text-gray-500">
              <TooltipEdge message="Click to copy email">
                <p onClick={() => handleCopy(cell.row.original.m_customer_relation?.cust_email)} className="text-white cursor-pointer">{cell.row.original.m_customer_relation?.cust_email}</p>
              </TooltipEdge>
            </div>
          </div>
        )
      }
    },
    {
      Header: "Customer Name",
      accessor: (d) => capitalise(d.m_customer_relation?.cust_username),
    },
    {
      Header: "Acc. Name",
      accessor: "acc_name",
    },
    {
      Header: "Acc. No",
      accessor: "acc_no",
    },
    {
      Header: "Acc. Balance",
      accessor: "acc_balance",
    },
    // {
    //   Header: "Customer Name",
    //   accessor: (d) => capitalise(d.m_customer_relation?.cust_username),
    // },
    {
      Header: "MetriX",
      accessor: "metrix",
      Cell: (cell) => {
        const cellId = cell.row.original.acc_id;
        const enabled = cell.row.original.acc_is_active;
        const acc_id = cell.row.original.acc_no;
        const user_id = cell.row.original.fk_cust_id;
        const username = cell.row.original?.m_customer_relation?.cust_username;
        if (
          allowedActions?.filter((data) => data.permission_id === 71)?.length > 0
        ) {
          return (
            <button
              type="button"
              class="rounded px-2 py-1 text-sm font-semibold text-white shadow-sm transition ease-in-out duration-150 cursor-pointer leading-6"
              onClick={(e) => {
                //navigate("/accm?uid="+cellId+"&name="+cellId+"&accid="+client.mua_acc_login);
                // console.log(cell.row);
                goToMetrix(
                  "/accm?uid=" +
                  user_id +
                  "&name=" +
                  username +
                  "&accid=" +
                  acc_id
                );
              }}
            >
              <MonitorIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          );
        }
      },
    },
    {
      Header: "Parameter",
      accessor: "config",
      Cell: (cell) => {
        const cellId = cell.row.original.acc_id;
        const enabled = cell.row.original.acc_is_active;
        const acc_id = cell.row.original.acc_no;
        const user_id = cell.row.original.fk_cust_id;
        const username = cell.row.original?.m_customer_relation?.cust_username;
        if (
          allowedActions?.filter((data) => data.permission_id === 72)?.length > 0
        ) {
          return (
            <button
              type="button"
              class="rounded px-2 py-1 text-sm font-semibold text-white shadow-sm transition ease-in-out duration-150 cursor-pointer leading-6"
              onClick={(e) => {
                setConfigData("");
                addParamConfig(cell.row.original);
              }}
            >
              <SettingsIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          );
        }
      },
    },
    {
      Header: "TOP Account",
      accessor: (d) => (d.acc_is_top == true ? "Yes" : "No"),
      Cell: (cell) => {
        const cellId = cell.row.original.acc_id;
        const enabled = cell.row.original.acc_is_top;

        if (
          allowedActions?.filter((data) => data.permission_id === 73)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled, "acc_is_top")}
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "STOP TRADE",
      accessor: (d) => (d.acc_is_stopped_trading == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.acc_id;
        const enabled = cell.row.original.acc_is_stopped_trading;

        if (
          allowedActions?.filter((data) => data.permission_id === 75)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() =>
                  onDeleteOpen(cellId, enabled, "acc_is_stopped_trading")
                }
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "CLOSE ALL TRADE",
      accessor: (d) =>
        d.acc_is_closed_all_trade == true ? "Active" : "Inactive",
      Cell: (cell) => {
        const cellId = cell.row.original.acc_id;
        const enabled = cell.row.original.acc_is_closed_all_trade;

        if (
          allowedActions?.filter((data) => data.permission_id === 74)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() =>
                  onDeleteOpen(cellId, enabled, "acc_is_closed_all_trade")
                }
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    // {
    //   Header: "BAN (IS BANNED)",
    //   accessor: (d) => (d.lam_is_banned == true ? "Active" : "Inactive"),
    //   Cell: (cell) => {
    //     const cellId = cell.row.original.lam_id;
    //     const enabled = cell.row.original.lam_is_banned;
    //     const userBanned = cell.row.original.master_whop_user_relation.user_is_banned;

    //     if (
    //       allowedActions?.filter((data) => data.permission_id === 54)?.length >
    //       0
    //     ) {
    //       return (
    //         <div className="flex flex-col justify-center items-center">
    //           <Switch
    //             disabled={userBanned}
    //             checked={enabled}
    //             onChange={() => onDeleteOpen(cellId, enabled, 'lam_is_banned')}
    //             className={userStatusToggle(
    //               enabled ? "bg-edgePrimary" : "bg-gray-200",
    //               "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
    //             )}
    //           >
    //             <span className="sr-only">Use setting</span>
    //             <span
    //               aria-hidden="true"
    //               className={userStatusToggle(
    //                 enabled ? "translate-x-5" : "translate-x-0",
    //                 "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
    //               )}
    //             />
    //           </Switch>
    //           <span className="text-red-500 text-xs mt-2">{userBanned ? "User is Banned" : null}</span>
    //         </div>
    //       );
    //     } else {
    //       return null;
    //     }
    //   },
    // },
    // {
    //   Header: "License Key",
    //   accessor: (d) => capitalise(d.master_whop_license_relation?.license_key),
    // },
    {
      Header: "Status",
      accessor: (d) => (d.acc_is_active == true ? "Active" : "In-Active"),
      Cell: (cell) => {
        const cellId = cell.row.original.acc_id;
        const enabled = cell.row.original.acc_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 69)?.length > 0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled, "acc_is_active")}
                className={userStatusToggle(
                  enabled ? "bg-edgePrimary" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        console.log(cell.row.original);
        const cellId = cell.row.original.acc_id;
        const isBtn = cell.row.original.isBtn;
        const lam_is_deleted = cell.row.original.acc_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 68?.length
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 70
        )?.length;
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 bottom-full mb-2 w-40 bg-white border rounded-md shadow-lg z-50">
        //         {allowedActions.some((data) => data.permission_id == 68) && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //         {allowedActions.some((data) => data.permission_id == 70) && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpenSection(cellId, lam_is_deleted)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-red-600`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.some((data) => data.permission_id === 68) && (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
      rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )}

              {allowedActions.some((data) => data.permission_id === 70) && (
                <button
                  onClick={() => onDeleteOpenSection(cellId, lam_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )}
            </span>
          </>
        );
      },
    },
  ];

export const plan_columnsBak = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Plan ID",
      accessor: "plan_id",
    },
    {
      Header: "Product ID",
      accessor: "plan_product",
    },
    {
      Header: "Plan Type",
      accessor: "plan_plan_type",
    },
    {
      Header: "Release Method",
      accessor: "plan_release_method",
    },
    {
      Header: "Visibility",
      accessor: "plan_visibility",
    },
    {
      Header: "Billing Period",
      accessor: "plan_billing_period",
    },
    {
      Header: "Direct Link",
      accessor: "plan_direct_link",
    },
    {
      Header: "Renewal Price",
      accessor: "plan_renewal_price",
    },
    {
      Header: "Initial Price",
      accessor: "plan_initial_price",
    },
    {
      Header: "Base Currency",
      accessor: "plan_base_currency",
    },
    {
      Header: "Payment Method",
      accessor: "plan_accepted_payment_methods",
    },
    {
      Header: "Product Name",
      accessor: (d) => capitalise(d.master_whop_product_relation?.prod_name),
    },
    {
      Header: "Status",
      accessor: (d) => (d.plan_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.plan_id;
        const enabled = cell.row.original.plan_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 62)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "Created By",
      accessor: (d) => capitalise(d.plan_cu_relation?.user_first_name),
    },
    {
      Header: "Updated By",
      accessor: (d) => capitalise(d.plan_uu_relation?.user_first_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.plan_id;
        const isBtn = cell.row.original.isBtn;
        const plan_is_deleted = cell.row.original.plan_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 61
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 63
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 61)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 63)?.length >
                0 && isBtn ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, plan_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const plan_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  onChangePublic,
  handleDrawer,
  allowedActions,
  handleCopy,
  handleShare,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Plan Name",
      accessor: (d) => capitalise(d?.plan_name),
    },
    {
      Header: "Plan Type",
      accessor: "whop_plan_type",
      // accessor: "plan_period",
    },
    {
      Header: "Subscription Price",
      // accessor: "plan_subs_price",
      Cell: ({ cell }) => (
        <span className="text-xs">${cell.row.original.plan_subs_price}</span>
      ),
    },
    {
      Header: "Quick Payment Link",
      accessor: "whop_plan_direct_link",
      Cell: (cell) => {
        const plan_name = cell.row.original.plan_name;
        const whop_plan_direct_link = cell.row.original.whop_plan_direct_link;
        return (
          <div className="flex items-center whitespace-nowrap">

            {cell.row.original.whop_plan_direct_link && (
              <>
                <Link
                  target="_blank"
                  to={`${cell.row.original.whop_plan_direct_link}`}
                  className="inline-flex px-2 ml-3 text-xs text-edgePrimary leading-5 rounded-full"
                >
                  <span>
                    Checkout Link
                  </span>
                  <FaExternalLinkAlt className="w-4 h-4 ml-2" />
                </Link>
                <button
                  className="bg-gray-600 hover:bg-gray-700 text-white text-xs py-1 px-2 rounded flex items-center gap-1 ml-1 mr-1"
                  onClick={() =>
                    handleCopy(
                      cell.row.original.whop_plan_direct_link,
                      ''
                    )
                  }
                >
                  <FaClipboard /> Copy
                </button>

                <button
                  className="bg-edgePrimary hover:bg-edgePrimary text-black text-xs py-1 px-2 rounded flex items-center gap-1"
                  onClick={() =>
                    handleShare(
                      cell.row.original.whop_plan_direct_link,
                      // `Transaction ID: ${payment.whop_plan_direct_link }`,
                      ''
                    )
                  }
                >
                  <FaShareAlt /> Share
                </button>
              </>
            )}
          </div>
        );
      }
    },
    // {
    //   Header: "Product ID",
    //   accessor: "fk_prod_id",
    // },
    // {
    //   Header: "Product Name",
    //   accessor: (d) => capitalise(d.product_master_relation?.prod_title),
    // },
    // {
    //   Header: "Plan Name",
    //   accessor: (d) => capitalise(d?.plan_name),
    // },
    // {
    //   Header: "Plan Type",
    //   accessor: "plan_period",
    // },
    // {
    //   Header: "Subscription Price",
    //   accessor: "plan_subs_price",
    // },
    // {
    //   Header: "Expiry",
    //   accessor: (d) => moment(d.plan_expiry).format("DD-MM-YYYY"),
    // },
    // {
    //   Header: "Desc",
    //   accessor: "plan_desc",
    //   Cell: ({ row }) => {
    //     const { plan_id, plan_desc } = row.original;
    //     const [isExpanded, setIsExpanded] = React.useState(false);

    //     // Helper function to add line breaks after every 10 words
    //     const formatTooltip = (text) => {
    //       return text?.split(" ").reduce((acc, word, index) => {
    //         acc += word + " ";
    //         if ((index + 1) % 5 === 0) acc += "<br />";
    //         return acc;
    //       }, "");
    //     };

    //     const formattedDescription = formatTooltip(plan_desc);

    //     // Check if the description needs truncation
    //     const shouldTruncate =
    //       plan_desc?.length > 100 || plan_desc?.split(" ")?.length > 5;
    //     const truncatedDescription = shouldTruncate
    //       ? plan_desc?.split(" ")?.slice(0, 5)?.join(" ") + "..."
    //       : plan_desc;

    //     return (
    //       <>
    //         <span
    //           dangerouslySetInnerHTML={{
    //             __html: isExpanded ? formattedDescription : '',
    //             // __html: isExpanded ? formattedDescription : truncatedDescription,
    //           }}
    //         ></span>
    //         {shouldTruncate && (
    //           <button
    //             onClick={() => setIsExpanded(!isExpanded)}
    //             style={{ marginLeft: "10px", cursor: "pointer", color: "blue" }}
    //           >
    //             {isExpanded ? "Hide Desc" : "Show Desc"}
    //           </button>
    //         )}
    //         {/* {formattedDescription && (<span
    //           data-tooltip-id="description-tooltip"
    //           data-tooltip-html={formattedDescription} // Use data-tooltip-html for HTML content
    //           style={{
    //             marginLeft: "10px",
    //             cursor: "pointer",
    //             color: "gray",
    //           }}
    //         >
    //           <EyeIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
    //         </span>)} */}

    //         <Tooltip id="description-tooltip" place="top" />
    //       </>
    //     );
    //   },
    // },
    {
      Header: "Public Private Status",
      accessor: (d) => (d.qp_is_public == true ? "Public" : "Private"),
      Cell: (cell) => {
        const cellId = cell.row.original.plan_id;
        const enabled = cell.row.original.qp_is_public;
        if (
          allowedActions?.filter((data) => data.permission_id === 49)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onChangePublic(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: "Status",
      accessor: (d) => (d.plan_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.plan_id;
        const enabled = cell.row.original.plan_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 47)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    // {
    //   Header: "Created By",
    //   accessor: (d) => capitalise(d.mplan_cu_relation?.user_username),
    // },
    // {
    //   Header: "Updated By",
    //   accessor: (d) => capitalise(d.mplan_uu_relation?.user_username),
    // },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.plan_id;
        const isBtn = cell.row.original.isBtn;
        const plan_is_deleted = cell.row.original.plan_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 46
        )?.length;
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 48
        )?.length;
        const getUid = localStorage.getItem("user_id");

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg z-50">
        //         {/* Edit Button */}
        //         {getEditPermission > 0 ? (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${active ? "bg-gray-100" : ""
        //                   } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>) : null}

        //         {/* Delete Button */}
        //         {getDeletePermission > 0 ? (<Menu.Item>
        //           {({ active }) => (
        //             <button
        //               onClick={() => onDeleteOpenSection(cellId, plan_is_deleted)}
        //               className={`${active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-red-600`}
        //             >
        //               <TrashIcon className="w-4 h-4 mr-2" />
        //               Delete
        //             </button>
        //           )}
        //         </Menu.Item>) : null}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {/* {(allowedActions.filter((data) => data.permission_id == 61)?.length > 0) ? ( */}
              <button
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                type="button"
                className={`px-1 py-1 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
              >
                <span className="sr-only">Edit</span>
                <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* ) : null} */}

              {/* {(allowedActions.filter((data) => data.permission_id == 63)?.length > 0 && isBtn) ? ( */}
              <button
                onClick={() => onDeleteOpenSection(cellId, plan_is_deleted)}
                type="button"
                className={`px-1 py-1 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
              >
                <span className="sr-only">Delete</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* ) : null} */}
            </span>
          </>
        );
      },
    },
  ];

export const matrics_trade_history_columns = ({ }) => [
  {
    Header: "Ticket ID",
    accessor: "mth_id",
  },
  {
    Header: "Ticket",
    accessor: "mth_order_ticket",
  },
  {
    Header: "Open Time",
    accessor: "mth_order_open_time",
  },
  /* {
    Header: "Type",
    accessor: "mth_order_type",
  }, */
  {
    Header: "Type",
    accessor: "mth_order_type",
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">
        {cell.value == 0 ? "BUY" : "SELL"}
      </div>
    ), // Adding a class to the cell
  },
  {
    Header: "Lots",
    accessor: "mth_order_lots",
  },
  {
    Header: "Symbol",
    accessor: "mth_order_symbol",
  },
  {
    Header: "Open Price ($)",
    accessor: "mth_order_open_price",
  },
  {
    Header: "Close Price ($)",
    accessor: "mth_order_close_price",
  },
  {
    Header: "Close Time",
    accessor: "mth_order_close_time",
  },
  {
    Header: "SL",
    accessor: "mth_order_sl",
  },
  {
    Header: "TP",
    accessor: "mth_order_tp",
  },
  {
    Header: "Profit",
    accessor: "mth_order_profit",
  },
  {
    Header: "Comission",
    accessor: "mth_order_comm",
  },
  {
    Header: "Swap",
    accessor: "mth_order_swap",
  },
  {
    Header: "Net PNL",
    accessor: "mth_scp_sum",
  },
  /* {
    Header: "Type",
    accessor: (d) => capitalise(d.m_role_relation?.role_name),
  } */
];

export const matrics_trade_live_columns = ({ }) => [
  {
    Header: "Order Ticket",
    accessor: "lt_order_ticket",
  },
  {
    Header: "Order Time",
    accessor: "lt_order_time",
  },
  /* {
    Header: "Type",
    accessor: "lt_order_type",
  }, */
  {
    Header: "Type",
    accessor: "lt_order_type",
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">
        {cell.value == 0 ? "BUY" : "SELL"}
      </div>
    ), // Adding a class to the cell
  },
  {
    Header: "Lots",
    accessor: "lt_order_lots",
  },
  {
    Header: "Symbol",
    accessor: "lt_order_symbol",
  },
  {
    Header: "Open Price ($)",
    accessor: "lt_order_price",
  },
  {
    Header: "SL",
    accessor: "lt_order_sl",
  },
  {
    Header: "TP",
    accessor: "lt_order_tp",
  },
  {
    Header: "Profit",
    accessor: "lt_order_profit",
  },
  {
    Header: "Comissionn",
    accessor: "lt_order_comm",
  },
  {
    Header: "Swap",
    accessor: "lt_order_swap",
  },
];

export const copy_trade_columns = ({ OnChangeAccountType, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Account NO",
    accessor: "mua_acc_login",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.mua_id;
      const mua_acc_login = cell.row.original.mua_acc_login;
      const mua_acc_ms_is_master = cell.row.original.mua_acc_ms_is_master;
      const getEditPermission = allowedActions.filter(
        (data) => data.permission_id == 53
      );
      const getDeletePermission = allowedActions.filter(
        (data) => data.permission_id == 55
      );
      const getUid = localStorage.getItem("user_id");
      let actionButtonStatus = false;
      if (mua_acc_ms_is_master) {
        actionButtonStatus = true;
      }

      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 68)?.length >
            0 ? (
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className={` ${!mua_acc_ms_is_master
                  ? " bg-gray-700 text-white "
                  : "bg-white text-gray-700"
                  } " relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 focus:z-10"`}
              // onClick={() => OnChangeAccountType(cellId, mua_acc_ms_is_master,mua_acc_login)}
              >
                Slave
              </button>
              <button
                type="button"
                className={` ${mua_acc_ms_is_master
                  ? " bg-gray-700 text-white "
                  : "bg-white text-gray-700"
                  } " relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"`}
                onClick={() =>
                  OnChangeAccountType(
                    cellId,
                    mua_acc_ms_is_master,
                    mua_acc_login
                  )
                }
              >
                Master
              </button>
            </span>
          ) : null}
        </>
      );
    },
  },
];

export const group_columns = ({
  onDeleteOpen,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Group Id",
      accessor: "grp_id",
    },
    {
      Header: "Group Name",
      accessor: (d) => capitalise(d.grp_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.grp_id;
        const isBtn = cell.row.original.isBtn;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 71)?.length >
              0 || localStorage.getItem("grp_id") == 1 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex rounded-md shadow"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 73)?.length >
              0 ? (
              <span className="inline-flex rounded-md shadow">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 74)?.length >
              0 ? (
              <Link
                to={`/group/${cellId}`}
                className="inline-flex rounded-md shadow"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  View
                </button>
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

export const ai_log_columns = ({ }) => [
  {
    Header: "Acc No",
    accessor: "ail_acc_login",
  },
  {
    Header: "Account Name",
    accessor: (d) => capitalise(d.mt_user_account_relation?.mua_acc_name),
  },
  {
    Header: "Created At",
    accessor: "ail_created_at",
  },
  {
    Header: "Updated At",
    accessor: "ail_updated_at",
  },
];

export const course_columns = ({
  chapterModalOpen,
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },

    {
      Header: "Course Name",
      accessor: "course_title",
      Cell: (cell) => {
        const course_id = cell.row.original.course_id;
        const course_title = cell.row.original.course_title;
        return (
          <span>{course_title}</span>

        );
      },
    },
    {
      Header: "Chapters",
      accessor: "course_chapter",
      Cell: (cell) => {
        const course_id = cell.row.original.course_id;
        const course_title = cell.row.original.course_title;
        return (
          <div>
            {allowedActions?.filter((data) => data.permission_id === 93)?.length >
              0 ? (
              <Link
                to={`/chapter/${course_id}`}
                className="inline-flex px-2 ml-3 text-xs text-edgePrimary leading-5 rounded-full"
              >
                <span>View Chapters</span>
                <BookmarkIcon className="w-4 h-4 ml-2" />
              </Link>
            ) : null}

          </div>
        );
      },
    },
    {
      Header: "Course Desc",
      accessor: "course_desc",
      Cell: ({ row }) => {
        const { course_id, course_desc } = row.original;
        const [isExpanded, setIsExpanded] = React.useState(false);

        // Helper function to add line breaks after every 10 words
        const formatTooltip = (text) => {
          return text?.split(" ").reduce((acc, word, index) => {
            acc += word + " ";
            if ((index + 1) % 5 === 0) acc += "<br />";
            return acc;
          }, "");
        };

        const formattedDescription = formatTooltip(course_desc);

        // Check if the description needs truncation
        const shouldTruncate =
          course_desc?.length > 100 || course_desc?.split(" ")?.length > 5;
        const truncatedDescription = shouldTruncate
          ? course_desc?.split(" ")?.slice(0, 5)?.join(" ") + "..."
          : course_desc;

        return (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: isExpanded ? formattedDescription : '',
                // __html: isExpanded ? formattedDescription : truncatedDescription,
              }}
            ></span>
            {shouldTruncate && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  color: "#26FF10",
                }}
              >
                {isExpanded ? "Hide Desc" : "Show Desc"}
              </button>
            )}
            {/* {formattedDescription && (<span
              data-tooltip-id="description-tooltip"
              data-tooltip-html={formattedDescription} // Use data-tooltip-html for HTML content
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                color: "gray",
              }}
            >
              <EyeIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
              <Tooltip id="description-tooltip" place="top" />
            </span>)} */}
          </>
        );
      },
    },

    {
      Header: "Status",
      accessor: (d) => (d.course_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.course_id;
        const enabled = cell.row.original.course_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 89)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          return null;
        }
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.course_id;
        const course_is_deleted = cell.row.original.course_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 29
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 31
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          (getEditPermission?.length > 0 || getUid == cellId) &&
          getDeletePermission?.length > 0
        ) {
          actionButtonStatus = true;
        }

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 bottom-full mb-2 w-40 bg-white border rounded-md shadow-lg z-50">
        //         {(
        //           allowedActions?.filter((data) => data.permission_id === 94)?.length >
        //           0
        //         ) ? (<Menu.Item>
        //           {({ active }) => (
        //             <button
        //               onClick={() => chapterModalOpen(cellId, cell.row.original)}
        //               className={`${active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //             >
        //               <PlusIcon className="w-4 h-4 mr-2" />
        //               Add Chapter
        //             </button>
        //           )}
        //         </Menu.Item>) : ""}

        //         {(
        //           allowedActions?.filter((data) => data.permission_id === 88)?.length >
        //           0
        //         ) ? (<Menu.Item>
        //           {({ active }) => (
        //             <button
        //               onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //               className={`${active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //             >
        //               <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //               Edit
        //             </button>
        //           )}
        //         </Menu.Item>) : ""}

        //         {(
        //           allowedActions?.filter((data) => data.permission_id === 90)?.length >
        //           0
        //         ) ? (<Menu.Item>
        //           {({ active }) => (
        //             <button
        //               onClick={() => onDeleteOpenSection(cellId, course_is_deleted)}
        //               className={`${active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-red-600`}
        //             >
        //               <TrashIcon className="w-4 h-4 mr-2" />
        //               Delete
        //             </button>
        //           )}
        //         </Menu.Item>) : ""}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {/* <button
                onClick={() => {
                  chapterModalOpen(cellId, cell.row.original);
                }}
                className="px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover whitespace-nowrap"
              >
                Add Chapter
               
              </button> */}

              {/* {(allowedActions.filter((data) => data.permission_id == 29)?.length > 0) ? ( */}
              <button
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                type="button"
                className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover whitespace-nowrap`}
              >
                <span className="sr-only">Edit</span>
                <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* ) : null} */}

              {/* {(allowedActions.filter((data) => data.permission_id == 25)?.length > 0) ? ( */}
              <button
                onClick={() => onDeleteOpenSection(cellId, course_is_deleted)}
                type="button"
                className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
              >
                <span className="sr-only">Delete</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* ) : null} */}
            </span>
          </>
        );
      },
    },
  ];

export const chapter_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    // {
    //   Header: "Course ID",
    //   accessor: "fk_course_id",
    // },
    {
      Header: "Course Name",
      accessor: (d) => capitalise(d.m_course_relation?.course_title),
    },
    {
      Header: "Chapter Type",
      accessor: "chapter_type",
    },
    {
      Header: "Chapter Name",
      accessor: "chapter_title",
    },
    // {
    //   Header: "Chapter Desc",
    //   accessor: "chapter_desc",
    // },
    {
      Header: "Chapter URL",
      accessor: "chapter_path",
      Cell: ({ row }) => {
        const { chapter_id, chapter_type, chapter_path, chapter_desc } =
          row.original;
        const [isExpanded, setIsExpanded] = React.useState(false);

        // Helper function to add line breaks after every 10 words
        const formatTooltip = (text) => {
          return text?.split(" ").reduce((acc, word, index) => {
            acc += word + " ";
            if ((index + 1) % 5 === 0) acc += "<br />";
            return acc;
          }, "");
        };

        const formattedDescription = formatTooltip(chapter_desc);

        // Check if the description needs truncation
        const shouldTruncate =
          chapter_desc?.length > 100 || chapter_desc?.split(" ")?.length > 5;
        const truncatedDescription = shouldTruncate
          ? chapter_desc?.split(" ")?.slice(0, 5)?.join(" ") + "..."
          : chapter_desc;

        switch (chapter_type) {
          case "AUDIO":
            return (
              <a
                href={chapter_path}
                target="_blank"
                rel="noopener noreferrer"
                className="text-edgePrimary "
              >
                <SpeakerWaveIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
              </a>
            );
          case "VIDEO":
            return (
              <a
                href={chapter_path}
                target="_blank"
                rel="noopener noreferrer"
                className="text-edgePrimary "
              >
                <PlayIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
              </a>
            );
          case "FILE":
            return (
              <a href={chapter_path} download className="text-edgePrimary ">
                <DocumentIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
              </a>
            );
          case "TEXT":
            return (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    // __html: isExpanded
                    //   ? formattedDescription
                    //   : truncatedDescription,
                    __html: isExpanded
                      ? formattedDescription
                      : '',
                  }}
                ></span>
                {shouldTruncate && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      color: "#26FF10",
                    }}
                  >
                    {isExpanded ? "Hide Desc" : "Show Desc"}
                  </button>
                )}
                {/* {formattedDescription && (<span
                  data-tooltip-id="description-tooltip"
                  data-tooltip-html={formattedDescription} // Use data-tooltip-html for HTML content
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                    color: "gray",
                  }}
                >
                  <EyeIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
                  <Tooltip id="description-tooltip" place="top" />
                </span>)} */}
              </>
            );
          default:
            return <span className="text-gray-500">N/A</span>;
        }
      },
    },

    // {
    //   Header: "Chapter Duration",
    //   accessor: "duration",
    // },
    // {
    //   Header: "Chapter Time",
    //   accessor: "chapter_time",
    // },
    {
      Header: "Status",
      accessor: (d) => (d.chapter_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.chapter_id;
        const enabled = cell.row.original.chapter_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 98)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          return null;
        }
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.chapter_id;
        const chapter_is_deleted = cell.row.original.chapter_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 29
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 31
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          (getEditPermission?.length > 0 || getUid == cellId) &&
          getDeletePermission?.length > 0
        ) {
          actionButtonStatus = true;
        }

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 bottom-full mb-2 w-40 bg-white border rounded-md shadow-lg z-50">
        //         {allowedActions.some((data) => data.permission_id == 97) && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${
        //                   active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //         {allowedActions.some((data) => data.permission_id == 99) && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpenSection(cellId, chapter_is_deleted)}
        //                 className={`${
        //                   active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-red-600`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 97)?.length >
                0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 99)?.length >
                0 ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, chapter_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const chatTopic_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Topic ID",
      accessor: "sct_id",
    },

    // {
    //   Header: "Topic Name",
    //   accessor: "sct_title",

    // },
    {
      Header: "Topic Name",
      accessor: "sct_title",
      Cell: ({ row }) => {
        const { sct_id, sct_title } = row.original;
        const [isExpanded, setIsExpanded] = React.useState(false);
        // Helper function to add line breaks after every 10 words
        const formatTooltip = (text) => {
          return text?.split(" ").reduce((acc, word, index) => {
            acc += word + " ";
            if ((index + 1) % 5 === 0) acc += "<br />";
            return acc;
          }, "");
        };

        const formattedDescription = formatTooltip(sct_title);

        // Check if the description needs truncation
        const shouldTruncate =
          sct_title?.length > 100 || sct_title?.split(" ")?.length > 5;
        const truncatedDescription = shouldTruncate
          ? sct_title?.split(" ")?.slice(0, 5)?.join(" ") + "..."
          : sct_title;

        return (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: isExpanded ? formattedDescription : '',
                // __html: isExpanded ? formattedDescription : truncatedDescription,
              }}
            ></span>
            {shouldTruncate && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ marginLeft: "10px", cursor: "pointer", color: "blue" }}
              >
                {isExpanded ? "Hide Desc" : "Show Desc"}
              </button>
            )}

            <Tooltip id="description-tooltip" place="top" />
          </>
        );
      },
    },
    {
      Header: "Desc",
      accessor: "sct_description",
      Cell: ({ row }) => {
        const { sct_id, sct_description } = row.original;
        const [isExpanded, setIsExpanded] = React.useState(false);

        // Helper function to add line breaks after every 10 words
        const formatTooltip = (text) => {
          return text?.split(" ").reduce((acc, word, index) => {
            acc += word + " ";
            if ((index + 1) % 5 === 0) acc += "<br />";
            return acc;
          }, "");
        };

        const formattedDescription = formatTooltip(sct_description);

        // Check if the description needs truncation
        const shouldTruncate =
          sct_description?.length > 100 ||
          sct_description?.split(" ")?.length > 5;
        const truncatedDescription = shouldTruncate
          ? sct_description?.split(" ")?.slice(0, 5)?.join(" ") + "..."
          : sct_description;

        return (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: isExpanded ? formattedDescription : '',
                // __html: isExpanded ? formattedDescription : truncatedDescription,
              }}
            ></span>
            {shouldTruncate && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ marginLeft: "10px", cursor: "pointer", color: "blue" }}
              >
                {isExpanded ? "Hide Desc" : "Show Desc"}
              </button>
            )}
            {/* {formattedDescription && (<span
              data-tooltip-id="description-tooltip"
              data-tooltip-html={formattedDescription} // Use data-tooltip-html for HTML content
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                color: "gray",
              }}
            >
              <EyeIcon className="h-5 w-5 text-edgePrimary hover:text-gray-700" />
            </span>)} */}

            <Tooltip id="description-tooltip" place="top" />
          </>
        );
      },
    },

    {
      Header: "Status",
      accessor: (d) => (d.sct_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.sct_id;
        const enabled = cell.row.original.sct_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 105)?.length > 0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-edgePrimary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          return null;
        }
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.sct_id;
        const sct_is_deleted = cell.row.original.sct_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 29
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 31
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          (getEditPermission?.length > 0 || getUid == cellId) &&
          getDeletePermission?.length > 0
        ) {
          actionButtonStatus = true;
        }

        // return (
        //   <Menu as="div" className="relative inline-block text-left">
        //     <Menu.Button className="p-2 rounded-md hover:bg-gray-200">
        //       <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
        //     </Menu.Button>

        //     <Transition
        //       as={Fragment}
        //       enter="transition ease-out duration-100"
        //       enterFrom="transform opacity-0 scale-95"
        //       enterTo="transform opacity-100 scale-100"
        //       leave="transition ease-in duration-75"
        //       leaveFrom="transform opacity-100 scale-100"
        //       leaveTo="transform opacity-0 scale-95"
        //     >
        //       <Menu.Items className="absolute right-0 bottom-full mb-2 w-40 bg-white border rounded-md shadow-lg z-50">
        //         {allowedActions.some((data) => data.permission_id == 104) && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
        //                 className={`${
        //                   active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
        //               >
        //                 <Cog6ToothIcon className="w-4 h-4 mr-2" />
        //                 Edit
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}

        //         {allowedActions.some((data) => data.permission_id == 106) && (
        //           <Menu.Item>
        //             {({ active }) => (
        //               <button
        //                 onClick={() => onDeleteOpenSection(cellId, sct_is_deleted)}
        //                 className={`${
        //                   active ? "bg-gray-100" : ""
        //                 } flex items-center w-full px-4 py-2 text-sm text-red-600`}
        //               >
        //                 <TrashIcon className="w-4 h-4 mr-2" />
        //                 Delete
        //               </button>
        //             )}
        //           </Menu.Item>
        //         )}
        //       </Menu.Items>
        //     </Transition>
        //   </Menu>
        // );

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {allowedActions.filter((data) => data.permission_id == 104)
                ?.length > 0 ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Edit</span>
                  <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {allowedActions.filter((data) => data.permission_id == 106)
                ?.length > 0 ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, sct_is_deleted)}
                  type="button"
                  className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>
          </>
        );
      },
    },
  ];

export const paramConfig_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  setConfigData,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Param Name",
      accessor: "pc_name",
    },
    {
      Header: "Start Balance",
      accessor: "pc_start_balance",
    },
    {
      Header: "Start Balance",
      accessor: "pc_end_balance",
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.pc_id;
        const isBtn = cell.row.original.isBtn;
        const pc_is_deleted = cell.row.original.pc_is_deleted;
        const getEditPermission = allowedActions.filter(
          (data) => data.permission_id == 53
        );
        const getDeletePermission = allowedActions.filter(
          (data) => data.permission_id == 55
        );
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (
          ((getEditPermission?.length > 0 && isBtn) || getUid == cellId) &&
          getDeletePermission?.length > 0 &&
          isBtn
        ) {
          actionButtonStatus = true;
        }

        return (
          <>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {/* {(allowedActions.filter((data) => data.permission_id == 53)?.length > 0) ? ( */}
              <button
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                type="button"
                className={`px-2 py-2 bg-[#26FF10] text-black text-xs border border-edgePrimaryBorder 
                    rounded-l-md hover:bg-edgePrimaryHover`}
              >
                <span className="sr-only">Edit</span>
                <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* ) : null} */}

              {/* {(allowedActions.filter((data) => data.permission_id == 55)?.length > 0 && isBtn) ? ( */}
              <button
                onClick={() => onDeleteOpenSection(cellId, pc_is_deleted)}
                type="button"
                className={`px-2 py-2 bg-[#26FF10] text-xs text-black border border-edgePrimaryBorder rounded-r-md hover:bg-edgePrimaryHover`}
              >
                <span className="sr-only">Delete</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* ) : null} */}
            </span>
          </>
        );
      },
    },
  ];

export const payment_columns = ({
  handleCheckTransaction,
  handleViewTransaction,
  allowedActions,
  downloadInvoice,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ),
    },
    {
      Header: "Transaction No",
      accessor: "trax_no",
      Cell: ({ cell }) => <div className="p-2">{cell.value}</div>,
    },
    {
      Header: "Amount",
      accessor: "trax_req_amt",
      Cell: ({ cell }) => (
        <div className="p-2 flex items-center">
          <FaMoneyBillWave className="text-green-400 mr-1" />$
          {cell.value?.toFixed(2)}
        </div>
      ),
    },
    {
      Header: "Customer Name",
      accessor: (d) => `${d.cust_first_name} ${d.cust_last_name}`,
      Cell: ({ cell }) => (
        <div className="p-2 flex items-center whitespace-nowrap">
          <FaUser className="text-blue-400 mr-1" />
          {cell.value}
        </div>
      ),
    },
    {
      Header: "Customer Email",
      accessor: "cust_email",
      Cell: ({ cell }) => (
        <div className="p-2 flex items-center">
          <FaEnvelope className="text-yellow-400 mr-1" />
          {cell.value}
        </div>
      ),
    },
    {
      Header: "Customer Mobile",
      accessor: "cust_mobile",
      Cell: ({ cell }) => (
        <div className="p-2 flex items-center">
          <FaMobileAlt className="text-purple-400 mr-1" />
          {cell.value}
        </div>
      ),
    },
    {
      Header: "Transaction Start Date",
      accessor: "trax_start_date",
      Cell: ({ cell }) => (
        <div className="p-2 whitespace-nowrap">
          {moment(cell.value).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      ),
    },
    {
      Header: "Transaction End Date",
      accessor: "trax_end_date",
      Cell: ({ cell }) => (
        <div className="p-2 whitespace-nowrap">
          {moment(cell.value).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      ),
    },
    {
      Header: "Transaction Status",
      accessor: "trax_status",
      Cell: ({ cell }) => (
        <div
          className={`p-2 ${(cell.value === "Approved" || cell.value === "Completed") ? "text-green-500" : "text-red-500"
            } flex items-center`}
        >
          {(cell.value === "Approved" || cell.value === "Completed") ? (
            <FaCheckCircle className="mr-1" />
          ) : (
            <FaTimesCircle className="mr-1" />
          )}
          {cell.value || "Pending"}
        </div>
      ),
    },
    // {
    //   Header: "Approved",
    //   accessor: "trax_approved_txt",
    //   Cell: ({ cell }) => (
    //     <div
    //       className={`p-2 ${cell.value === "True" ? "text-green-500" : "text-red-500"
    //         } flex items-center`}
    //     >
    //       {cell.value === "True" ? (
    //         <FaCheckCircle className="mr-1" />
    //       ) : (
    //         <FaTimesCircle className="mr-1" />
    //       )}
    //       {cell.value === "True" ? "Approved" : "Declined"}
    //     </div>
    //   ),
    // },
    {
      Header: "Orders",
      accessor: "orders",
      Cell: ({ cell }) => (
        <div className="p-2 flex items-center  whitespace-nowrap">
          <FaListUl className="text-blue-400 mr-1" />
          {cell.value || "No Orders"}
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "action",
      Cell: ({ cell }) => {
        const transactionId = cell.row.original.transaction_id;
        const traxNo = cell.row.original.trax_no;
        const file_name = cell.row.original.file_name;

        return (
          <div className="p-2 flex gap-2">
            {allowedActions.filter((data) => data.permission_id == 118)?.length >
              0 ? (
              <button
                onClick={() => handleViewTransaction(traxNo, transactionId)}
                className="bg-edgePrimary hover:bg-edgePrimary text-black text-xs px-3 py-1 rounded flex items-center"
              >
                <FaEye className="mr-1" />
                View
              </button>
            ) : null}
             <button
                onClick={() => downloadInvoice(traxNo, transactionId,file_name)}
                className="bg-edgePrimary hover:bg-edgePrimary text-black text-xs px-3 py-1 rounded flex items-center"
              >
                <FaDownload className="mr-1" />
                Invoice
              </button>
          </div>
        );
      },
    },
  ];
