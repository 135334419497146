import React, { useEffect, useState, Fragment, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import {
  role_columns,
  user_columns,
} from "../../../components/tables/tableheader";
import {
  deleteUser,
  getAllUsers,
  addUser,
  updateUser,
} from "../../../redux/user/actions";

import { getAllRoles } from "../../../redux/role/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { FileUploader } from "react-drag-drop-files";
import PhoneInputWithCountry, {
  isValidPhoneNumber,
  parsePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import moment from "moment";
import { utils, writeFile, read } from "xlsx";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
} from "../../../helper/commonHelper";
import CommonPagination from "../../../components/CommonPagination";
import prodServices from "../../../ApiServices/prodServices";
import { useSearch } from "../../../components/tables/SearchContext";
import Api from "../../../ApiServices/Api";
function UserList() {
  const pages = [{ title: "User List", href: "/user-list", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [user_id, setUserid] = useState("");
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [selectedUserRoleId, setSelectedUserRoleId] = useState({
    value: "",
    label: "",
  });
  const [selectedUserId, setSelectedUserId] = useState({
    value: "",
    label: "",
  });

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    user_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    user_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role: { list: roleList } } = useSelector((getAllRoles) => getAllRoles);

  const [pathname, setPathname] = useState("");
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  //Mobile number validation in patient
  useEffect(() => {
    if (!initialRender) {
      // console.log(mobileNumber.toString())
      if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
        const phoneNumber = parsePhoneNumber(mobileNumber);
        console.log(phoneNumber);
        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setUserMobileNumber(phoneNumber.nationalNumber);
        }
        setMobileNumberValid(true);
      } else {
        setMobileNumberValid(false);
      }
    } else {
      setInitialRender(false);
    }
  }, [mobileNumber]);

  useEffect(() => {
    document.title = "EdgeFin  • Users";
    setIsLoading(true);
    // getAllUserList(3);
    // dispatch(getAllUsers(3));
    dispatch(getAllRoles());
    setIsLoading(false);
  }, []);

  const { searchQuery, setSearchQuery } = useSearch();

  const [filteredUserList, setFilteredUserList] = useState([]);
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredUserList(userList);
    } else {
      const filtered = userList.filter((topic) =>
        topic?.user_email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.user_first_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.user_mobile?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.user_last_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.m_role_relation?.role_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );

      setFilteredUserList(filtered);
    }
  }, [searchQuery, userList]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getAllUserList(3);
    }, 3000); // 5 seconds delay

    return () => clearTimeout(delayDebounce);
  }, [searchQuery]);

  const getAllUserList = async (search) => {
    let payload = {
      serach_txt: searchQuery,
      search
    };
    setIsLoading(true);
    try {
      const res = await prodServices.getAllUser(payload);
      if (res.status) {
        // console.log("res.data.data=> products", res);
        setUserList(res.data.data?.getUserList);
        setFilteredUserList(res.data.data?.getUserList);
        setPageCount(res?.data?.data?.pagination?.totalPages || 0);
        // console.log("res?.data?.data?.pagination?.totalPages", res?.data?.data?.pagination);
        setTotalCount(res?.data?.data?.pagination?.totalCount || 0);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules");
      toast.error("Fatal Error");
      setIsLoading(false);
    }
  };

  const onStatusUpdate = (user_id, user_is_active) => {
    let payload = {
      table_name: "m_user",
      table_field: "user_id",
      table_field_val: user_id,
      table_status_field: "user_is_active",
      table_status_val: user_is_active ? false : true,
      table_text: "User",
      deleted_by_key: "user_updated_by",
      delete_active_txt: user_is_active ? " Disabled" : " Enabled",
    };
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        // dispatch(getAllUsers(3));
        getAllUserList(3);
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  const userSchema = Yup.object({
    user_username: Yup.string()
      .min(3)
      .max(25)
      .required("Username is required"),
    user_first_name: Yup.string()
      .min(3)
      .max(25)
      .required("First Name is required"),
    user_last_name: Yup.string()
      .min(2)
      .max(25)
      .required("Last Name is required"),
    user_email: Yup.string().email().required("Email address is required"),
    user_password: user_id
      ? Yup.string()
      : Yup.string().min(6).required("Password is required"),
    user_mobile: Yup.string()
      .min(8)
      .max(15)
      .required("Mobile Number is required"),
    fk_role_id: Yup.string().required("Role is required"),
  });
  let DeleteUserID = 0;

  let initialValues = {
    user_id: "",
    user_username: "",
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_mobile: "",
    fk_role_id: "",
    user_password: "",
    user_is_active: true,
  };

  const [formUser, setFormUser] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setSelectedUserRoleId("");
    setSelectedUserId("");
    values.fk_role_id = "";
    setMobileNumberValid(true);

    if ((type === "edit", id)) {
      setUserid(id);
      let mergedPhone = `+${obj.user_dial_code}${obj.user_mobile}`;
      if (
        obj.user_mobile &&
        obj.user_dial_code &&
        isValidPhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)
      ) {
        const phoneNumber = parsePhoneNumber(
          `+${obj.user_dial_code}${obj.user_mobile}`
        );
        console.log(phoneNumber);
        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setMobileNumber(phoneNumber.number);
        }
      }
      const initialValues = {
        user_id: obj.user_id,
        user_username: obj.user_username,
        user_first_name: obj.user_first_name,
        user_last_name: obj.user_last_name,
        user_email: obj.user_email,
        user_mobile: obj.user_mobile,
        fk_role_id: obj.fk_role_id,
        user_is_active: obj.user_is_active,
      };

      if (Array.isArray(roleList.data) && roleList.data.length > 0) {
        let role = roleList.data.filter(
          (v) => v.role_id == initialValues.fk_role_id
        );
        if (role.length > 0) {
          setSelectedUserRoleId({
            value: role[0].role_id,
            label: role[0].role_name,
          });
        }
      }
      setFormUser(initialValues);
    } else {
      setMobileNumber("");
      setUserid("");
      setFormUser(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (user_id, user_is_active) => {
    setConfirmationModal({ user_id, user_is_active, status: true });
  };

  const onDeleteOpenSection = (user_id, user_is_deleted) => {
    DeleteUserID = user_id;
    setRemoveConfirmationModal({ user_id, user_is_deleted, status: true });
  };

  const setSelectedRole = async (events) => {
    console.log("setSelectedRole events", events.value);
    let cid = events.value ? events.value : null;
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formUser,
      validationSchema: userSchema,
      onSubmit: async (values, action) => {
        let body = {
          user_id: user_id,
          user_username: values.user_username,
          user_first_name: values.user_first_name,
          user_last_name: values.user_last_name,
          user_email: values.user_email,
          user_mobile: values.user_mobile,
          dial_code: 91,
          fk_role_id: values.fk_role_id,
          user_password: values.user_password,
          user_is_active: true,
        };

        if (
          body.user_first_name != undefined ||
          body.user_first_name != null ||
          body.user_first_name != ""
        ) {
          // setIsLoading(true)
          setTimeout(() => {
            setDisableSubmitButton(false);
          }, 1000);
          setDisableSubmitButton(true);
          let { data, status } = await MasterServices.saveMUser(body);
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              setMobileNumber("");
              setMobileCountryCode("");
              // dispatch(getAllUsers(3));
              getAllUserList(3);
              setIsLoading(false);
              setmodalOpenFlage(false);
              setDisableSubmitButton(false);
            } else {
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage("");
              }, 1000);
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false);

          action.resetForm();
          if (modalOpenFlage === true) {
            // dispatch(getAllUsers(3));
            getAllUserList(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false);
        }
      },
    });

  console.log(errors);

  const onDeleteUser = (user_id) => {
    // dispatch(deleteUser(user_id));
    onDeleteUserSection(user_id);
    // dispatch(getAllUsers(3));
    getAllUserList(3);
    setRemoveConfirmationModal({ user_id: null, status: false });
  };

  const onDeleteUserSection = async (user_id) => {
    const body = {
			user_id: user_id,
		};
    const res = await Api().post(`/api/dashboard/user/remove`, body);
    if (res.status) {
      getAllUserList(3);
    }
  }

  const filterRole = (role) => {
    const result = roleList.filter(
      (obj) => obj?.role_name?.toLowerCase() === role?.toLowerCase()
    );
    let resp = null;
    if (result.length > 0) {
      resp = result[0].role_id;
    } else {
      resp = undefined;
    }
    return resp;
  };
  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("User handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (
                obj.user_username != undefined ||
                obj.user_first_name != undefined
              ) {
                const newObj = {
                  user_username:
                    obj.user_username !== undefined
                      ? obj.user_username
                        ? obj.user_username.trim()
                        : obj.user_username.trim()
                      : "",
                  user_password:
                    obj.user_password !== undefined
                      ? obj.user_password
                        ? obj.user_password.toString().trim()
                        : obj.user_password.toString().trim()
                      : "",
                  user_first_name:
                    obj.user_first_name !== undefined
                      ? obj.user_first_name
                        ? obj.user_first_name.trim()
                        : obj.user_first_name.trim()
                      : "",
                  user_last_name:
                    obj.user_last_name !== undefined
                      ? obj.user_last_name
                        ? obj.user_last_name.trim()
                        : obj.user_last_name.trim()
                      : "",
                  user_email:
                    obj.user_email !== undefined
                      ? obj.user_email
                        ? obj.user_email.trim()
                        : obj.user_email.trim()
                      : "",
                  user_mobile:
                    obj.user_mobile !== undefined
                      ? obj?.user_mobile
                        ? parseInt(obj?.user_mobile + "")
                        : parseInt(obj?.user_mobile + "")
                      : "",
                  RoleName:
                    obj.role_name !== undefined
                      ? obj.role_name
                        ? obj.role_name.trim()
                        : obj.role_name.trim()
                      : "",
                  fk_role_id: filterRole(obj.role_name),
                };

                newData.push(newObj);
              }
            });

            console.log("newArray: ", newData);
            const filteredArray = newData.filter(
              (obj) => obj.fk_role_id !== undefined
            );
            console.log("filteredArray: ", filteredArray);

            if (filteredArray.length > 0) {
              importUser(filteredArray);
            }
          } else {
            toast.success("Excel is empty");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const importUser = (data) => {
    const payload = { excel_data: data };
    MasterServices.userExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("User Imported Successfully");
          // dispatch(getAllUsers(3));
          getAllUserList(3);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg);
      });
  };

  const handleExport = (excel_type) => {
    if (
      !userList ||
      userList.length === 0 ||
      !roleList ||
      roleList.length === 0
    ) {
      toast.error("User list or role list is empty!");
      return;
    }
    if (userList?.length > 0) {
      let newSheetdata = [];
      userList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          user_id: obj?.user_id || "-",
          user_username: capitalizeFirstLetter(obj?.user_username) || "-",
          user_first_name: capitalizeFirstLetter(obj?.user_first_name) || "-",
          user_last_name: capitalizeFirstLetter(obj?.user_last_name) || "-",
          user_email: obj?.user_email || "-",
          user_mobile: obj?.user_mobile || "-",
          role_name: obj?.m_role_relation?.role_name || "-",
          user_created_at:
            moment(obj?.user_created_at).format("DD-MM-YYYY") || "-",
          Status: obj?.user_is_active == true ? "Active" : "In-Active" || "-",
        };
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName =
        "User_Excel_Report_" +
        moment(currentDate).format("DD-MM-YYYY") +
        "." +
        excel_type;

      const headings = [
        [
          "SN",
          "User ID",
          "User Name",
          "User First Name",
          "User Last Name",
          "User Email",
          "User Mobie",
          "Role",
          "Created Date",
          "Status",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  };

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4 sticky">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.user_is_active ? "Disable " : "Enable") +
          " this User?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.user_id,
            confirmationModal.user_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.user_is_deleted ? " Undo" : "Delete") +
          " this User?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteUser(removeConfirmationModal.user_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-xl font-semibold text-white">User List ({totalCount})</h1>
          <p className="mt-2 text-sm text-gray-300">A list of all the Users.</p>
        </div>
        <div className="flex">
          <input
            type="text"
            placeholder="Search Users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="inline-flex items-center justify-center rounded-md bbt bg-[#222222] px-4 py-2 text-sm font-medium text-white shadow-sm hffff ml-auto ml-2 mr-2"
          />
          {allowedActions.filter((data) => data.permission_id == 18)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-[#27ff10] px-4 py-2 text-sm font-medium text-back shadow-sm  focus:outline-none focus:ring-2 focus:ring-[#27ff10] focus:ring-offset-2 ml-auto"
            >
              Add User
            </Link>
          ) : null}
          {allowedActions.filter((data) => data.permission_id == 22)?.length >
            0 && userList?.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => handleExport("xlsx")}
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-[#27ff10] px-4 py-2 text-sm font-medium text-back shadow-sm hover:bg-[#27ff10] focus:outline-none focus:ring-2 focus:ring-[#27ff10] focus:ring-offset-2 ml-2"
            >
              Download Excel
            </Link>
          ) : null}
          {allowedActions.filter((data) => data.permission_id == 23)?.length >
            0 ? (
            <label
              htmlFor="file-upload"
              className="inline-flex items-center justify-center rounded-md bg-[#27ff10] px-3 py-1 text-xs font-medium text-back shadow-sm hover:bg-[#27ff10] focus:outline-none focus:ring-2 focus:ring-[#27ff10] focus:ring-offset-2 ml-2 cursor-pointer transition duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M12 10a4 4 0 11-8 0 4 4 0 018 0zm2-2a6 6 0 11-12 0 6 6 0 0112 0z"
                  clipRule="evenodd"
                />
                <path d="M10 2a8 8 0 00-8 8c0 4.418 3.582 8 8 8s8-3.582 8-8a8 8 0 00-8-8zM5.293 8.707a1 1 0 011.414 0L10 11.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
              Upload Users
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleImport}
              />
            </label>
          ) : null}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={user_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={filteredUserList}
          is_toggle={false}
        />
      )}

      {/* {userList.length > 0 && (
        <CommonPagination
          pageNo={pageNo}
          pageCount={pageCount}
          gotoPage={setPageNo}
          previousPage={() => setPageNo((prev) => Math.max(prev - 1, 0))}
          nextPage={() =>
            setPageNo((prev) => Math.min(prev + 1, pageCount - 1))
          }
        />
      )} */}
      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-700 bg-[#151515] shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto flex flex-col justify-between">
                          <div className="bg-[#222222] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {user_id ? "Update" : "Add"} User
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-edgePrimary hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="user_username"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Username
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Username"
                                    name="user_username"
                                    autoComplete="new-username"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.user_username &&
                                    touched.user_username ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_username.replace(
                                        "user_username",
                                        "Username"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    First Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="First Name"
                                    name="user_first_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.user_first_name &&
                                    touched.user_first_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_first_name.replace(
                                        "user_first_name",
                                        "First Name"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-300 mt-2 mb-1"
                                  >
                                    Last Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Last Name"
                                    name="user_last_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.user_last_name &&
                                    touched.user_last_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_last_name.replace(
                                        "user_last_name",
                                        "Last Name"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-300 mt-2 mb-1"
                                  >
                                    User Email
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="email"
                                    placeholder="User Email"
                                    name="user_email"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                    disabled={user_id ? true : false}
                                  />
                                  {errors.user_email && touched.user_email ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_email.replace(
                                        "user_email",
                                        "Email"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-300 mt-2 mb-1"
                                  >
                                    User Mobile
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Mobile"
                                    name="user_mobile"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                    disabled={user_id ? true : false}
                                  />
                                  {errors.user_mobile && touched.user_mobile ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_mobile.replace(
                                        "user_mobile",
                                        "Mobile"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-300 mt-2 mb-1"
                                  >
                                    Password
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    placeholder="Enter Password"
                                    name="user_password"
                                    autoComplete="new-password"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.user_password &&
                                    touched.user_password ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_password.replace(
                                        "user_password",
                                        "Password"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-300 mt-2 mb-1"
                                  >
                                    Role
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <select
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fk_role_id}
                                    name="fk_role_id"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  >
                                    {roleList.length > 0 ? (
                                      roleList.map((role, idx) => (
                                        <option key={idx} value={role.role_id}>
                                          {role.role_name}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="0">
                                        Loading Roles...
                                      </option>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* <div className="mt-6 w-full px-4 py-4 flex items-center justify-center
                             absolute bottom-0 border-t border-gray-600">
                              <button
                                type="submit"
                                className={`group relative inline-flex w-max justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                                  ? "cursor-not-allowed bg-gray-600"
                                  : "bg-cyan-600 hover:bg-cyan-700"
                                  }`}
                                disabled={disableSubmitButton}
                              >
                                {disableSubmitButton ? (
                                  <FaSpinner className="absolute left-0 top-0 h-5 w-5 animate-spin" />
                                ) : null}
                                {user_id ? "Update" : "Add"} User
                              </button>
                            </div> */}

                            <div className="flex flex-shrink-0 justify-end px-4 py-4 border-t border-gray-600">
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                onClick={() => {
                                  setmodalOpenFlage(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={disableSubmitButton}
                                type="submit"
                                className="ml-4 inline-flex justify-center rounded-md bbt bg-[#27ff10] py-2 px-4 text-sm font-medium text-black shadow-sm  focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                {disableSubmitButton ? (
                                  <FaSpinner className="absolute left-0 top-0 h-5 w-5 animate-spin" />
                                ) : null}
                                {user_id ? "Update" : "Add"} User
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default UserList;
