import React, { useRef, useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { accountNew_columns } from "../../../../components/tables/tableheader";
import { accountSchemaNew } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  CircleStackIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import WhopServices from "../../../../ApiServices/WhopServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { utils, writeFile, read } from "xlsx";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
} from "../../../../helper/commonHelper";
import Select from "react-select";
import Editor from "@monaco-editor/react";
import prodServices from "../../../../ApiServices/prodServices";
import AccountServices from "../../../../ApiServices/AccountServices";
import CustomerServices from "../../../../ApiServices/CustomerServices";
import ParamServices from "../../../../ApiServices/ParamServices";
import CommonPagination from "../../../../components/CommonPagination";
import { useSearch } from "../../../../components/tables/SearchContext";

const team = [];

function AccountList() {
  const pages = [{ title: "Account List", href: "/account", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [acc_id, setAccountid] = useState("");
  const [selectedLicId, setSelectedLicId] = useState({ value: "", label: "" });
  const [selectedUserId, setSelectedUserId] = useState({
    value: "",
    label: "",
  });
  const [selectedLicenseId, setSelectedLicenseId] = useState({
    value: "",
    label: "",
  });
  const [selectedLicenseObj, setSelectedLicenseObj] = useState(null);
  const [selectedParamId, setSelectedParamId] = useState({
    value: "",
    label: "",
  });
  const [selectedProductId, setSelectedProductId] = useState({
    value: "",
    label: "",
  });
  const [selectedPreAccountId, setSelectedPreAccountId] = useState({
    value: "",
    label: "",
  });
  const [paramConfigList, setParamConfigList] = useState([]);
  const [licenseList, setLicenseList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [licenseListOfUser, setLicenseListOfUser] = useState([]);
  const [preAccountList, setPreAccountList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [configData, setConfigData] = useState("");
  const [currentConfigUser, setCurrentConfigUser] = useState(null);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    acc_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    acc_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  const editorRef = useRef(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "EdgeFin •  Accounts";
    getAllAccounts(3 + "&filter=no");
    getAllUser(1);
    getAllPCParam(1);
  }, []);

  const getAllPCParam = async (payload) => {
    // setIsLoading(true);
    try {
      const res = await ParamServices.getAllParam({
        serach: 1,
        acc_balance: payload,
      });
      if (res.status) {
        setParamConfigList(res.data.data?.params);
      }
      setIsLoading(false);
      return res?.data?.data?.params;
    } catch (e) {
      console.log(e, "error in getAllPCParam");
      setIsLoading(false);
    }
  };

  const getUserLicenses = async (payload) => {
    try {
      const res = await prodServices.getAllUserLicenses(payload);
      if (res.status) {
        setLicenseListOfUser(res.data.data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getUserLicenses");
    }
  };

  const getAllAccounts = async (payload) => {
    setIsLoading(true);
    try {
      const res = await AccountServices.getAllAccount(payload);
      if (res.status) {
        setAccountList(res?.data?.data?.accounts);
        setPageCount(res?.data?.data?.pagination?.totalPages || 0);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules");
      toast.error("Fatal Error");
      setIsLoading(false);
    }
  };
  const getAllLicense = async (value) => {
    let payload = `1&fk_cust_id=${value}&fk_prod_id=${selectedProductId?.value}`;
    try {
      const res = await WhopServices.getAllWhopLicense(payload);
      if (res.status) {
        setLicenseList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllLicense");
      toast.error("Fatal Error");
    }
  };
  const getAllProductList = async (value) => {
    let payload = `1&fk_cust_id=${value}&prod_is_whop=1`;
    try {
      const res = await prodServices.getAllProduct(payload);
      if (res.status) {
        setProductList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllProduct");
      toast.error("Fatal Error");
    }
  };
  const getAllUser = async (payload) => {
    try {
      const res = await CustomerServices.getAllCustomer(payload);
      if (res.status) {
        setUserList(res.data.data?.customers);
      }
    } catch (e) {
      console.log(e, "error in getAllUser");
      toast.error("Fatal Error");
    }
  };

  //const onStatusUpdate = (acc_id, lam_is_banned) => {
  const onStatusUpdate = (pk_id, acc_status, type) => {
    let payload = {
      table_name: "m_account",
      table_field: "acc_id",
      table_field_val: pk_id,
      table_status_field: type, //"lam_is_banned",
      table_status_val: acc_status ? false : true,
      table_text: "Account",
      deleted_by_key: "acc_uu_by",
      delete_active_txt: "Acc Status", //acc_status ? " Un-Baned" : " Ban",
    };
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        getAllAccounts(3);
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  let initialValues = {
    acc_id: "",
    cust_username: "",
    fk_cust_id: "",
    acc_no: "",
    acc_no_str: "",
    acc_name: "",
    cust_username: "",
    cust_email: "",
    fk_pc_id: "",
    fk_up_id: "",
  };

  const [formAccount, setFormAccount] = useState(initialValues);

  const handleDrawer = async (type, id, obj) => {
    // const pcParamList = await getAllPCParam(obj?.acc_balance);
    setSelectedLicId("");
    setSelectedPreAccountId("");
    setSelectedProductId("");
    setSelectedUserId("");
    setSelectedLicenseId("");
    setSelectedLicenseObj(null);
    setSelectedParamId("");
    // await getAllLicense(obj?.fk_cust_id);
    values.fk_cust_id = "";
    // await getAllLicense(obj?.fk_cust_id);
    if ((type === "edit", id)) {
      setAccountid(id);
      let initialValues = {
        acc_id: acc_id,
        fk_cust_id: obj.fk_cust_id,
        acc_no: obj.acc_no,
        acc_no_str: obj.acc_no_str,
        acc_name: obj.acc_name,
        cust_username: obj.cust_username,
        cust_email: obj.cust_email,
        fk_pc_id: obj.fk_pc_id,
        fk_up_id: obj.fk_up_id,
      };
      setFormAccount(initialValues);

      if (Array.isArray(userList) && userList.length > 0) {
        let user = userList.filter((v) => v.value == initialValues.fk_cust_id);
        if (user.length > 0) {
          initialValues.cust_username = user[0].cust_username;
          setSelectedUserId({ value: user[0].value, label: user[0].label });
        }
      }

      /* console.log('pcParamList=>',pcParamList)
      if (Array.isArray(pcParamList) && pcParamList.length > 0) {
        let param = pcParamList.filter(v => v.value == initialValues.fk_pc_id)
        console.log('param=>',param,initialValues.fk_pc_id)
        if (param.length > 0) {
          setSelectedParamId({ value: param[0].value, label: param[0].label })
        }
      } */
    } else {
      setAccountid("");
      setFormAccount(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (pk_id, acc_status, type) => {
    // console.log('confirmationModal.acc_status=>',confirmationModal,acc_status,type)
    let title = "Are you sure you want to modify?";
    if (type == "acc_is_stopped_trading") {
      title =
        "Are you sure you want to " +
        (acc_status ? "Re-Start Trading" : "Stop Trading") +
        " this Account?";
    } else if (type == "acc_is_closed_all_trade") {
      title =
        "Are you sure you want to " +
        (acc_status
          ? "Open All Trading"
          : "Close All Trading") +
        " this Account?";
    } else if (type === "acc_is_top") {
      const action = acc_status ? "Yes" : "No";
      title = `Are you sure you want to Set Top Account ${action} trading for this account?`;
    } else {
      title =
        "Are you sure you want to " +
        (acc_status ? "In-Active" : "Active") +
        " this Account?";
    }
    setConfirmationModal({ pk_id, acc_status, type, status: true, title });
  };

  const onDeleteOpenSection = (acc_id, acc_is_deleted) => {
    setRemoveConfirmationModal({ acc_id, acc_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formAccount,
      validationSchema: accountSchemaNew,
      onSubmit: async (values, action) => {
        let body = {
          acc_id: acc_id,
          fk_cust_id: values.fk_cust_id,
          acc_no: values.acc_no,
          acc_name: values.acc_name,
          // cust_username: values.cust_username,
          // cust_email: values.cust_email,
          acc_no_str: values.acc_no_str,
          fk_pc_id: values.fk_pc_id,
          fk_up_id: values.fk_up_id,
        };

        if (
          body.acc_no != undefined ||
          body.acc_no != null ||
          body.acc_no != ""
        ) {
          setTimeout(() => {
            setDisableSubmitButton(false);
          }, 1000);
          setDisableSubmitButton(true);
          setAccountList([]);
          setIsLoading(true);
          let { data, status, message } = await AccountServices.SaveAccount(
            body
          );
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllAccounts(3);
              setIsLoading(false);
              setmodalOpenFlage(false);
              setDisableSubmitButton(false);
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage("");
              }, 1000);
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false);

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllAccounts(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false);
        }
      },
    });

  const onDeleteAccount = async (acc_id) => {
    let { data, status } = await AccountServices.deleteAccount({ acc_id });
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        getAllAccounts(3);
      }
    } else {
      toast.error(data.message);
    }
    setRemoveConfirmationModal({ acc_id: null, status: false });
  };

  const filterUser = (user) => {
    const result = userList.filter(
      (obj) => obj?.cust_email?.toLowerCase() === user?.toLowerCase()
    );
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value;
    } else {
      resp = undefined;
    }
    return resp;
  };

  const filterLicense = (License) => {
    const result = licenseList.filter(
      (obj) => obj?.label?.toLowerCase() === License?.toLowerCase()
    );
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value;
    } else {
      resp = undefined;
    }
    return resp;
  };
  const filterProduct = (product) => {
    const result = productList.filter(
      (obj) => obj?.label?.toLowerCase() === product?.toLowerCase()
    );
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value;
    } else {
      resp = undefined;
    }
    return resp;
  };

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Account handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.acc_no != undefined || obj.license_key != undefined) {
                const newObj = {
                  acc_no:
                    obj.acc_no !== undefined
                      ? obj.acc_no
                        ? obj.acc_no.trim()
                        : obj.acc_no.trim()
                      : "",
                  acc_no_str:
                    obj.acc_no !== undefined
                      ? obj.acc_no
                        ? obj.acc_no.trim()
                        : obj.acc_no.trim()
                      : "",
                  acc_name:
                    obj.acc_name !== undefined
                      ? obj.acc_name
                        ? obj.acc_name.trim()
                        : obj.acc_name.trim()
                      : "",
                  fk_cust_id: filterUser(obj.cust_email),
                };

                newData.push(newObj);
              }
            });

            console.log("newArray: ", newData);
            const filteredArray = newData.filter(
              (obj) => obj.fk_cust_id !== undefined
            );
            console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              importAccount(newData);
            }
          } else {
            toast.success("Excel is empty");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const importAccount = (data) => {
    const payload = { excel_data: data };
    MasterServices.userExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Account Imported Successfully");
          getAllAccounts(3);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg);
      });
  };
  const { searchQuery, setSearchQuery } = useSearch();
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredAccounts(accountList);
    } else {
      const filtered = accountList.filter((topic) =>
        topic?.acc_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.acc_no?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.master_whop_product_relation?.acc_no_str?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.m_customer_relation?.cust_email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        topic?.m_customer_relation?.cust_username?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      setFilteredAccounts(filtered);
    }
  }, [searchQuery, accountList]);

  const handleExport = (excel_type) => {
    if (!accountList || accountList.length === 0) {
      toast.error("Account list is empty!");
      return;
    }
    if (accountList?.length > 0) {
      let newSheetdata = [];
      accountList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          acc_id: obj?.acc_id || "-",
          fk_cust_id: obj?.fk_cust_id || "-",
          fk_pc_id: obj?.fk_pc_id || "-",
          acc_no: obj?.acc_no || "-",
          acc_no_str: obj?.master_whop_product_relation?.acc_no_str || "-",
          cust_email: obj?.m_customer_relation?.cust_email || "-",
          cust_username: obj?.m_customer_relation?.cust_username || "-",
          acc_created_at:
            moment(obj?.acc_created_at).format("DD-MM-YYYY") || "-",
          Status: obj?.acc_is_active == true ? "Active" : "In-Active" || "-",
        };
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName =
        "Account_Excel_Report_" +
        moment(currentDate).format("DD-MM-YYYY") +
        "." +
        excel_type;
      const headings = [
        [
          "SN",
          "Account ID",
          "User ID",
          "Param Config ID",
          "Account No",
          "Account Str No",
          "User Email",
          "User Name",
          "Created Date",
          "Status",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  };

  const goToMetrix = (data) => {
    navigate(data);
  };

  const addParamConfig = (data) => {
    setCurrentConfigUser(data);
    console.log("setCurrentConfigUser PS=>", data);
    if (
      data &&
      data?.acc_no &&
      data.acc_param_config &&
      data.acc_param_config != null
    ) {
      if (data?.acc_param_is_json) {
        setConfigData(JSON.stringify(JSON.parse(data.acc_param_config)));
      } else {
        setConfigData(JSON.stringify(data.acc_param_config));
      }
    } else {
      // toast.error("Sorry Here No Account No Attached ...");
      // return;
    }
    setOpen(true);
  };

  const updateUserConfig = async (e) => {
    /* if(configData != null){}else{} */
    if (!currentConfigUser) {
      toast.error("Something went wrong...");
      return;
    }

    let { data, status } = await AccountServices.updateConfigData({
      acc_id: currentConfigUser?.acc_id,
      data: configData,
    });
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        getAllAccounts(3 + "&filter=no&isAll=1");
      }
      setOpen(false);
    } else {
      toast.error(data.message);
    }
  };

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    editor.onDidChangeModelContent(() => {
      setConfigData(editor.getValue());
    });
  };

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          confirmationModal?.title
            ? confirmationModal?.title
            : "Are you sure you want to modify settings?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.pk_id,
            confirmationModal.acc_status,
            confirmationModal.type
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.acc_is_deleted ? " Undo" : "Delete") +
          " this Account?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteAccount(removeConfirmationModal.acc_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h1 className="text-xl font-semibold text-white">Account List ({accountList?.length})</h1>
        <div className="flex">
          {/* hidden hide */}
          <input
            type="text"
            placeholder="Search Accounts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="inline-flex items-center justify-center rounded-md bbt bg-[#222222] px-4 py-2 text-sm font-medium text-white shadow-sm hffff ml-auto ml-2 mr-2"
          />
          {allowedActions.filter((data) => data.permission_id == 67)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-auto"
            >
              <PlusCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Add Account
            </Link>
          ) : null}
          {/* {allowedActions.filter((data) => data.permission_id == 52)?.length >
          0 ? ( */}
          {/* <Link
          to={`../account/0`}
        >
          <button
            className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
            size="default"
            variant="outlined"
          >
            <CircleStackIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Add Multiple Account<span className="sr-only">, </span>
          </button>
        </Link> */}
          {/* ) : null} */}
          {allowedActions.filter((data) => data.permission_id == 76)?.length >
            0 && accountList?.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => handleExport("xlsx")}
              type="button"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
            >
              <ArrowDownCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Download Excel
            </Link>
          ) : null}
          {/* {allowedActions.filter((data) => data.permission_id == 77)?.length >
            0 ? (
            <label
              htmlFor="file-upload"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-3 py-1 text-xs font-medium text-black shadow-sm hffff  ml-2 cursor-pointer transition duration-300 ease-in-out"
            >
              <ArrowUpCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Upload Account
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleImport}
              />
            </label>
          ) : null} */}
        </div>
      </div>
      {/* flex use beelow class */}

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={accountNew_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
            goToMetrix,
            addParamConfig,
            setConfigData,
          })}
          data={filteredAccounts}
          is_toggle={false}
        />
      )}

      {console.log("configData=>", allowedActions)}


      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-[#151515] shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-[#222222]  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {acc_id ? "Update" : "Add"} Account
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-edgePrimaryHover hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div>
                                <label
                                  htmlFor="acc_no"
                                  className="block text-sm font-medium text-gray-200 mb-1"
                                >
                                  User Email
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              {/* {userList && userList.length > 0 ? ( */}
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <Select
                                  name="acc_no"
                                  id="wp_user_id"
                                  // menuPlacement="top"
                                  placeholder={
                                    <span>
                                      Select User Email
                                      {/* <span className="text-red-600">*</span> */}
                                    </span>
                                  }
                                  value={selectedUserId}
                                  onChange={(e) => {
                                    console.log(e);
                                    values.fk_cust_id = e.value;
                                    values.cust_username = e.cust_username;
                                    values.cust_email = e.cust_email;
                                    getUserLicenses(`fk_cust_id=${e.value}`);
                                    setSelectedUserId(e);
                                  }}
                                  components={animatedComponents}
                                  options={userList ? userList : []}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      background: "#151515",
                                      color: "#FFF",
                                      borderRadius: state.isFocused
                                        ? "4px 4px 0 0"
                                        : 3,
                                    }),
                                    menuList: (styles) => ({
                                      ...styles,
                                      background: "#333333",
                                      color: "#FFFFFF",
                                    }),
                                    option: (
                                      styles,
                                      { isFocused, isSelected }
                                    ) => ({
                                      ...styles,
                                      color: isFocused
                                        ? "#151515"
                                        : isSelected
                                          ? "#111111"
                                          : undefined, //"#111111",
                                      background: isFocused
                                        ? "#FFF"
                                        : isSelected
                                          ? "#FFF"
                                          : undefined,
                                      zIndex: 1,
                                    }),
                                    menu: (base) => ({
                                      ...base,
                                      zIndex: 100,
                                    }),
                                  }}
                                  classNamePrefix="select"
                                  className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                />

                                {errors.fk_cust_id && touched.fk_cust_id ? (
                                  <div className="text-sm text-red-600">
                                    {errors.fk_cust_id.replace(
                                      "fk_cust_id",
                                      "User"
                                    )}
                                  </div>
                                ) : null}
                              </div>
                              {/* ) : null} */}

                              {/* {acc_id ? (<><div>
                                <label
                                  htmlFor="acc_no"
                                  className="block text-sm font-medium text-gray-200 mb-1"
                                >
                                  Param Config
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="fk_pc_id"
                                    id="fk_pc_id"
                                    // menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select Param Config
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedParamId}
                                    onChange={(e) => {
                                      values.fk_pc_id = e.value;
                                      setSelectedParamId(e);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      paramConfigList ? paramConfigList
                                        : []
                                    }
                                         styles={{
                                        control: (base, state) => ({
                                          ...base,
                                          background: "#151515",
                                          color: "#FFF",
                                          borderRadius: state.isFocused ? "4px 4px 0 0" : 3,
                                        }),
                                        menuList: styles => ({
                                          ...styles,
                                          background: '#333333',
                                          color: "#FFFFFF",
                                        }),
                                        option: (styles, { isFocused, isSelected }) => ({
                                          ...styles,
                                          color: isFocused ? '#151515' : isSelected ? '#111111' : undefined, //"#111111",
                                          background: isFocused ? '#FFF' : isSelected ? '#FFF' : undefined,
                                          zIndex: 1
                                        }),
                                        menu: base => ({
                                          ...base,
                                          zIndex: 100
                                        })
                                      }}
                                      classNamePrefix="select"
                                      className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />

                                  {errors.fk_pc_id && touched.fk_pc_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_pc_id.replace("fk_pc_id", "Param COnfig")}
                                    </div>
                                  ) : null}
                                </div></>) : (<></>)} */}

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="cust_username"
                                    className="block text-sm font-medium text-gray-200 mb-1"
                                  >
                                    User Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    disabled={true}
                                    value={values.cust_username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="User Name"
                                    name="cust_username"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.cust_username &&
                                    touched.cust_username ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_username.replace(
                                        "cust_username",
                                        "User Name"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="fk_up_id"
                                    className="block text-sm font-medium text-gray-200 mb-1"
                                  >
                                    License
                                    <span className="text-red-400">*</span>
                                  </label>
                                </div>
                                <Select
                                  name="fk_up_id"
                                  id="fk_up_id"
                                  menuPlacement="top"
                                  placeholder={
                                    <span className="text-gray-400">
                                      Select License
                                      {/* <span className="text-red-400">*</span> */}
                                    </span>
                                  }
                                  value={selectedLicenseId}
                                  onChange={(e) => {
                                    values.fk_up_id = e.value;
                                    setSelectedLicenseId({
                                      value: e.value,
                                      label: e.label,
                                    });
                                    setSelectedLicenseObj(e);
                                  }}
                                  components={animatedComponents}
                                  options={
                                    licenseListOfUser
                                      ? licenseListOfUser.map((v) => ({
                                        value: v.up_id,
                                        label: v.up_license_key,
                                        up_id: v.up_id,
                                        up_license_key: v.up_license_key,
                                        up_start_date: v.up_start_date,
                                        up_end_date: v.up_end_date,
                                        fk_acc_id: v.fk_acc_id,
                                        fk_plan_id: v.fk_plan_id,
                                        fk_cust_id: v.fk_cust_id,
                                        fk_prod_id: v.fk_prod_id,
                                        product_relation: v.product_relation,
                                        plan_relation: v.plan_relation,
                                      }))
                                      : []
                                  }
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      background: "#151515",
                                      color: "#FFF",
                                      borderRadius: state.isFocused
                                        ? "4px 4px 0 0"
                                        : 3,
                                    }),
                                    menuList: (styles) => ({
                                      ...styles,
                                      background: "#333333",
                                      color: "#FFFFFF",
                                    }),
                                    option: (
                                      styles,
                                      { isFocused, isSelected }
                                    ) => ({
                                      ...styles,
                                      color: isFocused
                                        ? "#151515"
                                        : isSelected
                                          ? "#111111"
                                          : undefined, //"#111111",
                                      background: isFocused
                                        ? "#FFF"
                                        : isSelected
                                          ? "#FFF"
                                          : undefined,
                                      zIndex: 1,
                                    }),
                                    menu: (base) => ({
                                      ...base,
                                      zIndex: 100,
                                    }),
                                  }}
                                  classNamePrefix="select"
                                  className="block w-full max-w-lg rounded-md border-[1px] border-gray-50/10 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                />
                                {errors.fk_up_id && touched.fk_up_id ? (
                                  <div className="text-sm text-red-400">
                                    {errors.fk_up_id.replace(
                                      "fk_up_id",
                                      "License"
                                    )}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="acc_name"
                                    className="block text-sm font-medium text-gray-200 mb-1"
                                  >
                                    Account Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.acc_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Account Name"
                                    name="acc_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.acc_name && touched.acc_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.acc_name.replace(
                                        "acc_name",
                                        "Account Name"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="acc_no"
                                    className="block text-sm font-medium text-gray-200 mb-1"
                                  >
                                    Account No
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.acc_no}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Account No"
                                    name="acc_no"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.acc_no && touched.acc_no ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.acc_no.replace(
                                        "acc_no",
                                        "Account No"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {/* Users Licenses */}
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">


                                {selectedLicenseObj && (
                                  <div className="p-2 mt-1">
                                    <table>
                                      {/* <tr>
                                      <td className="text-white pr-2">KEY: </td>
                                      <td>{selectedLicenseObj?.up_license_key}</td>
                                    </tr> */}
                                      <tr>
                                        <td className="text-white pr-2">
                                          Start Date:{" "}
                                        </td>
                                        {/* <td>{new Date(selectedLicenseObj?.up_start_date).toISOString().split('T')[0]}</td> */}
                                        <td className="text-gray-400">
                                          {selectedLicenseObj?.up_start_date &&
                                            !isNaN(
                                              new Date(
                                                selectedLicenseObj.up_start_date
                                              )
                                            )
                                            ? new Date(
                                              selectedLicenseObj.up_start_date
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                            : "Invalid Date"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-white pr-2">
                                          End Date:{" "}
                                        </td>
                                        {/* <td>{selectedLicenseObj?.up_end_date}</td> */}
                                        <td className="text-gray-400">
                                          {selectedLicenseObj?.up_end_date &&
                                            !isNaN(
                                              new Date(
                                                selectedLicenseObj.up_end_date
                                              )
                                            )
                                            ? new Date(
                                              selectedLicenseObj.up_end_date
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                            : "Invalid Date"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-white pr-2">
                                          Product:{" "}
                                        </td>
                                        <td className="text-gray-400">
                                          {
                                            selectedLicenseObj?.product_relation
                                              ?.prod_title
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-white pr-2">
                                          Product Type:{" "}
                                        </td>
                                        <td className="text-gray-400">
                                          {
                                            selectedLicenseObj?.product_relation
                                              ?.prod_type
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-white pr-2">
                                          Plan:{" "}
                                        </td>
                                        <td className="text-gray-400">
                                          {
                                            selectedLicenseObj?.plan_relation
                                              ?.plan_name
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-white pr-2">
                                          Plan Price:{" "}
                                        </td>
                                        <td className="text-gray-400">
                                          {
                                            selectedLicenseObj?.plan_relation
                                              ?.plan_subs_price
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-white pr-2">
                                          Plan Period:{" "}
                                        </td>
                                        <td className="text-gray-400">
                                          {
                                            selectedLicenseObj?.plan_relation
                                              ?.plan_period
                                          }
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                )}


                              </div>

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : ""
                              }`}
                          >
                            {acc_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Add Acc Config */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateUserConfig(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-[#151515] shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-[#222222] px-4 py-6 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                Parameter Module - Details
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative rounded-md bg-white text-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                                  onClick={() => setOpen(false)}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1">
                              <p className="text-sm text-white">
                                Account:{" "}
                                {currentConfigUser
                                  ? currentConfigUser.acc_no
                                  : "N/A"}
                              </p>
                              <p className="text-sm text-white">
                                Product:{" "}
                                {currentConfigUser
                                  ? currentConfigUser?.acc_no_str
                                  : "N/A"}
                              </p>
                              <p className="text-sm text-white">
                                Name:{" "}
                                {currentConfigUser
                                  ? currentConfigUser?.acc_no +
                                  " - " +
                                  currentConfigUser.m_customer_relation
                                    ?.cust_email
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pb-5 pt-6">
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium leading-6 text-white"
                                  >
                                    Config Data - (JSON/TEXT)
                                  </label>
                                  <div className="mt-2">
                                    <Editor
                                      height="70vh"
                                      defaultLanguage="json"
                                      defaultValue={configData}
                                      onMount={handleEditorDidMount}
                                      theme="vs-dark"
                                      className="border bg-[#222222]"
                                    />
                                    {/* <textarea
                                      id="description"
                                      name="description"
                                      rows={25}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      defaultValue={configData}
                                      onChange={(e) => {
                                        if (e.target.value != null && e.target.value != "") {
                                          setConfigData(e.target.value);
                                        }
                                      }}
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            /* onClick={(e)=>{
                              updateUserConfig();
                            }} */
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md bg-[#27ff10] px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default AccountList;
