import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import chartServices from '../../ApiServices/chartServices';
import { getGraphGap } from '../../helper/commonHelper';

const AMDrawdownChart = ({uid, data }) => {
  let accNo = data;
  let categoriesRit = accNo == '11236281' ? [
    "Oct 09, '24",
    "Oct 11, '24",
    "Oct 21, '24",
    "Oct 22, '24",
    "Oct 23, '24",
    "Nov 27, '24",
    "Dec 10, '24",
    "Dec 12, '24",
    "Dec 17, '24",
    "Dec 18, '24",
    "Dec 31, '24",
    "Jan 13, '25",
    "Jan 20, '25",
    "Jan 21, '25",
    "Jan 22, '25",
    "Jan 23, '25",
    "Jan 24, '25",
    "Jan 27, '25",
    "Feb 04, '25",
    "Feb 05, '25",
    "Feb 06, '25",
    "Feb 07, '25",
    "Feb 10, '25",
    "Feb 11, '25",
    "Feb 12, '25",
    "Feb 13, '25"
  ] : [
    "Jan 03, '23",
    "Jan 05, '23",
    "Jan 06, '23",
    "Jan 12, '23",
    "Jan 13, '23",
    "Jan 18, '23",
    "Jan 19, '23",
    "Jan 20, '23",
    "Jan 25, '23",
    "Jan 26, '23",
    "Jan 27, '23",
    "Jan 30, '23",
    "Feb 01, '23",
    "Feb 02, '23",
    "Feb 03, '23",
    "Feb 08, '23",
    "Feb 09, '23",
    "Feb 10, '23",
    "Feb 13, '23",
    "Feb 15, '23",
    "Feb 16, '23",
    "Feb 17, '23",
    "Feb 21, '23",
    "Feb 22, '23",
    "Feb 24, '23",
    "Feb 27, '23",
    "Feb 28, '23",
    "Mar 01, '23",
    "Mar 02, '23",
    "Mar 03, '23",
    "Mar 07, '23",
    "Mar 10, '23",
    "Mar 13, '23",
    "Mar 15, '23",
    "Mar 22, '23",
    "Mar 23, '23",
    "Mar 24, '23",
    "Mar 27, '23",
    "Mar 28, '23",
    "Apr 03, '23",
    "Apr 04, '23",
    "Apr 06, '23",
    "Apr 07, '23",
    "Apr 10, '23",
    "Apr 12, '23",
    "Apr 13, '23",
    "Apr 14, '23",
    "Apr 17, '23",
    "Apr 18, '23",
    "Apr 19, '23",
    "Apr 21, '23",
    "Apr 24, '23",
    "Apr 25, '23",
    "Apr 26, '23",
    "Apr 27, '23",
    "Apr 28, '23",
    "May 01, '23",
    "May 08, '23",
    "May 11, '23",
    "May 12, '23",
    "May 16, '23",
    "May 18, '23",
    "May 19, '23",
    "May 24, '23",
    "May 25, '23",
    "May 29, '23",
    "May 30, '23",
    "May 31, '23",
    "Jun 01, '23",
    "Jun 02, '23",
    "Jun 05, '23",
    "Jun 07, '23",
    "Jun 08, '23",
    "Jun 09, '23",
    "Jun 12, '23",
    "Jun 15, '23",
    "Jun 20, '23",
    "Jun 21, '23",
    "Jun 22, '23",
    "Jun 23, '23",
    "Jun 29, '23",
    "Jun 30, '23",
    "Jul 03, '23",
    "Jul 04, '23",
    "Jul 05, '23",
    "Jul 06, '23",
    "Jul 07, '23",
    "Jul 10, '23",
    "Jul 11, '23",
    "Jul 12, '23",
    "Jul 13, '23",
    "Jul 19, '23",
    "Jul 20, '23",
    "Jul 25, '23",
    "Jul 27, '23",
    "Jul 31, '23",
    "Aug 01, '23",
    "Aug 02, '23",
    "Aug 04, '23",
    "Aug 10, '23",
    "Aug 11, '23",
    "Aug 14, '23",
    "Aug 15, '23",
    "Aug 17, '23",
    "Aug 22, '23",
    "Aug 23, '23",
    "Aug 24, '23",
    "Aug 29, '23",
    "Aug 30, '23",
    "Sep 01, '23",
    "Sep 06, '23",
    "Sep 07, '23",
    "Sep 08, '23",
    "Sep 12, '23",
    "Sep 13, '23",
    "Sep 18, '23",
    "Sep 19, '23",
    "Sep 20, '23",
    "Sep 22, '23",
    "Sep 25, '23",
    "Sep 26, '23",
    "Oct 02, '23",
    "Oct 05, '23",
    "Oct 06, '23",
    "Oct 11, '23",
    "Oct 12, '23",
    "Oct 13, '23",
    "Oct 16, '23",
    "Oct 23, '23",
    "Oct 24, '23",
    "Oct 25, '23",
    "Oct 26, '23",
    "Oct 27, '23",
    "Oct 30, '23",
    "Oct 31, '23",
    "Nov 01, '23",
    "Nov 02, '23",
    "Nov 03, '23",
    "Nov 06, '23",
    "Nov 07, '23",
    "Nov 08, '23",
    "Nov 09, '23",
    "Nov 13, '23",
    "Nov 14, '23",
    "Nov 17, '23",
    "Nov 21, '23",
    "Nov 22, '23",
    "Nov 27, '23",
    "Nov 29, '23",
    "Dec 04, '23",
    "Dec 05, '23",
    "Dec 06, '23",
    "Dec 07, '23",
    "Dec 13, '23",
    "Dec 15, '23",
    "Dec 19, '23",
    "Dec 20, '23",
    "Dec 21, '23",
    "Dec 22, '23",
    "Dec 26, '23",
    "Dec 28, '23",
    "Dec 29, '23",
    "Jan 08, '24",
    "Jan 10, '24",
    "Jan 17, '24",
    "Jan 18, '24",
    "Jan 19, '24",
    "Jan 23, '24",
    "Jan 25, '24",
    "Jan 26, '24",
    "Jan 29, '24",
    "Jan 31, '24",
    "Feb 01, '24",
    "Feb 02, '24",
    "Feb 06, '24",
    "Feb 08, '24",
    "Feb 14, '24",
    "Feb 15, '24",
    "Feb 19, '24",
    "Feb 20, '24",
    "Feb 28, '24",
    "Feb 29, '24",
    "Mar 07, '24",
    "Mar 12, '24",
    "Mar 14, '24",
    "Mar 18, '24",
    "Mar 19, '24",
    "Mar 20, '24",
    "Mar 21, '24",
    "Mar 25, '24",
    "Mar 26, '24",
    "Mar 28, '24",
    "Mar 29, '24",
    "Apr 01, '24",
    "Apr 02, '24",
    "Apr 03, '24",
    "Apr 04, '24",
    "Apr 05, '24",
    "Apr 08, '24",
    "Apr 15, '24",
    "Apr 17, '24",
    "Apr 18, '24",
    "Apr 25, '24",
    "Apr 30, '24",
    "May 02, '24",
    "May 03, '24",
    "May 06, '24",
    "May 07, '24",
    "May 09, '24",
    "May 15, '24",
    "May 17, '24",
    "May 20, '24",
    "May 21, '24",
    "May 22, '24",
    "May 24, '24",
    "May 28, '24",
    "May 29, '24",
    "May 30, '24",
    "May 31, '24",
    "Jun 03, '24",
    "Jun 07, '24",
    "Jun 10, '24",
    "Jun 12, '24",
    "Jun 13, '24",
    "Jun 14, '24",
    "Jun 17, '24",
    "Jun 20, '24",
    "Jun 21, '24",
    "Jul 04, '24",
    "Jul 05, '24",
    "Jul 11, '24",
    "Jul 12, '24",
    "Jul 16, '24",
    "Jul 18, '24",
    "Jul 23, '24",
    "Jul 24, '24",
    "Jul 25, '24",
    "Aug 02, '24",
    "Aug 05, '24",
    "Aug 08, '24",
    "Aug 13, '24",
    "Aug 14, '24",
    "Aug 16, '24",
    "Aug 19, '24",
    "Aug 20, '24",
    "Aug 23, '24",
    "Aug 29, '24",
    "Aug 30, '24",
    "Sep 02, '24",
    "Sep 05, '24",
    "Sep 09, '24",
    "Sep 10, '24",
    "Sep 11, '24",
    "Sep 12, '24",
    "Sep 13, '24",
    "Sep 16, '24",
    "Sep 18, '24",
    "Sep 19, '24",
    "Sep 23, '24",
    "Sep 24, '24",
    "Sep 25, '24",
    "Oct 09, '24",
    "Oct 10, '24",
    "Oct 11, '24",
    "Oct 18, '24",
    "Oct 21, '24",
    "Oct 22, '24",
    "Oct 24, '24",
    "Oct 25, '24",
    "Oct 29, '24",
    "Oct 31, '24",
    "Nov 01, '24",
    "Nov 08, '24",
    "Nov 13, '24",
    "Nov 14, '24",
    "Nov 18, '24",
    "Nov 21, '24",
    "Nov 26, '24",
    "Nov 27, '24",
    "Nov 29, '24",
    "Dec 04, '24",
    "Dec 05, '24",
    "Dec 09, '24",
    "Dec 12, '24",
    "Dec 18, '24",
    "Dec 19, '24",
    "Dec 20, '24",
    "Dec 26, '24",
    "Dec 30, '24",
    "Jan 02, '25",
    "Jan 10, '25",
    "Jan 13, '25",
    "Jan 14, '25",
    "Jan 15, '25",
    "Jan 17, '25",
    "Jan 20, '25",
    "Jan 21, '25",
    "Jan 22, '25",
    "Jan 23, '25"
  ];

  let drawdownRit = accNo == '11236281' ? [
    0.17,
    0.15,
    0.70,
    0.23,
    0.39,
    0.01,
    0.04,
    0.12,
    0.01,
    0.01,
    0.40,
    0.06,
    4.09,
    4.38,
    0.07,
    0.47,
    0.39,
    0.16,
    0.24,
    0.40,
    0.87,
    0.65,
    0.45,
    0.41,
    0.44,
    0.09
  ] : [
    0.01,
    0.01,
    0.20,
    0.11,
    0.02,
    0.07,
    0.01,
    0.01,
    0.02,
    0.02,
    0.01,
    0.02,
    0.01,
    0.01,
    0.11,
    0.01,
    0.01,
    0.03,
    0.03,
    0.01,
    0.01,
    0.12,
    0.03,
    0.02,
    0.17,
    0.01,
    0.08,
    0.01,
    0.46,
    0.03,
    0.45,
    0.05,
    0.03,
    0.02,
    0.03,
    0.08,
    0.34,
    0.03,
    0.01,
    1.18,
    0.04,
    0.01,
    0.18,
    0.25,
    0.01,
    0.04,
    0.01,
    0.02,
    0.01,
    0.01,
    0.01,
    0.03,
    0.68,
    0.02,
    0.01,
    0.01,
    0.01,
    0.04,
    0.17,
    0.41,
    0.01,
    0.34,
    0.01,
    0.06,
    0.22,
    0.02,
    0.01,
    0.05,
    0.06,
    0.06,
    0.01,
    0.01,
    0.16,
    0.01,
    0.01,
    0.17,
    0.01,
    0.11,
    0.01,
    0.07,
    0.02,
    0.17,
    0.02,
    0.04,
    0.05,
    0.02,
    0.04,
    0.14,
    0.01,
    0.14,
    0.07,
    0.01,
    0.11,
    0.01,
    0.33,
    0.03,
    0.01,
    0.01,
    0.01,
    0.05,
    0.17,
    0.02,
    0.03,
    0.01,
    0.34,
    0.03,
    0.02,
    0.07,
    0.06,
    0.10,
    0.01,
    0.04,
    0.03,
    0.05,
    0.01,
    0.02,
    0.01,
    0.03,
    0.01,
    0.06,
    0.01,
    1.25,
    0.02,
    0.02,
    0.02,
    0.40,
    0.01,
    0.01,
    0.15,
    0.17,
    0.01,
    0.02,
    0.01,
    0.06,
    0.13,
    0.01,
    0.01,
    0.19,
    0.05,
    0.01,
    0.02,
    0.02,
    0.01,
    3.09,
    0.05,
    0.02,
    0.02,
    0.01,
    0.01,
    0.01,
    0.01,
    0.01,
    0.01,
    0.01,
    0.04,
    0.17,
    0.01,
    0.24,
    0.01,
    0.01,
    0.10,
    0.01,
    0.01,
    0.02,
    0.01,
    0.01,
    0.01,
    0.04,
    0.01,
    0.01,
    0.02,
    0.01,
    0.48,
    0.06,
    0.01,
    0.02,
    0.01,
    0.25,
    0.01,
    0.01,
    0.02,
    0.02,
    0.06,
    0.01,
    0.08,
    0.03,
    0.05,
    0.57,
    0.09,
    0.01,
    0.02,
    0.01,
    0.01,
    0.02,
    0.02,
    0.60,
    0.07,
    0.01,
    0.02,
    0.01,
    0.02,
    0.04,
    0.01,
    0.10,
    0.03,
    0.55,
    0.88,
    0.19,
    0.02,
    0.04,
    0.02,
    0.01,
    0.01,
    0.01,
    0.03,
    0.01,
    0.06,
    0.01,
    0.01,
    0.35,
    0.02,
    0.02,
    0.01,
    0.06,
    0.01,
    0.02,
    0.01,
    0.01,
    0.03,
    0.01,
    0.16,
    0.87,
    0.02,
    0.04,
    0.09,
    0.01,
    0.02,
    0.30,
    0.01,
    0.01,
    0.33,
    0.02,
    0.23,
    0.06,
    0.11,
    0.01,
    0.02,
    0.01,
    0.01,
    0.01,
    0.01,
    0.01,
    0.01,
    0.02,
    0.02,
    0.09,
    0.01,
    0.01,
    0.02,
    0.03,
    0.09,
    0.01,
    0.01,
    0.01,
    0.01,
    10.11,
    0.04,
    0.01,
    0.09,
    0.01,
    0.01,
    0.04,
    0.02,
    0.01,
    0.03,
    0.05,
    0.01,
    0.01,
    0.04,
    0.01,
    0.01,
    0.05,
    0.02,
    0.03,
    0.03,
    0.02,
    0.02,
    0.01,
    0.01,
    0.01,
    0.02,
    0.02,
    0.02,
    0.01,
    0.05,
    0.45,
    0.08,
    0.03,
    0.01
  ];

  const [accChartData, setAccChartData] = useState(
    {
      chart: {
        type: "column",
        backgroundColor: "#151515",
        style: {
          fontFamily: 'Onest'
        }
      },
      title: {
        text: "",
        style: { color: "#fff" }
      },
      xAxis: {
        categories: [], // Show all categories
        crosshair: true,
        labels: {
          style: { color: "#fff" },
          step: 5, // Show every 5th label (gap of 5)
        },
        title: {
          text: "Dates",
          style: { color: "#fff" }
        }
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        backgroundColor: "#151515" || 'rgba(255,255,255,0.25)',
        itemStyle: {
          color: '#fff'
        }
      },
      yAxis: {
        gridLineColor: "#333333",
        labels: {
          style: { color: "#fff" }
        },
        title: { enabled: false }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [{
        name: "Drawdown",
        color: "rgba(255, 0, 0, 0.8)",
        borderColor: "rgba(255, 0, 0, 0.7)",
        data: []
      }],
      credits: {
        text: 'EdgeFin: <a href="https://www.EdgeFin.com" target="_blank">EF</a>'
      }
    }
  );

  const processData = (drawdownData) => {
    const firstDate = new Date(drawdownData[0].dacc_date_time);
    const lastDate = new Date(drawdownData[drawdownData.length - 1].dacc_date_time);
    const diffDays = (lastDate - firstDate) / (1000 * 60 * 60 * 24);

    let groupBy = "daily";
    if (diffDays > 367) groupBy = "yearly";
    else if (diffDays > 30) groupBy = "monthly";

    const groupedData = {};

    drawdownData.forEach(item => {
      const date = new Date(item.dacc_date_time);
      let key;

      if (groupBy === "yearly") {
        key = date.getFullYear().toString();
      } else if (groupBy === "monthly") {
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      } else {
        key = date.toISOString().split('T')[0]; // Preserve multiple same-day entries
      }

      if (!groupedData[key]) groupedData[key] = [];
      groupedData[key].push(item.dacc_max_dd_percent);
    });

    return { groupedData, groupBy, diffDays };
  };

  const updateChartData = (drawdownData) => {

    const categories = [];
    const drawdownValues = [];
    let groupBy = "";

    if (accNo != '11236281' && accNo != '11357218') {
      const { groupedData, groupBy } = processData(drawdownData);

      Object.entries(groupedData).forEach(([date, values]) => {
        values.forEach((val) => {
          categories.push(date);
          drawdownValues.push(val);
        });
      });
    }
    let getGap = getGraphGap(drawdownValues);
    getGap = (accNo == '11236281') ? 12 : accNo == '11357218' ? 90 : 30;
    // getGap = (accNo == '11236281') ? 12 : accNo == '11357218' ? 90 : getGap;

    return {
      chart: {
        type: "column",
        backgroundColor: "#151515",
        style: {
          fontFamily: 'Onest'
        }
      },
      title: {
        text: "Drawdown (Percentage)",
        style: { color: "#fff" }
      },
      xAxis: {
        categories: (accNo == '11236281' || accNo == '11357218') ? categoriesRit : categories, // Show all categories
        crosshair: true,
        labels: {
          style: { color: "#fff" },
          step: getGap, // Show every 5th label (gap of 5)
        },
        title: {
          text: groupBy === "yearly" ? "Years" : groupBy === "monthly" ? "Months" : "Dates",
          style: { color: "#fff" }
        }
      },
      yAxis: {
        gridLineColor: "#333333",
        labels: {
          style: { color: "#fff" },
          formatter: function () {
            return this.value + "%";
          }
        },
        title: { enabled: false }
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        backgroundColor: "#151515" || 'rgba(255,255,255,0.25)',
        itemStyle: {
          color: '#fff'
        }
      },
      tooltip: {
        shared: false,
        useHTML: true,
        formatter: function () {
          const date = this.x;
          const drawdown = this.y.toFixed(2);
          return `<b>${date}</b><br/>Drawdown: <b>${drawdown}%</b>`;
        }
      },
      series: [{
        name: "Drawdown",
        color: "rgba(255, 0, 0, 0.8)",
        borderColor: "rgba(255, 0, 0, 0.7)",
        data: (accNo == '11236281' || accNo == '11357218') ? drawdownRit : drawdownValues
      }],
      credits: {
        text: 'EdgeFin: <a href="https://www.EdgeFin.com" target="_blank">EF</a>'
      }
    };
  };

  const getAccDrawdownChartData = useCallback(async (accid) => {
    if (!accid) return;
    try {
      const { data } = await chartServices.getAccDrawdownChart({ acc_no: accid,uid: uid });
      if (data.status && data?.data) {
        if(data?.data?.length>0){
          setAccChartData(updateChartData(data?.data));
          
        }
      }
    } catch (error) {
      console.error("Error fetching account Drawdown chart data:", error);
    }
  }, []);

  useEffect(() => {
    if (data) {
      getAccDrawdownChartData(data);
    }
  }, [data, getAccDrawdownChartData]);

  return (
    <HighchartsReact highcharts={Highcharts} options={accChartData} />
    // <div className="backdrop-blur-sm loader-overlay">
    //   <div className="loader-content mt-4">
    //     {/* {accChartData ? (
    //       <HighchartsReact highcharts={Highcharts} options={accChartData} />
    //     ) : (
    //       <p>Loading chart...</p>
    //     )} */}
    // //     <HighchartsReact highcharts={Highcharts} options={accChartData} />
    // //   </div>
    // // </div>
  );
};

export default AMDrawdownChart;
