import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import paymentServices from "../../../ApiServices/paymentServices";
import Breadcrumb from "../../../components/Breadcrumb";
import CommonPagination from "../../../components/CommonPagination";
import {
  FaSearch,
  FaEye,
  FaFileInvoice,
  FaCheck,
  FaTimes,
  FaClipboard,
  FaShareAlt,
} from "react-icons/fa";
import { toast } from "react-toast";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
} from "../../../helper/commonHelper";
import { ArrowDownCircleIcon } from "lucide-react";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import { useSearch } from "../../../components/tables/SearchContext";
import CustomerServices from "../../../ApiServices/CustomerServices";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";

const DipgateCustomerList = () => {
  const navigate = useNavigate();
  const [getDipgateCustomerList, setDipgateCustomerList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { searchQuery, setSearchQuery } = useSearch();
  const [filteredDipgateCustomerList, setFilteredDipgateCustomerList] = useState([]);

  const [allowedActions, setAllowedActions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);


  useEffect(() => {
    fetchDipgateCustomerList(pageNo);
  }, [pageNo, searchQuery]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredDipgateCustomerList(getDipgateCustomerList);
    } else {
      const filtered = getDipgateCustomerList.filter((cust) => {
        return (
          (cust?.dipgate_org_client_id && cust.dipgate_org_client_id.toString().toLowerCase().includes(searchQuery.toLowerCase())) ||
          (cust?.dipgate_service_id && cust.dipgate_service_id.toString().toLowerCase().includes(searchQuery.toLowerCase())) ||
          (cust?.m_customer_relation?.cust_username && cust.m_customer_relation.cust_username.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (cust?.m_customer_relation?.cust_email && cust.m_customer_relation.cust_email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (cust?.m_customer_relation?.cust_mobile && cust.m_customer_relation.cust_mobile.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (cust?.m_customer_relation?.cust_id && cust.m_customer_relation.cust_id.toString().toLowerCase().includes(searchQuery.toLowerCase())) ||
          (cust?.m_customer_relation?.cust_fname && cust.m_customer_relation.cust_fname.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      });

      setFilteredDipgateCustomerList(filtered);
    }
  }, [searchQuery, getDipgateCustomerList]);


  const fetchDipgateCustomerList = async (pageIndex) => {
    setLoading(true);
    setError(null);
    try {
      const payload = {
        page: pageIndex + 1,
        pageSize: 25,
        search_txt: "",
        sortByKey: "dipgate_client_id",
        sortBy: "asc",
        isAll: 0,
        search_txt: searchQuery
      };
      const { data } = await CustomerServices.getDipgateCustomerList(payload);
      if (data?.status && data?.data) {
        setDipgateCustomerList(data.data.customers || []);
        setFilteredDipgateCustomerList(data.data.customers || []);
        setPageCount(data.data.pagination?.totalPages || 0);
        setTotalCount(data.data.pagination?.totalCount || 0);
      }
    } catch (err) {
      console.error("Error fetching payments:", err);
      setError("Failed to load payments. Try again later.");
    } finally {
      setLoading(false);
    }
  };
  const syncDipgateCustomerList = async (pageIndex) => {

    try {

      const { data } = await CustomerServices.syncDipgateAllCustomer({});
      if (data?.status && data?.data) {
       toast.success("Dipgate Client List Synced Successfully");
      }
    } catch (err) {
      console.error("Error fetching payments:", err);
      setError("Failed to load payments. Try again later.");
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "index", disableSortBy: true },
      { Header: "Dipgate Client ID", accessor: "dipgate_org_client_id" },
      { Header: "Dipgate Service ID", accessor: "dipgate_service_id" },
      { Header: "Linked", accessor: "dipgate_client_is_linked" },
      { Header: "Un-Linked Date", accessor: "dipgate_client_unlink_date" },
      { Header: "Customer ID", accessor: "cust_id" },
      { Header: "Customer Name", accessor: "cust_username" },
      { Header: "Customer Email", accessor: "cust_email" },
      { Header: "Customer Mobile", accessor: "cust_mobile" },
      { Header: "Created AT", accessor: "dipgate_client_created_at" },
    ],
    []
  );

  const handleExport = () => {
    if (!getDipgateCustomerList || getDipgateCustomerList.length === 0) {
      toast.error("No payments found to export!");
      return;
    }

    const dataSheet = getDipgateCustomerList.map((payment, index) => ({
      index: index + 1,
      dipgate_org_client_id: payment.dipgate_org_client_id,
      dipgate_service_id: payment.dipgate_service_id,
      dipgate_client_is_linked: payment.dipgate_client_is_linked ? "Yes" : "No",
      dipgate_client_unlink_date: payment.dipgate_client_unlink_date ? new Date(payment.dipgate_client_unlink_date) : "N/A",
      cust_id: payment?.m_customer_relation?.cust_id,
      cust_username: payment?.m_customer_relation?.cust_username,
      cust_email: payment?.m_customer_relation?.cust_email,
      cust_mobile: payment?.m_customer_relation?.cust_mobile,
      dipgate_client_created_at: payment.dipgate_client_created_at,
    }));

    const fileName = `Quick_Payment_Report_${moment().format(
      "DD-MM-YYYY"
    )}.xlsx`;
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [
      [
        "SN",
        "Dipgate Client ID",
        "Dipgate Service ID",
        "Linked",
        "Un-Linked Date",
        "Customer ID",
        "Customer Name",
        "Customer Email",
        "Customer Mobile",
        "Created AT",
      ],
    ]);
    utils.sheet_add_json(ws, dataSheet, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Dipgate Client List");
    writeFile(wb, fileName);
  };

  const data = useMemo(
    () =>
      filteredDipgateCustomerList.map((payment, index) => ({
        index: index + 1,
        dipgate_org_client_id: payment.dipgate_org_client_id,
        dipgate_service_id: payment.dipgate_service_id,
        dipgate_client_is_linked: payment.dipgate_client_is_linked ? "Yes" : "No",
        dipgate_client_unlink_date: payment.dipgate_client_unlink_date ? new Date(payment.dipgate_client_unlink_date) : "N/A",
        cust_id: payment?.m_customer_relation?.cust_id,
        cust_username: payment?.m_customer_relation?.cust_username,
        cust_email: payment?.m_customer_relation?.cust_email,
        cust_mobile: payment?.m_customer_relation?.cust_mobile,
        dipgate_client_created_at: payment.dipgate_client_created_at,

      })),
    [getDipgateCustomerList, navigate]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy, usePagination);

  return (
    <div className="min-h-screen p-6 bg-[#000000] text-white">
      <Breadcrumb
        pages={[{ title: "Quick Payment List", href: "/quick-payment" }]}
      />


      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <div>
          <h4 className="text-xl font-semibold">Dipgate Client List ({totalCount})</h4>
          <h5 className="text-sm text-gray-300 mt-2">
            Browse all Dipgate Client and their current statuses
          </h5>
        </div>
        <div className="mt-4 flex items-center gap-2">
          <input
            type="text"
            placeholder="Search Dipgate User..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-md bg-[#222222] px-4 py-2 text-sm font-medium text-white shadow-sm border border-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
          />
          {allowedActions.filter((data) => data.permission_id == 127)?.length >
            0 && getDipgateCustomerList.length > 0 ? (
            <>
              <button
                onClick={syncDipgateCustomerList}
                className="rounded-md bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm flex items-center gap-2 border border-transparent hover:bg-[#1fd50d]"
              >
                <ArrowDownCircleIcon className="h-5 w-5" aria-hidden="true" />
                Sync Dipgate
              </button>

            </>
          ) : null}
        </div>
      </div>
      {loading ? (
          <FallingLinesLoader />
        // <p className="text-center text-gray-400">
        //   Loading Dipgate Client List...
        // </p>
      ) : error ? (
        <p className="text-center text-red-400">{error}</p>
      ) : (
        <div className="border border-gray-50/10 bg-[#151515] rounded-md overflow-hidden mt-4">
          <div className="w-full overflow-x-auto">
            <table
              {...getTableProps()}
              className="min-w-full table-auto text-center border-collapse"
            >
              <thead className="bg-[#222]">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="border-b py-2 px-2 text-xs text-gray-200 cursor-pointer"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="hover:bg-[#444]">
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            className="border-b py-2 px-2 text-xs text-gray-200"
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="border-b py-2 px-2 text-xs text-gray-200 text-center"
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Pagination */}
      {getDipgateCustomerList.length > 0 && (
        <CommonPagination
          pageNo={pageNo}
          pageCount={pageCount}
          gotoPage={setPageNo}
          previousPage={() => setPageNo((prev) => Math.max(prev - 1, 0))}
          nextPage={() =>
            setPageNo((prev) => Math.min(prev + 1, pageCount - 1))
          }
        />
      )}
    </div>
  );
};

export default DipgateCustomerList;
