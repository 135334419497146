import React, { useEffect, useState, Fragment, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import {
  product_columns,
  whop_product_columns,
} from "../../../../components/tables/tableheader";
import { productSchema } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import WhopServices from "../../../../ApiServices/WhopServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { utils, writeFile, read } from "xlsx";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
} from "../../../../helper/commonHelper";

function ProductList() {
  const pages = [{ title: "Product List", href: "/product", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [wp_prod_id, setProductid] = useState("");
  const [prodList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    wp_prod_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    wp_prod_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "EdgeFin •  Products";
    getAllProducts(3);
  }, []);

  const getAllProducts = async (payload) => {
    setIsLoading(true);
    try {
      const res = await WhopServices.getAllWhopProduct(payload);
      if (res.status) {
        setProductList(res.data.data);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules");
      toast.error("Fatal Error");
      setIsLoading(false);
    }
  };

  const onStatusUpdate = (wp_prod_id, prod_is_active) => {
    let payload = {
      table_name: "master_whop_product",
      table_field: "wp_prod_id",
      table_field_val: wp_prod_id,
      table_status_field: "prod_is_active",
      table_status_val: prod_is_active ? false : true,
      table_text: "Product",
      deleted_by_key: "prod_updated_by",
      delete_active_txt: prod_is_active ? " Disabled" : " Enabled",
    };
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        getAllProducts(3);
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  let initialValues = {
    wp_prod_id: "",
    prod_id: "",
    prod_name: "",
    prod_description: "",
    prod_is_active: true,
  };

  const [formProduct, setFormProduct] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setProductid(id);
      const initialValues = {
        wp_prod_id: obj.wp_prod_id,
        prod_id: obj.prod_id,
        prod_name: obj.prod_name,
        prod_description: obj.prod_description,
        prod_is_active: obj.prod_is_active,
      };

      setFormProduct(initialValues);
    } else {
      setProductid("");
      setFormProduct(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (wp_prod_id, prod_is_active) => {
    setConfirmationModal({ wp_prod_id, prod_is_active, status: true });
  };

  const onDeleteOpenSection = (wp_prod_id, prod_is_deleted) => {
    setRemoveConfirmationModal({ wp_prod_id, prod_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formProduct,
      validationSchema: productSchema,
      onSubmit: async (values, action) => {
        let body = {
          wp_prod_id: wp_prod_id,
          prod_id: values.prod_id,
          prod_name: values.prod_name,
          prod_description: values.prod_description,
          prod_is_active: true,
        };

        if (
          body.prod_name != undefined ||
          body.prod_name != null ||
          body.prod_name != ""
        ) {
          setProductList([]);
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false);
          }, 1000);
          setDisableSubmitButton(true);
          let { data, status, message } = await WhopServices.SaveWhopProduct(
            body
          );
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllProducts(3);
              setIsLoading(false);
              setmodalOpenFlage(false);
              setDisableSubmitButton(false);
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage("");
              }, 1000);
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false);

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllProducts(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false);
        }
      },
    });

  const onDeleteProduct = async (wp_prod_id) => {
    let { data, status } = await WhopServices.deleteWhopProduct({ wp_prod_id });
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        getAllProducts(3);
      }
    } else {
      toast.error(data.message);
    }
    setRemoveConfirmationModal({ wp_prod_id: null, status: false });
  };

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Product handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (
                obj.prod_id != undefined ||
                obj.prod_description != undefined
              ) {
                const newObj = {
                  wp_prod_id:
                    obj.wp_prod_id !== undefined
                      ? obj.wp_prod_id
                        ? obj.wp_prod_id.trim()
                        : obj.wp_prod_id.trim()
                      : "",
                  prod_id:
                    obj.prod_id !== undefined
                      ? obj.prod_id
                        ? obj.prod_id.trim()
                        : obj.prod_id.trim()
                      : "",
                  prod_name:
                    obj.prod_name !== undefined
                      ? obj.prod_name
                        ? obj.prod_name.toString().trim()
                        : obj.prod_name.toString().trim()
                      : "",
                  prod_description:
                    obj.prod_description !== undefined
                      ? obj.prod_description
                        ? obj.prod_description.trim()
                        : obj.prod_description.trim()
                      : "",
                  prod_company_id:
                    obj.prod_company_id !== undefined
                      ? obj.prod_company_id
                        ? obj.prod_company_id.trim()
                        : obj.prod_company_id.trim()
                      : "",
                  prod_page_id:
                    obj.prod_page_id !== undefined
                      ? obj.prod_page_id
                        ? obj.prod_page_id.trim()
                        : obj.prod_page_id.trim()
                      : "",
                  prod_visibility:
                    obj.prod_visibility !== undefined
                      ? obj.prod_visibility
                        ? obj.prod_visibility.trim()
                        : obj.prod_visibility.trim()
                      : "",
                  prod_created_at:
                    obj.prod_created_at !== undefined
                      ? obj.prod_created_at
                        ? obj.prod_created_at.trim()
                        : obj.prod_created_at.trim()
                      : "",
                };

                newData.push(newObj);
              }
            });

            console.log("newArray: ", newData);

            if (newData.length > 0) {
              importProduct(newData);
            }
          } else {
            toast.success("Excel is empty");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const importProduct = (data) => {
    const payload = { excel_data: data };
    MasterServices.userExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Product Imported Successfully");
          getAllProducts(3);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg);
      });
  };

  const handleExport = (excel_type) => {
    if (!prodList || prodList.length === 0) {
      toast.error("Product list is empty!");
      return;
    }
    if (prodList?.length > 0) {
      let newSheetdata = [];
      prodList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          wp_prod_id: obj?.wp_prod_id || "-",
          prod_id: obj?.prod_id || "-",
          prod_name: capitalizeFirstLetter(obj?.prod_name) || "-",
          prod_description: capitalizeFirstLetter(obj?.prod_description) || "-",
          prod_company_id: obj?.prod_company_id || "-",
          prod_page_id: obj?.prod_page_id || "-",
          prod_visibility: obj?.prod_visibility || "-",
          prod_created_at:
            moment(obj?.prod_created_at).format("DD-MM-YYYY") || "-",
          Status: obj?.prod_is_active == true ? "Active" : "In-Active" || "-",
        };
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName =
        "Product_Excel_Report_" +
        moment(currentDate).format("DD-MM-YYYY") +
        "." +
        excel_type;
      const headings = [
        [
          "SN",
          "Product ID",
          "Product Unique ID",
          "Product Name",
          "Product Description",
          "Product Company Id",
          "Page Id",
          "Product Visibility",
          "Created Date",
          "Status",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  };

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.prod_is_active ? "Disable " : "Enable") +
          " this Product?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.wp_prod_id,
            confirmationModal.prod_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.prod_is_deleted ? " Undo" : "Delete") +
          " this Product?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteProduct(removeConfirmationModal.wp_prod_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h1 className="text-xl font-semibold text-white">Product List</h1>
        <div className="flex">
          {allowedActions.filter((data) => data.permission_id == 28)?.length >
          0 ? (
            <Link
              onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-auto"
            >
              <PlusCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Add Product
            </Link>
          ) : null}
          {allowedActions.filter((data) => data.permission_id == 47)?.length >
            0 && prodList?.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => handleExport("xlsx")}
              type="button"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
            >
              <ArrowDownCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Download Excel
            </Link>
          ) : null}
          {allowedActions.filter((data) => data.permission_id == 48)?.length >
          0 ? (
            <label
              htmlFor="file-upload"
              className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-3 py-1 text-xs font-medium text-black shadow-sm hffff  ml-2 cursor-pointer transition duration-300 ease-in-out"
            >
              <ArrowUpCircleIcon
                className="-ml-1.5 h-5 w-5 mr-1"
                aria-hidden="true"
              />
              Upload Product
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleImport}
              />
            </label>
          ) : null}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={whop_product_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={prodList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-[#151515] shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-[#222222]  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {wp_prod_id ? "Update" : "Add"} Product
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-edgePrimaryHover hover:text-gray-200"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_name"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Product Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.prod_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Name"
                                    name="prod_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.prod_name && touched.prod_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_name.replace(
                                        "prod_name",
                                        "Product Name"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_description"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Product Desc.
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    rows="4"
                                    value={values.prod_description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Desc."
                                    name="prod_description"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-50/10 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm bg-[#222222] text-white"
                                  />
                                  {errors.prod_description &&
                                  touched.prod_description ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_description.replace(
                                        "prod_description",
                                        "Product Desc."
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md bbt bg-[#27ff10] py-2 px-4 text-sm font-medium text-black shadow-sm  focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${
                              disableSubmitButton
                                ? "bg-gray-700 hover:bg-gray-700"
                                : ""
                            }`}
                          >
                            {wp_prod_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default ProductList;
