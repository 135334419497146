import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paymentServices from "../../../ApiServices/paymentServices";
import {
    FaArrowLeft, FaUser, FaMoneyBillWave, FaCalendar, FaCreditCard, FaCheckCircle,
    FaTimesCircle, FaBuilding, FaMobileAlt, FaClock, FaListUl,
    FaUserAlt,
    FaVoicemail,
    FaPlane,
    FaFirstOrderAlt,
    FaFirstOrder,
    FaMoneyBill,
    FaMemory
} from "react-icons/fa";

const PaymentDetails = () => {
    const { trax_no, transaction_id } = useParams();
    const navigate = useNavigate();
    const [payment, setPayment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            try {
                setLoading(true);
                setError(null);
                const { data } = await paymentServices.getSingleTransactionDetails({ trax_no, transactionID: transaction_id });
                console.log("paymentServices=>", data);
                if (data?.status && data?.data) {
                    setPayment(data.data);
                } else {
                    setError("Payment details not found.");
                }
            } catch (err) {
                console.error("Error fetching payment details:", err);
                setError("Failed to fetch payment details.");
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentDetails();
    }, [trax_no]);

    if (loading) return <p className="text-center text-gray-400">Loading payment details...</p>;
    if (error) return (
        <div className="min-h-screen p-4 bg-[#000000]">
            <h4 className="text-2xl font-semibold text-white mb-3">Payment List</h4>
            <h4 className="text-md text-gray-300 mb-4">Browse all payments and their current statuses</h4>
            <p className="text-center text-red-400">{error}</p>
        </div>
    );

    const transactionResponse = payment || {};
    const customerresponse = payment.rcpqm_m_customer_relation || {};
    const orderResponse = payment.m_order?.[0] || {};
    const paymentResponse = payment.rcpqm_m_payment_relation || {};
    const planResponse = payment.rcpqm_m_payment_relation?.plan_relation || {};
    const productResponse = payment.rcpqm_m_payment_relation?.plan_relation?.product_master_relation || {};
    const licenseResponse = payment.license || {};
    const accountResponse = payment.license?.m_account_relation || {};

    return (
        <div className="min-h-screen p-6 bg-[#000000] text-white">
            <button
                className="flex items-center bg-edgePrimary hover:bg-edgePrimary text-black text-xs py-2 px-4 rounded mb-4"
                onClick={() => navigate(-1)}
            >
                <FaArrowLeft className="mr-2" /> Back to Transactions
            </button>

            <h2 className="text-3xl font-semibold mb-4">Payment Details</h2>

            {/* Transaction Information */}
            <div className="bg-[#151515] p-4 rounded-lg shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <FaListUl className="mr-2" /> Transaction Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <InfoItem icon={<FaUser />} label="Transaction ID" value={transactionResponse.trax_no} />
                    <InfoItem icon={<FaUserAlt />} label="Payment User ID" value={transactionResponse?.whop_user_id || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="Payment User" value={customerresponse?.cust_username || "N/A"} />
                    <InfoItem icon={<FaVoicemail />} label="Payment User Email" value={customerresponse?.cust_email || "N/A"} />
                    <InfoItem icon={<FaPlane />} label="Plan Code" value={transactionResponse?.quick_payment_code || "N/A"} />
                    <InfoItem icon={<FaClock />} label="Transaction Start" value={new Date(transactionResponse.trax_start_date)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-")} />
                    <InfoItem icon={<FaClock />} label="Transaction End" value={new Date(transactionResponse.trax_end_date)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-")} />
                    <InfoItem icon={<FaMoneyBillWave />} label="Amount Requested" value={`$${transactionResponse.trax_req_amt.toFixed(2)}`} />
                </div>
            </div>

            {/* Order Information */}
            <div className="bg-[#151515] p-4 rounded-lg shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <FaListUl className="mr-2" /> Order Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <InfoItem icon={<FaFirstOrder />} label="System Order ID" value={orderResponse.ord_sys_order_id} />
                    <InfoItem icon={<FaFirstOrderAlt />} label="EdgeFin Order ID" value={orderResponse?.ord_id || "N/A"} />
                    <InfoItem icon={<FaMemory />} label="Qunatity" value={orderResponse?.ord_quantity || "N/A"} />
                    <InfoItem icon={<FaMoneyBill />} label="Total Amount" value={orderResponse?.ord_total_amount || "N/A"} />
                    {/* <InfoItem icon={<FaPlane />} label="Invoice Number" value={orderResponse?.ord_invoice_number || "N/A"} /> */}
                    <InfoItem icon={<FaPlane />} label="Order Status" value={orderResponse?.ord_payment_status || "N/A"} />
                    <InfoItem icon={<FaClock />} label="Order Date" value={new Date(orderResponse.ord_created_at)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-")} />
                </div>
            </div>


            {/* Product Information */}
            <div className="bg-[#151515] p-4 rounded-lg shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <FaListUl className="mr-2" /> Product Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <InfoItem icon={<FaFirstOrder />} label="System Product ID" value={productResponse.whop_prod_id} />
                    <InfoItem icon={<FaFirstOrderAlt />} label="EdgeFin Product ID" value={productResponse?.prod_id || "N/A"} />
                    <InfoItem icon={<FaMemory />} label="Product Name" value={productResponse?.prod_title || "N/A"} />
                    <InfoItem icon={<FaClock />} label="Product Date" value={new Date(productResponse.whop_prod_created_at)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-")} />
                </div>
            </div>

            {/* Plan Information */}
            <div className="bg-[#151515] p-4 rounded-lg shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <FaListUl className="mr-2" /> Plan Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <InfoItem icon={<FaFirstOrder />} label="System Plan ID" value={planResponse.whop_plan_id} />
                    <InfoItem icon={<FaFirstOrderAlt />} label="EdgeFin Plan ID" value={planResponse?.plan_id || "N/A"} />
                    <InfoItem icon={<FaMemory />} label="Plan Type" value={planResponse?.whop_plan_type || "N/A"} />
                    <InfoItem icon={<FaMemory />} label="Method" value={planResponse?.whop_plan_release_method || "N/A"} />
                    <InfoItem icon={<FaMemory />} label="Price" value={planResponse?.whop_plan_initial_price || "N/A"} />
                    <InfoItem icon={<FaMemory />} label="Currency" value={planResponse?.whop_plan_base_currency || "N/A"} />
                    <InfoItem icon={<FaClock />} label="Plan Date" value={new Date(planResponse.whop_plan_created_at)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-")} />
                </div>
            </div>

            {/* Customer Information */}
            <div className="bg-[#151515] p-4 rounded-lg shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <FaListUl className="mr-2" /> Customer Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <InfoItem icon={<FaUser />} label="Whop Customer ID" value={customerresponse.whop_user_id} />
                    <InfoItem icon={<FaUserAlt />} label="EdgeFin Customer ID" value={customerresponse?.cust_id || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="Customer FName" value={customerresponse?.cust_fname || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="Customer LName" value={customerresponse?.cust_lname || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="Customer Username" value={customerresponse?.cust_username || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="Customer Email" value={customerresponse?.cust_email || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="Customer Mobile" value={customerresponse?.cust_mobile || "N/A"} />
                    <InfoItem icon={<FaMoneyBillWave />} label="Dipgate Client ID" value={customerresponse?.dipgate_client_id || "N/A"} />
                    <InfoItem icon={<FaMoneyBillWave />} label="Is Dipgate Client" value={customerresponse?.cust_is_dipgate ? "Yes" : "No"} />
                    <InfoItem icon={<FaPlane />} label="Gender" value={customerresponse?.cust_gender || "N/A"} />
                    <InfoItem icon={<FaClock />} label="DOB" value={customerresponse.cust_dob ? new Date(customerresponse.cust_dob)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-") : "N/A"} />
                </div>
            </div>

            {/* License Information */}
            <div className="bg-[#151515] p-4 rounded-lg shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <FaListUl className="mr-2" /> License Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <InfoItem icon={<FaUser />} label="Whop License ID" value={licenseResponse.whop_lic_mem_id} />
                    <InfoItem icon={<FaUserAlt />} label="EdgeFin License ID" value={licenseResponse?.up_id || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="License Key" value={licenseResponse?.up_license_key || "N/A"} />
                    <InfoItem icon={<FaUserAlt />} label="License Email" value={licenseResponse?.whop_lic_email || "N/A"} />
                    <InfoItem icon={<FaPlane />} label="License Status" value={licenseResponse?.whop_lic_status || "N/A"} />
                    <InfoItem icon={<FaClock />} label="License Start" value={licenseResponse.up_start_date ? new Date(licenseResponse.up_start_date)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-") : "N/A"} />
                    <InfoItem icon={<FaClock />} label="License End" value={licenseResponse.up_end_date ? new Date(licenseResponse.up_end_date)
                        .toLocaleDateString("en-GB") // "en-GB" formats as DD/MM/YYYY
                        .replace(/\//g, "-") : "N/A"} />
                </div>
            </div>


        </div>
    );
};

// Reusable Info Component
const InfoItem = ({ icon, label, value }) => (
    <div className="flex flex-col bg-[#222] p-3 rounded-lg shadow-md">
        <div className="flex items-center mb-1 text-gray-400">
            {icon} <span className="ml-2">{label}</span>
        </div>
        <p className="text-white font-semibold">{value}</p>
    </div>
);

export default PaymentDetails;
