import React, { useState } from 'react';
// import PropTypes from 'prop-types';

const Tooltip = ({children, message}) => {

  return (
    <div className="group relative flex flex-col items-center justify-center">
      {children}
      <div className="absolute left-1/2 bottom-10 ml-auto mr-auto min-w-max -translate-x-1/2 scale-0 transform rounded-lg px-3 py-2 text-xs font-medium transition-all duration-500 group-hover:scale-100">
        <div className="flex max-w-xs flex-col items-center shadow-lg">
          <div className="rounded bg-gray-800 p-2 text-center text-xs text-white">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
