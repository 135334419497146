import React from "react";

const FooterStats = ({ threeStats }) => {
    const classNames = (...classes) => classes.filter(Boolean).join(" ");

    return (
        <div className="flex items-center justify-between rounded-lg shadow-sm mb-4">
            <dl className="mt-5 mb-5 grid grid-cols-2 divide-y divide-[#393939] overflow-hidden rounded-md bg-[#222222] shadow md:grid-cols-6 md:divide-x md:divide-y-0">
                {threeStats.map((item) => (
                    <div key={item.name} className="px-4 py-5 sm:p-6">
                        
                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                            <div className={`flex items-baseline text-2xl font-semibold ${item.stat < 0 ? 'text-red-500' : 'text-[#27ff10]'}`}>
                                {item.stat || "-"}
                            </div>
                            {item.changeType !== "" && (
                                <div
                                    className={classNames(
                                        item.changeType === "increase"
                                            ? "bg-green-100 text-green-800"
                                            : "bg-red-100 text-red-800",
                                        "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                                    )}
                                >
                                    {item.changeType === "increase" ? (
                                        <svg
                                            className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5 10a1 1 0 011.707-.707l2.543 2.543V3a1 1 0 112 0v8.836l2.543-2.543A1 1 0 0115 10l-4 4a1 1 0 01-1.414 0l-4-4A1 1 0 015 10z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M15 10a1 1 0 00-1.707-.707L10.75 12.09V3a1 1 0 10-2 0v9.09L6.707 9.293A1 1 0 005 10l4 4a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    )}
                                    <span className="sr-only">
                                        {item.changeType === "increase" ? "Increased" : "Decreased"} by
                                    </span>
                                    {item.change}
                                </div>
                            )}
                        </dd>
                        <dt className="text-base font-normal text-gray-300">{item.name}</dt>
                    </div>
                ))}
            </dl>
        </div>
    );
};

export default FooterStats;
