import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, usePagination } from "react-table";
import MasterServices from "../../../ApiServices/MasterServices"; // Replace with your API service
import { toast } from "react-toast";

const TradeTable = ({ activeTab, tradeLive, tradeHistory, uid, name, accid }) => {
    const [tradeHistoryList, setTradeHistoryList] = useState([]);
    const [tradeLiveList, setTradeLiveList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch data from server
    const fetchData = useCallback(

        async ({ pageIndex }) => {
            if (!uid || !name || !accid) {
                toast.error("Missing required parameters: UID, Name, or Account ID");
                return;
            }
            setTradeLiveList([]);
            setTradeHistoryList([]);
            setIsLoading(true);

            try {
                const payload = `?uid=${uid}&name=${name}&accid=${accid}&page=${pageIndex + 1}`;
                let res;
                if (activeTab == "liveTrades") {
                    res = await MasterServices.getTradeLive(payload); // Replace with your API endpoint
                } else {
                    res = await MasterServices.getTradeHistory(payload); // Replace with your API endpoint
                }
                if (res.status) {
                    if (activeTab == "liveTrades") {
                        setTradeLiveList(res.data?.data?.trade_history || []);
                        setPageCount(res.data?.data?.trade_history_pagination?.total_pages || 0);
                    } else {
                        setTradeHistoryList(res.data?.data?.trade_history || []);
                        setPageCount(res.data?.data?.trade_history_pagination?.total_pages || 0);
                    }
                } else {
                    toast.error("Failed to fetch data. Please try again.");
                }
            } catch (error) {
                toast.error("An error occurred while fetching data.");
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [activeTab, uid, name, accid]
    );

    // Define columns based on activeTab
    const columns = useMemo(() => {
        if (activeTab === "liveTrades") {
            return [
                { Header: "Order Ticket", accessor: "lt_order_ticket" },
                { Header: "Order Time", accessor: "lt_order_time" },
                { Header: "Type", accessor: "lt_order_type" },
                { Header: "Lots", accessor: "lt_order_lots" },
                { Header: "Symbol", accessor: "lt_order_symbol" },
                { Header: "Open Price ($)", accessor: "lt_order_price" },
                { Header: "SL", accessor: "lt_order_sl" },
                { Header: "TP", accessor: "lt_order_tp" },
                { Header: "Profit", accessor: "lt_order_profit" },
                { Header: "Commission", accessor: "lt_order_comm" },
                { Header: "Swap", accessor: "lt_order_swap" },
            ];
        } else if (activeTab === "tradeHistory") {
            return [
                { Header: "Ticket ID", accessor: "mth_id" },
                { Header: "Ticket", accessor: "mth_order_ticket" },
                { Header: "Open Time", accessor: "mth_order_open_time" },
                { Header: "Type", accessor: "mth_order_type" },
                { Header: "Lots", accessor: "mth_order_lots" },
                { Header: "Symbol", accessor: "mth_order_symbol" },
                { Header: "Open Price ($)", accessor: "mth_order_open_price" },
                { Header: "Close Price ($)", accessor: "mth_order_close_price" },
                { Header: "Close Time", accessor: "mth_order_close_time" },
                { Header: "SL", accessor: "mth_order_sl" },
                { Header: "TP", accessor: "mth_order_tp" },
                { Header: "Profit", accessor: "mth_order_profit" },
                { Header: "Commission", accessor: "mth_order_comm" },
                { Header: "Swap", accessor: "mth_order_swap" },
                { Header: "Net PNL", accessor: "mth_prev_csp_sum" },
            ];
        }
        return [];
    }, [activeTab]);

    // Select data based on activeTab
    const data = useMemo(() => {
        return activeTab === "liveTrades" ? tradeLiveList?.length > 0 ? tradeLiveList : tradeLive : tradeHistoryList?.length > 0 ? tradeHistoryList : tradeHistory;
    }, [activeTab, tradeLive, tradeHistory, tradeLiveList, tradeHistoryList]);

    const gotoPage = (pageIndex) => {
        setPageNo(pageIndex)
        fetchData({ pageIndex });
    }

    // React Table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        nextPage,
        previousPage,
        pageOptions,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        usePagination
    );

    // Fetch data when page changes
    useEffect(() => {
        fetchData({ pageIndex });
    }, [fetchData, pageIndex]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Check for empty data
    if (!data || data.length === 0) {
        const noDataMessage =
            activeTab === "liveTrades"
                ? {
                    title: "No live trade data available",
                    description:
                        "No live trades are currently available. Please check your data source or try again later.",
                }
                : {
                    title: "No trade history data available",
                    description:
                        "No historical trade data is currently available. Please check your data source or try again later.",
                };

        return (
            <div className="relative min-h-60 w-full flex flex-col justify-center items-center my-6 bg-[#222222] shadow-sm border border-slate-600 rounded-lg p-4">
                <div className="p-3 text-center">
                    <div className="flex justify-center mb-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="text-slate-400 w-12 h-12"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                            />
                        </svg>
                    </div>
                    <h5 className="text-slate-200 text-2xl font-semibold">
                        {noDataMessage.title}
                    </h5>
                    <p className="block text-slate-200 leading-normal font-light mb-4 max-w-lg">
                        {noDataMessage.description}
                    </p>
                </div>
            </div>
        );
    }


    return (
        <div className="mt-4">
            <div className="overflow-x-auto">
                <table
                    {...getTableProps()}
                    className="w-full border-collapse text-xs xs:text-base rounded-lg overflow-hidden"
                >
                    {/* Table Header */}
                    <thead className="bg-gradient-to-r from-gray-800 via-gray-700 to-gray-900">
                        {headerGroups.map((headerGroup,index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="p-3 text-left text-gray-400 font-medium uppercase tracking-wide"
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {/* Table Body */}
                    <tbody
                        {...getTableBodyProps()}
                        className="bg-[#1b1b1b]"
                    >
                        {page.map((row,index) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    key={index}
                                    className="border-t border-gray-700 hover:bg-[#2a2a2a] transition duration-200"
                                >
                                    {row.cells.map((cell) => (
                                        <td
                                            {...cell.getCellProps()}
                                            className="p-3 text-gray-400 border-b border-gray-700 last:border-none"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>



            {/* Pagination Controls */}
            {/* justify-between */}


            <div className="flex items-center mt-4">
                <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className="px-3 py-1 bg-[#222222] text-gray-300 rounded-md mr-2"
                >
                    {"<"}
                </button>

                {/* Render page numbers */}
                <div className="flex flex-wrap gap-1">
                    {Array.from({ length: pageCount }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => gotoPage(index)}
                            className={`px-3 py-1 ${index === pageNo ? "bg-edgePrimary text-black" : "bg-[#222222] text-gray-300"
                                } rounded-md`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>

                <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className="px-3 py-1 bg-[#222222] text-gray-300 rounded-md ml-2"
                >
                    {">"}
                </button>
                <span className="text-xs ml-2">
                    Page <strong>{pageIndex + 1}</strong> of {pageCount}
                </span>
            </div>

        </div>
    );
};

export default TradeTable;
