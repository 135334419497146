/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {

  // Whop User Section Start
  async createWhopCheckout(payload) {
    const response = await Api().post(`api/whop-checkout`, payload);
    return response;
  },

};
